/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020, 2021
 *
 *==================================================
 */
//Standard libraries
import React from "react";
import { useSite } from "../../_foundation/hooks/useSite";



interface interProps {
  amount?: any;
  color?: any;
}
const FPRPoints: React.FC<interProps> = (props: any) => {
  const { mySite } = useSite();
  const FPR_CONVERSION_FACTOR = +mySite?.storeCfg?.userData?.FPR_CONVERSION_FACTOR || 0.005;
  const amount:any = + props?.amount || 0;
  const color = props?.color || '#112b4d';
  const floorFigure = (amountAsString, decimals = 2) => {
    amountAsString = amountAsString.toString();
    var dotIndex = amountAsString.indexOf('.');
    var toTruncate = dotIndex !== -1 && (amountAsString.length > dotIndex + decimals + 1);
    var approach = Math.pow(10, decimals);
    var amountToTruncate = toTruncate ? amountAsString.slice(0, dotIndex + decimals + 1) : amountAsString;
    return toTruncate
      ? Math.floor(parseFloat(amountToTruncate) * approach) / approach
      : parseFloat(amountAsString);
  }
  const earns:any = (Number(amount) / Number(FPR_CONVERSION_FACTOR));
  return (
    <p className="FP_PointCost" style={{
      fontWeight: 300,
      color,
      fontSize: 13
    }}>
      or redeem up to <b style={{ borderBottom: '1px dotted ' + color }}>{parseInt(earns).toLocaleString("en-US")} Points</b>
    </p>
  );
};

export default FPRPoints;
