/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React from "react";
import { Link } from "react-router-dom";
//UI
import {
  StyledPaper,
  StyledMenuTypography,
  StyledBox,
} from "@hcl-commerce-store-sdk/react-component";

interface TwoTierMenuListProps {
  page?: any;
}

/**
 * TwoTierMenu component
 * expanded menu two tier submenu
 * @param props
 */
const TwoTierMenuList: React.FC<TwoTierMenuListProps> = (props: any) => {
  const page = props.page ? props.page : [];

  return (
    <>
      <StyledPaper className="expanded-menu-two-tier-submenu ">
        <StyledBox m={2}>
          <Link
            to={{
              pathname: page.seo?.href,
              state: {
                breadCrumbTrailEntryView: [
                  { label: page.name, value: page.id, seo: page.seo },
                ],
              },
            }}>
            <StyledMenuTypography
              variant="body1"
              className="expanded-menu-bold">
              {page.name}
            </StyledMenuTypography>
          </Link>
          <ul>
            {page.children &&
              page.children.map((page2: any, i: number) => (
                <li key={page2.id}>
                  <Link
                    to={{
                      pathname: page2.seo?.href,
                      state: {
                        breadCrumbTrailEntryView: [
                          { label: page.name, value: page.id, seo: page.seo },
                          {
                            label: page2.name,
                            value: page2.id,
                            seo: page2.seo,
                          },
                        ],
                      },
                    }}>
                    <StyledMenuTypography
                      variant="body2"
                      className="expanded-menu-sub-links">
                      {page2.name}
                    </StyledMenuTypography>
                  </Link>
                </li>
              ))}
          </ul>
        </StyledBox>
      </StyledPaper>
    </>
  );
};

export default TwoTierMenuList;
