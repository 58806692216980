import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import jsonData from "../staticPagesMetaData.json";
import { useSite } from "../../../../_foundation/hooks/useSite";
import { Helmet } from "react-helmet";

const content =jsonData.contents[10]?.BestFordRangerAccessories;

const handleShareClick = (event: React.MouseEvent<HTMLSpanElement>) => {
  const url = event.currentTarget.dataset.copyToClipboard!;
  navigator.clipboard
    .writeText(url)
    .then(() => {
      const confirmation = window.prompt(
        "Copy to clipboard: Ctrl/Cmd+C, Enter",
        url
      );
      if (confirmation) {
        navigator.clipboard.writeText(url);
      }
    })
    .catch(() => {
      window.alert(`Failed to copy link to clipboard: ${url}`);
    });
};

const breadcrumbList = {
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  itemListElement: [
    {
      "@type": "ListItem",
      position: 1,
      name: "Home",
      item: "https://accessories.ford.com/",
    },
    {
      "@type": "ListItem",
      position: 2,
      name: "News",
      item: "https://accessories.ford.com/blogs/news",
    },
    {
      "@type": "ListItem",
      position: 3,
      name: "Best Ford Ranger Accessories",
      item: "https://accessories.ford.com/blogs/news/best-ford-ranger-accessories",
    },
  ],
};

const BestFordRangerAccessories = () => {
  const {storeDisplayName } = useSite();
  let port = !window.location.port.trim() ? '' : ':' + window.location.port;
  return (
    <div>
       <Helmet>
          <title>{content?.title}</title>
          <meta name="description" content={content?.metaDescription}></meta>
          <meta name="keywords" content={content?.metaKeyword}/>
          <meta property="og:site_name" content={storeDisplayName}/>
          <meta property="og:url" content={(window.location.protocol +'//'+window.location.hostname+':' + port + window.location.pathname)}/>
          <meta property="og:title" content={content?.title}/>
          <meta property="og:type" content="article"/>  
          <meta property="og:description" content={content?.metaDescription}/>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={content?.title}/>  
          <meta name="twitter:description" content={content?.metaDescription} />
        </Helmet>
      <div id="shopify-section-template-article" className="shopify-section">
        <section 
          title="Blog Hero"
          className="article-hero "
          style={{
            backgroundImage:
              "url(https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BestFordRangerAccessories/ranger_1500x.jpeg)",
          }}>
          <div className="article-hero__container container">
            <h1 className="article-hero__heading title--md">
              Best Ford Ranger Accessories
            </h1>

            <div className="article-hero__publish-date">February 01, 2023</div>
          </div>
        </section>

        <div className="article__main">
          <nav className="breadcrumbs" role="navigation">
            <ul className="flex">
              <li>
              <a href="/">
              <span>Home</span>
              </a>
                <span className="breadcrumbs-separator">&gt;</span>
              </li>
              <li>
                <Link to="/blogs/news">
                  <span>News</span>
                </Link>
              </li>
              <span className="breadcrumbs-separator">&gt;</span>
              <li>
                <span>Best Ford Ranger Accessories</span>
              </li>
            </ul>
          </nav>
          <script type="application/ld+json">
            {JSON.stringify(breadcrumbList)}
          </script>

          <div className="article-actions">
            <Link className="article-actions__return" to="/blogs/news">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16">
                <g>
                  <path
                    fill="#102b4e"
                    d="M8,0 C12.418,0 16,3.582 16,8 C16,12.418 12.418,16 8,16 C3.582,16 0,12.418 0,8 C0,3.582 3.582,0 8,0 Z M6,3 C5.724,3 5.474,3.112 5.293,3.293 C5.112,3.474 5,3.724 5,4 C5,4.276 5.112,4.526 5.293,4.707 L9.409,8 L5.293,11.293 C5.112,11.474 5,11.724 5,12 C5,12.276 5.112,12.526 5.293,12.707 C5.474,12.888 5.724,13 6,13 C6.276,13 6.526,12.888 6.707,12.707 L11.707,8.707 C11.888,8.526 12,8.276 12,8 C12,7.724 11.888,7.474 11.707,7.293 L6.707,3.293 C6.526,3.112 6.276,3 6,3 Z"></path>
                </g>
              </svg>
              Back to articles
            </Link>

            <div
              className="social-icons "
              itemScope
              itemType="https://schema.org/Organization">
              <link itemProp="url" href="https://accessories.ford.com" />

              <h3 className="social-icons__share-title">Share</h3>

              <div className="social-icons__list-item">
                <a
                  href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/best-ford-ranger-accessories&amp;text=Best+Ford+Ranger+Accessories"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="16"
                    viewBox="0 0 20 16">
                    <g>
                      <g>
                        <path
                          fill="#102b4e"
                          d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Twitter</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/best-ford-ranger-accessories"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M.29 5.24h3.2v10.764H.29z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">LinkedIn</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/best-ford-ranger-accessories"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="16"
                    viewBox="0 0 8 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Facebook</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <span
                  className="share"
                  onClick={handleShareClick}
                  data-copy-to-clipboard="https://accessories.ford.com/blogs/news/best-ford-ranger-accessories">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Share URL</span>
                </span>
              </div>
            </div>
          </div>

          <article className="article-content rte">
            <h1>
              <b></b>
            </h1>
            <p>
              <span style={{fontWeight: 400}}>
                The Ford Ranger is the ultimate mid-size pickup and serves as an
                ideal companion for any outdoor adventure. With 2-door and
                4-door options, the Ford Ranger is a versatile workhorse
                designed to pull heavy loads, conquer tough terrain and provide
                excellent comfort with ample space. Although not the biggest
                truck in the Ford vehicle lineup, the Ranger is still fully
                capable of handling whatever you need with a 7,500lb towing
                capacity, when equipped with the available Trailer Tow package,
                and a multitude of add-on accessory options available. The
                Ranger came back into production in North America in 2019 and
                has been a pivotal staple of the Ford brand ever since. Check
                out this guide to the best Ford Ranger accessories you can add
                to your truck to increase performance and make it your own.
              </span>
              &nbsp;
            </p>
            <p>
              <span style={{fontWeight: 400}}>
                Are you looking to add something new to your Ranger? Dive into
                the list below:&nbsp;
              </span>
            </p>
            <p>&nbsp;</p>
            <h2>
              <Link to="/search?searchTerm=ranger+tonneau+cover">
                <span style={{fontWeight: 400}}>
                  Tonneau Cover
                  <br />
                </span>
              </Link>
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BestFordRangerAccessories/Ranger_TonneauCover.jpeg"
                alt="Ranger Tonneau Cover" width={'1480'} height={'100%'}
              />
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                Keep your essentials and other gear protected by adding a
                tonneau cover to your Ranger. A tonneau cover is a hard or soft
                cover that shields the bed of your Ranger from the elements. We
                offer a variety of tonneau cover styles for your pickup truck,
                from folding retractable to hard plastic, and even painted to
                match the color of your vehicle’s body paint. These covers help
                keep your essentials hidden and out of sight.&nbsp;
              </span>
            </p>
            <br />
            <h2>
              <Link to="/ranger-2006-2011-side-window-air-deflectors-1">
                <span style={{fontWeight: 400}}>
                  Side Window Air Deflectors
                  <br />
                </span>
              </Link>
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BestFordRangerAccessories/Ranger_AirDeflectors.jpeg"
                alt="Ranger Air Deflectors" width={'1024'} height={'100%'}
              />
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                Let fresh air into your car with side window air deflectors!
                These easy-to-install, plastic deflectors conveniently attach to
                your windows with self-stick adhesive, so you can spend more
                time out on the road. Deflectors keep out snow or rain so you
                can crack open your windows and get some fresh air when
                experiencing inclement weather.
              </span>
            </p>
            <p>&nbsp;</p>
            <h2>
              <Link to="/search?searchTerm=ranger+bed+slide">
                <span style={{fontWeight: 400}}>Bed Slide</span>
              </Link>
            </h2>
            <p>
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BestFordRangerAccessories/Ranger_BedSlide.jpeg"
                alt="Ranger Bed Slide" width={'1480'} height={'100%'}
              />
            </p>
            <p>
              <span style={{fontWeight: 400}}>
                Tired of climbing into your truck bed to reach items that
                shifted during travel? By installing a bed slide, you can turn
                the bed of your truck into an easy-access drawer that slides to
                load and unload your gear for less lifting. Our bed slide
                product comes with D-ring tie-downs and guard rails to allow for
                easy organization and secure hauling. It is also made from
                heavy-duty and weather-resistant material to handle the job you
                need in virtually all kinds of weather.
              </span>
            </p>
            <p>&nbsp;</p>
            <h2>
              <Link to="/search?searchTerm=ranger+bed+liner">
                <span style={{fontWeight: 400}}>Bed Liner</span>
              </Link>
            </h2>
            <p>
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BestFordRangerAccessories/Ranger_BedLiner.jpeg"
                alt="Ranger Bed Liner" width={'1480'} height={'100%'}
              />
            </p>
            <p>
              <span style={{fontWeight: 400}}>
                Keep your Ranger’s truck bed clean and protected with a bed
                liner! Depending on your choice of material, this product
                provides easy clean-up when hauling messy materials. Our bed
                liners come in three distinct formats for the ultimate
                customization. Carpeted bed liners provide a cushion. Bed mats
                made of spill-resistant rubber allow for easy clean-up and
                traction. Bed trays are plastic inserts that help protect your
                truck bed from the elements. Protect the bed of your Ford Ranger
                by using one of our bed liner, mat, or tray products!
              </span>
            </p>
            <p>&nbsp;</p>
            <h2>
              <Link to="/search?searchTerm=ranger+roof+rack+yakima">
                <span style={{fontWeight: 400}}>Roof Rack</span>
              </Link>
            </h2>
            <p>
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BestFordRangerAccessories/Ranger_RoofRack.jpeg"
                alt="Ranger Roof Rack" width={'1480'} height={'100%'}
              />
            </p>
            <p>
              <span style={{fontWeight: 400}}>
                Finding space to pack everything you (and your travel party)
                need for a road trip is often a difficult task, even with the
                added storage of a truck bed. Additionally, larger items like
                kayaks and bicycles may not fit in the bed of your Ranger,
                especially if it’s covered. Adding a roof rack to the top of
                your vehicle not only lets you bring these oversized items along
                on your trip but also gives you extra room in your truck bed for
                other items. Make the most of your Ranger adventures with a roof
                rack and the plethora of additional accessories that can attach
                to it!
              </span>
            </p>
            <p>&nbsp;</p>
            <h2>
              <Link to="/search?searchTerm=ranger+step+bars">
                <span style={{fontWeight: 400}}>Step Bars</span>
              </Link>
            </h2>
            <p>
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BestFordRangerAccessories/Ranger_StepBars.jpeg"
                alt="Ranger Step Bars" width={'1480'} height={'100%'}
              />
            </p>
            <p>
              <span style={{fontWeight: 400}}>
                Depending on tire size and how high your Ranger is off the
                ground, it can be challenging to get in and out of. Step bars
                are a convenient solution for easier access in and out of your
                vehicle. They can also aid in preventing damage from rocks when
                off-roading, while instantly upgrading the look of your truck
                with sleek, high-quality finishes.
              </span>
            </p>
            <br />
            <h2>
              <Link to="/ranger-off-road-air-snorkel">
                <span style={{fontWeight: 400}}>Off-Road Air Snorkel</span>
              </Link>
            </h2>
            <p>
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BestFordRangerAccessories/Ranger_AirSnorkel.jpeg"
                alt="Ranger Air Snorkel" width={'1480'} height={'100%'}
              />
            </p>
            <p>
              <span style={{fontWeight: 400}}>
                Before off-roading in your vehicle, it is important to ensure
                your Ranger is in good shape and prepared for anything it may
                encounter along the way. Whether you’re planning on water
                fording or blasting through dusty terrains, our off-road air
                snorkel helps protect your engine during your adventure. The air
                snorkel delivers clean air to your engine from a higher source
                and helps protect it from collecting dust and debris. This
                allows for your engine to run smoother, lowering your
                maintenance needs between trips.&nbsp;
              </span>
            </p>
            <br />
            <h2>
              <Link to="/2019-2020-ranger-ecoboost-power-pack-performance-calibration">
                <span style={{fontWeight: 400}}>
                  EcoBoost Power Pack Performance Calibration
                </span>
              </Link>
            </h2>
            <p>
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BestFordRangerAccessories/ecoboost_power_pack.jpeg"
                alt="Ecoboost power pack" width={'1480'} height={'100%'}
              />
            </p>
            <p>
              <span style={{fontWeight: 400}}>
                Maximize your Ford Ranger’s potential with EcoBoost Power Pack
                Calibration. Designed to elevate your engine’s performance, this
                power pack adds on 45 horsepower and a torque gain of 60 lb.-ft
                to your ride. Better yet, our calibration is Ford Performance
                engineered and dyno tested, giving you improved drivability and
                throttle response that you can count on.
              </span>
            </p>
            <br />
            <h2>
              <Link to="/2019-2021-ranger-tow-hooks-pair-blue">
                <span style={{fontWeight: 400}}>Tow Hooks</span>
              </Link>
            </h2>
            <p>
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BestFordRangerAccessories/Ranger_TowHooks.jpeg"
                alt="Ranger Two hooks" width={'1480'} height={'100%'}
              />
            </p>
            <p>
              <span style={{fontWeight: 400}}>
                It’s better to be safe than sorry, especially when it comes to
                your Ranger. As a recovery plan for off-roading mishaps, add tow
                hooks to the front of your vehicle to ensure a superior tow.
                Additionally, our tow hooks are available in both{" "}
              </span>
              <Link to="/2019-2021-ranger-tow-hooks-pair-red">
                <span style={{fontWeight: 400}}>red</span>
              </Link>
              <span style={{fontWeight: 400}}> and </span>
              <a href="/2019-2021-ranger-tow-hooks-pair-blue?refSrc=6824073986239&amp;nosto=productpage-nosto-1-fallback-nosto-1">
                <span style={{fontWeight: 400}}>blue</span>
              </a>
              <span style={{fontWeight: 400}}>
                {" "}
                to add even more personalization to your driving experience!
              </span>
            </p>
            <br />
            <h2>
              <span style={{fontWeight: 400}}>
                Additional Ranger Accessories
              </span>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                Are you looking for more specific additions to your Ranger’s
                appearance and outdoor functionality? We’ve put together a list
                of more of the best accessories for the Ford Ranger that let you
                further personalize your truck.
              </span>
            </p>
            <ul>
              <li style={{fontWeight: 400}}>
                <Link to="/search?searchTerm=ranger+sport+bar">
                  <b>Sports Bar</b>
                </Link>
                <span style={{fontWeight: 400}}>
                  {" "}
                  - Enhance the appearance of your vehicle with a sports bar. We
                  also offer branded options so you can represent your Ranger in
                  style!
                </span>
              </li>
              <li style={{fontWeight: 400}}>
                <Link to="/ranger-2019-2021-lund-bed-extender-1">
                  <b>Bed Extender</b>
                </Link>{" "}
                <span style={{fontWeight: 400}}>
                  - Increase the space in your bed by using a bed extender. With
                  this accessory, you can haul extra or longer items that would
                  normally go past your tailgate.&nbsp;&nbsp;
                </span>
              </li>
              <li style={{fontWeight: 400}}>
                <Link to="/search?searchTerm=ranger+bed+tent+">
                  <b>Truck Bed Tent</b>
                </Link>
                <span style={{fontWeight: 400}}>
                  {" "}
                  - Taking your Ranger on a camping excursion? Bring a truck bed
                  tent to camp in the comfort of your Ranger truck bed.
                </span>
              </li>
              <li style={{fontWeight: 400}}>
                <Link to="/ranger-tremor-running-boards">
                  <b>Ranger Tremor Running Boards</b>
                </Link>
                <span style={{fontWeight: 400}}>
                  {" "}
                  - Facilitate the exiting and entering of your Ranger with
                  specialized off-road running boards. With a sleek matte black
                  finish, these running boards elevate both the style and
                  utility of your ride.&nbsp;
                </span>
              </li>
            </ul>
            <br />
            <h2>
              <span style={{fontWeight: 400}}>Built For Adventure</span>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                Ready to customize your Ranger and improve its performance with
                a variety of different accessories? Trusted Ford vehicle
                accessory products allow you to personalize your vehicle with
                features that add functionality with the power and expertise of
                Ford. Pair a roof rack and off-road air snorkel together for a
                successful off-roading adventure, or install your tonneau cover
                and bed slide for a worry-free day on the job. Equip your Ranger
                to best suit your needs today with our wide selection of Ford
                Ranger accessories!&nbsp;
              </span>
            </p>
          </article>

          <div className="article-share">
            <div
              className="social-icons "
              itemScope
              itemType="https://schema.org/Organization">
              <link itemProp="url" href="https://accessories.ford.com" />

              <h3 className="social-icons__share-title">Share Article</h3>

              <div className="social-icons__list-item">
                <a
                  href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/best-ford-ranger-accessories&amp;text=Best+Ford+Ranger+Accessories"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="16"
                    viewBox="0 0 20 16">
                    <g>
                      <g>
                        <path
                          fill="#102b4e"
                          d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Twitter</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/best-ford-ranger-accessories"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M.29 5.24h3.2v10.764H.29z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">LinkedIn</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/best-ford-ranger-accessories"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="16"
                    viewBox="0 0 8 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Facebook</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <span
                  className="share"
                  onClick={handleShareClick}
                  data-copy-to-clipboard="https://accessories.ford.com/blogs/news/best-ford-ranger-accessories">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Share URL</span>
                </span>
              </div>
            </div>
          </div>

          <div className="article-pagination article-pagination--desktop">
            <Link
              className="article-pagination__item article-pagination__item--previous"
              to="/blogs/news/8-must-have-ford-f-150-accessories"  onClick={() => window.scrollTo(0, 0)}>
              <div className="article-pagination__arrow">
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/chevron-thin-white-right_60x.jpeg"
                  alt="Chevron White" width={'32'} height={'62'}
                />
              </div>
              <div className="article-pagination__data">
                <div className="article-pagination__label">
                  Previous Article
                </div>
                <div className="article-pagination__title">
                  8 Must-Have Ford F-150 Accessories
                </div>
              </div>
            </Link>

            <Link
              className="article-pagination__item article-pagination__item--next"
              to="/blogs/news/9-best-ford-bronco-accessories"  onClick={() => window.scrollTo(0, 0)}>
              <div className="article-pagination__data">
                <div className="article-pagination__label">Next Article</div>
                <div className="article-pagination__title">
                  9 Best Ford Bronco Accessories
                </div>
              </div>
              <div className="article-pagination__arrow">
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/chevron-thin-white-right_60x.jpeg"
                  alt="Chevron White" width={'32'} height={'62'}
                />
              </div>
            </Link>
          </div>

          <div className="article-pagination article-pagination--mobile">
            <Link
              className="article-pagination__item article-pagination__item--previous"
              to="/blogs/news/8-must-have-ford-f-150-accessories"  onClick={() => window.scrollTo(0, 0)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="15"
                viewBox="0 0 8 15">
                <g>
                  <g transform="rotate(-270 4 7.5)">
                    <path
                      fill="#fff"
                      d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z"></path>
                  </g>
                </g>
              </svg>
              Prev Article
            </Link>

            <Link
              className="article-pagination__item article-pagination__item--next"
              to="/blogs/news/9-best-ford-bronco-accessories"  onClick={() => window.scrollTo(0, 0)}>
              Next Article
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="15"
                viewBox="0 0 8 15">
                <g>
                  <g transform="rotate(-270 4 7.5)">
                    <path
                      fill="#fff"
                      d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z"></path>
                  </g>
                </g>
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BestFordRangerAccessories;
