/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */
//Standard libraries
import React, {
  useState,
  useEffect,
  MouseEvent,
  ChangeEvent,
  KeyboardEvent,
  useMemo,
  useCallback,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import Axios, { Canceler } from "axios";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import getDisplayName from "react-display-name";
//Foundation libraries
import { useSite } from "../../_foundation/hooks/useSite";
import assignedPromotionCode from "../../_foundation/apis/transaction/assignedPromotionCode.service";
import { localStorageUtil } from "../../_foundation/utils/storageUtil";
import { ACCOUNT, SELECTED_PROFILE } from "../../_foundation/constants/common";
//Custom libraries
import { CHECKOUT, CHECKOUT_REVIEW } from "../../constants/routes";
import {
  RECURRING_ORDER_OPTIONS,
  RESOURCE_NAME_CART,
} from "../../constants/order";
import {
  INVENTORY,
  CommerceEnvironment,
  KEY_CODES,
  ORDER_ID,
  HYPHEN,
  EMPTY_STRING,
} from "../../constants/common";
import {
  cartSelector,
  orderItemsSelector,
  isCheckoutDisabledSelector,
  isRecurringOrderDisabledSelector,
  isFetchingSelector,
} from "../../redux/selectors/order";
import {
  forUserIdSelector,
  loginStatusSelector,
} from "../../redux/selectors/user";
import { currentContractIdSelector } from "../../redux/selectors/contract";

import {
  enUS,
  fr,
  de,
  it,
  es,
  ptBR,
  zhCN,
  zhTW,
  ja,
  ru,
  ro,
} from "date-fns/locale";
//GA360
import cartService from "../apis/transaction/cart.service";
import personContactService from "../apis/transaction/personContact.service";
import * as accountActions from "../../redux/actions/account";
import * as orderActions from "../../redux/actions/order";
import { find } from "lodash-es";
import axios from "axios";
import shippingInfoService from "../../_foundation/apis/transaction/shippingInfo.service";
import { useShipping } from "../../_foundation/hooks/use-shipping";
import { ESLint } from "eslint";
import { setActionforRedeemAPIError } from '../../redux/actions/payment-common-action';
import { __APIERROR } from '../../redux/action-types/payment-constant';
import AdobeService from "../adobeServices/adobeService";
// import { useSite } from "../../_foundation/hooks/useSite";
/**
 * Shopping cart component
 * displays order item table, order total summary and promo code section
 * @param props
 */
export const useCart = () => {
  const forUserId = useSelector(forUserIdSelector);
  const widgetName = getDisplayName("CartWidget");
  const contractId = useSelector(currentContractIdSelector);
  let cart = useSelector(cartSelector);
  const orderItems = useSelector(orderItemsSelector);
  const isCheckoutDisabled = useSelector(isCheckoutDisabledSelector);
  const [isRecurringOrder, setIsRecurringOrder] = useState<boolean>(false);
  const [recurringOrderFrequency, setRecurringOrderFrequency] =
    useState<string>("0");
  const [recurringOrderStartDate, setRecurringOrderStartDate] = useState<Date>(
    () => new Date()
  );
  const isRecurringOrderDisabled = useSelector(
    isRecurringOrderDisabledSelector
  );
  const recurringOrderDetails = useMemo(
    () =>
      localStorageUtil.get(
        ACCOUNT + HYPHEN + ORDER_ID + HYPHEN + cart?.orderId
      ),
    [cart]
  );
  const [selectedProfile, setSelectedProfile] = useState<string>(
    localStorageUtil.get(SELECTED_PROFILE) ?? EMPTY_STRING
  );

  let isFetching = useSelector(isFetchingSelector);
  const loginStatus = useSelector(loginStatusSelector);

  const [promoCode, setPromoCode] = useState<string>(EMPTY_STRING);
  const [promoCodeError, setPromoCodeError] = useState<boolean>(false);
  const [recurringOrderStartDateError, setRecurringOrderStartDateError] =
    useState<boolean>(false);

  const selectedPromoCodes: any[] = cart
    ? cart.promotionCode
      ? cart.promotionCode
      : []
    : [];
  const isCartLocked = (): boolean => {
    if (forUserId) {
      return false;
    } else {
      return cart
        ? cart.locked === "true" || cart.locked === true
          ? true
          : false
        : false;
    }
  };
  const frequencyOptions = RECURRING_ORDER_OPTIONS;
  const hasDiscounts = cart && cart.adjustment ? true : false;

  const dispatch = useDispatch();
  const history = useHistory();
  const { i18n } = useTranslation();
  const { mySite, storeDisplayName } = useSite();
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = useMemo(() => [], []);

  const defaultCurrencyID: string = mySite ? mySite.defaultCurrencyID : "";
  const checkInventory: boolean = mySite
    ? mySite.inventorySystem === INVENTORY.NON_ATP
    : false;
  const isRecurringOrderFeatureEnabled = mySite?.isB2B && loginStatus;
  const locale: string = i18n.languages[0].split("-").join("_");
  const localeList = [enUS, fr, de, it, es, ptBR, zhCN, zhTW, ja, ru, ro];
  const localeMap = initLocaleMap();

  const payloadBase: any = {
    currency: defaultCurrencyID,
    contractId: contractId,
    checkInventory: checkInventory,
    widget: widgetName,
  };
  const [isOnlyDealerShipPickup, setOnlyDealerShipPickup] = useState<boolean>(false);
  const [isAllowBoth, setAllowBoth] = useState<boolean>(false);
  const [isOnlyShipping, setIOnlyShipping] = useState<boolean>(false);
  const [isCheckoutError, setIsCheckoutError] = useState<boolean>(false);
  useEffect(() => {
    const orderExtendAttribute = cart?.orderExtendAttribute;
    // const orderExtendAttribute = [
    //   {
    //     "attributeValue": "true",
    //     "attributeType": "shipping",
    //     "attributeName": "isSDSShipBasket"
    //   },
    //   {
    //     "attributeValue": "false",
    //     "attributeType": "shipping",
    //     "attributeName": "isShipPickupOnly"
    //   },
    //   {
    //     "attributeValue": "true",
    //     "attributeType": "shipping",
    //     "attributeName": "isPOBasket"
    //   },
    //   {
    //     "attributeValue": "FS_FE",
    //     "attributeType": "shipping",
    //     "attributeName": "calculatedOrdShipping"
    //   }
    // ];

    if (orderExtendAttribute && orderExtendAttribute?.length > 0) {
      const isSDSShipBasket = find(orderExtendAttribute, (item) => (item?.attributeName === 'isSDSShipBasket' && item?.attributeValue === "true"));
      const isPOBasket = find(orderExtendAttribute, (item) => (item?.attributeName === 'isPOBasket' && item?.attributeValue === "true"));
      setIsCheckoutError(false);
      if (!isSDSShipBasket && isPOBasket) {
        setOnlyDealerShipPickup(true);
        setAllowBoth(false);
        dispatch(orderActions.CHECKOUT_BTN_ACTION({
          deliveryInstructionBtn: false,
          isCheckoutBtn: false
        }));
      } else if (!isSDSShipBasket && !isPOBasket) {
        setAllowBoth(true);
        setOnlyDealerShipPickup(false);
        dispatch(orderActions.CHECKOUT_BTN_ACTION({
          deliveryInstructionBtn: true,
          isCheckoutBtn: true
        }));
      }
      else if (isSDSShipBasket && !isPOBasket) {
        setAllowBoth(false);
        setIOnlyShipping(true);
        setOnlyDealerShipPickup(false);
        dispatch(orderActions.CHECKOUT_BTN_ACTION({
          deliveryInstructionBtn: true,
          isCheckoutBtn: true
        }));
      }
      else if (isSDSShipBasket && isPOBasket) {
        setIsCheckoutError(true);
        dispatch(orderActions.CHECKOUT_BTN_ACTION({
          deliveryInstructionBtn: false,
          isCheckoutBtn: false
        }));

      }
    }
  }, [cart?.orderExtendAttribute]);



  // useEffect(() => {
  //   if (recurringOrderDetails && recurringOrderDetails.length === 3) {
  //     setIsRecurringOrder(recurringOrderDetails[0]);
  //     setRecurringOrderFrequency(recurringOrderDetails[1]);
  //     setRecurringOrderStartDate(recurringOrderDetails[2]);
  //   }
  // }, [recurringOrderDetails]);

  // useEffect(() => {
  //   if (mySite && contractId && defaultCurrencyID) {
  //     if (cart && cart.resourceName !== RESOURCE_NAME_CART) {
  //       const cartPayload: any = {
  //         orderId: cart?.orderId,
  //         body: {
  //           orderId: cart?.orderId,
  //         },
  //         ...payloadBase,
  //       };
  //       // no matter whehter setPendingorder success or not we are working on current cart.
  //       dispatch(orderActions.RESET_ACTIVE_INPROGRESS_ORDER_ACTION());
  //       cartService
  //         .setPendingOrder(cartPayload)
  //         .catch((e) => {
  //           console.log("Could not set pending order ", e);
  //         })
  //         .finally(() => {
  //           let payload = {
  //             ...payloadBase,
  //             fetchCatentries: true,
  //             cancelToken: new CancelToken(function executor(c) {
  //               cancels.push(c);
  //             }),
  //           };
  //           dispatch(orderActions.FETCHING_CART_ACTION({ ...payload }));
  //         });
  //     } else {
  //       let payload = {
  //         ...payloadBase,
  //         fetchCatentries: true,
  //         cancelToken: new CancelToken(function executor(c) {
  //           cancels.push(c);
  //         }),
  //       };
  //       dispatch(orderActions.FETCHING_CART_ACTION({ ...payload }));
  //     }
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [mySite, contractId, defaultCurrencyID]);

  //GA360
  // React.useEffect(() => {
  //   if (mySite.enableGA) {
  //     AsyncCall.sendCartPageViewEvent(
  //       { pageTitle: storeDisplayName },
  //       { enableUA: mySite.enableUA, enableGA4: mySite.enableGA4 }
  //     );
  //     AsyncCall.sendViewCartEvent(
  //       { cart, orderItems },
  //       { enableUA: mySite.enableUA, enableGA4: mySite.enableGA4 }
  //     );
  //   }
  //   return () => {
  //     cancels.forEach((cancel) => cancel());
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  function initLocaleMap() {
    let localeMap = {};
    for (let i in CommerceEnvironment.reverseLanguageMapForDateFns) {
      const dateFnCode = CommerceEnvironment.reverseLanguageMapForDateFns[i];

      for (let j = 0; j < localeList.length; j++) {
        if (localeList[j].code === dateFnCode) {
          localeMap[i] = localeList[j];
        }
      }
    }
    return localeMap;
  }

  /**
   * Reset promo code error if it was true
   */
  function resetPromoCodeError() {
    if (promoCodeError === true) {
      setPromoCodeError(false);
    }
  }
  const applyPromotionCodeForRedeem = (redeemCode, setShowLoader) => {
    const code = redeemCode.trim();
    setShowLoader(true);
    if (code !== "") {
      let payload = {
        ...payloadBase,
        promoCode: code.toUpperCase(),
      };
      const body = {
        body: { ...payload },
        cancelToken: new CancelToken(function executor(c) {
          cancels.push(c);
        }),
      };

      if (payload?.widget) {
        body["widget"] = payload.widget;
      }

      assignedPromotionCode
        .applyPromotioncode(body)
        .then((res) => {
          let payload = {
            ...payloadBase,
            fetchCatentries: true,
            cancelToken: new CancelToken(function executor(c) {
              cancels.push(c);
            }),
          };
          dispatch(setActionforRedeemAPIError({ isFlag: false }));
          dispatch(orderActions.FETCHING_CART_ACTION({ ...payload }));
          setPromoCodeError(false);
          setPromoCode("");
          setShowLoader(false);
        })
        .catch((e) => {
          const ERROR = e?.response?.data?.errors[0]?.errorCode;
          if (ERROR === __APIERROR) {
            dispatch(setActionforRedeemAPIError({ isFlag: true }));
          }
          console.log("Could not add promo code");
          setPromoCodeError(true);
          setShowLoader(false);
        });
    } else {
      setPromoCodeError(true);
      setShowLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  const applyPromotionCode = useCallback((setShowLoader) => {
    const code = promoCode.trim();

    //Adobe Tag Manager
    (async () => {
      let res = await AdobeService.track({
        /* pageName: `collection:promo landing:${code.toUpperCase()}`,
        variantName: "global-nameplate-load",
        siteSection: "collection landing",
        hierarchy: "collection:promo landing",
        eventType: "promo landing", */
        pageName: 'cart:checkout:landing page',
        variantName: "global-onclick",
        siteSection: "checkout",
        hierarchy: "cart:checkout:checkout",
        onClickName: 'fa:checkout:content action:apply discount code',
        onClickLinkName: 'fa:cart:checkout:landing page:content action',
        promoCode: code.toUpperCase(),
        totalDiscounts: 0,
        eventType: "globalOnclickDiscountApply",
      });
      AdobeService.callSatellite(res);
    })();

    if (code !== "") {
      let payload = {
        ...payloadBase,
        promoCode: code.toUpperCase(),
      };
      const body = {
        body: { ...payload },
        cancelToken: new CancelToken(function executor(c) {
          cancels.push(c);
        }),
      };

      if (payload?.widget) {
        body["widget"] = payload.widget;
      }

      assignedPromotionCode
        .applyPromotioncode(body)
        .then((res) => {
          let payload = {
            ...payloadBase,
            fetchCatentries: true,
            cancelToken: new CancelToken(function executor(c) {
              cancels.push(c);
            }),
          };
          dispatch(setActionforRedeemAPIError({ isFlag: false }));
          dispatch(orderActions.FETCHING_CART_ACTION({ ...payload }));
          setPromoCodeError(false);
          setPromoCode("");
          setShowLoader(false);
        })
        .catch((e) => {
          const ERROR = e?.response?.data?.errors[0]?.errorCode;
          if (ERROR === __APIERROR) {
            dispatch(setActionforRedeemAPIError({ isFlag: true }));
          }
          console.log("Could not add promo code");
          setShowLoader(false);
          setPromoCodeError(true);
        });
    } else {
      setPromoCodeError(true);
      setShowLoader(false);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoCode]);

  function applyPromoCodeBasedOnKey(e: KeyboardEvent<HTMLAnchorElement>, setShowLoader) {
    if (e.keyCode === KEY_CODES.ENTER) {
      applyPromotionCode(setShowLoader);
    }
  }

  /**
   * Update promotion code state upon input change
   */
  function onPromoCodeChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const newPromoCode = event.target.value;
    setPromoCode(newPromoCode?.toUpperCase());
  }

  /**
   * Apply promo code to cart if code is not empty
   */
  function applyPromoCode(event: MouseEvent<HTMLAnchorElement>, setShowLoader) {
    setShowLoader(true);
    event.preventDefault();
    applyPromotionCode(setShowLoader);
  }

  /**
   * Remove applied promo code from cart
   */
  const onPromoCodeRemove = useCallback((code: string) => {
    if (code !== "") {
      const parameters = {
        ...payloadBase,
        promoCode: code?.toUpperCase(),
        cancelToken: new CancelToken(function executor(c) {
          cancels.push(c);
        }),
      };

      if (payloadBase?.widget) {
        parameters["widget"] = payloadBase.widget;
      }

      assignedPromotionCode
        .removePromotionCode({ ...parameters })
        .then((res) => {
          let payload = {
            ...payloadBase,
            cancelToken: new CancelToken(function executor(c) {
              cancels.push(c);
            })
          }
          if (code === 'FORD_REWARDS_DISCOUNT') {
            cartService?.getCart({}).then((res) => {
              let oItems = res?.data?.orderItem?.length > 0 ? res?.data?.orderItem : [];
              payload = {
                ...payload,
                orderItem: oItems.map(({ orderItemId }) => { return { orderItemId } }),
                orderExtendAttribute: [{
                  "attributeName": "FPR_AMOUNT",
                  "attributeType": "Amount",
                  "attributeValue": "0"
                },
                {
                  "attributeName": "FPR_POINT",
                  "attributeType": "Points",
                  "attributeValue": "0"
                }
                ]
              };
              dispatch(orderActions.APPLY_REDEEM_ACTION(payload));
            });
          }
          payload = {
            ...payload,
            fetchCatentries: true
          };

          dispatch(orderActions.FETCHING_CART_ACTION({ ...payload }));
          dispatch(setActionforRedeemAPIError({ isFlag: false }));

        })
        .catch((e) => {
          const ERROR = e?.response?.data?.errors[0]?.errorCode;
          if (ERROR === __APIERROR) {
            dispatch(setActionforRedeemAPIError({ isFlag: true }));
          }
          console.log("Could not remove promo code");
        });
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  /**
   * Handle recurring order start date change
   */
  function onDateChange(date: Date | null) {
    date
      ? setRecurringOrderStartDate(date)
      : setRecurringOrderStartDate(new Date());
  }

  function onDateError(error) {
    if (error !== "") {
      setRecurringOrderStartDateError(true);
    } else {
      setRecurringOrderStartDateError(false);
    }
  }

  /**
   * Check if user can continue to checkout
   */
  function canContinue() {
    return (
      !isCheckoutError &&
      !isCartLocked() &&
      !isCheckoutDisabled &&
      (!isRecurringOrder ||
        (isRecurringOrder &&
          !isRecurringOrderDisabled &&
          recurringOrderFrequency !== "" &&
          recurringOrderStartDate != null &&
          !recurringOrderStartDateError))
    );
  }

  /**
   * Proceed to checkout if cart is valid
   */

  const checkoutFinalFordealer = () => {
    const DealerInfo = localStorage.getItem("selectedDealer");
    if (DealerInfo) {
      const { addressLine,
        addressType,
        city,
        country,
        state,
        zipCode,
        nickName } = JSON.parse(DealerInfo);
      addDelaerContact({
        addressLine,
        addressType,
        city,
        country,
        state,
        zipCode,
        nickName
      })
    }

  }
  const usableShippingModeAPI = () => {
    return new Promise((resolve) => {
      Axios.get(
        `/wcs/resources/store/${mySite?.storeID}/cart/@self/usable_shipping_mode`,
        {
          headers: {
            'content-type': 'application/json',
          }
        }
      ).then(res => {
        const data = res.data.usableShippingMode;
        if (data) {
          let customArray: any = [];
          data.filter((item) => {

            if (item.shipModeCode === "Standard") {
              customArray.splice(0, 0, item);
            }
            if (item.shipModeCode === "Express") {
              customArray.splice(1, 0, item);
            }
          });
          localStorageUtil.set('AllShippingMode', customArray)
          const DefaultMethodIf = data.filter((item) => item.shipModeCode === "PickupInStore")
          localStorageUtil.set('SelectedShipModeID', DefaultMethodIf[0].shipModeId)
          resolve(true);
        }
      }).catch(e => {
        console.log("Error-", e)
        resolve(true);
      });
    });

  }
  async function finalStep(addressId) {
    // if (addressId) {
    dispatch(orderActions.GET_SHIPINFO_ACTION({ ...payloadBase }));
    dispatch(
      accountActions.GET_ADDRESS_DETAIL_ACTION({ ...payloadBase })
    );

    //  const createNewAddressLocal = { ...AddressData, addressId: addressData.addressId }
    // console.log("createNewAddressLocal--", createNewAddressLocal)
    // // console.log("createNewAddressLocal--",[{newAddressData,...addressData.addressId}])
    //  localStorageUtil.set('shippingAddressId1', [createNewAddressLocal])
    // if (page === CheckoutPageType.SHIPPING) {
    //   dispatch(orderActions.GET_SHIPINFO_ACTION({ ...payloadBase }));
    // } else if (page === CheckoutPageType.PAYMENT) {
    //   dispatch(orderActions.GET_PAYMETHODS_ACTION({ ...payloadBase }));
    // }
    // const successMessage = {
    //   key: ADD_SUCCESS_MSG,
    //   messageParameters: {
    //     "0": AddressData.nickName,
    //   },
    // };
    // dispatch(
    //   successActions.HANDLE_SUCCESS_MESSAGE_ACTION(successMessage)
    // );
    // usableShippingModeAPI();
    await usableShippingModeAPI();
    history.push(`/checkout/shipping`);
    // }
  }
  function addDelaerContact(AddressData) {
    AddressData = { ...AddressData }
    personContactService
      .addPersonContact({
        body: AddressData,
        ...payloadBase,
      })
      .then((res) => res.data)
      .then(async (addressData) => {
        finalStep(addressData.addressId)
        // if (addressData.addressId) {
        //   dispatch(orderActions.GET_SHIPINFO_ACTION({ ...payloadBase }));
        //   dispatch(
        //     accountActions.GET_ADDRESS_DETAIL_ACTION({ ...payloadBase })
        //   );

        //   const createNewAddressLocal = { ...AddressData, addressId: addressData.addressId }
        //   // console.log("createNewAddressLocal--", createNewAddressLocal)
        //   // // console.log("createNewAddressLocal--",[{newAddressData,...addressData.addressId}])
        //   localStorageUtil.set('shippingAddressId1', [createNewAddressLocal])
        //   // if (page === CheckoutPageType.SHIPPING) {
        //   //   dispatch(orderActions.GET_SHIPINFO_ACTION({ ...payloadBase }));
        //   // } else if (page === CheckoutPageType.PAYMENT) {
        //   //   dispatch(orderActions.GET_PAYMETHODS_ACTION({ ...payloadBase }));
        //   // }
        //   // const successMessage = {
        //   //   key: ADD_SUCCESS_MSG,
        //   //   messageParameters: {
        //   //     "0": AddressData.nickName,
        //   //   },
        //   // };
        //   // dispatch(
        //   //   successActions.HANDLE_SUCCESS_MESSAGE_ACTION(successMessage)
        //   // );
        //   // usableShippingModeAPI();
        //   await usableShippingModeAPI();
        //   history.push(`/checkout/shipping`);

        // }
      }).catch(e => {
        console.log("Error-", e);
        finalStep('')
      });;
  }
  const usableShippingModeAPICalling = async () => {

    localStorageUtil.remove('AllShippingMode');
    try {


      const res = await axios.get(
        `/wcs/resources/store/${mySite?.storeID}/cart/@self/usable_shipping_mode`,
        {
          headers: {
            'content-type': 'application/json',
          }
        }
      )

      const data = res.data.usableShippingMode
      const DefaultMethodIf = data.filter((item) => item.shipModeCode === "PickupInStore");
      localStorageUtil.set('SelectedShipModeID', DefaultMethodIf[0].shipModeId);
      localStorageUtil.set('AllShippingMode', DefaultMethodIf);
      return true;
    }
    catch
    {
      console.log("Error--");
      return false;
    }
  }
  const {

    shipInfoBody,

  } = useShipping();
  const shipingInfoAPI = async () => {
    // var loggedInInfo = JSON.stringify(logininfo);

    const orderID = localStorageUtil.get('orderId')
    const shippingAddressID = localStorageUtil.get('shippingAddressId1');
    let orderItemsArray: any[] = [];
    const shipModeId: any = localStorageUtil.get('SelectedShipModeID');
    const orderItemIds: any = orderItems.map(({ orderItemId }) => (orderItemId));
    let dealer = localStorage.getItem("selectedDealer");
    orderItemIds.map((ids) => {
      let orderItemJson: any = {
        shipModeId: shipModeId,
        orderItemId: ids,

      };

      if (localStorageUtil.get('isDeliveryInstruction') === 'true') {
        Object.assign(orderItemJson, { shipInstructions: localStorageUtil.get('deliveryInstruction') });
        // orderItemJson.shipInstructions=localStorageUtil.get('deliveryInstruction');
      }

      if (dealer) {
        const { uniqueID } = JSON.parse(dealer);

        orderItemJson = {
          ...orderItemJson,
          physicalStoreId: uniqueID,
        };

      }
      orderItemsArray.push(orderItemJson);
    });
    let body: any = {
      ...shipInfoBody,
      orderItem: orderItemsArray,
      shipModeId: shipModeId,
    };

    if (dealer) {
      const { uniqueID } = JSON.parse(dealer);
      body = {
        ...body,
        physicalStoreId: uniqueID
      };
    }
    const payload = {
      ...payloadBase,
      body,
    };

    try {
      await shippingInfoService.updateOrderShippingInfo(payload);

      // dispatch(orderActions.GET_SHIPINFO_ACTION({ ...payloadBase }));
      dispatch(
        orderActions.GET_CART_ACTION({ ...payloadBase, fetchCatentries: true })
      );


      //  usableShippingModeAPI();
      return true;
    }

    catch
    {

      return false;
    }
  }
  const commonPromoRemove = async () => {
    const isPromo = find(selectedPromoCodes, (promo) => promo.code === 'FORD_REWARDS_DISCOUNT');
    if (isPromo) {
      onPromoCodeRemove(isPromo.code?.toUpperCase());
    }
  }
  async function checkout(event) {
    event.target.setAttribute('disabled', true);
    localStorageUtil.remove("currentBillingAddress");
    localStorageUtil.set('is-billing-address', 'false');
    if (canContinue()) {
      if (localStorageUtil.get('ShippingType') === 'DEALERSHIP') {
        const ismodeSuccess = await usableShippingModeAPICalling();
        if (ismodeSuccess) {
          const isSuccess = await shipingInfoAPI()
          event.target.setAttribute('disabled', false);
          if (isSuccess) {
            if (cart && cart.orderId) {
              const recurringOrderInfo: any[] = [
                isRecurringOrder,
                recurringOrderFrequency,
                recurringOrderStartDate,
              ];
              localStorageUtil.set(
                ACCOUNT + HYPHEN + ORDER_ID + HYPHEN + cart.orderId,
                recurringOrderInfo
              );
            }

            commonPromoRemove();
            if (selectedProfile) {
              // dispatch(orderActions.FETCH_ALLOWABLE_SHIPMODES_ACTION({}));
              const parameters = {
                ...payloadBase,
                body: {
                  toOrderId: ".",
                  payInfoFrom: selectedProfile,
                  shippingAddressFromOrderProfile: 1,
                  shippingModeFromOrderProfile: 1,
                },
              };
              cartService
                .copyOrder(parameters)
                .then((res) => {
                  if (res?.status === 200) {
                    history.push(CHECKOUT_REVIEW, { selectedProfile });
                  }
                })
                .catch((e) =>
                  console.log("Couldn't copy shipping info from checkout profile")
                );
            }
            //  else if (DealerInfo) {
            //   checkoutFinalFordealer();
            // }
            else {
              history.push(CHECKOUT);
            }
            event.target.setAttribute('disabled', false);
          }
        }

      }
      else {
        commonPromoRemove();
        if (cart && cart.orderId) {
          const recurringOrderInfo: any[] = [
            isRecurringOrder,
            recurringOrderFrequency,
            recurringOrderStartDate,
          ];
          localStorageUtil.set(
            ACCOUNT + HYPHEN + ORDER_ID + HYPHEN + cart.orderId,
            recurringOrderInfo
          );
        }

        if (selectedProfile) {
          // dispatch(orderActions.FETCH_ALLOWABLE_SHIPMODES_ACTION({}));
          const parameters = {
            ...payloadBase,
            body: {
              toOrderId: ".",
              payInfoFrom: selectedProfile,
              shippingAddressFromOrderProfile: 1,
              shippingModeFromOrderProfile: 1,
            },
          };
          cartService
            .copyOrder(parameters)
            .then((res) => {
              if (res?.status === 200) {
                history.push(CHECKOUT_REVIEW, { selectedProfile });
              }
            })
            .catch((e) =>
              console.log("Couldn't copy shipping info from checkout profile")
            );
        }
        //  else if (DealerInfo) {
        //   checkoutFinalFordealer();
        // }
        else {
          history.push(CHECKOUT);
        }
      }

    }
    event.target.setAttribute('disabled', false);
  }
  return {
    isFetching,
    orderItems,
    isRecurringOrderFeatureEnabled,
    isRecurringOrder,
    isRecurringOrderDisabled,
    recurringOrderFrequency,
    setIsRecurringOrder,
    setRecurringOrderFrequency,
    hasDiscounts,
    recurringOrderStartDate,
    frequencyOptions,
    localeMap,
    locale,
    onDateChange,
    onDateError,
    promoCode,
    resetPromoCodeError,
    onPromoCodeChange,
    applyPromoCodeBasedOnKey,
    promoCodeError,
    applyPromoCode,
    selectedPromoCodes,
    onPromoCodeRemove,
    cart,
    canContinue,
    checkout,
    selectedProfile,
    setSelectedProfile,
    isOnlyDealerShipPickup,
    isAllowBoth,
    isOnlyShipping,
    isCheckoutError,
    applyPromotionCodeForRedeem
  };
};
