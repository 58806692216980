import { createReducer, AnyAction } from "@reduxjs/toolkit";
//import _ from 'lodash'
//Redux
import initStates from "./initStates";
import * as ACTIONS from "../action-types/product-details";

 const productDetailsReducer = createReducer(initStates.productDetailsData, (builder) => {
    builder.addCase(ACTIONS.RESPONSE_SUCCESSED_PRODUCT,(state:any,action:AnyAction)=>
    {
       
const response = action.response;
state.productData=response

 //console.log("productReducer",response);

    })

builder.addCase(ACTIONS.RESPONSE_FAILURE_PRODUCT,(state:any,action:AnyAction)=>
{
const response = action.response;
state.productData=response
//console.log("productReducerError",state);
}
)} )
export {productDetailsReducer};