/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { combineReducers } from "redux";
//Redux
import account from "./account";
import api from "./api";
import catalog from "./catalog";
import user from "./user";
import order from "./order";
import error from "./error";
import seo from "./seo";
import contract from "./contract";
import search from "./search";
import organization from "./organization";
import context from "./context";
import success from "./success";
import confirmation from "./confirmation";
import recurringOrder from "./recurringOrder";
import orderDetails from "./orderDetails";
import site from "./site";
import localStorage from "./local-storage";
import checkoutProfile from "./checkout-profile";
import { popularProductReducer } from "./popular-products";
import { shopAccessoriesReducer } from "./shop-accessories";
import { productListReducer } from "./product-list";
import { headerCategoriesReducer } from "./header-categories";
import { productDetailsReducer } from "./product-details";
import { PDPReducer } from "./product-discription-page";
import searchNow from "./search-now";
import vehicles from "./vehicles";
import countries from "./country";
import WishList from "./wishList";
import {PAYMENT_COMMOM_REDUCER} from './payment-common-reducer'
import {
  ErrorReducerState,
  AccountReducerState,
  CatalogReducerState,
  UserReducerState,
  OrderReducerState,
  SEOReducerState,
  ContractReducerState,
  SearchReducerState,
  OrganizationReducerState,
  ContextReducerState,
  SuccessMessageReducerState,
  ConfirmationReducerState,
  RecurringOrderReducerState,
  OrderDetailsMapReducerState,
  SiteReducerState,
  ApiReducerState,
  CheckoutProfileReducerState,
  SearchNowReducerState,
  PopularProductModel,
  FeturedRideSectionModel,
  HeaderCategoriesSection,
  ProductDetailsSection,
  CountryReducerState,
  WishListReducerState,
  VehiclesReducerState
} from "./reducerStateInterface";

export * from "./reducerStateInterface";
export interface RootReducerState {
  account: AccountReducerState;
  api: ApiReducerState;
  catalog: CatalogReducerState;
  user: UserReducerState;
  order: OrderReducerState;
  error: ErrorReducerState;
  seo: SEOReducerState;
  contract: ContractReducerState;
  search: SearchReducerState;
  organization: OrganizationReducerState;
  context: ContextReducerState;
  success: SuccessMessageReducerState;
  confirmation: ConfirmationReducerState;
  recurringOrder: RecurringOrderReducerState;
  orderDetails: OrderDetailsMapReducerState;
  site: SiteReducerState;
  checkoutProfile: CheckoutProfileReducerState;
  searchNow: SearchNowReducerState;
  vehicles: any,
  popularProductReducer: PopularProductModel;
  shopAccessoriesReducer: FeturedRideSectionModel;
  headerCategoriesReducer: HeaderCategoriesSection;
  productDetailsReducer: ProductDetailsSection;
  countries: CountryReducerState;
  WishList: WishListReducerState;
}

const reducers = {
  account,
  api,
  catalog,
  user,
  order,
  error,
  seo,
  contract,
  search,
  context,
  organization,
  success,
  confirmation,
  recurringOrder,
  orderDetails,
  site,
  localStorage,
  checkoutProfile,
  searchNow,
  vehicles,
  popularProductReducer,
  shopAccessoriesReducer,
  headerCategoriesReducer,
  productListReducer,
  productDetailsReducer,
  PDPReducer,
  countries,
  WishList,
  PAYMENT_COMMOM_REDUCER
};

const rootReducer = combineReducers(reducers);

export default rootReducer;
