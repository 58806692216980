import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import jsonData from "../staticPagesMetaData.json";
import { useSite } from "../../../../_foundation/hooks/useSite";
import { Helmet } from "react-helmet";

const content =jsonData.contents[11]?.BestFordRaptorAccessories;

const handleShareClick = (event: React.MouseEvent<HTMLSpanElement>) => {
  const url = event.currentTarget.dataset.copyToClipboard!;
  navigator.clipboard
    .writeText(url)
    .then(() => {
      const confirmation = window.prompt(
        "Copy to clipboard: Ctrl/Cmd+C, Enter",
        url
      );
      if (confirmation) {
        navigator.clipboard.writeText(url);
      }
    })
    .catch(() => {
      window.alert(`Failed to copy link to clipboard: ${url}`);
    });
};

const breadcrumbList = {
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  itemListElement: [
    {
      "@type": "ListItem",
      position: 1,
      name: "Home",
      item: "https://accessories.ford.com/",
    },
    {
      "@type": "ListItem",
      position: 2,
      name: "News",
      item: "https://accessories.ford.com/blogs/news",
    },
    {
      "@type": "ListItem",
      position: 3,
      name: "Must-Have Ford Raptor Accessories",
      item: "https://accessories.ford.com/blogs/news/must-have-ford-raptor-accessories",
    },
  ],
};

const BestFordRaptorAccessories = () => {
  const {storeDisplayName } = useSite();
  let port = !window.location.port.trim() ? '' : ':' + window.location.port;
  return (
    <div>
       <Helmet>
          <title>{content?.title}</title>
          <meta name="description" content={content?.metaDescription}></meta>
          <meta name="keywords" content={content?.metaKeyword}/>
          <meta property="og:site_name" content={storeDisplayName}/>
          <meta property="og:url" content={(window.location.protocol +'//'+window.location.hostname+':' + port + window.location.pathname)}/>
          <meta property="og:title" content={content?.title}/>
          <meta property="og:type" content="article"/>  
          <meta property="og:description" content={content?.metaDescription}/>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={content?.title}/>  
          <meta name="twitter:description" content={content?.metaDescription} />
        </Helmet>
      <div id="shopify-section-template-article" className="shopify-section">
        <section
          title="Blog Hero"
          className="article-hero "
          style={{
            backgroundImage:
              "url(https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/MustHaveFordRaptorAccessories/RS_RaptorAccessories_1500x.jpeg)",
          }}>
          <div className="article-hero__container container">
            <h1 className="article-hero__heading title--md">
              Must-Have Ford Raptor Accessories
            </h1>

            <div className="article-hero__publish-date">March 31, 2023</div>
          </div>
        </section>
        <div className="article__main">
          <nav className="breadcrumbs" role="navigation">
            <ul className="flex">
              <li>
              <a href="/">
              <span>Home</span>
              </a>
                <span className="breadcrumbs-separator">&gt;</span>
              </li>
              <li>
                <Link to="/blogs/news">
                  <span>News</span>
                </Link>
              </li>
              <span className="breadcrumbs-separator">&gt;</span>
              <li>
                <span>Must-Have Ford Raptor Acces...</span>
              </li>
            </ul>
          </nav>
          <script type="application/ld+json">
            {JSON.stringify(breadcrumbList)}
          </script>

          <div className="article-actions">
            <Link className="article-actions__return" to="/blogs/news">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16">
                <g>
                  <path
                    fill="#102b4e"
                    d="M8,0 C12.418,0 16,3.582 16,8 C16,12.418 12.418,16 8,16 C3.582,16 0,12.418 0,8 C0,3.582 3.582,0 8,0 Z M6,3 C5.724,3 5.474,3.112 5.293,3.293 C5.112,3.474 5,3.724 5,4 C5,4.276 5.112,4.526 5.293,4.707 L9.409,8 L5.293,11.293 C5.112,11.474 5,11.724 5,12 C5,12.276 5.112,12.526 5.293,12.707 C5.474,12.888 5.724,13 6,13 C6.276,13 6.526,12.888 6.707,12.707 L11.707,8.707 C11.888,8.526 12,8.276 12,8 C12,7.724 11.888,7.474 11.707,7.293 L6.707,3.293 C6.526,3.112 6.276,3 6,3 Z"></path>
                </g>
              </svg>
              Back to articles
            </Link>

            <div
              className="social-icons "
              itemScope
              itemType="https://schema.org/Organization">
              <link itemProp="url" href="https://accessories.ford.com" />
              <h3 className="social-icons__share-title">Share</h3>

              <div className="social-icons__list-item">
                <a
                  href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/must-have-ford-raptor-accessories&amp;text=Must-Have+Ford+Raptor+Accessories"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="16"
                    viewBox="0 0 20 16">
                    <g>
                      <g>
                        <path
                          fill="#102b4e"
                          d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Twitter</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/must-have-ford-raptor-accessories"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M.29 5.24h3.2v10.764H.29z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">LinkedIn</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/must-have-ford-raptor-accessories"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="16"
                    viewBox="0 0 8 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Facebook</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <span
                  className="share"
                  onClick={handleShareClick}
                  data-copy-to-clipboard="https://accessories.ford.com/blogs/news/must-have-ford-raptor-accessories">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Share URL</span>
                </span>
              </div>
            </div>
          </div>
          <article className="article-content rte">
            <p>
              <span style={{fontWeight: 400}}>
                Ford Raptor owners have a lot in common. Not only do they have a
                high-performing vehicle in their garage, but they are some of
                the most intense, thrill-seeking adventurers on the road.
                However, each driver’s needs and passions are different. For
                example, some frequently take their Raptor out on trails for
                rock crawling excursions while others prefer Baja-style
                off-roading. Additionally, the F-150 Raptor and Bronco Raptor
                have different features. With varying uses, different add-ons
                are beneficial for harnessing your F-150 and Bronco Raptor’s
                capabilities to best fit your personal needs.
                <br />
                <br />
              </span>
              While the Raptor is already the most upgraded version out there,
              adding accessories can provide a customized touch to optimize your
              extreme experience for how you use it most. Discover these
              must-have, valuable Ford Raptor accessories to take your ultimate
              ride to the next level.
            </p>
            <p>&nbsp;</p>
            <h2>
              <span style={{fontWeight: 400}}>
                Best Ford F-150 Raptor Accessories
              </span>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                For die-hard fans of Ford, off-road enthusiasts, and
                truck-lovers alike, the Ford F-150 Raptor is the pinnacle of
                equipped vehicles. As one of the hottest vehicles on the market
                and arguably the most popular member of the Raptor family, the
                F-150 Raptor, with its large size, long-travel suspension, and
                performance capabilities among other features, has been loved
                for years. Whether you’re taking your Raptor down a city road,
                in mud terrain, or out for an off-road experience, check out
                this list of additional accessories to enhance your journey.
                <br />
                <br />
              </span>
            </p>
            <p>
              &nbsp;
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/MustHaveFordRaptorAccessories/RoofRack_38b2c009-3db1-455a-bd86-b55b2dce5fac.jpeg"
                alt="Raptor Roof Rack" width={'1480'} height={'100%'}
              />
            </p>
            <h2>
              <Link to="/search?searchTerm=f-150+roof+rack">
                <span style={{fontWeight: 400}}>Roof Rack</span>
              </Link>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                Adding a roof rack to your F-150 Raptor can easily expand your
                truck’s capabilities by serving as a platform for components
                like cargo baskets, ski, and snowboard racks, or paddleboard and
                canoe carriers. These different gear holders all need to be
                anchored to the top of your vehicle, so adding a roof rack and
                crossbar system will keep you prepared for any adventure.
              </span>
              <span style={{fontWeight: 400}}>
                &nbsp;
                <br />
                <br />
              </span>
            </p>
            <p>
              &nbsp;
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/MustHaveFordRaptorAccessories/AirCompressor_b30f4710-b0d6-4b49-be11-14728c268d64.jpeg"
                alt="Raptor Air Compressor" width={'1480'} height={'100%'}
              />
            </p>
            <h2>
              <Link to="/arb-ford-performance-portable-air-compressor-kit">
                <span style={{fontWeight: 400}}>Air Compressor</span>
              </Link>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                Our Ford Performance by ARB High Output Air Compressor is
                lightweight and made of high-strength engineering-grade
                materials for reliable tire inflation and ease of portability.
                The kit includes a 19-foot air hose, battery clamps, and an ARB
                air chuck with quick press-fitting all wrapped up in a durable
                carrying case for your on-the-go needs.
                <br />
                <br />
              </span>
            </p>
            <p>
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/MustHaveFordRaptorAccessories/TruckBedCover_8243f7cf-3546-40e3-a3ce-7a562e9a3dba.jpeg"
                alt="Raptor Truck Bed Cover" width={'1024'} height={'100%'}
              />
              &nbsp;
            </p>
            <h2>
              <Link to="/search?searchTerm=f-150+tonneau">
                <span style={{fontWeight: 400}}>Truck Bed Cover</span>
              </Link>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                Whether you’re taking your F-150 Raptor mudding or racing
                through dunes, you can help protect your truck and the
                belongings in its bed from the elements with a tonneau cover.
                These truck bed covers are available as soft retractable,
                folding and roll up, or hard painted and folding options for
                versatility that complements your lifestyle.
                <br />
              </span>
            </p>
            <p>&nbsp;</p>
            <h2>
              <span style={{fontWeight: 400}}>
                Best Ford Bronco Raptor Accessories
              </span>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                Designed and engineered by Ford Performance, the Ford Bronco
                Raptor is the most powerful street-legal Bronco ever made. It
                can conquer dunes, mud, and Baja rock-crawling, which further
                elevates it to the next level extreme. Similar to the F-150
                Raptor, the Bronco Raptor is the epitome of extreme Built Wild™
                durability and design with signature cues throughout the
                vehicle. Although the Bronco Raptor is capable of extreme
                performance on its own, we’ve put together a few components you
                can add to further prepare and equip your Raptor for the most
                rugged duties.
                <br />
                <br />
              </span>
            </p>
            <p>
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/MustHaveFordRaptorAccessories/SplashGuards_9865a3b5-736d-453e-8676-f2127070b459.jpeg"
                alt="Raptor Splash Guards" width={'1480'} height={'100%'}
              />
              &nbsp;
            </p>
            <h2>
              <Link to="/bronco-2021-gatorback-bucking-bronco-logo-rear-splash-guard-pair-1">
                <span style={{fontWeight: 400}}>Splash Guards</span>
              </Link>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                Keep your Bronco Raptor protected from mud and debris on your
                adventures with splash guards. Our rugged front and rear splash
                guard options are built tough for durability you can trust and
                are easy to assemble and install. Many of our splash guard
                products also feature various logos and details to enhance the
                customization of your Bronco.&nbsp;
              </span>
            </p>
            <br />
            <p>
              <b>Reminder:</b>
              <span style={{fontWeight: 400}}>
                {" "}
                With the Bronco Raptor offering different tire size options, it
                is important to keep your vehicle’s specifications in mind when
                choosing the right splash guards!&nbsp;
                <br />
                <br />
              </span>
            </p>
            <p>
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/MustHaveFordRaptorAccessories/CargoLiner.jpeg"
                alt="Cargo Liner" width={'1480'} height={'100%'}
              />
              &nbsp;
            </p>
            <h2>
              <Link to="/bronco-4dr-2021-cargo-area-liner-w-bronco-logo">
                <span style={{fontWeight: 400}}>Cargo Liner</span>
              </Link>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                When taking your Bronco Raptor out to do what it’s meant to do,
                things are going to get slightly (or very) messy. Help protect
                the floor of your Bronco’s trunk with a stain-resistant cargo
                liner that embraces adventure while helping to keep your vehicle
                clean! Our rugged liner trays are engineered to fit the specific
                contours of your Bronco and feature a raised perimeter lip to
                keep spills contained. They are resistant to UV rays in case of
                sun exposure and can easily be removed to clean with soapy
                water, leaving no trace of your wild excursions when you return
                home.
                <br />
                <br />
              </span>
            </p>
            <p>
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/MustHaveFordRaptorAccessories/HeavyDutyRecovery.jpeg"
                alt="Off-Road Heavy Duty Recovery" width={'1000'} height={'100%'}
              />
              &nbsp;
            </p>
            <h2>
              <Link to="/ford-performance-by-warn-off-road-heavy-duty-recovery-kit">
                <span style={{fontWeight: 400}}>
                  Off-Road Heavy Duty Recovery Kit
                </span>
              </Link>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                Don’t get stuck off road without the essentials! The Ford
                Performance by WARN Off-Road Heavy Duty Recovery Kit is designed
                to assist with recovery when your vehicle is stuck in mud, sand
                or snow. It can be used with winches up to 12,000 lb. capacities
                and includes a recovery strap, tree trunk protector, winching
                gloves, two D-shackles and a heavy-duty snatch block with a
                greaseable port, all within a storage bag
                <br />
              </span>
            </p>
            <p>&nbsp;</p>
            <h2>
              <span style={{fontWeight: 400}}>Go Beyond Greatness</span>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                Ready to further elevate your already remarkable Raptor with new
                accessories? Trusted accessory products from Ford let you add a
                personal touch to your vehicle without sacrificing performance
                by adding features with the power and expertise of Ford. Whether
                you’re taking the road less traveled or looking to improve the
                daily utility, get ready for the ride of your life with our wide
                selection of the best Ford Raptor accessories.
                <br />
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/MustHaveFordRaptorAccessories/Raptor_Accessories.jpeg"
                  alt="Raptor Accessories" width={'1800'} height={'100%'}
                />
                <br />
              </span>
            </p>
          </article>
          <div className="article-share">
            <div
              className="social-icons "
              itemScope
              itemType="https://schema.org/Organization">
              <link itemProp="url" href="https://accessories.ford.com" />
              <h3 className="social-icons__share-title">Share Article</h3>

              <div className="social-icons__list-item">
                <a
                  href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/must-have-ford-raptor-accessories&amp;text=Must-Have+Ford+Raptor+Accessories"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="16"
                    viewBox="0 0 20 16">
                    <g>
                      <g>
                        <path
                          fill="#102b4e"
                          d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Twitter</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/must-have-ford-raptor-accessories"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M.29 5.24h3.2v10.764H.29z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">LinkedIn</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/must-have-ford-raptor-accessories"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="16"
                    viewBox="0 0 8 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Facebook</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <span
                  className="share"
                  onClick={handleShareClick}
                  data-copy-to-clipboard="https://accessories.ford.com/blogs/news/must-have-ford-raptor-accessories">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Share URL</span>
                </span>
              </div>
            </div>
          </div>

          <div className="article-pagination article-pagination--desktop">
            <Link
              className="article-pagination__item article-pagination__item--previous"
              to="/blogs/news/vehicle-camping-packing-list"  onClick={() => window.scrollTo(0, 0)}>
              <div className="article-pagination__arrow">
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/chevron-thin-white-right_60x.jpeg"
                  alt="Chevron White" width={'32'} height={'62'}
                />
              </div>
              <div className="article-pagination__data">
                <div className="article-pagination__label">
                  Previous Article
                </div>
                <div className="article-pagination__title">
                  Vehicle Camping Packing List
                </div>
              </div>
            </Link>

            <Link
              className="article-pagination__item article-pagination__item--next"
              to="/blogs/news/how-to-strap-a-kayak-to-a-roof-rack"  onClick={() => window.scrollTo(0, 0)}>
              <div className="article-pagination__data">
                <div className="article-pagination__label">Next Article</div>
                <div className="article-pagination__title">
                  How to Strap a Kayak to a Roof Rack
                </div>
              </div>
              <div className="article-pagination__arrow">
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/chevron-thin-white-right_60x.jpeg"
                  alt="Chevron White" width={'32'} height={'62'}
                />
              </div>
            </Link>
          </div>

          <div className="article-pagination article-pagination--mobile">
            <Link
              className="article-pagination__item article-pagination__item--previous"
              to="/blogs/news/vehicle-camping-packing-list"  onClick={() => window.scrollTo(0, 0)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="15"
                viewBox="0 0 8 15">
                <g>
                  <g transform="rotate(-270 4 7.5)">
                    <path
                      fill="#fff"
                      d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z"></path>
                  </g>
                </g>
              </svg>
              Prev Article
            </Link>

            <Link
              className="article-pagination__item article-pagination__item--next"
              to="/blogs/news/how-to-strap-a-kayak-to-a-roof-rack"  onClick={() => window.scrollTo(0, 0)}>
              Next Article
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="15"
                viewBox="0 0 8 15">
                <g>
                  <g transform="rotate(-270 4 7.5)">
                    <path
                      fill="#fff"
                      d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z"></path>
                  </g>
                </g>
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BestFordRaptorAccessories;
