/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { Suspense, useEffect, useState } from "react";
import { Link, useHistory, useLocation, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
//import { paramCase } from "change-case";
//Foundation libraries
//import { useSite } from "../../_foundation/hooks/useSite";
//Custom libraries
//import { footerConfig } from "./footerConstant";
//import ContentRecommendationWidget from "../commerce-widgets/content-recommendation-widget";
//import { SessionErrorDialog } from "../widgets/session-error-modal";
import ConfirmationDialog from "../widgets/confirmation-dialog/ConfirmationDialog";
//import Script from "react-load-script";
import {
  WISH_LIST_OLD,
  OLD_HOME,
  MAKERETURN_OLD,
  ACCOUNT_OLD
} from "../../constants/routes";
//UI
import {
  StyledGrid,
  StyledContainer,
  StyledTypography,
  StyledFooter,
  StyledTooltip,
  StyledBox,
  ContentCarouselWidget,
} from "@hcl-commerce-store-sdk/react-component";
import {StyledProgressPlaceholder} from '../../_foundation/hooks/circular-progress/styled-progress-placeholder'
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "./FooterStyle.scss";
import FooterSm from './FooterSm';
import { useTheme } from "@material-ui/core/styles";
import { useSite } from "../../_foundation/hooks/useSite";
import { useSelector } from "react-redux";
import { loginStatusSelector } from "../../redux/selectors/user";
import eSpotService from "../../_foundation/apis/transaction/eSpot.service";
import HTMLReactParser from "html-react-parser";
//custom
import { EMPTY_STRING } from "../../constants/common";
import SessionTimeOutPopup from "../pages/sign-in/SessionTimeOutPopup";
import AdobeService from "../../_foundation/adobeServices/adobeService";
import { localStorageUtil } from "../../_foundation/utils/storageUtil";
import { CURRENT_USER } from "../../_foundation/constants/common";
import { content } from "html2canvas/dist/types/css/property-descriptors/content";
////console.log("999",StyledTooltip)
function Footer(props: any) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallDesktop = useMediaQuery(theme.breakpoints.down(600));
  const isLargeDesktop = useMediaQuery(theme.breakpoints.up(600));
  const { mySite } = useSite();
  const storeID: string = mySite ? mySite.storeID : EMPTY_STRING;
  const catalogID: string = mySite ? mySite.catalogID : EMPTY_STRING;
  const loginStat = useSelector(loginStatusSelector);
  const [footerDisclouser, setFooterDisclouser] = useState<any | []>([]);
  const [footerBottom, setFooterBottom] = useState("");
  const [footerTop, setFooterTop] = useState("");
  const [showFooter, setShowFooter] = useState(false);
  //const { mySite } = useSite();
  /*const Logo = () => {
    return mySite != null ? (
      <div className="footer-logo">
        <ContentRecommendationWidget
          {...{
            widget: {
              id: `footer-${paramCase(footerConfig.espot.eSpotName)}`,
              widgetName: "content-recommendation-widget",
              name: footerConfig.espot.eSpotName,
              properties: {
                emsName: footerConfig.espot.eSpotName,
              },
            },
            page: { name: "" },
          }}></ContentRecommendationWidget>
      </div>
    ) : null;
  };*/

  const initMarketingContent = () => {
    const parameters: any = {
      storeId: storeID,
      name: "US_Disclosure_Espot",
      catalogId: catalogID,
      widget: "e-marketing-spot-widget",
      query: {
        DM_ReturnCatalogGroupId: true,
        DM_FilterResults: false,
      }
    };
    eSpotService
      .findByName(parameters)
      .then((res) => {
        const eSpotRoot = res.data.MarketingSpotData[0];
        setFooterDisclouser(eSpotRoot.baseMarketingSpotActivityData[0].marketingContentDescription[0].marketingText)

      })
      .catch((e) => {
        //console.log(e);
      });
  };

  useEffect(() => {
    initMarketingContent();
  }, []);
  const initMarketingContentFooterBottom = () => {
    const parameters: any = {
      storeId: storeID,
      name: "US_Footer_Espot",
      catalogId: catalogID,
      widget: "e-marketing-spot-widget",
      query: {
        DM_ReturnCatalogGroupId: true,
        DM_FilterResults: false,
      }
    };
    eSpotService
      .findByName(parameters)
      .then((res) => {
        const eSpotRoot = res.data.MarketingSpotData[0];
        setFooterBottom(eSpotRoot.baseMarketingSpotActivityData[0].marketingContentDescription[0].marketingText)
        setShowFooter(true);
      }) 
      .catch((e) => {
        //console.log(e);
      });
  };

  useEffect(() => {
    initMarketingContentFooterBottom();
  }, []);

  //asign tag//
  function referalExitAdobeTagImpl(objData) {
    (async () => {
      let res = await AdobeService.track(objData);
      AdobeService.callSatellite(res);
    })();
  }
  //apply listner on each <a>
  const addOnClickEvent = () => {

    let links = document.getElementsByTagName('a');
    for (let index = 0; index < links.length; index++) {
      let title = links[index].title;
      ////console.log("title===>",title)
      let href = links[index].href;
      ////console.log("href====>",href)
      if (href === 'https://www.ford.com/') {
        links[index].addEventListener("click", () => { referalExitAdobeTagImpl({ pageName: 'home', siteSection: 'home', hierarchy: 'home', onClickName: "referral:ford.com", onClickLinkName: 'referral:exit', referredTo: 'https://www.ford.com/', variantName: 'referral-exit', eventType: "referralExit" }) });
      } else if (href === 'https://www.lincoln.com/') {
        links[index].addEventListener("click", () => { referalExitAdobeTagImpl({ pageName: 'home', siteSection: 'home', hierarchy: 'home', onClickName: "referral:lincoln.com", onClickLinkName: 'referral:exit', referredTo: 'https://www.lincoln.com/', variantName: 'referral-exit', eventType: "referralExit" }) });
      } else if (href === 'https://corporate.ford.com/contact.html') {
        links[index].addEventListener("click", () => { referalExitAdobeTagImpl({ pageName: 'home', siteSection: 'home', hierarchy: 'home', onClickName: "referral:ford:corporate:home", onClickLinkName: 'referral:exit', referredTo: 'https://corporate.ford.com/contact.html', variantName: 'referral-exit', eventType: "referralExit" }) });
      } else if (href === 'https://parts.ford.com/en.html') {
        links[index].addEventListener("click", () => { referalExitAdobeTagImpl({ pageName: 'home', siteSection: 'home', hierarchy: 'home', onClickName: "referral:fpol:home", onClickLinkName: 'referral:exit', referredTo: 'https://parts.ford.com/shop/en/us', variantName: 'referral-exit', eventType: "referralExit" }) });
      } else if (href === 'https://collision.ford.com/') {
        links[index].addEventListener("click", () => { referalExitAdobeTagImpl({ pageName: 'home', siteSection: 'home', hierarchy: 'home', onClickName: "referral:collision:home", onClickLinkName: 'referral:exit', referredTo: 'https://collision.ford.com/', variantName: 'referral-exit', eventType: "referralExit" }) });
      } else if (href === 'https://powerstrokediesel.com/') {
        links[index].addEventListener("click", () => { referalExitAdobeTagImpl({ pageName: 'home', siteSection: 'home', hierarchy: 'home', onClickName: "referral:powerstrokediesel.com", onClickLinkName: 'referral:exit', referredTo: 'https://powerstrokediesel.com/', variantName: 'referral-exit', eventType: "referralExit" }) });
      } else if (href === 'https://performance.ford.com/home.html') {
        links[index].addEventListener("click", () => { referalExitAdobeTagImpl({ pageName: 'home', siteSection: 'home', hierarchy: 'home', onClickName: "referral:fr:home", onClickLinkName: 'referral:exit', referredTo: 'https://performance.ford.com/home.html', variantName: 'referral-exit', eventType: "referralExit" }) });
      } else if (href === 'https://www.ford.com/support/service-maintenance/tire-finder/') {
        links[index].addEventListener("click", () => { referalExitAdobeTagImpl({ pageName: 'home', siteSection: 'home', hierarchy: 'home', onClickName: "referral:flmo:service:tire finder", onClickLinkName: 'referral:exit', referredTo: 'https://www.ford.com/support/service-maintenance/tire-finder/', variantName: 'referral-exit', eventType: "referralExit" }) });
      } else if (href === 'https://www.vpartsinc.com/') {
        links[index].addEventListener("click", () => { referalExitAdobeTagImpl({ pageName: 'home', siteSection: 'home', hierarchy: 'home', onClickName: "referral:vpartsinc.com", onClickLinkName: 'referral:exit', referredTo: 'https://www.vpartsinc.com/', variantName: 'referral-exit', eventType: "referralExit" }) });
      } else if (href === 'https://chargers.ford.com/') {
        links[index].addEventListener("click", () => { referalExitAdobeTagImpl({ pageName: 'home', siteSection: 'home', hierarchy: 'home', onClickName: "referral:fcg:home", onClickLinkName: 'referral:exit', referredTo: 'https://chargers.ford.com/', variantName: 'referral-exit', eventType: "referralExit" }) });
      }
    }
  }

  const initMarketingContentFooterGuest = () => {
    const parameters: any = {
      storeId: storeID,
      name: "US_Footer_Top",
      catalogId: catalogID,
      widget: "e-marketing-spot-widget",
      query: {
        DM_ReturnCatalogGroupId: true,
        DM_FilterResults: false,
      }
    };
    eSpotService
      .findByName(parameters)
      .then((res) => {
        const eSpotRoot = res.data.MarketingSpotData[0];
        setFooterTop(eSpotRoot.baseMarketingSpotActivityData[0].marketingContentDescription[0].marketingText)
        //init the click listner
        setTimeout(() => {
          addOnClickEvent()
        }, 1000);
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  useEffect(() => {
    initMarketingContentFooterGuest();
  }, []);
  // const Accessories = () => {
  //   const Accessories = [
  //     {
  //       linkDescription: t("Footer.Accessories.Exterior"),
  //       linkText: t("Footer.Accessories.Exterior"),
  //       linkURL: "/exterior",
  //     },
  //     {
  //       linkText: t("Footer.Accessories.Electronics"),
  //       linkURL: "/electronics",
  //     },
  //     {
  //       linkText: t("Footer.Accessories.Interior"),
  //       linkURL: "/interior",
  //     },
  //     {
  //       linkText: t("Footer.Accessories.Wheels"),
  //       linkURL: "/wheels",
  //     },
  //     {
  //       linkText: t("Footer.Accessories.BedProducts"),
  //       linkURL: "/bed-products",
  //     },
  //     {
  //       linkText: t("Footer.Accessories.LincolnAccessories"),
  //       linkURL: "https://accessories.lincoln.com/",
  //     },
  //     {
  //       linkText: t("View Accessories on Your Vehicle"),
  //       linkURL: "https://fordaccess.com/Ford.aspx?location=70707",
  //     },
  //   ];
  //   return (
  //     <div className="footer-nav footer-nav__column__link-ul">
  //       <StyledTypography variant="overline">
  //         {t("Footer.Accessories.Label")}
  //       </StyledTypography>
  //       <StyledTypography variant="caption">
  //         {Accessories.map((v: any) => (
  //           <div key={v.linkURL}>
  //             <StyledTooltip>
  //               {v.linkText == "View Accessories on Your Vehicle" ? (<a href={v.linkURL} target="_blank">{v.linkText}</a>) :
  //                 (
  //                   <>
  //                     {v.linkText == t("Footer.Accessories.LincolnAccessories") ? (<a href={v.linkURL} target="_blank">{v.linkText}</a>) : (
  //                       <Link
  //                         key={v.linkURL + "/"}
  //                         // to={`/${v.linkURL}`}
  //                         to={v.linkURL}
  //                         onClick={() => { window.scrollTo(0, 0); }}
  //                       //title={ v["linkDescription"] ? v["linkDescription"] : v.linkText}
  //                       >
  //                         {v.linkText}
  //                       </Link>
  //                     )}
  //                   </>
  //                 )
  //               }
  //             </StyledTooltip>
  //           </div>
  //         ))}
  //       </StyledTypography>
  //     </div>
  //   );
  // };

  // const Performance = () => {
  //   const Performance = [
  //     {
  //       linkText: t("Footer.Performance.Appearance"),
  //       linkURL: "/appearance",
  //     },
  //     {
  //       linkText: t("Footer.Performance.Chassis"),
  //       linkURL: "/chassis",
  //     },
  //     {
  //       linkText: t("Footer.Performance.Electrical"),
  //       linkURL: "/electrical",
  //     },
  //     {
  //       linkText: t("Footer.Performance.Body"),
  //       linkURL: "/body",
  //     },
  //     {
  //       linkText: t("Footer.Performance.Driveline"),
  //       linkURL: "/driveline",
  //     },
  //   ];
  //   return (
  //     <div className="contact-us footer-nav__column__link-ul">
  //       <StyledTypography variant="overline">
  //         {t("Footer.Performance.Label")}
  //       </StyledTypography>
  //       <StyledTypography variant="caption">
  //         {Performance.map((v: any) => (
  //           <div key={v.linkURL}>
  //             <StyledTooltip>
  //               <Link
  //                 key={v.linkURL + "/"}
  //                 to={v.linkURL}
  //                 onClick={() => { window.scrollTo(0, 0); }}
  //               >
  //                 {v.linkText}
  //               </Link>
  //             </StyledTooltip>
  //           </div>
  //         ))}
  //       </StyledTypography>
  //     </div>
  //   );
  // };
  const history = useHistory();
  useEffect(() => {

    if (window.location.href.includes("LogoutRedirect")) {
      handleLogout();
      history.push('/');
    }
  })
  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
  };

  // const MyProfile = () => {
  //   const isLoggedIn = loginStat;
  //   var protocol = window.location.protocol;
  //   var port = !window.location.port.trim() ? '' : ':' + window.location.port;
  //   var url = window.location.href;
  //   var currentURL = url.indexOf("&krypto") > 0 ? url.substring(0, url.indexOf("&krypto")) : url;
  //   const FMA_LOGIN_LINK = `${mySite.storeCfg.userData["FMA_SIGN_IN"]}?redirectURL=${protocol}//${window.location.hostname}${port}/FordUSAccessories/webapp/wcs/stores/servlet/LogonRedirect?storeId=${mySite.storeID}&catalogId=${mySite.catalogID}&langId=-1&currentURL=${encodeURIComponent(currentURL)}`
  //   const FMA_LOGOUT_LINK = `${mySite.storeCfg.userData["FMA_SIGN_OUT"]}?redirectURL=${encodeURIComponent(`${protocol}//${window.location.hostname}${port}/LogoutRedirect?storeId=${mySite.storeID}&catalogId=${mySite.catalogID}&langId=-1&lang=en_us`)}&currentURL=${encodeURIComponent(window.location.origin)}&pid=${localStorage.getItem("pid")}&sessionId=${localStorage.getItem("sessionId")}`
  //   const MyProfile = [
  //     loginStat ?
  //       {
  //         linkText: t("LOGOUT"),
  //         linkURL: FMA_LOGOUT_LINK
  //       } :
  //       {
  //         linkText: t("LOGIN"),
  //         linkURL: FMA_LOGIN_LINK
  //       },
  //     !loginStat ?
  //       {
  //         linkText: t("Create an Account"),
  //         linkURL: FMA_LOGIN_LINK,
  //       } : {},
  //     {
  //       linkText: t("Footer.MyProfile.AccountDetails"),
  //       linkURL: isLoggedIn ? "/account/addresses" : FMA_LOGIN_LINK,
  //     },
  //     {
  //       linkText: t("Footer.MyProfile.OrdersAndReturns"),
  //       linkURL: isLoggedIn ? "/account/order-history" : FMA_LOGIN_LINK,
  //     },
  //     {
  //       linkText: t("Footer.MyProfile.MyGarage"),
  //       linkURL: isLoggedIn ? "/account/my-garage" : FMA_LOGIN_LINK,
  //     },
  //   ];
  //   return (
  //     <div className="contact-us footer-nav__column__link-ul">
  //       <StyledTypography variant="overline">
  //         {t("Footer.MyProfile.Label")}
  //       </StyledTypography>
  //       <StyledTypography variant="caption">
  //         {MyProfile.map((v: any) => (
  //           <div key={v.linkURL}>
  //             <StyledTooltip>
  //               {
  //                 (isLoggedIn === false || v.linkText === "LOGOUT" || v.linkText === "LOGIN" || v.linkText === "Create an Account") ?
  //                   (
  //                     <a href={v.linkURL}>{v.linkText}</a>
  //                   ) : (
  //                     <Link
  //                       key={v.linkURL + "/"}
  //                       to={v.linkURL}
  //                       onClick={() => {
  //                         window.scrollTo(0, 0);
  //                       }}>{v.linkText}
  //                     </Link>)}
  //             </StyledTooltip>
  //           </div>
  //         ))}
  //       </StyledTypography>
  //     </div>
  //   );
  // };

  // const Resources = () => {
  //   const { t } = useTranslation();
  //   const resources = [
  //     {
  //       linkText: t("Footer.Resources.FAQ"),
  //       linkURL: "/Faq",
  //     },
  //     {
  //       linkText: t("Footer.Resources.ContactUs"),
  //       linkURL: "/contact-us",
  //     },
  //     {
  //       linkText: t("Footer.Resources.ShippingAndDelivery"),
  //       linkURL: "/shipping-delivery",
  //     },
  //     {
  //       linkText: t("Footer.Resources.Returns"),
  //       linkURL: "/return",
  //     },
  //     {
  //       linkText: t("Footer.Resources.MakeReturn"),
  //       linkURL: "/MakeReturn",
  //     },
  //     {
  //       linkText: t("Footer.Resources.TermsAndConditions"),
  //       linkURL: "/terms-conditions",
  //     },
  //     {
  //       linkText: t("Footer.Resources.WarrantyInfo"),
  //       linkURL: "/warranty-information",
  //     },
  //     {
  //       linkText: t("Footer.Resources.Feedback"),
  //       linkURL: "Feedback",
  //     },
  //   ];
  //   return (
  //     <>
  //       <div className="contact-us footer-nav__column__link-ul">
  //         <StyledTypography variant="overline">
  //           {t("Footer.Resources.Label")}
  //         </StyledTypography>
  //         <StyledTypography variant="caption">
  //           {resources.map((v) => (
  //             <div key={v.linkURL}>
  //               <StyledTooltip>
  //                 {
  //                   (() => {
  //                     if (v.linkText === "Feedback")
  //                       return <a href="javascript:void(0);" onClick={() => { (window as any).KAMPYLE_ONSITE_SDK.showForm(8521) }}>{v.linkText}</a>;
  //                     else
  //                       return <Link
  //                         key={v.linkURL + "/"}
  //                         to={v.linkURL}
  //                         onClick={() => window.scrollTo(0, 0)}
  //                       >
  //                         {v.linkText}
  //                       </Link>
  //                   })()
  //                 }
  //               </StyledTooltip>
  //             </div>
  //           ))}
  //         </StyledTypography>
  //       </div>
  //     </>
  //   );
  // };


  // const RelatedLinks = () => {
  //   const RelatedLinks = [
  //     {
  //       linkText: t("Footer.RelatedLinks.Ford"),
  //       linkURL: "https://www.ford.com/",
  //     },
  //     {
  //       linkText: t("Footer.RelatedLinks.Lincoln"),
  //       linkURL: "https://www.lincoln.com/",
  //     },
  //     {
  //       linkText: t("Footer.RelatedLinks.ContactFord"),
  //       linkURL: "https://corporate.ford.com/contact.html",
  //     },
  //     {
  //       linkText: t("Footer.RelatedLinks.Racing"),
  //       linkURL: "https://performance.ford.com/home.html",
  //     },
  //     {
  //       linkText: t("Footer.RelatedLinks.FordParts"),
  //       linkURL: "https://parts.ford.com/en.html",
  //     },
  //     {
  //       linkText: t("Footer.RelatedLinks.FordChargeStations"),
  //       linkURL: "https://chargers.ford.com/",
  //     },
  //     {
  //       linkText: t("Footer.RelatedLinks.FordCollision"),
  //       linkURL: "https://collision.ford.com/",
  //     },
  //     {
  //       linkText: t("Footer.RelatedLinks.PowerStrokeDiesel"),
  //       linkURL: "https://powerstrokediesel.com/",
  //     },
  //     {
  //       linkText: t("Footer.RelatedLinks.TireFinder"),
  //       linkURL: "https://www.ford.com/support/service-maintenance/tire-finder/",
  //     },
  //     {
  //       linkText: t("Footer.RelatedLinks.VintageParts"),
  //       linkURL: "https://www.vpartsinc.com/",
  //     },
  //   ];
  //   //Adobe tag
  //   const [onClickEventAdd, setOnClickEventAdd] = useState(false);

  //   function referalExitAdobeTagImpl(objData) {
  //     (async () => {
  //       let res = await AdobeService.track(objData);
  //       AdobeService.callSatellite(res);
  //     })();
  //   }
  //   const addOnClickEvent = () => {
  //     ////console.log("ReferalReferalReferalReferalReferal")
  //     if (!onClickEventAdd) {
  //       let links = document.getElementsByTagName('a');
  //       for (let index = 0; index < links.length; index++) {
  //         let title = links[index].title;
  //         ////console.log("title===>",title)
  //         let href = links[index].href;
  //         ////console.log("href====>",href)
  //         if (href === 'https://www.ford.com/') {
  //           links[index].addEventListener("click", () => { referalExitAdobeTagImpl({ pageName: 'home', siteSection: 'home', hierarchy: 'home', onClickName: "referral:ford.com", onClickLinkName: 'referral:exit', referredTo: 'https://www.ford.com/', variantName: 'referral-exit', eventType: "referralExit" }) });
  //         } else if (href === 'https://www.lincoln.com/') {
  //           links[index].addEventListener("click", () => { referalExitAdobeTagImpl({ pageName: 'home', siteSection: 'home', hierarchy: 'home', onClickName: "referral:lincoln.com", onClickLinkName: 'referral:exit', referredTo: 'https://www.lincoln.com/', variantName: 'referral-exit', eventType: "referralExit" }) });
  //         } else if (href === 'https://corporate.ford.com/contact.html') {
  //           links[index].addEventListener("click", () => { referalExitAdobeTagImpl({ pageName: 'home', siteSection: 'home', hierarchy: 'home', onClickName: "referral:ford:corporate:home", onClickLinkName: 'referral:exit', referredTo: 'https://corporate.ford.com/contact.html', variantName: 'referral-exit', eventType: "referralExit" }) });
  //         } else if (href === 'https://parts.ford.com/en.html') {
  //           links[index].addEventListener("click", () => { referalExitAdobeTagImpl({ pageName: 'home', siteSection: 'home', hierarchy: 'home', onClickName: "referral:fpol:home", onClickLinkName: 'referral:exit', referredTo: 'https://parts.ford.com/shop/en/us', variantName: 'referral-exit', eventType: "referralExit" }) });
  //         } else if (href === 'https://collision.ford.com/') {
  //           links[index].addEventListener("click", () => { referalExitAdobeTagImpl({ pageName: 'home', siteSection: 'home', hierarchy: 'home', onClickName: "referral:collision:home", onClickLinkName: 'referral:exit', referredTo: 'https://collision.ford.com/', variantName: 'referral-exit', eventType: "referralExit" }) });
  //         } else if (href === 'https://powerstrokediesel.com/') {
  //           links[index].addEventListener("click", () => { referalExitAdobeTagImpl({ pageName: 'home', siteSection: 'home', hierarchy: 'home', onClickName: "referral:powerstrokediesel.com", onClickLinkName: 'referral:exit', referredTo: 'https://powerstrokediesel.com/', variantName: 'referral-exit', eventType: "referralExit" }) });
  //         } else if (href === 'https://performance.ford.com/home.html') {
  //           links[index].addEventListener("click", () => { referalExitAdobeTagImpl({ pageName: 'home', siteSection: 'home', hierarchy: 'home', onClickName: "referral:fr:home", onClickLinkName: 'referral:exit', referredTo: 'https://performance.ford.com/home.html', variantName: 'referral-exit', eventType: "referralExit" }) });
  //         } else if (href === 'https://www.ford.com/support/service-maintenance/tire-finder/') {
  //           links[index].addEventListener("click", () => { referalExitAdobeTagImpl({ pageName: 'home', siteSection: 'home', hierarchy: 'home', onClickName: "referral:flmo:service:tire finder", onClickLinkName: 'referral:exit', referredTo: 'https://www.ford.com/support/service-maintenance/tire-finder/', variantName: 'referral-exit', eventType: "referralExit" }) });
  //         } else if (href === 'https://www.vpartsinc.com/') {
  //           links[index].addEventListener("click", () => { referalExitAdobeTagImpl({ pageName: 'home', siteSection: 'home', hierarchy: 'home', onClickName: "referral:vpartsinc.com", onClickLinkName: 'referral:exit', referredTo: 'https://www.vpartsinc.com/', variantName: 'referral-exit', eventType: "referralExit" }) });
  //         } else if (href === 'https://chargers.ford.com/') {
  //           links[index].addEventListener("click", () => { referalExitAdobeTagImpl({ pageName: 'home', siteSection: 'home', hierarchy: 'home', onClickName: "referral:fcg:home", onClickLinkName: 'referral:exit', referredTo: 'https://chargers.ford.com/', variantName: 'referral-exit', eventType: "referralExit" }) });
  //         }
  //       }
  //       setOnClickEventAdd(true);
  //     }
  //   };
  //   React.useEffect(() => {
  //     addOnClickEvent()
  //   },)
  //   const location = useLocation();

  //   useEffect(() => {
  //     if (location.pathname == "/account" || location.pathname === ACCOUNT_OLD) {
  //       (async () => {
  //         let res = await AdobeService.track({
  //           pageName: "account dashboard",
  //           variantName: "account-details",
  //           siteSection: "account",
  //           hierarchy: "account",
  //           pagenameNoProduct: "account",
  //           eventType: "account",
  //         });
  //         AdobeService.callSatellite(res);
  //       })();
  //     } else if (location.pathname == "/account/wish-list" || location.pathname === WISH_LIST_OLD) {
  //       (async () => {
  //         let res = await AdobeService.track({
  //           pageName: "account:wishlist",
  //           variantName: "account-details",
  //           siteSection: "account",
  //           pagenameNoProduct: "account:wishlist",
  //           hierarchy: "account:wishlist",
  //           eventType: "account wishlist",
  //         });
  //         AdobeService.callSatellite(res);
  //         // console.log("11111 wishlist",adobeTagCall);  
  //       })();
  //     } else if (location.pathname == "/the-road-ahead" || location.pathname === OLD_HOME + "/the-road-ahead") {
  //       (async () => {
  //         let res = await AdobeService.track({
  //           pageName: "blogs:landing",
  //           variantName: "blog-load",
  //           siteSection: "blogs",
  //           pagenameNoProduct: "blogs:landing",
  //           hierarchy: "blogs",
  //           eventType: "blogs",
  //         });
  //         AdobeService.callSatellite(res);
  //       })();
  //     } else if (location.pathname == "/MakeReturn" || location.pathname === MAKERETURN_OLD) {
  //       (async () => {
  //         let res = await AdobeService.track({
  //           pageName: "make a return",
  //           variantName: "global-load",
  //           siteSection: "help center",
  //           pagenameNoProduct: "make a return",
  //           hierarchy: "help center",
  //           eventType: "returns",
  //         });
  //         AdobeService.callSatellite(res);
  //       })();
  //     }
  //   }, [location])
  //   const [adobeTagCall, setAdobeTagCall] = useState(0);

  //   const brandscollectionlist = [
  //     { collections: "Truxedo", url: "/truxedo" },
  //     { collections: "Yakima Products", url: "/yakima" },
  //     { collections: "Undercover", url: "/undercover" },
  //     { collections: "Covercraft Industries", url: "/covercraft" },

  //     { collections: "Thule", url: "/thule" },
  //     { collections: "Bushwacker", url: "/bushwacker" },
  //     { collections: "Bestop", url: "/bestop" },
  //     { collections: "Gatorback", url: "/gatorback" },
  //     { collections: "Advantage", url: "/advantage" },
  //   ]
  //   React.useEffect(() => {
  //     brandscollectionlist.map((d) => {
  //       if (location.pathname == d.url || location.pathname === OLD_HOME + d.url) {
  //         (async () => {
  //           let res = await AdobeService.track({
  //             pageName: `shop by brands:${d.collections}`,
  //             variantName: "global-nameplate-load",
  //             siteSection: "shop by brands",
  //             pagenameNoProduct: `shop by brands:${d.collections}`,
  //             hierarchy: "shop by brands",
  //             eventType: "global-nameplate-load",
  //           });
  //           AdobeService.callSatellite(res);
  //         })();
  //       }
  //     }
  //     )
  //   }, [location])

  //   const articlslist = [
  //     { collections: "Different Types Of Bike Racks", url: "/blogs/news/different-types-of-bike-racks" },
  //     { collections: "How To strap A Kayak To A Roof Rack", url: "/blogs/news/how-to-strap-a-kayak-to-a-roof-rack" },
  //     { collections: "Must Have Ford Raptor Accessories", url: "/blogs/news/must-have-ford-raptor-accessories" },

  //     { collections: "Vehicle Camping Packing List", url: "/blogs/news/vehicle-camping-packing-list" },
  //     { collections: "Your Guide To A 'Blacked-Out' Vehicle Look", url: "/blogs/news/your-guide-to-a-blacked-out-vehicle-look" },
  //     { collections: "Must-Have Vehicle Accessories For Summer", url: "/blogs/news/must-have-vehicle-accessories-for-summer" },

  //     { collections: "Bronco vs. Bronco Sport", url: "/blogs/news/bronco-vs-bronco-sport" },
  //     { collections: "Winter Car Camping Accessories For Your Ford", url: "/blogs/news/winter-car-camping-accessories-for-your-ford" },
  //     { collections: "Best Ford Mustang Accessories", url: "/blogs/news/best-ford-mustang-accessories" },

  //     { collections: "Our Best Towing Accessories", url: "/blogs/news/our-best-towing-accessories" },
  //     { collections: "Make The Most Of Your Maverick®", url: "/blogs/news/make-the-most-of-your-maverick" },
  //     { collections: "How To Winterize Your Vehicle", url: "/blogs/news/how-to-winterize-your-vehicle" },

  //     { collections: "Our Best Snowboard Racks", url: "/blogs/news/our-best-snowboard-racks" },
  //     { collections: "Family Road Trip Tips", url: "/blogs/news/family-road-trip-tips" },
  //     { collections: "5 Unique Ways To Use Your Truck Bed", url: "/blogs/news/5-unique-ways-to-use-your-truck-bed" },

  //     { collections: "5-unique-ways-to-use-your-truck-bed", url: "/blogs/news/5-unique-ways-to-use-your-truck-bed" },
  //     { collections: "Overlanding 101", url: "/blogs/news/overlanding-101" },
  //     { collections: "Tailgating Essentials", url: "/blogs/news/tailgating-essentials" },

  //     { collections: "Best Truck Bed Accessories", url: "/blogs/news/best-truck-bed-accessories" },
  //     { collections: "5 Essentials For Off Road Adventures", url: "/blogs/news/5-essentials-for-off-road-adventures" },
  //     { collections: "8 Best Overlanding Accessories For Your Ford", url: "/blogs/news/8-best-overlanding-accessories-for-your-ford" },

  //     { collections: "9 Best Ford Bronco Accessories", url: "/blogs/news/9-best-ford-bronco-accessories" },
  //     { collections: "Best Ford Ranger Accessories", url: "/blogs/news/best-ford-ranger-accessories" },
  //     { collections: "8 Must Have Ford F-150 Accessories", url: "/blogs/news/8-must-have-ford-f-150-accessories" },

  //     { collections: "Taking Care Of Your Vehicle All Year Long", url: "/blogs/news/taking-care-of-your-vehicle-all-year-long" },
  //     { collections: "5 Ways To Weatherproof Your Vehicle", url: "/blogs/news/5-ways-to-weatherproof-your-vehicle" },
  //     { collections: "Custom Lincoln Aviator - Angus's Build", url: "/blogs/news/custom-lincoln-aviator-anguss-build" },

  //     { collections: "Custom Lincoln Aviator - Vinnie's Build", url: "/blogs/news/custom-lincoln-aviator-vinnies-build" },
  //     { collections: "Orange Custom Ford Bronco - vinnie's Build", url: "/blogs/news/orange-custom-ford-bronco-vinnies-build" },
  //     { collections: "The Walking Dead Custom Bronco - Angus's Build", url: "/blogs/news/the-walking-dead-custom-bronco-anguss-build" },

  //     { collections: "Custom Ford F-150 FX4 - Vinnie's Build", url: "/blogs/news/custom-ford-f-150-fx4-vinnies-build" },
  //     { collections: "The Walking Dead Custom Ford F-150 - Angus's Build", url: "/blogs/news/the-walking-dead-custom-ford-f-150-anguss-build" },
  //   ]
  //   React.useEffect(() => {
  //     articlslist.map((d) => {
  //       if (location.pathname == d.url || location.pathname === OLD_HOME + d.url) {
  //         (async () => {
  //           let res = await AdobeService.track({
  //             pageName: `blogs:${d.collections}`,
  //             variantName: "blog-load",
  //             siteSection: "blogs",
  //             hierarchy: "blogs",
  //             pagenameNoVehicle: `blogs:${d.collections}`,
  //             pagenameNoProduct: `blogs:${d.collections}`,
  //             eventType: "blog-load",
  //           });
  //           AdobeService.callSatellite(res);
  //         })();
  //       }
  //     })
  //   }, [location.pathname])

  //   return (
  //     <>
  //       <div className="contact-us footer-nav__column__link-ul">
  //         <StyledTypography variant="overline">
  //           {t("Footer.RelatedLinks.Label")}
  //         </StyledTypography>
  //         <StyledTypography variant="caption">
  //           {RelatedLinks.map((v: any) => (
  //             <div key={v.linkURL}>
  //               <StyledTooltip>
  //                 <a
  //                   href={v.linkURL}
  //                   target="_blank"
  //                   rel="noopener noreferrer"
  //                 >
  //                   {v.linkText}
  //                 </a>
  //               </StyledTooltip>
  //             </div>
  //           ))}
  //         </StyledTypography>
  //       </div>
  //     </>
  //   );
  // };
  const applyAdobeForArticls = (location) => {
    const articlslist = [
      { collections: "Different Types Of Bike Racks", url: "/blogs/news/different-types-of-bike-racks" },
      { collections: "How To strap A Kayak To A Roof Rack", url: "/blogs/news/how-to-strap-a-kayak-to-a-roof-rack" },
      { collections: "Must Have Ford Raptor Accessories", url: "/blogs/news/must-have-ford-raptor-accessories" },

      { collections: "Vehicle Camping Packing List", url: "/blogs/news/vehicle-camping-packing-list" },
      { collections: "Your Guide To A 'Blacked-Out' Vehicle Look", url: "/blogs/news/your-guide-to-a-blacked-out-vehicle-look" },
      { collections: "Must-Have Vehicle Accessories For Summer", url: "/blogs/news/must-have-vehicle-accessories-for-summer" },

      { collections: "Bronco vs. Bronco Sport", url: "/blogs/news/bronco-vs-bronco-sport" },
      { collections: "Winter Car Camping Accessories For Your Ford", url: "/blogs/news/winter-car-camping-accessories-for-your-ford" },
      { collections: "Best Ford Mustang Accessories", url: "/blogs/news/best-ford-mustang-accessories" },

      { collections: "Our Best Towing Accessories", url: "/blogs/news/our-best-towing-accessories" },
      { collections: "Make The Most Of Your Maverick®", url: "/blogs/news/make-the-most-of-your-maverick" },
      { collections: "How To Winterize Your Vehicle", url: "/blogs/news/how-to-winterize-your-vehicle" },

      { collections: "Our Best Snowboard Racks", url: "/blogs/news/our-best-snowboard-racks" },
      { collections: "Family Road Trip Tips", url: "/blogs/news/family-road-trip-tips" },
      { collections: "5 Unique Ways To Use Your Truck Bed", url: "/blogs/news/5-unique-ways-to-use-your-truck-bed" },

      { collections: "5-unique-ways-to-use-your-truck-bed", url: "/blogs/news/5-unique-ways-to-use-your-truck-bed" },
      { collections: "Overlanding 101", url: "/blogs/news/overlanding-101" },
      { collections: "Tailgating Essentials", url: "/blogs/news/tailgating-essentials" },

      { collections: "Best Truck Bed Accessories", url: "/blogs/news/best-truck-bed-accessories" },
      { collections: "5 Essentials For Off Road Adventures", url: "/blogs/news/5-essentials-for-off-road-adventures" },
      { collections: "8 Best Overlanding Accessories For Your Ford", url: "/blogs/news/8-best-overlanding-accessories-for-your-ford" },

      { collections: "9 Best Ford Bronco Accessories", url: "/blogs/news/9-best-ford-bronco-accessories" },
      { collections: "Best Ford Ranger Accessories", url: "/blogs/news/best-ford-ranger-accessories" },
      { collections: "8 Must Have Ford F-150 Accessories", url: "/blogs/news/8-must-have-ford-f-150-accessories" },

      { collections: "Taking Care Of Your Vehicle All Year Long", url: "/blogs/news/taking-care-of-your-vehicle-all-year-long" },
      { collections: "5 Ways To Weatherproof Your Vehicle", url: "/blogs/news/5-ways-to-weatherproof-your-vehicle" },
      { collections: "Custom Lincoln Aviator - Angus's Build", url: "/blogs/news/custom-lincoln-aviator-anguss-build" },

      { collections: "Custom Lincoln Aviator - Vinnie's Build", url: "/blogs/news/custom-lincoln-aviator-vinnies-build" },
      { collections: "Orange Custom Ford Bronco - vinnie's Build", url: "/blogs/news/orange-custom-ford-bronco-vinnies-build" },
      { collections: "The Walking Dead Custom Bronco - Angus's Build", url: "/blogs/news/the-walking-dead-custom-bronco-anguss-build" },

      { collections: "Custom Ford F-150 FX4 - Vinnie's Build", url: "/blogs/news/custom-ford-f-150-fx4-vinnies-build" },
      { collections: "The Walking Dead Custom Ford F-150 - Angus's Build", url: "/blogs/news/the-walking-dead-custom-ford-f-150-anguss-build" },
    ];
    articlslist.map((d) => {
      if (location.pathname == d.url || location.pathname === OLD_HOME + d.url) {
        (async () => {
          let res = await AdobeService.track({
            pageName: `blogs:${d.collections}`,
            variantName: "blog-load",
            siteSection: "blogs",
            hierarchy: "blogs",
            pagenameNoVehicle: `blogs:${d.collections}`,
            pagenameNoProduct: `blogs:${d.collections}`,
            eventType: "blog-load",
          });
          AdobeService.callSatellite(res);
        })();
      }
    });
  }
  const applyAdobeForBrand = (location) => {
    const brandscollectionlist = [
      { collections: "Truxedo", url: "/truxedo" },
      { collections: "Yakima Products", url: "/yakima" },
      { collections: "Undercover", url: "/undercover" },
      { collections: "Covercraft Industries", url: "/covercraft" },

      { collections: "Thule", url: "/thule" },
      { collections: "Bushwacker", url: "/bushwacker" },
      { collections: "Bestop", url: "/bestop" },
      { collections: "Gatorback", url: "/gatorback" },
      { collections: "Advantage", url: "/advantage" },
    ];
    brandscollectionlist.map((d) => {
      if (location.pathname == d.url || location.pathname === OLD_HOME + d.url) {
        (async () => {
          let res = await AdobeService.track({
            pageName: `shop by brands:${d.collections}`,
            variantName: "global-nameplate-load",
            siteSection: "shop by brands",
            pagenameNoProduct: `shop by brands:${d.collections}`,
            hierarchy: "shop by brands",
            eventType: "global-nameplate-load",
          });
          AdobeService.callSatellite(res);
        })();
      }
    }
    );
  }
  const applyAdobeTagsFromPathname = (location) => {
    applyAdobeForBrand(location);
    applyAdobeForArticls(location);
    if (location.pathname == "/account" || location.pathname === ACCOUNT_OLD) {
      (async () => {
        let res = await AdobeService.track({
          pageName: "account dashboard",
          variantName: "account-details",
          siteSection: "account",
          hierarchy: "account",
          pagenameNoProduct: "account",
          eventType: "account",
        });
        AdobeService.callSatellite(res);
      })();
    } else if (location.pathname == "/account/wish-list" || location.pathname === WISH_LIST_OLD) {
      (async () => {
        let res = await AdobeService.track({
          pageName: "account:wishlist",
          variantName: "account-details",
          siteSection: "account",
          pagenameNoProduct: "account:wishlist",
          hierarchy: "account:wishlist",
          eventType: "account wishlist",
        });
        AdobeService.callSatellite(res);
        // console.log("11111 wishlist",adobeTagCall);  
      })();
    } else if (location.pathname == "/the-road-ahead" || location.pathname === OLD_HOME + "/the-road-ahead") {
      (async () => {
        let res = await AdobeService.track({
          pageName: "blogs:landing",
          variantName: "blog-load",
          siteSection: "blogs",
          pagenameNoProduct: "blogs:landing",
          hierarchy: "blogs",
          eventType: "blogs",
        });
        AdobeService.callSatellite(res);
      })();
    } else if (location.pathname == "/MakeReturn" || location.pathname === MAKERETURN_OLD) {
      (async () => {
        let res = await AdobeService.track({
          pageName: "make a return",
          variantName: "global-load",
          siteSection: "help center",
          pagenameNoProduct: "make a return",
          hierarchy: "help center",
          eventType: "returns",
        });
        AdobeService.callSatellite(res);
      })();
    }
  }
  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      if (location.pathname == "/" || location.pathname === OLD_HOME + "/") {
        (async () => {
          let res = await AdobeService.track({
            pageName: "home",
            variantName: "global-load",
            siteSection: "home",
            pagenameNoProduct: `home`,
            hierarchy: "home",
            eventType: "homeload",
          });
          const isUserLoggedin = localStorageUtil.get(CURRENT_USER) && localStorageUtil.get(CURRENT_USER)?.userLoggedIn;
          res = {
            ...res,
            user: {
              loginStatus: isUserLoggedin ? 'logged in' : 'logged out',
              registeredStatus: isUserLoggedin ? "registered" : "non-registered"
            }
          }
          AdobeService.callSatellite(res);
        })();

      }
    }, 0);
    if (location.pathname) {
      applyAdobeTagsFromPathname(location);
    }

  }, [location.pathname])
  const isLoggedIn = loginStat;
  var protocol = window.location.protocol;
  var port = !window.location.port.trim() ? '' : ':' + window.location.port;
  var url = window.location.href;
  var currentURL = url.indexOf("&krypto") > 0 ? url.substring(0, url.indexOf("&krypto")) : url;
  const FMA_LOGIN_LINK = `${mySite.storeCfg.userData["FMA_SIGN_IN"]}?redirectURL=${protocol}//${window.location.hostname}${port}/LoginRedirect?storeId=${mySite.storeID}&catalogId=${mySite.catalogID}&langId=-1&currentURL=${encodeURIComponent(currentURL)}`
  const FMA_LOGOUT_LINK = `${mySite.storeCfg.userData["FMA_SIGN_OUT"]}?redirectURL=${encodeURIComponent(`${protocol}//${window.location.hostname}${port}/LogoutRedirect?storeId=${mySite.storeID}&catalogId=${mySite.catalogID}&langId=-1&lang=en_us`)}&currentURL=${encodeURIComponent(window.location.origin)}/FordUSAccessories&pid=${localStorage.getItem("pid")}&sessionId=${localStorage.getItem("sessionId")}`

  const FooterContent =
    HTMLReactParser(footerTop, {
      replace: (domNode) => {
        if (domNode['attribs'] && domNode['attribs'].id === "Login") {
          if (isLoggedIn) {
            return (
              <a id="Logout" href={FMA_LOGOUT_LINK} className="sc-iemWCZ iRMAGA">LOGOUT</a>
            );
          }
          else {
            return (
              <a id="Login" href={FMA_LOGIN_LINK}>{domNode?.['children'][0]?.data}</a>
            );
          }
        }
        else if (domNode['attribs'] && domNode['attribs'].id === "Create_an_Account") {
          if (isLoggedIn) {
            return (
              <></>
            );
          }
          else {
            return (
              <a id="Create_an_Account" href={FMA_LOGIN_LINK}>{domNode?.['children'][0]?.data}</a>
            );
          }
        }
        else if (domNode['attribs'] && domNode['attribs'].id === "Account_Details") {
          if (isLoggedIn) {
            return (
              <Link to="/account/addresses">{domNode?.['children'][0]?.data}</Link>
            );
          }
          else {
            return (
              <a id="Account_Details" href={FMA_LOGIN_LINK}>{domNode?.['children'][0]?.data}</a>
            );
          }
        }
        else if (domNode['attribs'] && domNode['attribs'].id === "Orders") {
          if (isLoggedIn) {
            return (
              <Link to="/account/order-history">{domNode?.['children'][0]?.data}</Link>

            );
          }
          else {
            return (
              <a id="Orders" href={FMA_LOGIN_LINK}>{domNode?.['children'][0]?.data}</a>
            );
          }
        }
        else if (domNode['attribs'] && domNode['attribs'].id === "Garage") {
          if (isLoggedIn) {
            return (
              <Link to="/account/my-garage">{domNode?.['children'][0]?.data}</Link>
            );
          }
          else {
            return (
              <a id="Garage" href={FMA_LOGIN_LINK}>{domNode?.['children'][0]?.data}</a>
            );
          }
        }
        else if (domNode['attribs'] && domNode['attribs'].id === "Feedback") {
          return (
            <a id="Feedback" href="javascript:void(0);" onClick={() => { (window as any).KAMPYLE_ONSITE_SDK.showForm(8521) }} className="sc-iemWCZ iRMAGA">{domNode?.['children'][0]?.data}</a>
          );
        }
        return domNode;
      },
    });
     
  return (
      <>
      {showFooter && <Suspense fallback={<StyledProgressPlaceholder className="vertical-padding-20" style={{ height: '142px' }} />}>
        <StyledFooter style={{ marginTop: "0px", minHeight:'618px', background:"#0c1218"}}>
      <div style={{minHeight:'240px'}}>{typeof footerDisclouser === "string" && HTMLReactParser(footerDisclouser)}</div>

        {/* <StyledBox sx={{ background: "#102b4e", p: 2, color: "white" }}>
          <StyledContainer>
            <StyledTypography
              className="disclosure"
              variant="h3"
              align="center">
              {t("Footer.CaliforniaResidents")}
            </StyledTypography>

            <StyledTypography
              className="footer-text-block__subtitle"
              variant="subtitle2"
              align="center">
              {t("Footer.ConsumerPrivacyAct")}
              <a className="link-item" href={"https://www.ford.com/help/privacy/ccpa/?referringApplicationId=20426"} target="_blank">{t(" Here")}</a>
              {"."}
            </StyledTypography>
            <StyledTypography align="center">
              <button className="footerbtn" onClick={() => window.open("https://www.ford.com/help/privacy/ccpa/?referringApplicationId=20426", "_blank")}>
                Do Not Sell My Personal Information
              </button>
            </StyledTypography>
          </StyledContainer>
        </StyledBox> */}
        {isLargeDesktop && <StyledBox sx={{ background: "#0c1218", p: 2, color: "white" }}>

          {FooterContent}

        </StyledBox>}
        {isSmallDesktop && <FooterSm />}

        <StyledBox sx={{ background: "white", color: "black" }}>
          <StyledContainer>
            {HTMLReactParser(footerBottom)}
     
          </StyledContainer>
        </StyledBox>
      </StyledFooter></Suspense> }
      {/* <SessionErrorDialog /> */}
      <SessionTimeOutPopup />
      <ConfirmationDialog />
    </>
  );

}

export default withRouter(React.memo(Footer));