import React, { Component, useState } from "react";
import { useSite } from "../../../_foundation/hooks/useSite";
import eventBus from "../../../utils/event";

const SessionTimeOutPopup = () => {
  const [close, setClose] = useState(false);
  const { mySite } = useSite();
  var url = window.location.href;
  var protocol = window.location.protocol;
  var port = !window.location.port.trim() ? '' : ':' + window.location.port;
  var currentURL = url.indexOf("&krypto") > 0 ? url.substring(0, url.indexOf("&krypto")) : url;
  const FMA_LOGIN_LINK = `${mySite.storeCfg.userData["FMA_SIGN_IN"]}?redirectURL=${protocol}//${window.location.hostname}${port}/LoginRedirect?storeId=${mySite.storeID}&catalogId=${mySite.catalogID}&langId=-1&currentURL=${encodeURIComponent(currentURL)}`
  const FMA_LOGOUT_LINK = `${mySite.storeCfg.userData["FMA_SIGN_OUT"]}?redirectURL=${encodeURIComponent(`${protocol}//${window.location.hostname}${port}/LogoutRedirect?storeId=${mySite.storeID}&catalogId=${mySite.catalogID}&langId=-1&lang=en_us`)}&currentURL=${encodeURIComponent(currentURL)}&pid=${localStorage.getItem("pid")}&sessionId=${localStorage.getItem("sessionId")}` 
  
  const handleClose = () => {
    
    document.cookie = "WC_PERSISTENT" + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    setClose(false);
  };
  React.useEffect(() => {

    eventBus.on("autoLogoutEventPopUp", (data) => {
      if (data) {
        setClose(true);
        // const FMA_LOGOUT_LINK = `${mySite.storeCfg.userData["FMA_SIGN_OUT"]}?redirectURL=${encodeURIComponent(`${protocol}//${window.location.hostname}${port}/LogoutRedirect?storeId=${mySite.storeID}&catalogId=${mySite.catalogID}&langId=-1&lang=en_us`)}&currentURL=${encodeURIComponent(window.location.origin)}&pid=${localStorage.getItem("pid")}&sessionId=${localStorage.getItem("sessionId")}`
        // window.location.href = FMA_LOGOUT_LINK;
      }

    });
  }, []);
  return (
      <div id="fma__modal" className="fma-modal" data-fma-popup={close} style={{ display: close ? "block" : "none" }}>
      <div className={`fma_mo_content ${close ? "" : "hidden"}`}>
        {close && <img
          className="fma-popup_image"
          src="EmeraldSAS/images/Login-popup/22_Maverick-1_750X726_fe724b69-c92d-4091-ab6a-aa7489c0690d_1024x.png"
          alt="Session Timeout"
          width="600"
          height="300"
        /> }
        <a href={FMA_LOGOUT_LINK}><span className="fma-close" onClick={handleClose}>
          &times;
        </span></a>
        <div className="fma-popup-content">
          <h2 className="fma-header" style={{ color: "white", fontSize: "24px" }}>
            <span>Session Timeout </span>
          </h2>
          <a
            href={FMA_LOGOUT_LINK}
            onClick={handleClose}
            data-fma-redirect='{ "isCustomer": false, "action": "updateHref" }'
          >
            <button className="fma-login fma-login-mobile btn--rounded">
              Go To Home
            </button>
          </a>
          <p className="fma_description" style={{ color: "white", fontSize: "19px" }}>
            Your Session has timed out and you have been Logged off. Sign in again to access your store.
          </p>
          <a
            onClick={handleClose}
            href={FMA_LOGOUT_LINK}
            data-fma-redirect='{ "isCustomer": false, "action": "updateHref" }'
          >
            <button className="fma-login btn--rounded">Go To Home</button>
          </a>
        </div>
      </div>
    </div>
  );

};

export default SessionTimeOutPopup;





