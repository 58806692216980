import React, { useState } from "react";
//import Drawer from '@material-ui/core/Drawer';
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
//import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
//import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import "./HeaderFord.scss";

interface MenuListSmProps {
  data?: any;
}
const MenuListSm: React.FC<MenuListSmProps> = (props: any) => {
  const [openSubMenu, setOpenSubMenu] = useState(false);
  /* const [openPerDrawer, setOpenPerDrawer] = useState(false); */
  const data = props.data.menu ? props.data.menu : [];

  const toggleSubmenu = () => {
    props.data.closeNavigation(false);
  }   

  return (
    <>
      <ListItem>
        {data.Icon && (
          <img alt={data.Icon} src={data.Icon} height="26" width="26" />
        )}
        {!openSubMenu && (
          <ListItemText>
            <Link onClick={() => toggleSubmenu()} to={data.seo.href} className="link">
              {data.name}
            </Link>
          </ListItemText>
        )}
        {!openSubMenu && data.children && (
          <ListItemIcon
            onClick={() => setOpenSubMenu(!openSubMenu)}
            style={{ color: "#2861a4" }}>
            {" "}
            <ExpandMoreIcon
              aria-hidden="false"
              height="15px"
              width="15px"
            />{" "}
          </ListItemIcon>
        )}
      </ListItem>

      {openSubMenu && (
        <div className="Nestedlink">
          <List>
            <ListItem>
              <IconButton
                onClick={() => setOpenSubMenu(!openSubMenu)}
                style={{ color: "#2861a4" }}>
                <ChevronLeftIcon
                  aria-hidden="false"
                  height="30px"
                  width="30px"
                />
              </IconButton>

              <ListSubheader>
                <a href={data.seo.href} className="link_label" onClick={() => toggleSubmenu()}>
                  {data.name}
                </a>
              </ListSubheader>
            </ListItem>
            <Divider variant="middle" />

            {data.children.map((AccList) => (
              <ListItem>
                <ListItemText>
                  <Link to={AccList.seo.href} className="link_list" onClick={() => toggleSubmenu()}>
                    {AccList.name}
                  </Link>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </div>
      )}
    </>
  );
};
export default MenuListSm;
