/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { createReducer, AnyAction } from "@reduxjs/toolkit";
//Redux
import initStates from "./initStates";
import * as ACTIONS from "../action-types/search-now";

/**
 * search now reducer
 */
const searchNowReducer = createReducer(initStates.searchNow, (builder) => {
  builder.addCase(
    ACTIONS.FETCH_MODEL_YEAR_REQUESTED,
    (state: any, action: AnyAction) => {
      state.models = [];
      state.years = {};
    }
  );

  builder.addCase(
    ACTIONS.FETCH_MODEL_YEAR_SUCCESS,
    (state: any, action: AnyAction) => {
      const response = action.response;
      //console.log("reducer success state", action.response);
      state.models = response.models;
      state.years = response.years;
    }
  );

});
export default searchNowReducer;
