import {Component} from 'react';
import { Link } from 'react-router-dom';
import React from "react";
import jsonData from "../staticPagesMetaData.json";
import { useSite } from "../../../../_foundation/hooks/useSite";
import { Helmet } from "react-helmet";

const content =jsonData.contents[36]?.WinterCarCamp;
const handleShareClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    const url = event.currentTarget.dataset.copyToClipboard!;
    navigator.clipboard.writeText(url).then(() => {
        const confirmation = window.prompt("Copy to clipboard: Ctrl/Cmd+C, Enter", url);
        if (confirmation) {
          navigator.clipboard.writeText(url);
        }
      }).catch(() => {
        window.alert(`Failed to copy link to clipboard: ${url}`);
      });
    };

const breadcrumbList =  {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://accessories.ford.com/"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "News",
        "item": "https://accessories.ford.com/blogs/news"
      },
      {
        "@type":"ListItem",
        "position":3,
        "name":"Winter Car Camping Accessories for Your Ford",
        "item":"https://accessories.ford.com/blogs/news/winter-car-camping-accessories-for-your-ford"
    }
    ]
  }


const WinterCarCamp=()=>{
    const {storeDisplayName } = useSite();
    let port = !window.location.port.trim() ? '' : ':' + window.location.port;

    return(
<div>
    <Helmet>
        <title>{content?.title}</title>
        <meta name="description" content={content?.metaDescription}></meta>
        <meta name="keywords" content={content?.metaKeyword}/>
        <meta property="og:site_name" content={storeDisplayName}/>
        <meta property="og:url" content={(window.location.protocol +'//'+window.location.hostname+':' + port + window.location.pathname)}/>
        <meta property="og:title" content={content?.title}/>
        <meta property="og:type" content="article"/>  
        <meta property="og:description" content={content?.metaDescription}/>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={content?.title}/>  
        <meta name="twitter:description" content={content?.metaDescription} />
    </Helmet>
<div id="shopify-section-template-article" className="shopify-section">




<section className="article-hero" title="Blog Hero" style={{backgroundImage: "url(https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/WinterCarCamping/WinterCarCamping_RS_1500x.jpeg)"}}>
    <div className="article-hero__container container">

        
        

        
        
            <h1 className="article-hero__heading title--md">
                Winter Car Camping Accessories for Your Ford
            </h1>
        

        
        
            <div className="article-hero__publish-date">
                March 14, 2023
            </div>
        
    </div>
</section>



<div className="article__main">

    
    

<nav className="breadcrumbs" role="navigation">
    <ul className="flex">
        <li>
        <a href="/">
              <span>Home</span>
              </a>
                <span className="breadcrumbs-separator">&gt;</span>
              </li>
              <li>
                <Link to="/blogs/news">
                  <span>News</span>
                </Link>
            </li>
            <span className="breadcrumbs-separator">&gt;</span>
            <li>
                <span>Winter Car Camping Accessor...</span>
            </li></ul>
</nav>
<script type="application/ld+json">
      {JSON.stringify(breadcrumbList)}
    </script>
    <div className="article-actions">

        
        <Link className="article-actions__return" to="/blogs/news">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><path fill="#102b4e" d="M8,0 C12.418,0 16,3.582 16,8 C16,12.418 12.418,16 8,16 C3.582,16 0,12.418 0,8 C0,3.582 3.582,0 8,0 Z M6,3 C5.724,3 5.474,3.112 5.293,3.293 C5.112,3.474 5,3.724 5,4 C5,4.276 5.112,4.526 5.293,4.707 L9.409,8 L5.293,11.293 C5.112,11.474 5,11.724 5,12 C5,12.276 5.112,12.526 5.293,12.707 C5.474,12.888 5.724,13 6,13 C6.276,13 6.526,12.888 6.707,12.707 L11.707,8.707 C11.888,8.526 12,8.276 12,8 C12,7.724 11.888,7.474 11.707,7.293 L6.707,3.293 C6.526,3.112 6.276,3 6,3 Z"></path></g></svg>
            Back to articles
        </Link>

        
        

        <div className="social-icons " itemScope itemType="https://schema.org/Organization">
    <link itemProp="url" href="https://accessories.ford.com"/>

    

        
        
        
        

        
            <h3 className="social-icons__share-title">
                Share
            </h3>
        

        <div className="social-icons__list-item">
            <a href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/winter-car-camping-accessories-for-your-ford&amp;text=Winter+Car+Camping+Accessories+for+Your+Ford" target="_blank" rel="noopener" className="share">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16"><g><g><path fill="#102b4e" d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path></g></g></svg>
                <span className="visually-hidden">
                    Twitter
                </span>
            </a>
        </div>
        <div className="social-icons__list-item">
            <a href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/winter-car-camping-accessories-for-your-ford" target="_blank" rel="noopener" className="share">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><g><g><path fill="#102b4e" d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path></g><g><path fill="#102b4e" d="M.29 5.24h3.2v10.764H.29z"></path></g><g><path fill="#102b4e" d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path></g></g></g></svg>
                <span className="visually-hidden">
                    LinkedIn
                </span>
            </a>
        </div>
        <div className="social-icons__list-item">
            <a href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/winter-car-camping-accessories-for-your-ford" target="_blank" rel="noopener" className="share">
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16"><g><g><g><path fill="#102b4e" d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path></g></g></g></svg>
                <span className="visually-hidden">
                    Facebook
                </span>
            </a>
        </div>
        <div className="social-icons__list-item">
        <button className="share" onClick={handleShareClick}data-copy-to-clipboard="https://accessories.ford.com/blogs/news/winter-car-camping-accessories-for-your-ford">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><g><path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path></g></g></svg>
                <span className="visually-hidden">
                    Share URL
                </span>
            </button>
        </div>

    
</div>

</div>


    
    <article className="article-content rte">
    <span style={{fontWeight: 400}}>To be completely ingrained in the depths of winter is a daunting, yet beautiful adventure. With the help of winter camping accessories, you can make the most of nature’s wonders during this time of year. Although camping is an unforgettable adventure, campers need to prepare for all of winter’s surprises. Fortunately, your vehicle can be a huge support for your winter camping journey. To make the most of your next camping trip, make sure your vehicle is fully equipped with the best winter camping accessories!</span>
<p><span style={{fontWeight: 400}}><img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/WinterCarCamping/Tent.jpeg" alt="Winter Car Camping" width={'1480'} height={'100%'}/></span></p>
<h2><Link to="/search?searchTerm=tent"><span style={{fontWeight: 400}}>Tent</span></Link></h2>
<p><span style={{fontWeight: 400}}>First things first, a durable tent is one of the most important winter camping accessories you can own. Your tent acts as your shelter and protection from cold winds and snowfall, so be sure to pack a waterproof or water-resistant base camp for sleeping. Our wide variety of tents is heavily equipped with everything you need for the ultimate mobile base camp - not limited to foam mattresses, sleeping pads, abrasion-resistant flooring, and tie-downs. Even better, Ford offers truck bed, SUV, and rooftop tents so you can sleep in or on your vehicle, rather than on the extra-chilly ground in the winter months.&nbsp;</span></p>
<p><span style={{fontWeight: 400}} ><img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/WinterCarCamping/OffRoad_RecoveryKit_45370c62-9f22-47c1-9a3c-b17366af4e3a.jpeg"  width={'1480'} height={'100%'} alt="Off-Road Recovery kit"/></span></p>
<h2><Link to="/search?searchTerm=Off-Road+Recovery+Kit"><span style={{fontWeight: 400}}>Off-Road Recovery Kit</span></Link></h2>
<p><span style={{fontWeight: 400}}>When attempting to drive through snow, it’s not uncommon for your car to get stuck in its tracks. With a lack of traction on ice and snow’s slick surface, your car’s tires can lose traction or grip on the ground. To help prevent getting stuck in frigid weather, don’t forget to pick up an off-road recovery kit to prepare your car for winter. All Ford off-road-recovery kits come with recovery straps, winching gloves, and two WARN Industries D- shackles. If you are camping in a larger vehicle, a </span><Link to="/ford-performance-by-warn-off-road-heavy-duty-recovery-kit"><span style={{fontWeight: 400}}>heavy-duty recovery kit</span></Link><span style={{fontWeight: 400}}> may be your best bet.</span></p>
<img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/WinterCarCamping/Awning.jpeg" width={'1480'} height={'100%'}  alt="Awning"/><br/>
<h2><Link to="/yakima-awning-1"><span style={{fontWeight: 400}}>Awning</span></Link></h2>
<p><span style={{fontWeight: 400}}>With an all-weather Yakima Awning, you can enjoy the sight of freshly fallen snow without being disrupted by glaring sun rays or sudden showers. Needless to say, this is a must-have camping accessory. Specifically, Ford awnings provide campers with</span> <span style={{fontWeight: 400}}>a wide span of coverage and a carry case for easy travel. Better yet, our awnings are a breeze to set up. Just pull out your awning, prop it on your vehicle and insert the poles at your campsite!&nbsp;</span></p>
<p><span style={{fontWeight: 400}}><img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/WinterCarCamping/FirstAidKit.jpeg" width={'1480'} height={'100%'}  alt="First Aid Kid"/></span></p>
<h2><Link to="/bronco-2021-first-aid-safety-kit-1"><span style={{fontWeight: 400}}>First-Aid Kit</span></Link></h2>
<p><span style={{fontWeight: 400}}>A first-aid kit can be a necessity, especially when you’re seeking out a rugged winter adventure. Our Ford First Aid Safety Kit is one of the best winter car camping accessories for taking care of minor cuts and injuries. Not only does this kit come with traditional medical care like assorted bandages and gauze, but it also provides an emergency survival blanket.&nbsp;</span></p>
<img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/WinterCarCamping/RoofRack_f6b8f6c2-92d8-4cb3-88d4-347c35cb0685.jpeg" width={'100%'} height={'100%'}  alt="Roof Rack"/><br/>
<h2><Link to="/search?searchTerm=roof+rack"><span style={{fontWeight: 400}}>Roof Rack&nbsp;</span></Link></h2>
<p><span style={{fontWeight: 400}}>If the inside of your vehicle is packed to the brim, extend your packing to the roof of your vehicle. With a roof rack engineered to fit your specific Ford model, you can bring your larger pieces of gear along for the ride. From snowboards and skis to additional tents and gear, a roof rack has the capability and strength to manage those last remaining items. When it comes to camping, you need as much space as you can get – especially if you’re going to be sleeping in your vehicle.&nbsp;</span></p>
<br/><img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/WinterCarCamping/CargoCarrier.jpeg" width={'1480'} height={'100%'}  alt="Cargo Carrier"/><br/>
<h2><Link to="/search?searchTerm=cargo+box"><span style={{fontWeight: 400}}>Cargo Box</span></Link></h2>
<p><span style={{fontWeight: 400}}>Secure your rooftop belongings with a rack-mounted cargo box. Cargo boxes will protect your camping gear from winter weather and give you the ability to pack plenty of extra gear. Most cargo boxes are also able to carry the length of skis and snowboards, meaning you can make a stop at the slopes after your camping adventure.&nbsp;</span></p>
<p><span style={{fontWeight: 400}}><img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/WinterCarCamping/PivotStorageBox.jpeg" width={'1480'} height={'100%'}  alt="Pivot Storage Box"/></span></p>
<h2><Link to="/search?searchTerm=storage+box"><span style={{fontWeight: 400}}>Pivot Storage Box</span></Link></h2>
<p><span style={{fontWeight: 400}}>If you’re camping with a truck, you can further protect your gear with a truck bed storage box. Camping in your car requires endless amounts of gear, food, and other necessities, but with a storage box, you can make sure your possessions stay out of sight and secure. When you opt for a storage box designed for your Ford, your gear will be secure between the wheel well and the back of your truck bed, reducing the damage to your possessions from lying loose in the truck bed.&nbsp;</span></p>
<br/><img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/WinterCarCamping/CargoCover.jpeg" width={'1480'} height={'100%'}  alt="Cargo Cover"/><br/>
<h2><Link to="/search?searchTerm=cargo+cover"><span style={{fontWeight: 400}}>Cargo Cover</span></Link></h2>
<p><span style={{fontWeight: 400}}>When leaving your vehicle at your campsite, it’s important to conceal your belongings inside your vehicle with a cargo security cover. Security covers attach to the back of your rear seat to block out any sight of belongings. Once you return, you can simply fold and store the cover in your trunk. To preserve the aesthetics of your vehicle’s interior, be sure to purchase a security cover designed to complement your Ford model.&nbsp;</span></p>
<br/><img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/WinterCarCamping/DeckedDrawerSystem.jpeg"  width={'1480'} height={'100%'} alt="Decked Drawer Systems"/><br/>
<h2><Link to="/search?searchTerm=drawer"><span style={{fontWeight: 400}}>Decked Drawer Systems &amp; Cargo Area</span></Link></h2>
<p><span style={{fontWeight: 400}}>To consolidate your possessions in your truck bed, consider investing in a decked drawer system for your vehicle. Winter car camping often occurs in remote areas and can involve expensive gear that you’ll want to keep secure and out of sight. With a decked drawer system, you can also carry cargo on top of your organized tools below. Access to your tools is no problem either since each drawer can be easily pulled out along the tailgate.&nbsp;</span></p>
<p><span style={{fontWeight: 400}}><img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/WinterCarCamping/AirCompressor_6252ffc7-d045-4271-b632-2139d9b03663.jpeg" width={'1480'} height={'100%'}  alt="ARB Ford Performance Portable Air Compressor Kit"/></span></p>
<h2><Link to="/arb-ford-performance-portable-air-compressor-kit"><span style={{fontWeight: 400}}>ARB Ford Performance Portable Air Compressor Kit</span></Link></h2>
<p><span style={{fontWeight: 400}}>Don’t risk getting a flat tire in the depths of the woods. Have a backup plan with an air compressor kit to inflate your tires when they’re running low. For camping purposes, you’ll want to stick with a portable, lightweight air compressor to ease your travels and load. Our ARB High Output Air Compressor is perfect to get you back on the road– equipped with a 19-foot air hose, battery clamps, and an air chuck, all in a light carrying case.&nbsp;</span></p>
<p>&nbsp;</p>
<h2><span style={{fontWeight: 400}}>Other Winter Car Camping Essentials</span></h2>
<p><span style={{fontWeight: 400}}>Before you head off on your winter camping adventure, don’t forget to also pack these safety essentials:&nbsp;</span></p>
<ul>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Warm layers (base layer clothing and extra layers, just in case)</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Insulated sleeping bag</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Air mattress</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Sleeping pad/reflective heat mats</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Camping lanterns</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Hot water bottles</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Water boiler/stove (boil water for sterilization, cooking, hot drinks, or warmth)</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Condensation rags</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Winter boots</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Warm wool socks</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Extra battery-powered charging packs</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Blankets</span></li>
<li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Winter gloves</span></li>
</ul>
<p>&nbsp;</p>
<h2><span style={{fontWeight: 400}}>Prepping For Every Winter Adventure</span></h2>
<p><span style={{fontWeight: 400}}>In search of more winter car camping accessories? Ford Accessories has an endless variety of available </span><a href="/"><span style={{fontWeight: 400}}>parts and accessories</span></a><span style={{fontWeight: 400}}> for your vehicle to make your winter adventures as safe and comfortable as possible. Additionally, if you’re prepping to bring your snowboard along for the ride, first check out our list of best snowboard racks for your Ford!</span></p>

    
    

</article>


    
    


    
    <div className="article-share">
        
    <div className="social-icons " itemScope itemType="https://schema.org/Organization">
    <link itemProp="url" href="https://accessories.ford.com"/>

    

        
        
        
        

        
            <h3 className="social-icons__share-title">
                Share Article
            </h3>
        

        <div className="social-icons__list-item">
            <a href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/winter-car-camping-accessories-for-your-ford&amp;text=Winter+Car+Camping+Accessories+for+Your+Ford" target="_blank" rel="noopener" className="share">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16"><g><g><path fill="#102b4e" d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path></g></g></svg>
                <span className="visually-hidden">
                    Twitter
                </span>
            </a>
        </div>
        <div className="social-icons__list-item">
            <a href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/winter-car-camping-accessories-for-your-ford" target="_blank" rel="noopener" className="share">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><g><g><path fill="#102b4e" d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path></g><g><path fill="#102b4e" d="M.29 5.24h3.2v10.764H.29z"></path></g><g><path fill="#102b4e" d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path></g></g></g></svg>
                <span className="visually-hidden">
                    LinkedIn
                </span>
            </a>
        </div>
        <div className="social-icons__list-item">
            <a href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/winter-car-camping-accessories-for-your-ford" target="_blank" rel="noopener" className="share">
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16"><g><g><g><path fill="#102b4e" d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path></g></g></g></svg>
                <span className="visually-hidden">
                    Facebook
                </span>
            </a>
        </div>
        <div className="social-icons__list-item">
            <button className="share" onClick={handleShareClick}data-copy-to-clipboard="https://accessories.ford.com/blogs/news/winter-car-camping-accessories-for-your-ford">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><g><path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path></g></g></svg>
                <span className="visually-hidden">
                    Share URL
                </span>
            </button>
        </div>

    
</div>

    </div>

    
    

    
    <div className="article-pagination article-pagination--desktop">
        
            <Link className="article-pagination__item article-pagination__item--previous" to="/blogs/news/best-ford-mustang-accessories" onClick={() => window.scrollTo(0, 0)}>
                <div className="article-pagination__arrow">
                    <img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/chevron-thin-white-right_60x.jpeg"  width={'32'} height={'62'} alt="Chevron White"/>
                </div>
                <div className="article-pagination__data">
                    <div className="article-pagination__label">
                        Previous Article
                    </div>
                    <div className="article-pagination__title">
                        Best Ford Mustang Accessories
                    </div>
                </div>
            </Link>
        
                
            <Link className="article-pagination__item article-pagination__item--next" to="/blogs/news/bronco-vs-bronco-sport" onClick={() => window.scrollTo(0, 0)}>
                <div className="article-pagination__data">
                    <div className="article-pagination__label">
                        Next Article
                    </div>
                    <div className="article-pagination__title">
                        Bronco vs. Bronco Sport
                    </div>
                </div>
                <div className="article-pagination__arrow">
                    <img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/chevron-thin-white-right_60x.jpeg"  width={'32'} height={'62'} alt="Chevron White"/>
                </div>
            </Link>
        
    </div>

    
    <div className="article-pagination article-pagination--mobile">
        
            <Link className="article-pagination__item article-pagination__item--previous" to="/blogs/news/best-ford-mustang-accessories" onClick={() => window.scrollTo(0, 0)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="15" viewBox="0 0 8 15"><g><g transform="rotate(-270 4 7.5)"><path fill="#fff" d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z"></path></g></g></svg>
                Prev Article
            </Link>
        
                
            <Link className="article-pagination__item article-pagination__item--next" to="/blogs/news/bronco-vs-bronco-sport" onClick={() => window.scrollTo(0, 0)}>
                Next Article
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="15" viewBox="0 0 8 15"><g><g transform="rotate(-270 4 7.5)"><path fill="#fff" d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z"></path></g></g></svg>
            </Link>
        
    </div>


</div>





</div>




</div>

    );

}

export default WinterCarCamp;