import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Drawer from "@material-ui/core/Drawer";
import { Link } from "react-router-dom";
import List from '@material-ui/core/List';
import { useSelector, useDispatch } from "react-redux";
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CloseIcon from '@material-ui/icons/Close';
import MenuListSm from './MenuListSm'; 
import "./HeaderFord.scss";
import {
  StyledTooltip
} from "@hcl-commerce-store-sdk/react-component";
import eventBus from "./../../utils/event";
import { currentVehiclesSelector } from "../../redux/selectors/searchNow";
import { useSite } from "../../_foundation/hooks/useSite";
import { localStorageUtil } from "../../_foundation/utils/storageUtil";
import { loginStatusSelector, logonIdSelector, userNameSelector } from "../../redux/selectors/user";
import mygarageService from "../../_foundation/apis/transaction/mygarage.service";
import * as searchNowActions from "../../redux/actions/search-now";

interface MenuSmProps {
  data?: any; 
}

const MenuSm: React.FC<MenuSmProps> = (props: any) => {
const { t } = useTranslation();
const data = props.data ? props.data : [];
////console.log("MenuSmData",data);
const [openDrawer, setOpenDrawer] = useState(false);

let url = window.location.href;
let protocol = window.location.protocol;
let port = !window.location.port.trim() ? '' : ':' + window.location.port;
let currentURL = url.indexOf("&krypto") > 0 ? url.substring(0, url.indexOf("&krypto")) : url;

const dispatch = useDispatch();
const [myGarage, setMyGarage] = useState("");
//const [fordRewardPoints, setfordRewardPoints] = useState("");
const vehicles = useSelector(currentVehiclesSelector);
const { firstName, lastName } = useSelector(userNameSelector);
const fullName = firstName + " " + lastName;
const isUserLoggedIn = useSelector(loginStatusSelector);
const { mySite } = useSite();
const FMA_LOGIN_LINK = `${mySite.storeCfg.userData["FMA_SIGN_IN"]}?redirectURL=${protocol}//${window.location.hostname}${port}/LoginRedirect?storeId=${mySite.storeID}&catalogId=${mySite.catalogID}&langId=-1&currentURL=${encodeURIComponent(currentURL)}`; 

const removeLocalShippingData = () => {
  localStorageUtil.remove("shippingAddressId1")
}
const closeNavigation = (ele) => {
  setOpenDrawer(ele);
}


const deleteVehicle = () => {
  if (vehicles?.default?.vehicleId) {
    mygarageService.deleteVehicle(vehicles?.default?.vehicleId).then((res) => {
      dispatch(
        searchNowActions.getVehiclesAction({ isUserLoggedIn })
      );
    })
      .catch((e) => {
        //console.log(e);
      });
  }

}
const clearMyGarage = () => {
  localStorage.removeItem("myGarage");
  localStorage.removeItem("currentVehicle");
  eventBus.dispatch("clearMyGarageData", {});
  eventBus.dispatch("clearMyGarageDatafromPLP", {}); 
  setMyGarage("");
  dispatch(
    searchNowActions.getVehiclesAction({ isUserLoggedIn })
  );
  /*if (isUserLoggedIn) {
    deleteVehicle();
  } else {
    dispatch(
    searchNowActions.getVehiclesAction({ isUserLoggedIn })
  );
  }*/
   
};


  const HeaderLinks = [
    // {
    //   linkText: t("Header.HeaderLinks.FindADealer"),
    //   linkURL: "FindADealer",
    // },
    {
      linkText: t("Header.HeaderLinks.Wishlist"),
      linkURL: "/account/wish-list",
    },
    {
      linkText: t("Header.HeaderLinks.Contact"),
      linkURL: "/contact-us",
    },
    {
      linkText: t("Header.HeaderLinks.ReturnPolicy"),
      linkURL: "/return",
    },
    {
      linkText: t("Header.HeaderLinks.FAQ"),
      linkURL: "/Faq",
    },
  ];

  return (
    <span className="Icon_menu">
      {openDrawer ? (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          className="icon"
          onClick={() => setOpenDrawer(false)}>
          <CloseIcon />
        </IconButton>
      ) : (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          className="icon"
          onClick={() => setOpenDrawer(!openDrawer)}>
          <MenuIcon />
        </IconButton>
      )}

      <Drawer
        variant="persistent"
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        classes={{ paper: "drawerPaper" }}>
        <List className="site-header__nav-list">
          {data &&
            data.map((menu: any, index: number) => <MenuListSm data={{menu, closeNavigation}} />)}
        </List>

        <Divider className="divider" variant="middle" />
        <List className="site-header-eyebrow__nav">
          {/* {[
            "WISHLIST",
            "CONTACT",
            "RETURN POLICY",
            "FAQS",
          ].map((text, index) => (
            <ListItem className="site-header-eyebrow__nav-list-item">
              <ListItemText className="link_list2">{text}</ListItemText>
            </ListItem>
          ))} */}

          {HeaderLinks.map((v: any) => (
              <span key={v.linkURL}>
                <StyledTooltip className="site-header-eyebrow__nav-list-item header-top-button">
                  {
                    v.linkURL == "/account/wish-list" ?
                      <>
                        {isUserLoggedIn ?
                          <>
                            <Link onClick={() => closeNavigation(false)}  className="link_list2" key={v.linkURL} to={v.linkURL} >{v.linkText}</Link>
                          </>
                          :
                          <a onClick={() => closeNavigation(false)}  className="link_list2" href={FMA_LOGIN_LINK}>{v.linkText}</a>
                        }
                      </>
                      :
                      <Link onClick={() => closeNavigation(false)}  className="link_list2" key={v.linkURL} to={v.linkURL} >{v.linkText}</Link>
                  }
                </StyledTooltip>
              </span>
            ))}
        </List>

        {/* this block is showing My garage & My account in mobile */}
        <div className="DrawerBottomContainer">
          {isUserLoggedIn ? 
            <>
              {/* <Link to="/" className="DrawerBottom">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="20"
                  viewBox="0 0 17 20">
                  <g>
                    <g>
                      <path
                        fill="none"
                        stroke="#102b4e"
                        stroke-miterlimit="20"
                        stroke-width=".83"
                        d="M8.5 1v0s3.29-.121 4.593 2.473c.97 1.93.862 4.686-.789 6.134-.694.607-1.256.86-1.256.86v.646S16 12.246 16 15.761c0 0-.77.923-2.26 1.814A9.865 9.865 0 0 1 8.6 19c-2.313 0-4.145-.682-5.34-1.425C1.83 16.684 1 15.76 1 15.76c0-3.515 4.952-4.648 4.952-4.648v0-.646 0s-.562-.253-1.255-.86C3.045 8.16 2.94 5.404 3.907 3.473 5.21.879 8.501 1 8.501 1z"></path>
                    </g>
                  </g>
                </svg>
                My Account{" "}
              </Link> */}
              
              <Link to="/account" onClick={() => closeNavigation(false)}  className="DrawerBottom" style={{ marginBottom: "5px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="20"
                  viewBox="0 0 17 20">
                  <g>
                    <g>
                      <path
                        fill="none"
                        stroke="#102b4e"
                        stroke-miterlimit="20"
                        stroke-width=".83"
                        d="M8.5 1v0s3.29-.121 4.593 2.473c.97 1.93.862 4.686-.789 6.134-.694.607-1.256.86-1.256.86v.646S16 12.246 16 15.761c0 0-.77.923-2.26 1.814A9.865 9.865 0 0 1 8.6 19c-2.313 0-4.145-.682-5.34-1.425C1.83 16.684 1 15.76 1 15.76c0-3.515 4.952-4.648 4.952-4.648v0-.646 0s-.562-.253-1.255-.86C3.045 8.16 2.94 5.404 3.907 3.473 5.21.879 8.501 1 8.501 1z"></path>
                    </g>
                  </g>
                </svg>
                <span>{fullName} {/* {email} */}</span>
              </Link>
            </>
            :
            <a href={FMA_LOGIN_LINK} className="DrawerBottom"  onClick={() => removeLocalShippingData()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="20"
                viewBox="0 0 17 20">
                <g>
                  <g>
                    <path
                      fill="none"
                      stroke="#102b4e"
                      stroke-miterlimit="20"
                      stroke-width=".83"
                      d="M8.5 1v0s3.29-.121 4.593 2.473c.97 1.93.862 4.686-.789 6.134-.694.607-1.256.86-1.256.86v.646S16 12.246 16 15.761c0 0-.77.923-2.26 1.814A9.865 9.865 0 0 1 8.6 19c-2.313 0-4.145-.682-5.34-1.425C1.83 16.684 1 15.76 1 15.76c0-3.515 4.952-4.648 4.952-4.648v0-.646 0s-.562-.253-1.255-.86C3.045 8.16 2.94 5.404 3.907 3.473 5.21.879 8.501 1 8.501 1z"></path>
                  </g>
                </g>
              </svg>
              My Account{" "}
            </a> 

          }
          

          <Divider orientation="vertical" flexItem />

          <span data-vehicle-count={vehicles?.all?.length}
            className={ 
              vehicles?.all?.length > 0
                ? "DrawerBottom user-menu__item--garage user-menu__item--garageUnauthed user-menu__item--garage-no-chevron has-vehicles"
                : "DrawerBottom user-menu__item--garage user-menu__item--garageUnauthed user-menu__item--garage-no-chevron"
          }>
            {isUserLoggedIn ? 
              <Link to="/account/my-garage" >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="20"
                  viewBox="0 0 23 20">
                  <g>
                    <g>
                      <g>
                        <path
                          fill="none"
                          stroke="#112b4e"
                          stroke-linecap="round"
                          stroke-miterlimit="20"
                          stroke-width="1.5"
                          d="M1 8v0l10.5-7v0L22 8v0"></path>
                      </g>
                      <g>
                        <path
                          fill="none"
                          stroke="#112b4e"
                          stroke-miterlimit="20"
                          d="M21 7v12H2v0V7v0"></path>
                      </g>
                      <g>
                        <g>
                          <path
                            fill="none"
                            stroke="#112b4e"
                            stroke-miterlimit="20"
                            stroke-width=".5"
                            d="M7.607 11.86v-1.392s.068-.886.887-1.329c.409-.19.886-.19 1.91-.19h2.114c1.023 0 1.5 0 1.91.19.819.443.887 1.33.887 1.33v1.392zm11.133-1.138c-.205-.19-2.322-.127-2.322-.127s0-.57-.478-1.33C15.325 8.128 13.344 8 13.344 8H9.178s-1.503.127-2.118 1.266c-.41.823-.478 1.329-.478 1.329s-2.117-.127-2.322.127c-.205.19-.342.632-.205.886.205.443.683.506 1.23.506 0 0-.274 1.14-.274 2.342 0 1.202.069 1.772.069 1.772v1.645l.204.127h1.952l.205-.19v-1.202h8.118v1.202l.205.19h1.952l.204-.127v-1.645s.069-.57.069-1.772c0-1.203-.273-2.342-.273-2.342.546 0 1.024-.063 1.229-.506.137-.254 0-.633-.205-.886z"></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                <span>My Garage</span>
              </Link>
              :
              <>
                <a href={FMA_LOGIN_LINK} onClick={() => removeLocalShippingData()}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="20"
                    viewBox="0 0 23 20">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="none"
                            stroke="#112b4e"
                            stroke-linecap="round"
                            stroke-miterlimit="20"
                            stroke-width="1.5"
                            d="M1 8v0l10.5-7v0L22 8v0"></path>
                        </g>
                        <g>
                          <path
                            fill="none"
                            stroke="#112b4e"
                            stroke-miterlimit="20"
                            d="M21 7v12H2v0V7v0"></path>
                        </g>
                        <g>
                          <g>
                            <path
                              fill="none"
                              stroke="#112b4e"
                              stroke-miterlimit="20"
                              stroke-width=".5"
                              d="M7.607 11.86v-1.392s.068-.886.887-1.329c.409-.19.886-.19 1.91-.19h2.114c1.023 0 1.5 0 1.91.19.819.443.887 1.33.887 1.33v1.392zm11.133-1.138c-.205-.19-2.322-.127-2.322-.127s0-.57-.478-1.33C15.325 8.128 13.344 8 13.344 8H9.178s-1.503.127-2.118 1.266c-.41.823-.478 1.329-.478 1.329s-2.117-.127-2.322.127c-.205.19-.342.632-.205.886.205.443.683.506 1.23.506 0 0-.274 1.14-.274 2.342 0 1.202.069 1.772.069 1.772v1.645l.204.127h1.952l.205-.19v-1.202h8.118v1.202l.205.19h1.952l.204-.127v-1.645s.069-.57.069-1.772c0-1.203-.273-2.342-.273-2.342.546 0 1.024-.063 1.229-.506.137-.254 0-.633-.205-.886z"></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                  My Garage
                </a>
              </>
            }
            <button
              className="active-vehicle-subtext"
              data-garage-state-remove=""
              style={{ display: (vehicles?.default && vehicles?.default.model) ? "flex" : "none" }}>
              <span
                className="active-vehicle-subtext__vehicle-text"
                data-garage-state-vehicle="">
                {(vehicles?.default && vehicles?.default.model) && `${vehicles?.default?.make} ${vehicles?.default?.model} ${vehicles?.default?.year}`}
              </span>
              <span
                className="active-vehicle-subtext__remove"
                onClick={clearMyGarage}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={10}
                  height={10}
                  viewBox="0 0 10 10">
                  <g>
                    <g>
                      <path
                        fill="#4d4d4d"
                        d="M8.822 0L10 1.178 6.179 5 10 8.822 8.822 10 5 6.179 1.178 10 0 8.822 3.821 5 0 1.178 1.178 0 5 3.821z"
                      />
                    </g>
                  </g>
                </svg>
              </span>
            </button>
            
          </span>

          
        </div>
      </Drawer>
    </span>
  );
};

export default MenuSm;
