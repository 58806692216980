import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import jsonData from "../staticPagesMetaData.json";
import { useSite } from "../../../../_foundation/hooks/useSite";
import { Helmet } from "react-helmet";

const content =jsonData.contents[18]?.DifferentTypeOfBikeRacks;

const handleShareClick = (event: React.MouseEvent<HTMLSpanElement>) => {
  const url = event.currentTarget.dataset.copyToClipboard!;
  navigator.clipboard
    .writeText(url)
    .then(() => {
      const confirmation = window.prompt(
        "Copy to clipboard: Ctrl/Cmd+C, Enter",
        url
      );
      if (confirmation) {
        navigator.clipboard.writeText(url);
      }
    })
    .catch(() => {
      window.alert(`Failed to copy link to clipboard: ${url}`);
    });
};

const breadcrumbList = {
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  itemListElement: [
    {
      "@type": "ListItem",
      position: 1,
      name: "Home",
      item: "https://accessories.ford.com/",
    },
    {
      "@type": "ListItem",
      position: 2,
      name: "News",
      item: "https://accessories.ford.com/blogs/news",
    },
    {
      "@type": "ListItem",
      position: 3,
      name: "Different Types of Bike Racks",
      item: "https://accessories.ford.com/blogs/news/different-types-of-bike-racks",
    },
  ],
};

const DifferentTypeOfBikeRacks = () => {

  const {storeDisplayName } = useSite();
  let port = !window.location.port.trim() ? '' : ':' + window.location.port;
  return (
    <div>
        <Helmet>
          <title>{content?.title}</title>
          <meta name="description" content={content?.metaDescription}></meta>
          <meta name="keywords" content={content?.metaKeyword}/>
          <meta property="og:site_name" content={storeDisplayName}/>
          <meta property="og:url" content={(window.location.protocol +'//'+window.location.hostname+':' + port + window.location.pathname)}/>
          <meta property="og:title" content={content?.title}/>
          <meta property="og:type" content="article"/>  
          <meta property="og:description" content={content?.metaDescription}/>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={content?.title}/>  
          <meta name="twitter:description" content={content?.metaDescription} />
        </Helmet>
      <div id="shopify-section-template-article" className="shopify-section">
        <section
          title="Blog Hero"
          className="article-hero "
          style={{
            backgroundImage:
              "url(https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/DifferentTypesOfBikeRacks/RS_BikeRack_1500x.jpeg)",
          }}>
          <div className="article-hero__container container">
            <h1 className="article-hero__heading title--md">
              Different Types of Bike Racks
            </h1>

            <div className="article-hero__publish-date">March 31, 2023</div>
          </div>
        </section>

        <div className="article__main">
          <nav className="breadcrumbs" role="navigation">
            <ul className="flex">
              <li>
              <a href="/">
              <span>Home</span>
              </a>
                <span className="breadcrumbs-separator">&gt;</span>
              </li>
              <li>
                <Link to="/blogs/news">
                  <span>News</span>
                </Link>
              </li>
              <span className="breadcrumbs-separator">&gt;</span>
              <li>
                <span>Different Types of Bike Racks</span>
              </li>
            </ul>
          </nav>
          <script type="application/ld+json">
            {JSON.stringify(breadcrumbList)}
          </script>

          <div className="article-actions">
            <Link className="article-actions__return" to="/blogs/news">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16">
                <g>
                  <path
                    fill="#102b4e"
                    d="M8,0 C12.418,0 16,3.582 16,8 C16,12.418 12.418,16 8,16 C3.582,16 0,12.418 0,8 C0,3.582 3.582,0 8,0 Z M6,3 C5.724,3 5.474,3.112 5.293,3.293 C5.112,3.474 5,3.724 5,4 C5,4.276 5.112,4.526 5.293,4.707 L9.409,8 L5.293,11.293 C5.112,11.474 5,11.724 5,12 C5,12.276 5.112,12.526 5.293,12.707 C5.474,12.888 5.724,13 6,13 C6.276,13 6.526,12.888 6.707,12.707 L11.707,8.707 C11.888,8.526 12,8.276 12,8 C12,7.724 11.888,7.474 11.707,7.293 L6.707,3.293 C6.526,3.112 6.276,3 6,3 Z"></path>
                </g>
              </svg>
              Back to articles
            </Link>

            <div
              className="social-icons "
              itemScope
              itemType="https://schema.org/Organization">
              <link itemProp="url" href="https://accessories.ford.com" />

              <h3 className="social-icons__share-title">Share</h3>

              <div className="social-icons__list-item">
                <a
                  href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/different-types-of-bike-racks&amp;text=Different+Types+of+Bike+Racks"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="16"
                    viewBox="0 0 20 16">
                    <g>
                      <g>
                        <path
                          fill="#102b4e"
                          d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Twitter</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/different-types-of-bike-racks"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M.29 5.24h3.2v10.764H.29z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">LinkedIn</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/different-types-of-bike-racks"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="16"
                    viewBox="0 0 8 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Facebook</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <span
                  className="share"
                  onClick={handleShareClick}
                  data-copy-to-clipboard="https://accessories.ford.com/blogs/news/different-types-of-bike-racks">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Share URL</span>
                </span>
              </div>
            </div>
          </div>

          <article className="article-content rte">
            <p>
              <span style={{fontWeight: 400}}>
                Whether you’re packing your bike for a weekend cycle ride along
                the beach or prepping for days of intense mountain biking with
                your friends, your bike needs to be loaded up for
                transportation. With more than five different types of bike
                racks made for the exact specifications of your Ford vehicle,
                it’s tough to decide which one you will need.&nbsp;
              </span>
            </p>
            <p>
              <span style={{fontWeight: 400}}>
                When deciding on a bike rack, prioritize which capabilities are
                most important to your individual needs. For example, you may
                need a mount that allows for multiple bikes or one that’s
                relatively easy to load and unload. There are several types of
                bike racks for securing your bike including hitch bike racks,
                roof-mounted bike racks, spare tire mount racks, interior racks,
                and truck bed bike racks. We’ve put together a list of each type
                of rack so you can take a deep dive into which option is best
                for you.
                <br />
              </span>
            </p>
            <p>&nbsp;</p>
            <h2>
              <Link to="/search?searchTerm=hitch+bike+rack">
                <span style={{fontWeight: 400}}>
                  Hitch Mount Bike Racks
                  <br />
                  <img
                    src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/DifferentTypesOfBikeRacks/HitchMountBikeRack_480x480.jpeg"
                    alt="Hitch Mount Bike Rack" width={'480'} height={'100%'}
                  />
                  <br />
                </span>
              </Link>
            </h2>
            <p>
              <i>
                <span style={{fontWeight: 400}}>
                  Available Ford Accessories and optional equipment shown.
                </span>
              </i>
            </p>
            <p>
              <span style={{fontWeight: 400}}>
                Hitch-mounted bike racks slide onto a standard trailer hitch
                receiver. They are known for offering efficient loading and
                unloading, making them the ideal choice for people who
                frequently transport bikes. Additionally, these racks come in
                two types of structures to support different types of bikes for
                your specific needs: platform and hanging bike racks.&nbsp;
                <br />
                <br />
              </span>
            </p>
            <h3>
              <Link to="/search?searchTerm=tilting+bike">
                <span style={{fontWeight: 400}}>
                  Hitch-Mounted Tilting Bike Racks
                </span>
              </Link>
            </h3>
            <p>
              <span style={{fontWeight: 400}}>
                Hitch-mounted tilting bike racks support your bike by the wheels
                rather than suspending it by the frame. The adjustable wheel and
                frame clamps help secure your bicycle while the StrongArm hook
                design protects paint and carbon frames by securing bikes at the
                wheels. Since this hitch mount is connected to the tail end of
                your vehicle rather than the top or trunk, it is relatively easy
                to load and unload your bike.
              </span>
            </p>
            <p>
              <b>Perks</b>
              <span style={{fontWeight: 400}}>:</span>
            </p>
            <ul>
              <li style={{fontWeight: 400}}>
                <span style={{fontWeight: 400}}>
                  Holds between one and four bikes
                </span>
              </li>
              <li style={{fontWeight: 400}}>
                <span style={{fontWeight: 400}}>
                  StrongArm hook design secures bike at the wheels, protecting
                  paint and carbon frames
                </span>
              </li>
              <li style={{fontWeight: 400}}>
                <span style={{fontWeight: 400}}>
                  Tilts down with bikes loaded which allows for easy
                  rear-of-vehicle access
                </span>
              </li>
              <li style={{fontWeight: 400}}>
                <span style={{fontWeight: 400}}>
                  Control lever tilts the rack away from the vehicle and folds
                  flat for storage
                </span>
              </li>
            </ul>
            <p>&nbsp;</p>
            <h3>
              <Link to="/yakima-hitch-mounted-swing-bicycle-rack-for-4-bikes">
                <span style={{fontWeight: 400}}>
                  Hitch-Mounted Swing Bike Racks
                </span>
              </Link>
            </h3>
            <p>
              <span style={{fontWeight: 400}}>
                Unlike tilted bike racks, hitch-mounted swing bike racks carry
                bikes by their frames, meaning the cycle is suspended by the top
                crossbar. While these types of racks are suitable for a few
                different styles of bikes, they typically are designed for
                standard-frame, adult-size bikes. That said, adapter bars are
                available to help level additional varieties of bicycles by
                clamping onto the seat post and head tubes.
              </span>
            </p>
            <p>
              <b>Perks</b>
              <span style={{fontWeight: 400}}>:</span>
            </p>
            <ul>
              <li style={{fontWeight: 400}}>
                <span style={{fontWeight: 400}}>
                  Holds between two and four bikes
                </span>
              </li>
              <li style={{fontWeight: 400}}>
                <span style={{fontWeight: 400}}>
                  Due to being suspended on the rack, pressure is removed from
                  the tires
                </span>
              </li>
              <li style={{fontWeight: 400}}>
                <span style={{fontWeight: 400}}>
                  Fully padded arms and adjustable cradles provide superior bike
                  protection
                </span>
              </li>
              <li style={{fontWeight: 400}}>
                <span style={{fontWeight: 400}}>
                  Swings away with bikes loaded for easy rear-of-vehicle access
                </span>
              </li>
            </ul>
            <p>&nbsp;</p>
            <h2>
              <Link to="/search?searchTerm=rack+mounted+bicycle">
                <span style={{fontWeight: 400}}>
                  Roof Mount Racks
                  <br />
                  <img
                    src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/DifferentTypesOfBikeRacks/RoofMountRack_480x480.jpeg"
                    alt="Roof Mount Rack" width={'480'} height={'100%'}
                  />
                  <br />
                </span>
              </Link>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                Roof-mount bike racks are an excellent choice for bikers who are
                hauling additional equipment in their vehicle, have a big
                family, or have limited trunk space. They tend to be a
                cost-friendly option for those with a crossbar on their cars'
                roof because they only require an additional clip. Although you
                might need an extra set of hands to help you, the roof-mount
                bike rack is an excellent option.
              </span>
            </p>
            <p>
              <b>Perks:</b>
            </p>
            <ul>
              <li style={{fontWeight: 400}}>
                <span style={{fontWeight: 400}}>
                  Holds a variety of gear, such as kayaks, skis, snowboards, and
                  cargo boxes
                </span>
              </li>
              <li style={{fontWeight: 400}}>
                <span style={{fontWeight: 400}}>
                  Frees up space in the vehicle and allows for access to the
                  bike at any time
                </span>
              </li>
              <li style={{fontWeight: 400}}>
                <span style={{fontWeight: 400}}>
                  Cost-effective and easy-to-install option for vehicles that
                  already have a roof crossbar
                </span>
              </li>
            </ul>
            <p>&nbsp;</p>
            <h2>
              <Link to="/yakima-rack-mounted-lockable-bicycle-carrier-for-1-bike-1">
                <span style={{fontWeight: 400}}>
                  Rack Mounted Lockable Bicycle Carrier
                  <br />
                  <img
                    src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/DifferentTypesOfBikeRacks/RackMountedLockableBikeCarrier_480x480.jpeg"
                    alt="Rack mounted lockable bike carrier" width={'480'} height={'100%'}
                  />
                  <br />
                </span>
              </Link>
            </h2>
            <p>
              <i>
                <span style={{fontWeight: 400}}>
                  Available Ford Accessories and optional equipment shown
                </span>
              </i>
            </p>
            <p>
              <span style={{fontWeight: 400}}>
                Our newest premium upright bike mount product fits various bikes
                with quick and easy loading. If you have a roof rack kit or bed
                rack and cross bars already installed on your vehicle, this
                premium lockable bicycle carrier is an extremely secure option
                for transporting your bike. The carrier secures the bike by the
                wheels for ultimate protection over rugged terrain. It fits 26-
                to 29-inch wheels and tires from 23mm to 4.0 with no adjustments
                or wheel removal needed!&nbsp;
              </span>
            </p>
            <p>
              <b>Perks</b>
              <span style={{fontWeight: 400}}>:</span>
            </p>
            <ul>
              <li style={{fontWeight: 400}}>
                <span style={{fontWeight: 400}}>
                  No contact between the frame and your vehicle&nbsp;
                </span>
              </li>
              <li style={{fontWeight: 400}}>
                <span style={{fontWeight: 400}}>
                  The low-profile design minimizes rear door interference
                </span>
              </li>
            </ul>
            <p>&nbsp;</p>
            <h2>
              <Link to="/bronco-sport-2021-yakima-interior-bike-carrier">
                <span style={{fontWeight: 400}}>
                  Interior Bike Racks
                  <br />
                  <img
                    src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/DifferentTypesOfBikeRacks/InteriorBikeRack_480x480.jpeg"
                    alt="Interior bike rack" width={'480'} height={'100%'}
                  />
                  <br />
                </span>
              </Link>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                For the biking enthusiast with a Ford Bronco Sport and its
                spacious interior, it makes sense that you would want to fully
                use your space. The Yakima Interior Bike Carrier stores up to
                two bikes inside of your Bronco Sport by simply removing the
                front wheel and attaching the front forks to the crossbar that
                secures it in place.
              </span>
            </p>
            <p>
              <b>Perks</b>
              <span style={{fontWeight: 400}}>:&nbsp;</span>
            </p>
            <ul>
              <li style={{fontWeight: 400}}>
                <span style={{fontWeight: 400}}>
                  Interior bike racks can be a cost-effective way to transport
                  your bike compared to other options
                </span>
              </li>
              <li style={{fontWeight: 400}}>
                <span style={{fontWeight: 400}}>
                  This type of rack is easy to mount in your vehicle and is
                  lightweight
                </span>
              </li>
              <li style={{fontWeight: 400}}>
                <span style={{fontWeight: 400}}>
                  Carries up to two bikes inside the cargo area
                </span>
              </li>
            </ul>
            <p>&nbsp;</p>
            <h2>
              <Link to="/search?searchTerm=tailgate+bike">
                <span style={{fontWeight: 400}}>
                  Tailgate Bike Carrier
                  <br />
                  <img
                    src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/DifferentTypesOfBikeRacks/TailgateBikeCarrier_480x480.jpeg"
                    alt="Tailgate bike carrier" width={'480'} height={'100%'}
                  />
                  <br />
                </span>
              </Link>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                Tailgate bike carriers are a perfect option for bike enthusiasts
                who also own a truck. Truck beds provide the extra space and
                security you need to successfully haul your bike to wherever
                your adventures take you.{" "}
              </span>
              <Link to="/thule-pickup-tailgate-bike-carrier-for-8-bikes-1">
                <span style={{fontWeight: 400}}>Tailgate carriers</span>
              </Link>
              <span style={{fontWeight: 400}}>
                {" "}
                allow for the bike to rest on the tailgate of your bed while
                protecting both the bike and the truck’s tailgate. If you are
                transporting multiple bikes from one spot to another, a tailgate
                truck bed bike rack is an ideal choice.
              </span>
            </p>
            <p>
              <b>Perks:</b>
            </p>
            <ul>
              <li style={{fontWeight: 400}}>
                <span style={{fontWeight: 400}}>
                  Quick mounting and unmounting so that you can get out on the
                  trail&nbsp;
                </span>
              </li>
              <li style={{fontWeight: 400}}>
                <span style={{fontWeight: 400}}>
                  Flexible padding protects the truck’s tailgate
                </span>
              </li>
              <li style={{fontWeight: 400}}>
                <span style={{fontWeight: 400}}>
                  Depending on your choice of a tailgate carrier, it may be able
                  to carry over five bikes
                </span>
              </li>
            </ul>
            <p>&nbsp;</p>
            <h2>
              <span style={{fontWeight: 400}}>
                Let Your Rubber Meet the Road
              </span>
            </h2>
            <span style={{fontWeight: 400}}>
              Now that you have considered your specific needs, know all about
              your bike rack options, and have weighed the benefits of each,
              you’re ready to choose the type that’s right for your journey and
              let your rubber meet the road on your future adventures. Prepare
              for your next biking excursion by bringing the power and safety of
              trusted Ford accessories along on your trip. Whether you are
              heading off to a major cycling race or simply packing up your
              bikes for an upcoming camping trip, Ford has useful accessories
              for a smooth and stress-free ride. Plan for your next bike trip
              with our expansive{" "}
            </span>
            <Link to="/racks-and-carriers">
              <span style={{fontWeight: 400}}>
                selection of bike racks and carriers
              </span>
            </Link>
            <span style={{fontWeight: 400}}> today!</span>
          </article>

          <div className="article-share">
            <div
              className="social-icons "
              itemScope
              itemType="https://schema.org/Organization">
              <link itemProp="url" href="https://accessories.ford.com" />

              <h3 className="social-icons__share-title">Share Article</h3>

              <div className="social-icons__list-item">
                <a
                  href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/different-types-of-bike-racks&amp;text=Different+Types+of+Bike+Racks"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="16"
                    viewBox="0 0 20 16">
                    <g>
                      <g>
                        <path
                          fill="#102b4e"
                          d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Twitter</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/different-types-of-bike-racks"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M.29 5.24h3.2v10.764H.29z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">LinkedIn</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/different-types-of-bike-racks"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="16"
                    viewBox="0 0 8 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Facebook</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <span
                  className="share"
                  onClick={handleShareClick}
                  data-copy-to-clipboard="https://accessories.ford.com/blogs/news/different-types-of-bike-racks">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Share URL</span>
                </span>
              </div>
            </div>
          </div>

          <div className="article-pagination article-pagination--desktop">
            <Link
              className="article-pagination__item article-pagination__item--previous"
              to="/blogs/news/how-to-strap-a-kayak-to-a-roof-rack"  onClick={() => window.scrollTo(0, 0)}>
              <div className="article-pagination__arrow">
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/chevron-thin-white-right_60x.jpeg"
                  alt="Chevron White" width={'32'} height={'62'}
                />
              </div>
              <div className="article-pagination__data">
                <div className="article-pagination__label">
                  Previous Article
                </div>
                <div className="article-pagination__title">
                  How to Strap a Kayak to a Roof Rack
                </div>
              </div>
            </Link>
          </div>

          <div className="article-pagination article-pagination--mobile">
            <Link
              className="article-pagination__item article-pagination__item--previous"
              to="/blogs/news/how-to-strap-a-kayak-to-a-roof-rack"  onClick={() => window.scrollTo(0, 0)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="15"
                viewBox="0 0 8 15">
                <g>
                  <g transform="rotate(-270 4 7.5)">
                    <path
                      fill="#fff"
                      d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z"></path>
                  </g>
                </g>
              </svg>
              Prev Article
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DifferentTypeOfBikeRacks;
