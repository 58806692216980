import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import jsonData from "../staticPagesMetaData.json";
import { useSite } from "../../../../_foundation/hooks/useSite";
import { Helmet } from "react-helmet";

const content =jsonData.contents[23]?.MustHaveFordAccessories;

const handleShareClick = (event: React.MouseEvent<HTMLSpanElement>) => {
  const url = event.currentTarget.dataset.copyToClipboard!;
  navigator.clipboard
    .writeText(url)
    .then(() => {
      const confirmation = window.prompt(
        "Copy to clipboard: Ctrl/Cmd+C, Enter",
        url
      );
      if (confirmation) {
        navigator.clipboard.writeText(url);
      }
    })
    .catch(() => {
      window.alert(`Failed to copy link to clipboard: ${url}`);
    });
};

const breadcrumbList = {
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  itemListElement: [
    {
      "@type": "ListItem",
      position: 1,
      name: "Home",
      item: "https://accessories.ford.com/",
    },
    {
      "@type": "ListItem",
      position: 2,
      name: "News",
      item: "https://accessories.ford.com/blogs/news",
    },
    {
      "@type": "ListItem",
      position: 3,
      name: "8 Must-Have Ford F-150 Accessories",
      item: "https://accessories.ford.com/blogs/news/8-must-have-ford-f-150-accessories",
    },
  ],
};

const MustHaveFordAccessories = () => {
  const {storeDisplayName } = useSite();
  let port = !window.location.port.trim() ? '' : ':' + window.location.port;
  return (
    <div>
        <Helmet>
          <title>{content?.title}</title>
          <meta name="description" content={content?.metaDescription}></meta>
          <meta name="keywords" content={content?.metaKeyword}/>
          <meta property="og:site_name" content={storeDisplayName}/>
          <meta property="og:url" content={(window.location.protocol +'//'+window.location.hostname+':' + port + window.location.pathname)}/>
          <meta property="og:title" content={content?.title}/>
          <meta property="og:type" content="article"/>  
          <meta property="og:description" content={content?.metaDescription}/>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={content?.title}/>  
          <meta name="twitter:description" content={content?.metaDescription} />
        </Helmet>
      <div id="shopify-section-template-article" className="shopify-section">
        <section
          title="Blog Hero"
          className="article-hero "
          style={{
            backgroundImage:
              "url(https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/MustHaveF150Accessories/f150_a131a5d5-d897-4b17-8dcc-7606ec7bd103_1500x.jpeg)",
          }}>
          <div className="article-hero__container container">
            <h1 className="article-hero__heading title--md">
              8 Must-Have Ford F-150 Accessories
            </h1>

            <div className="article-hero__publish-date">February 01, 2023</div>
          </div>
        </section>

        <div className="article__main">
          <nav className="breadcrumbs" role="navigation">
            <ul className="flex">
              <li>
              <a href="/">
              <span>Home</span>
              </a>
                <span className="breadcrumbs-separator">&gt;</span>
              </li>
              <li>
                <Link to="/blogs/news">
                  <span>News</span>
                </Link>
              </li>
              <span className="breadcrumbs-separator">&gt;</span>
              <li>
                <span>8 Must-Have Ford F-150 Acce...</span>
              </li>
            </ul>
          </nav>
          <script type="application/ld+json">
            {JSON.stringify(breadcrumbList)}
          </script>

          <div className="article-actions">
            <Link className="article-actions__return" to="/blogs/news">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16">
                <g>
                  <path
                    fill="#102b4e"
                    d="M8,0 C12.418,0 16,3.582 16,8 C16,12.418 12.418,16 8,16 C3.582,16 0,12.418 0,8 C0,3.582 3.582,0 8,0 Z M6,3 C5.724,3 5.474,3.112 5.293,3.293 C5.112,3.474 5,3.724 5,4 C5,4.276 5.112,4.526 5.293,4.707 L9.409,8 L5.293,11.293 C5.112,11.474 5,11.724 5,12 C5,12.276 5.112,12.526 5.293,12.707 C5.474,12.888 5.724,13 6,13 C6.276,13 6.526,12.888 6.707,12.707 L11.707,8.707 C11.888,8.526 12,8.276 12,8 C12,7.724 11.888,7.474 11.707,7.293 L6.707,3.293 C6.526,3.112 6.276,3 6,3 Z"></path>
                </g>
              </svg>
              Back to articles
            </Link>

            <div
              className="social-icons "
              itemScope
              itemType="https://schema.org/Organization">
              <link itemProp="url" href="https://accessories.ford.com" />

              <h3 className="social-icons__share-title">Share</h3>

              <div className="social-icons__list-item">
                <a
                  href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/8-must-have-ford-f-150-accessories&amp;text=8+Must-Have+Ford+F-150+Accessories"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="16"
                    viewBox="0 0 20 16">
                    <g>
                      <g>
                        <path
                          fill="#102b4e"
                          d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Twitter</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/8-must-have-ford-f-150-accessories"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M.29 5.24h3.2v10.764H.29z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">LinkedIn</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/8-must-have-ford-f-150-accessories"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="16"
                    viewBox="0 0 8 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Facebook</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <span
                  className="share"
                  onClick={handleShareClick}
                  data-copy-to-clipboard="https://accessories.ford.com/blogs/news/8-must-have-ford-f-150-accessories">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Share URL</span>
                </span>
              </div>
            </div>
          </div>

          <article className="article-content rte">
            <h1>
              <b></b>
            </h1>
            <p>
              <span style={{fontWeight: 400}}>
                Since its inception in 1948, the Ford F-150 has positioned
                itself as one of the most popular vehicles of all time. This
                tough, lightweight, full-size truck generally has a 1,000-pound
                payload capacity, allowing utility in a variety of settings.
                What makes the F-150 stand out among other truck options is its
                ability to be customized and set up for different daily uses.
                F-150 owners have the option to put in seat covers for muddy
                excursions or install truck bed lights for late-night driving
                and off-roading. This truck takes your work and recreational
                activities to the next level with its ability to accessorize
                according to your needs.&nbsp;
                <br />
              </span>
            </p>
            <ul></ul>
            <br />
            <p>
              <span style={{fontWeight: 400}}>
                Selecting which customizable accessories to add to your vehicle
                can be very exciting, but with so many options for the F-150, it
                can be difficult to choose. Prioritizing your individual needs
                and how you primarily use your pickup truck can help make your
                decision easier.&nbsp;
              </span>
            </p>
            <p>&nbsp;</p>
            <h2>
              <Link to="/search?searchTerm=f-150+all+weather+floor+mat">
                <span style={{fontWeight: 400}}>
                  All-Weather Floor Mats
                </span>
              </Link>
            </h2>
            <p>
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/MustHaveF150Accessories/F150_FloorMat.jpeg"
                alt="F-150 Floor Mat"  width={'1480'} height={'100%'}
              />
            </p>
            <p>
              <span style={{fontWeight: 400}}>
                While it can be fun to dirty up the outside of your vehicle,
                it’s important to keep the inside as clean as possible. Using
                floor mats is just one option to keep your car's interior
                looking clean. Floor mats are intended to keep the carpeted
                areas of your vehicle's floors clean, and when you get a Ford
                floor mat, it is designed to perfectly fit your vehicle.
                All-weather floor mats prevent substances like dirt, grease, and
                even moisture from sinking in and damaging the carpet. Floor
                mats can be installed and removed as needed for ease of
                cleaning.&nbsp;
              </span>
            </p>
            <p>&nbsp;</p>
            <h2>
              <Link to="/search?searchTerm=f-150+seat+cover">
                <span style={{fontWeight: 400}}>Seat Covers</span>
              </Link>
            </h2>
            <p>
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/MustHaveF150Accessories/F150_SeatCovers.jpeg"
                alt="F-150 Seat Covers"  width={'1480'} height={'100%'}
              />
            </p>
            <p>
              <span style={{fontWeight: 400}}>
                Similar to floor mats, seat covers are designed to keep your
                interior clean, one seat at a time! Whether you’re on a road
                trip, going off-roading, or just on your daily commute, you may
                encounter situations that cause you to get wet, muddy, or even
                sandy. Vehicle interiors can be expensive, so they should be
                protected from any outdoor elements that get brought in. Seat
                covers catch all these materials and substances, preventing them
                from soaking into your seats. They are also simple to remove and
                machine wash at home for easy cleaning.&nbsp;
              </span>
            </p>
            <p>&nbsp;</p>
            <h2>
              <Link to="/search?searchTerm=f-150+tonneau+cover">
                <span style={{fontWeight: 400}}>Tonneau Cover</span>
              </Link>
            </h2>
            <p>
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/MustHaveF150Accessories/F150_TonneauCover.jpeg"
                alt="F-150 Tonneau Cover" width={'1480'} height={'100%'}
              />
              A tonneau cover is a hard or soft cover that protects both your
              pickup truck and the belongings in your truck bed from weather and
              everyday use. They cover the entire bed to ensure no rain, dust or
              snow enters. Tonneau covers also help keep cargo protected and out
              of sight.
            </p>
            <p>&nbsp;</p>
            <h2>
              <Link to="/search?searchTerm=f-150+Truck+Bed+Storage+Box">
                <span style={{fontWeight: 400}}>
                  Pivot Storage Box (R/L)
                </span>
              </Link>
            </h2>
            <p>
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/MustHaveF150Accessories/F150_PivotStorageBox.jpeg"
                alt="F-150 Pivot Storage Box" width={'1480'} height={'100%'}
              />
            </p>
            <p>
              <span style={{fontWeight: 400}}>
                It’s typical for drivers to store their tools or other important
                supplies in the back of their truck bed. However, this can leave
                your necessary items scattered and disorganized, especially if
                your pickup truck hits a bump in the road or is traveling at a
                high speed. Keep tools and other gear in one secured place by
                using a truck bed storage box. Our storage box fits between the
                wheel well and the back of your bed and pivots, allowing more
                access to the rest of your bed. This easy-to-install storage box
                is made of high-impact plastic for durable and weather-resistant
                protection for any project or adventure.&nbsp;
              </span>
            </p>
            <p>&nbsp;</p>
            <h2>
              <Link to="/bed-extender">
                <span style={{fontWeight: 400}}>Bed Extenders</span>
              </Link>
            </h2>
            <p>
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/MustHaveF150Accessories/F150_BedExtender.jpeg"
                alt="F-150 Bed Extender"  width={'1480'} height={'100%'}
              />
              While the F-150 typically offers more than enough space to haul
              whatever you may need, some items are just too long to fit in the
              bed. Bed extenders allow truck beds to carry longer objects like
              kayaks, lumber, ladders, and other work materials. Smaller tools
              and objects like hammers or backpacks fit easily in the bed space,
              but these unique, large items need extra space to be taken from
              place to place. Just open the pickup truck’s tailgate and place
              the bed extender on top to add 18 inches of space to your bed!
              This forms an enclosure that allows for longer items that extend
              past the bed to be transported easily and securely.&nbsp;&nbsp;
            </p>
            <p>&nbsp;</p>
            <h2>
              <Link to="/2015-2021-f-150-tow-hooks-pair-red">
                <span style={{fontWeight: 400}}>Tow Hooks</span>
              </Link>
            </h2>
            <p>
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/MustHaveF150Accessories/f150_red_hooks.jpeg"
                alt="F-150 Red Hooks"  width={'1480'} height={'100%'}
              />
              Before you head out on your next off-roading adventure, be sure to
              add a pair of steel tow hooks to the front of your vehicle. Our
              tow hooks help with vehicle recovery while adding to the modern
              look of your vehicle with a bold, red color design!
            </p>
            <p>&nbsp;</p>
            <h2>
              <Link to="/search?searchTerm=f-150+cargo+net">
                <span style={{fontWeight: 400}}>Cargo Net</span>
              </Link>
            </h2>
            <p>
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/MustHaveF150Accessories/F150_CargoNet.jpeg"
                alt="F-150 Cargo Net"  width={'1480'} height={'100%'}
              />
              Are you transporting smaller or lightweight items? Make sure they
              stay in place with a cargo net! These nets are designed to prevent
              items from shifting, spilling, or sliding around the bed of your
              truck. Cargo nets are easy to install, allowing for ease of usage
              and providing a sturdy, form-fitting hold on your gear.
              Additionally, our cargo nets are made to withstand different
              weather, so no matter rain, snow, or shine, your cargo net is
              going to stay durable and ready to go.&nbsp;
            </p>
            <p>&nbsp;</p>
            <h2>
              <Link to="/search?searchTerm=cargo+illumination+kit">
                <span style={{fontWeight: 400}}>Truck Bed Lights</span>
              </Link>
            </h2>
            <p>
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/MustHaveF150Accessories/F150_TruckBedLights.jpeg"
                alt="F-150 Truck Bed Lights"  width={'1260'} height={'100%'}
              />
            </p>
            <p>
              It can be difficult to see into your truck bed at night or when
              using a tonneau cover, and using a flashlight can be difficult
              when trying to grab items out of the bed. To solve visibility
              problems, we recommend using an illumination kit for all your
              nighttime lighting needs. These LED light pods to provide bright,
              long-lasting coverage of your bed so you can work all through the
              night if needed. For added conveniences, these lights come with a
              simple set-up system that does not require drilling and has an
              on/off button inside the truck bed for easy use. Looking to add
              the&nbsp; illumination kit to your Ford F-150? Check out our
              options below:
            </p>
            <br />
            <ul>
              <li style={{fontWeight: 400}}>
                <Link to="/f-150-2015-2017-bed-cargo-illumination-kit-1">
                  <span style={{fontWeight: 400}}>
                    2015-2017 Bed Cargo Illumination Kit
                  </span>
                </Link>
              </li>
              <li style={{fontWeight: 400}}>
                <Link to="/f-150-2018-2020-bed-cargo-illumination-kit-1">
                  <span style={{fontWeight: 400}}>
                    2018-2020 Bed Cargo Illumination Kit&nbsp;&nbsp;
                  </span>
                </Link>
              </li>
            </ul>
            <br />
            <h2>
              <span style={{fontWeight: 400}}>
                Additional F-150 Accessories
              </span>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                No matter the activity, your F-150 accessories can play a major
                part in making sure your truck is operating at its maximum
                capacity&nbsp; – and this list is just the beginning! Since
                everyone needs their F-150 for different tasks and prioritizes
                different features, your must-have list may differ from someone
                else’s. If these accessories aren’t revving your engine, we put
                together a list of additional F-150 accessories that may be more
                useful for you.
              </span>
            </p>
            <br />
            <ul>
              <li style={{fontWeight: 400}}>
                <Link to="/search?searchTerm=first+aid+kit">
                  <span style={{fontWeight: 400}}>First Aid Kit</span>
                </Link>
                <span style={{fontWeight: 400}}>
                  {" "}
                  - Keep a first aid kit in your F-150 to ensure you have items
                  like bandages, topicals, and even medication on hand for
                  emergencies when a medical facility is far away.&nbsp;
                </span>
              </li>
              <li style={{fontWeight: 400}}>
                <Link to="/cargo-management-system-cross-bars-1">
                  <span style={{fontWeight: 400}}>Cross Bars</span>
                </Link>
                <span style={{fontWeight: 400}}>
                  {" "}
                  - Attaching cross bars to the bed of your truck helps maximize
                  your cargo organization and load capacity.&nbsp;
                </span>
              </li>
              <li style={{fontWeight: 400}}>
                <Link to="/search?searchTerm=f-150+bedrug">
                  <span style={{fontWeight: 400}}>Bedrug Mat</span>
                </Link>
                <span style={{fontWeight: 400}}>
                  {" "}
                  - Protect the bed of your truck from cracking and breaking
                  with a heavy-duty bedrug mat.&nbsp;
                </span>
              </li>
              <li style={{fontWeight: 400}}>
                <Link to="/search?searchTerm=f-150+loading+ramp">
                  <span style={{fontWeight: 400}}>Loading Ramp</span>
                </Link>
                <span style={{fontWeight: 400}}>
                  {" "}
                  - Haul large objects on and off your pickup truck with ease by
                  using a loading ramp.&nbsp;
                </span>
              </li>
              <li style={{fontWeight: 400}}>
                <Link to="/f-150-2015-2020-led-warning-strobes-1">
                  <span style={{fontWeight: 400}}>Warning Strobes</span>
                </Link>
                <span style={{fontWeight: 400}}>
                  {" "}
                  - Make your truck more visible with strobe lights that make
                  your truck seen at night and can alert motorists to proceed
                  with caution while your crew is working.
                </span>
              </li>
            </ul>
            <p>&nbsp;</p>
            <h2>
              <span style={{fontWeight: 400}}>
                Stay Moving With Your F-150
              </span>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                Ready to customize your F-150 with new accessories? Trusted Ford
                accessory products allow you to stay ready for whatever
                adventure awaits by adding features to your vehicle with the
                power and expertise of Ford. Pair your durable floor mats and
                seat covers together for a day out in the mud, or securely carry
                your tools and transport large items to be equipped for a tough
                day on the job. Set out on your next adventure today with our
                wide selection of Ford F-150 accessories!&nbsp;
                <br />
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/MustHaveF150Accessories/F-150_accessories.jpeg"
                  alt="F-150 Accessories"  width={'1800'} height={'100%'}
                />
                <br />
              </span>
            </p>
          </article>

          <div className="article-share">
            <div
              className="social-icons "
              itemScope
              itemType="https://schema.org/Organization">
              <link itemProp="url" href="https://accessories.ford.com" />

              <h3 className="social-icons__share-title">Share Article</h3>

              <div className="social-icons__list-item">
                <a
                  href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/8-must-have-ford-f-150-accessories&amp;text=8+Must-Have+Ford+F-150+Accessories"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="16"
                    viewBox="0 0 20 16">
                    <g>
                      <g>
                        <path
                          fill="#102b4e"
                          d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Twitter</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/8-must-have-ford-f-150-accessories"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M.29 5.24h3.2v10.764H.29z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">LinkedIn</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/8-must-have-ford-f-150-accessories"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="16"
                    viewBox="0 0 8 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Facebook</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <span
                  className="share"
                  onClick={handleShareClick}
                  data-copy-to-clipboard="https://accessories.ford.com/blogs/news/8-must-have-ford-f-150-accessories">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Share URL</span>
                </span>
              </div>
            </div>
          </div>

          <div className="article-pagination article-pagination--desktop">
            <Link
              className="article-pagination__item article-pagination__item--previous"
              to="/blogs/news/taking-care-of-your-vehicle-all-year-long" onClick={() => window.scrollTo(0, 0)}>
              <div className="article-pagination__arrow">
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/chevron-thin-white-right_60x.jpeg"
                  alt="Chevron White"  width={'32'} height={'62'}
                />
              </div>
              <div className="article-pagination__data">
                <div className="article-pagination__label">
                  Previous Article
                </div>
                <div className="article-pagination__title">
                  Taking Care of Your Vehicle All Year Long
                </div>
              </div>
            </Link>

            <Link
              className="article-pagination__item article-pagination__item--next"
              to="/blogs/news/best-ford-ranger-accessories" onClick={() => window.scrollTo(0, 0)}>
              <div className="article-pagination__data">
                <div className="article-pagination__label">Next Article</div>
                <div className="article-pagination__title">
                  Best Ford Ranger Accessories
                </div>
              </div>
              <div className="article-pagination__arrow">
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/chevron-thin-white-right_60x.jpeg"
                  alt="Chevron White"  width={'32'} height={'62'}
                />
              </div>
            </Link>
          </div>

          <div className="article-pagination article-pagination--mobile">
            <Link
              className="article-pagination__item article-pagination__item--previous"
              to="/blogs/news/taking-care-of-your-vehicle-all-year-long"  onClick={() => window.scrollTo(0, 0)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="15"
                viewBox="0 0 8 15">
                <g>
                  <g transform="rotate(-270 4 7.5)">
                    <path
                      fill="#fff"
                      d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z"></path>
                  </g>
                </g>
              </svg>
              Prev Article
            </Link>

            <Link
              className="article-pagination__item article-pagination__item--next"
              to="/blogs/news/best-ford-ranger-accessories" onClick={() => window.scrollTo(0, 0)}>
              Next Article
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="15"
                viewBox="0 0 8 15">
                <g>
                  <g transform="rotate(-270 4 7.5)">
                    <path
                      fill="#fff"
                      d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z"></path>
                  </g>
                </g>
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MustHaveFordAccessories;
