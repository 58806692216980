import { createReducer, AnyAction } from "@reduxjs/toolkit";
//import _ from 'lodash'
//Redux
import initStates from "./initStates";
import * as ACTIONS from "../action-types/header-categories";

 const headerCategoriesReducer = createReducer(initStates.headerCategoriesData, (builder) => {
    builder.addCase(ACTIONS.RESPONSE_SUCCESSED_HEADER,(state:any,action:AnyAction)=>
    {
       
const response = action.response;
state.headerData=response

 

    })

builder.addCase(ACTIONS.RESPONSE_FAILURE_HEADER,(state:any,action:AnyAction)=>
{
const response = action.response;
state.headerData=response
}
)} )
export {headerCategoriesReducer};