import { createReducer, AnyAction } from "@reduxjs/toolkit";
import _ from 'lodash'
//Redux
import initStates from "./initStates";
import * as ACTIONS from "../action-types/shop-accessories";

 const shopAccessoriesReducer = createReducer(initStates.shopAccessoriesData, (builder) => {
    builder.addCase(ACTIONS.RESPONSE_SUCCESSED_SHOP,(state:any,action:AnyAction)=>
    {
        const response = action.response;
state.responses=response
const result=_.filter(state.responses,item=>
    {
        if(Array.isArray(item))
        {
        return item.flat(1);
    }
    })
    const marketingSpotData=result[0].map((item)=>item.baseMarketingSpotActivityData)
    state.featuredRideData=_.filter(marketingSpotData[0],{'baseMarketingSpotActivityName':'US_FeaturedRidesContent'})
    state.featuredVideoData=_.filter(marketingSpotData[0],{'baseMarketingSpotActivityName':'US_FeaturedVideoContent'})
    state.usDisclosure=_.filter(marketingSpotData[0],{'baseMarketingSpotActivityName':'US_DisclosureContent'})
    state.usDisclaimer=_.filter(marketingSpotData[0],{'baseMarketingSpotActivityName':'US_DisclaimerContent'})
// _.map(state.featuredRideData,(item,index)=>
// {
// if(Array.isArray(item))
// {
// _.map(item,(ele)=>
// {
//     state=_.map(ele.baseMarketingSpotActivityData,())

// })
// }
// })


    })

builder.addCase(ACTIONS.RESPONSE_FAILURE_SHOP,(state:any,action:AnyAction)=>
{
        const response = action.response;
state.featuredRideData=response
}
)} )
export { shopAccessoriesReducer};