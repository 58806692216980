import React from 'react';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import jsonData from "../staticPagesMetaData.json";
import { useSite } from "../../../../_foundation/hooks/useSite";
import { Helmet } from "react-helmet";

const content =jsonData.contents[27]?.RoofRack;

const handleShareClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    const url = event.currentTarget.dataset.copyToClipboard!;
    navigator.clipboard.writeText(url).then(() => {
        const confirmation = window.prompt("Copy to clipboard: Ctrl/Cmd+C, Enter", url);
        if (confirmation) {
            navigator.clipboard.writeText(url);
        }
    }).catch(() => {
        window.alert(`Failed to copy link to clipboard: ${url}`);
    });
};

const breadcrumbList = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
        {
            "@type": "ListItem",
            "position": 1,
            "name": "Home",
            "item": "https://accessories.ford.com/"
        },
        {
            "@type": "ListItem",
            "position": 2,
            "name": "News",
            "item": "https://accessories.ford.com/blogs/news"
        },
        {
            "@type": "ListItem",
            "position": 3,
            "name": "How to Strap a Kayak to a Roof Rack",
            "item": "https://accessories.ford.com/blogs/news/how-to-strap-a-kayak-to-a-roof-rack"
        }
    ]
}


const RoofRack = () => {

    const {storeDisplayName } = useSite();
    let port = !window.location.port.trim() ? '' : ':' + window.location.port;

    return (
        <div>
            <Helmet>
                <title>{content?.title}</title>
                <meta name="description" content={content?.metaDescription}></meta>
                <meta name="keywords" content={content?.metaKeyword}/>
                <meta property="og:site_name" content={storeDisplayName}/>
                <meta property="og:url" content={(window.location.protocol +'//'+window.location.hostname+':' + port + window.location.pathname)}/>
                <meta property="og:title" content={content?.title}/>
                <meta property="og:type" content="article"/>  
                <meta property="og:description" content={content?.metaDescription}/>
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={content?.title}/>  
                <meta name="twitter:description" content={content?.metaDescription} />
            </Helmet>
            <div id="shopify-section-template-article" className="shopify-section">




                <section className="article-hero" title="Blog Hero" style={{ backgroundImage: "url(https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/RoofRack/RS_StrapKayakToRoof_1500x.jpeg)" }}>
                    <div className="article-hero__container container">



                        <h1 className="article-hero__heading title--md">
                            How to Strap a Kayak to a Roof Rack
                        </h1>




                        <div className="article-hero__publish-date">
                            March 31, 2023
                        </div>

                    </div>
                </section>



                <div className="article__main">




                    <nav className="breadcrumbs" role="navigation">
                        <ul className="flex">
                            <li>
                            <a href="/">
              <span>Home</span>
              </a>
                <span className="breadcrumbs-separator">&gt;</span>
              </li>
              <li>
                <Link to="/blogs/news">
                  <span>News</span>
                </Link>
                            </li>
                            <span className="breadcrumbs-separator">&gt;</span>
                            <li>
                                <span>How to Strap a Kayak to a R...</span>
                            </li></ul>
                    </nav>
                    <script type="application/ld+json">
                        {JSON.stringify(breadcrumbList)}
                    </script>

                    <div className="article-actions">


                        <Link className="article-actions__return" to="/blogs/news">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><path fill="#102b4e" d="M8,0 C12.418,0 16,3.582 16,8 C16,12.418 12.418,16 8,16 C3.582,16 0,12.418 0,8 C0,3.582 3.582,0 8,0 Z M6,3 C5.724,3 5.474,3.112 5.293,3.293 C5.112,3.474 5,3.724 5,4 C5,4.276 5.112,4.526 5.293,4.707 L9.409,8 L5.293,11.293 C5.112,11.474 5,11.724 5,12 C5,12.276 5.112,12.526 5.293,12.707 C5.474,12.888 5.724,13 6,13 C6.276,13 6.526,12.888 6.707,12.707 L11.707,8.707 C11.888,8.526 12,8.276 12,8 C12,7.724 11.888,7.474 11.707,7.293 L6.707,3.293 C6.526,3.112 6.276,3 6,3 Z"></path></g></svg>
                            Back to articles
                        </Link>




                        <div className="social-icons " itemScope itemType="https://schema.org/Organization">
                            <link itemProp="url" href="https://accessories.ford.com" />



                            <h3 className="social-icons__share-title">
                                Share
                            </h3>


                            <div className="social-icons__list-item">
                                <a href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/how-to-strap-a-kayak-to-a-roof-rack&amp;text=How+to+Strap+a+Kayak+to+a+Roof+Rack" target="_blank" rel="noopener" className="share">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16"><g><g><path fill="#102b4e" d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path></g></g></svg>
                                    <span className="visually-hidden">
                                        Twitter
                                    </span>
                                </a>
                            </div>
                            <div className="social-icons__list-item">
                                <a href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/how-to-strap-a-kayak-to-a-roof-rack" target="_blank" rel="noopener" className="share">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><g><g><path fill="#102b4e" d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path></g><g><path fill="#102b4e" d="M.29 5.24h3.2v10.764H.29z"></path></g><g><path fill="#102b4e" d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path></g></g></g></svg>
                                    <span className="visually-hidden">
                                        LinkedIn
                                    </span>
                                </a>
                            </div>
                            <div className="social-icons__list-item">
                                <a href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/how-to-strap-a-kayak-to-a-roof-rack" target="_blank" rel="noopener" className="share">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16"><g><g><g><path fill="#102b4e" d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path></g></g></g></svg>
                                    <span className="visually-hidden">
                                        Facebook
                                    </span>
                                </a>
                            </div>
                            <div className="social-icons__list-item">
                                <button className="share" onClick={handleShareClick} data-copy-to-clipboard="https://accessories.ford.com/blogs/news/how-to-strap-a-kayak-to-a-roof-rack">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><g><path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path></g></g></svg>
                                    <span className="visually-hidden">
                                        Share URL
                                    </span>
                                </button>
                            </div>


                        </div>

                    </div>



                    <article className="article-content rte">
                        <p><span style={{ fontWeight: 400 }}>You don’t need the largest truck on the road to haul all the gear necessary for your next adventure. From skis and snowboards to paddleboards and kayaks, adding a few simple modifications to your vehicle opens a world of hauling possibilities. However, before traveling with large gear, it is important to ensure that everything is securely fastened. After deciding which type of roof rack and kayak carrier is best for your needs, you are ready to safely install your kayak. Check out this guide on how to strap a kayak to a roof rack!<br /></span></p>
                        <p>&nbsp;</p>
                        <h2><span style={{ fontWeight: 400 }}>Finding the Right Roof Rack for Your Kayak</span></h2>
                        <p><span style={{ fontWeight: 400 }}>When you’re preparing for your next trip to the water, it’s important to understand what options are out there for roof racks and kayak holders. If your Ford vehicle doesn’t already have roof rails, a roof rack and crossbar system are a great place to start. However, if your vehicle has factory-installed roof components, you may only need to add crossbars to easily transport your kayak. Once you’ve established a place for rooftop accessories to attach to, it’s time to start looking into different types of kayak carriers. With several decisions to make when it comes to both your kayak itself and your vehicle’s accessories, we’ve put together a guide to all the different products you might need to transport a kayak on a roof rack.&nbsp;<br /><br /><br /><img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/RoofRack/RoofRack_CrossBarSystem.jpeg" alt="Roof Rack Cross Bar System" width={'1480'} height={'100%'} /><br /></span></p>
                        <p><i><span style={{ fontWeight: 400 }}>Available Ford Accessories shown<br /><br /></span></i></p>
                        <h2><Link to="/search?searchTerm=roof+rack+and+crossbar"><span style={{ fontWeight: 400 }}>Roof Rack And Crossbar System</span></Link></h2>
                        <p><span style={{ fontWeight: 400 }}>One of the most important accessories needed for transporting kayaks is a set of crossbars. No matter what type of kayak carrier you decide on, it must attach to crossbars on your roof rack. If your Ford vehicle did not come with factory roof rails, adding a roof rack and crossbar system is essential for hauling a variety of items like skis, bicycles, cargo boxes, and of course, kayaks. Our roof rack and crossbar system options are made of heavy-duty, durable materials and are simple to install so you can spend less time getting your vehicle ready and more time on the water!<br /><br /><img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/RoofRack/CrossbarForFactoryRoofRails.jpeg" alt="Cross bar for Factory Roof Rails"  width={'1900'} height={'100%'}/><br /></span></p>
                        <h2><Link to="/search?searchTerm=cross+bars+for+factory"><span style={{ fontWeight: 400 }}>Crossbars For Factory Roof Rails</span></Link></h2>
                        <p><span style={{ fontWeight: 400 }}>If your vehicle already has factory roof rails installed, you’re one step closer to an excursion with your kayak in tow! Since many kayak carriers require attachment to cross bars, they have become an essential component to several of our kayak carriers. Our crossbars come with a mounting kit and fit perfectly on the roof rails that came with your Ford, so you just need to install a kayak carrier, strap in your boat, and head to the water!&nbsp;&nbsp;<br /><br /><img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/RoofRack/MountedKayakCarrier.jpeg" alt="Mounted Kayak Carrier"  width={'1688'} height={'100%'}/><br /></span></p>
                        <h2><Link to="/search?searchTerm=mounted+kayak+carrier"><span style={{ fontWeight: 400 }}>Mounted Kayak Carrier</span></Link></h2>
                        <p><span style={{ fontWeight: 400 }}>Our mounted kayak carrier options allow for different positions and configurations to fit your specific needs. Kayaks slide right into wide-mouth J-profile brackets, allowing for easy loading and unloading. Some of our products rotate to a stacked position for transporting multiple kayaks! Regardless of which mounted kayak carrier product you choose, one of the key benefits of this type of kayak holder is the ability to fold down when not in use. This provides increased overhead clearance and a sleek look when you don’t have your boat in tow. Mounted kayak carriers are easy to install and even easier to attach to your kayak, with heavy-duty kayak straps and bow/stern tie-downs included.<br /><br /><img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/RoofRack/LiftAssistKayakCarrier.jpeg" alt="Lift Assist Kayak Carrier" width={'4472'} height={'100%'}/><br /></span></p>
                        <h2><Link to="/search?searchTerm=lift+assist+kayak"><span style={{ fontWeight: 400 }}>Lift-Assist Kayak Carrier</span></Link></h2>
                        <p><span style={{ fontWeight: 400 }}>Getting your kayak mounted to the top of your vehicle at home is one task, but bringing it down for use is a completely different feat. Once you arrive at your destination, especially if you are by yourself, our lift-assist kayak carrier options may be helpful as they allow you to load and unload your kayak onto the carrier at waist height. And thanks to padding along multiple touchpoints, your kayak will have maximum protection as the double-extending arms gently lower it down several feet.&nbsp;<br /><br /><br /></span></p>
                        <h2><span style={{ fontWeight: 400 }}>Tips for Strapping a Kayak to a Roof Rack</span></h2>
                        <p><span style={{ fontWeight: 400 }}>Once you’ve prepared your vehicle with crossbars and have decided on which type of kayak carrier best fits your needs, you’re ready to strap your boat on! But before you hit the road, you’ll want to be sure you’ve securely affixed your kayak to your roof rack for a worry-free trip to the water. Explore these tips to help strap a kayak on your vehicle’s roof for your next adventure!</span></p>
                        <br />
                        <ul>
                            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>If you’re not using a lift-assist kayak carrier to position your boat onto the roof rack, you may need a second person to help lift your kayak on the top of your vehicle.&nbsp;</span></li>
                            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Follow product-specific instructions to correctly position your kayak on the carrier.</span></li>
                            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Tightly secure your kayak with your carrier’s specific straps and tie-downs.</span></li>
                            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Keep an eye on the maximum kayak weight for your specific kayak carrier.</span></li>
                            <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Tie up any loose ends of straps or rope to help prevent catching or unraveling during travel.</span></li>
                        </ul>
                        <br /><br />
                        <h2><span style={{ fontWeight: 400 }}>Kayak Racks for Your Ford Vehicle</span></h2>
                        <p><span style={{ fontWeight: 400 }}>Once your vehicle is set up with the correct equipment and your kayak is correctly strapped on, you’re ready to get out on the water! While roof racks work great for most vehicles, you may opt for a bed rack and crossbars instead of a roof rack if you’re using a pickup truck to haul your kayak.&nbsp;</span></p>
                        <br />
                        <p><span style={{ fontWeight: 400 }}>Are you ready to embark on your next kayaking adventure? Prepare your vehicle and stay ready for whatever happens by bringing the power and safety of available Ford accessories on your next trip to the water. Whether traveling to your nearest river or taking a road trip to a well-known lake, Ford has everything you need to get your kayak there safely. Set out on your next trip to the water today with our wide selection of roof racks and kayak accessories!</span></p>




                    </article>







                    <div className="article-share">


                        <div className="social-icons " itemScope itemType="https://schema.org/Organization">
                            <link itemProp="url" href="https://accessories.ford.com" />


                            <h3 className="social-icons__share-title">
                                Share Article
                            </h3>


                            <div className="social-icons__list-item">
                                <a href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/how-to-strap-a-kayak-to-a-roof-rack&amp;text=How+to+Strap+a+Kayak+to+a+Roof+Rack" target="_blank" rel="noopener" className="share">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16"><g><g><path fill="#102b4e" d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path></g></g></svg>
                                    <span className="visually-hidden">
                                        Twitter
                                    </span>
                                </a>
                            </div>
                            <div className="social-icons__list-item">
                                <a href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/how-to-strap-a-kayak-to-a-roof-rack" target="_blank" rel="noopener" className="share">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><g><g><path fill="#102b4e" d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path></g><g><path fill="#102b4e" d="M.29 5.24h3.2v10.764H.29z"></path></g><g><path fill="#102b4e" d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path></g></g></g></svg>
                                    <span className="visually-hidden">
                                        LinkedIn
                                    </span>
                                </a>
                            </div>
                            <div className="social-icons__list-item">
                                <a href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/how-to-strap-a-kayak-to-a-roof-rack" target="_blank" rel="noopener" className="share">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16"><g><g><g><path fill="#102b4e" d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path></g></g></g></svg>
                                    <span className="visually-hidden">
                                        Facebook
                                    </span>
                                </a>
                            </div>
                            <div className="social-icons__list-item">
                                <button className="share" onClick={handleShareClick} data-copy-to-clipboard="https://accessories.ford.com/blogs/news/how-to-strap-a-kayak-to-a-roof-rack">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><g><path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path></g></g></svg>
                                    <span className="visually-hidden">
                                        Share URL
                                    </span>
                                </button>
                            </div>


                        </div>

                    </div>





                    <div className="article-pagination article-pagination--desktop">

                        <Link className="article-pagination__item article-pagination__item--previous" to="/blogs/news/must-have-ford-raptor-accessories" onClick={() => window.scrollTo(0, 0)}>
                            <div className="article-pagination__arrow">
                                <img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/chevron-thin-white-right_60x.jpeg" alt="Chevron White" width={'32'} height={'62'}  />
                            </div>
                            <div className="article-pagination__data">
                                <div className="article-pagination__label">
                                    Previous Article
                                </div>
                                <div className="article-pagination__title">
                                    Must-Have Ford Raptor Accessories
                                </div>
                            </div>
                        </Link>


                        <Link className="article-pagination__item article-pagination__item--next" to="/blogs/news/different-types-of-bike-racks" onClick={() => window.scrollTo(0, 0)}>
                            <div className="article-pagination__data">
                                <div className="article-pagination__label">
                                    Next Article
                                </div>
                                <div className="article-pagination__title">
                                    Different Types of Bike Racks
                                </div>
                            </div>
                            <div className="article-pagination__arrow">
                                <img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/chevron-thin-white-right_60x.jpeg" alt="Chevron White" width={'32'} height={'62'}/>
                            </div>
                        </Link>

                    </div>


                    <div className="article-pagination article-pagination--mobile">

                        <Link className="article-pagination__item article-pagination__item--previous" to="/blogs/news/must-have-ford-raptor-accessories" onClick={() => window.scrollTo(0, 0)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="15" viewBox="0 0 8 15"><g><g transform="rotate(-270 4 7.5)"><path fill="#fff" d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z"></path></g></g></svg>
                            Prev Article
                        </Link>


                        <Link className="article-pagination__item article-pagination__item--next" to="/blogs/news/different-types-of-bike-racks" onClick={() => window.scrollTo(0, 0)}>
                            Next Article
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="15" viewBox="0 0 8 15"><g><g transform="rotate(-270 4 7.5)"><path fill="#fff" d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z"></path></g></g></svg>
                        </Link>

                    </div>


                </div>





            </div>


        </div>

    );

}

export default RoofRack;