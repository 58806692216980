/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React from "react";
//custom
import TwoTierMenuList from "./TwoTierMenuList";
import ThreeTierMenuList from "./ThreeTierMenuList";

interface MenuItemProps {
  page?: any;
}

/**
 * ExpandedMenuItem component
 * expanded menu parent items for desktop/tablet view
 * @param props
 */
const MenuItem: React.FC<MenuItemProps> = (props: any) => {
  const page = props.page ? props.page : [];

  return (
    <>
      {page.children?.[0]?.children ? (
        <>
          <ThreeTierMenuList
            page={page}
            // hideMenuList={hideMenuList}
          />
        </>
      ) : (
        <>
        
        {page.children &&  <TwoTierMenuList

            page={page}
            // hideMenuList={hideMenuList}
          />}
        </>
      )}
    </>
  );
};

export default MenuItem;
