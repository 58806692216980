import { BorderLeft } from "@material-ui/icons";
import React, { useEffect, Component } from "react";
// Abode Tag
import AdobeService from "../../../_foundation/adobeServices/adobeService";
import jsonData from "./staticPagesMetaData.json";
import { Helmet } from "react-helmet";

const contents =jsonData.contents[2]?.Warranty;
class WarrantyInformation extends Component {
  componentDidMount(): void {
    //Adobe Tag Service
    (async () => {
        let res = await AdobeService.track({pageName: 'warranty information', siteSection: 'help center',  pagenameNoProduct: "warranty information", hierarchy: 'help center', variantName: 'global-load', eventType:'global-load'});
        AdobeService.callSatellite(res);
    })()
  }
  render() {
    return ( 
      <div>
         <Helmet>
          <meta name="description" content={contents?.metaDescription}></meta>
          <meta name="keywords" content={contents?.metaKeyword}/>
          </Helmet>
        <div id="shopify-section-standard__hero" className="shopify-section">
          <section className="page-hero" id="page-hero-">
            <div className="page-hero__background object-cover page-hero__overlay page-hero__overlay--dark-overlay">
              <img 
                className="above-mobile-large" 
                src="https://accessories.ford.com/gcs/B2C/ford/Static_Pages/WarantyInfo/StandardContentPages_WarrantyHero_BroncoRocky_D.jpeg"
                data-preload="loaded"
                alt="Desktop Hero"  width={'2880'} height={'662'}></img>
              <img 
                className="mobile-large-only" 
                src="https://accessories.ford.com/gcs/B2C/ford/Static_Pages/WarantyInfo/StandardContentPages_WarrantyHero_BroncoRocky_M.jpeg"
                data-preload="loaded" 
                alt="Mobile Hero"  width={'750'}
                height={'726'}></img>
            </div>   
            <div className="page-hero__content container">
              <div className="page-hero__content-inner page-hero__content-inner--middle">  
                <h1 className="page-hero__heading title--md">
                  <p>
                    <span className="warranty-title"
                      style=
                      {{
                        color:"#ffffff",
                        fontSize:"38px",
                        letterSpacing:"3px",
                        lineHeight:"58px",
                        paddingLeft:"20px",
                        paddingRight:"60px"
                      }}>
                      Warranty Information
                    </span>
                  </p>          
                </h1>   
              </div>
            </div>
          </section>          
        </div>
        <div id="shopify-section-standard__content" className="shopify-section">
          <section className="content-section container--mb-2" 
            style=
            {{
              marginTop:"60px",
              marginBottom:"60px",
              paddingLeft:"50px",
              paddingRight:"50px"
            }}
          >
	          <div className="page__content rte">
	            <h1 style={{ color: "#4d4d4d" }}>LIMITED WARRANTY</h1>
              <p 
                style=
                {{
                  fontWeight: 400,
                  letterSpacing: "1.5px",
                  fontSize: "16px",
                }}
                >
                Ford warrants that new Ford branded products will be free from defects in materials
                and workmanship at the time of sale. This warranty does not cover damage or malfunctions 
                caused by normal wear and tear, accident, abuse, neglect, improper storage or handling,
                misuse, vandalism, acts of God, or other causes external to the product.
              </p>
              <p 
                style=
                {{
                  fontWeight: 400,
                  letterSpacing: "1.5px",
                  fontSize: "16px",
                }}>
                This warranty gives you specific legal rights, and you may have other rights, which vary from State to State.
              </p>
              <p 
                style=
                {{
                  fontWeight: 400,
                  letterSpacing: "1.5px",
                  fontSize: "16px",
                }}>
                Ford Accessories are defined as items that do not appear on the factory window sticker and are installed by a Ford Dealer. Not all Custom Accessories purchased through this 
                website are Genuine Ford Accessories which are warranted by Ford. This website also 
                contains Ford Licensed Accessories which are warranted by their respective manufacturers 
                and are not warranted by Ford.
              </p>
              <ul>
                <li style=
                {{
                  fontWeight: 400,
                  letterSpacing: "1.5px",
                  fontSize: "15px",
                }}>
                  Ford Original Accessories will be warranted for whichever provides you the greatest 
                  benefit: 24 months/unlimited mileage, or the remainder of your Bumper-to-Bumper 
                  3-year/36,000-mile (whichever comes first) New Vehicle Limited Warranty. Ford Licensed
                  Accessories (FLA) are warranted by the accessory&nbsp;manufacturer (not Ford Motor Company) 
                  for whichever provides you the greatest benefit: 24 months unlimited mileage, 
                  or the remainder of your Bumper-to-Bumper 3-year/36,000-mile New Vehicle Limited Warranty.
                  FLA are designed and developed by the<br/>accessory manufacturer and have not been 
                  designed or tested to Ford Motor Company engineering requirements.&nbsp;
                  Defective components replaced under the bumper-to-bumper warranty are eligible for labor 
                  reimbursement through Ford Motor Company.
                </li>
                <li style=
                {{
                  fontWeight: 400,
                  letterSpacing: "1.5px",
                  fontSize: "15px",
                }}>
                  Ford Licensed Accessories (FLA) are warranted by the accessories manufacturer's warranty.
                  &nbsp; FLA are warranted by the accessory manufacturer for whichever provides you with the 
                  greatest benefit: 24 months/unlimited mileage or the remainder of your Bumper-to-Bumper 
                  3-year/36,000-mile New Vehicle Limited Warranty if bought at time of vehicle sale.&nbsp; 
                  Contact your local Ford dealer for details and a copy of the FLA limited warranties.
                </li>
                <li style=
                {{
                  fontWeight: 400,
                  letterSpacing: "1.5px",
                  fontSize: "15px",
                }}>
                  Most Ford Performance Parts (FPP) are sold with no warranty, unless otherwise expressly 
                  designated.&nbsp;&nbsp; Check the FPP website: <a style={{textDecoration: 'Underline'}} target="_blank" rel="noopener noreferrer"  href="https://www.performanceparts.ford.com">www.performanceparts.ford.com</a> or contact the Ford Performance Parts Techline at (800) FORD788 for the latest limited warranty information on specific products.&nbsp;
                </li>
                <li style=
                {{
                  fontWeight: 400,
                  letterSpacing: "1.5px",
                  fontSize: "15px",
                }}>
                  Lincoln Original Accessories will be warranted for whichever provides you the 
                  greatest benefit: 24 months/unlimited mileage, or the remainder of your Bumper-to-Bumper 
                  4-year/50,000-mile (whichever comes first) New Vehicle Limited Warranty. Lincoln Licensed 
                  Accessories (LLA) are warranted by the accessory&nbsp;manufacturer (not Ford Motor Company) 
                  for whichever provides you the greatest benefit: 24 months unlimited mileage, or the 
                  remainder of your Bumper-to-Bumper 4-year/50,000-mile New Vehicle Limited Warranty. 
                  LLA are designed and developed by the accessory manufacturer and have not been designed 
                  or tested to Ford Motor Company engineering requirements.&nbsp;Defective components 
                  replaced under the bumper-to-bumper warranty are eligible for labor reimbursement 
                  through Ford Motor Company.
                </li>
              </ul>
              {/* <ul style={{fontWeight: 400}}></ul> */}
              <ul style={{ fontWeight: 400 }}>
                <h3>CALIFORNIA CUSTOMERS</h3>
                <ul style={{ letterSpacing: 1.5, lineHeight: "26px" }}>
                  <li>
                    Beginning July 1st, 2023, customers in California benefit
                    from the distinctive warranty coverage for any of Ford's
                    portfolio accessories. Customers must provide proof of
                    delivery in one of two ways.
                  </li>
                  <ul>
                    <li>
                      Provide the FedEx delivery label, indicating the date of
                      delivery; or
                    </li>
                    <li>
                      Provide the Printed or Electronic Receipt indicating the
                      date the accessory/part was delivered.
                    </li>
                  </ul>
                  <br></br>
                  <li>
                    Ask the dealership for an amended receipt if the
                    accessory/part is delivered at a later date than purchased
                    in order to indicate the date the accessory/part was
                    delivered to the customer.
                  </li>
                </ul>
              </ul>
	         </div>
          </section>
        </div>
      </div>
    );
  }
} export default WarrantyInformation;