/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { find } from "lodash-es";
import { call, put } from "redux-saga/effects";
//Foundation libraries
import mockServerService from "../../../_foundation/apis/mock/mockserver.service";
import { getSite } from "../../../_foundation/hooks/useSite";
//Redux
import * as ACTIONS from "../../action-types/search-now";
import mygarageService from "../../../_foundation/apis/transaction/mygarage.service";
import { localStorageUtil } from "../../../_foundation/utils/storageUtil";
import { CURRENT_USER } from "../../../_foundation/constants/common";
import eventBus from "../../../utils/event";

export function* fetchModels(action: any) {
  try {
    const siteInfo = getSite();
    const payload = action.payload;
    ////console.log("siteInfo", payload);

    let ua = navigator.userAgent.toLowerCase();
    let url = ''
    /* if (ua.indexOf('safari') != -1) { 
      if (ua.indexOf('chrome') > -1) {
        url = siteInfo?.transactionContext+'/store/'+siteInfo?.storeID+'/makemodelyear/getMakeModelYear?langId=-1'
      } else {
        let newURL = window.location.origin
        url = newURL + siteInfo?.transactionContext+'/store/'+siteInfo?.storeID+'/makemodelyear/getMakeModelYear?langId=-1'
      }
    } */
    const response = yield call(
      mockServerService.getMock,
      {},
      {},
      //`${siteInfo?.transactionContext}/store/${siteInfo?.storeID}/makemodelyear/getMakeModelYear?langId=-1`
      siteInfo?.transactionContext + '/store/' + siteInfo?.storeID + '/makemodelyear/getMakeModelYear?langId=-1'
    );
    let models: any = [];
    let years = {};
    if (payload.make) {
      const makeData = response.data.make[payload.make];
      let tmpModelsObj = makeData.model;
      models = Object.keys(tmpModelsObj).map((key) => {
        years[key.trim()] = tmpModelsObj[key].years;
        return key.trim();

      });
    }
    models.sort((a, b) => a.localeCompare(b));
    yield put({
      payload: payload,
      type: ACTIONS.FETCH_MODEL_YEAR_SUCCESS,
      response: { models, years },
    });
  } catch (error) {
    yield put({ type: ACTIONS.FETCH_MODEL_YEAR_ERROR, error });
  }
}
function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1);
}
export function* fetchVehicles(action) {
  try {
    let res: any = {
      default: {
        make: null,
        model: null,
        year: null
      },
      all: [],
    };
    const defaultV: any = localStorage.getItem('myGarage');
    if (localStorageUtil.get(CURRENT_USER) && localStorageUtil.get(CURRENT_USER)?.userLoggedIn) {
      const response = yield call(
        mygarageService.getVehicles

      );
      res.all = response?.data?.vehicleList;
      if (defaultV) {
        res.default = JSON.parse(defaultV);
        res.all = response?.data?.vehicleList?.length > 0 ? response?.data?.vehicleList : [];
      } else {
        res.default = {
          make: null,
          model: null,
          year: null
        }
      }

    } else {
      if (defaultV) {
        res.default = JSON.parse(defaultV);
        res.all = [];
      }

    }
    yield put({
      payload: {},
      type: ACTIONS.FETCH_VEHICLES_SUCCESS,
      response: {
        all: [...res.all],
        default: { ...res.default }
      }
    });
  } catch (error) {
    yield put({ type: ACTIONS.FETCH_VEHICLES_ERROR, error });
  }
}