export const categorySections = [
    // {division:"Accessories",category:"Accessories",url:"/all"},
    // {division:"Performance Parts",category:"Performance Parts",url:"/ford-performance-parts"},
    {division:"Accessories",category:"Bed/Cargo Area",url:"/bed-products"},
    {division:"Accessories",category:"Electronics",url:"/electronics"},
    {division:"Accessories",category:"exterior",url:"/exterior"},
    {division:"Accessories",category:"interior",url:"/interior"},
    {division:"Accessories",category:"wheels",url:"/wheels"},
    {division:"Performance Parts",category:"Accessories",url:"/accessories-1"},
    {division:"Performance Parts",category:"Appearance",url:"/appearance"},
    {division:"Performance Parts",category:"Body",url:"/body"},
    {division:"Performance Parts",category:"Chassis",url:"/chassis"},
    {division:"Performance Parts",category:"Driveline",url:"/driveline"},
    {division:"Performance Parts",category:"Electrical",url:"/electrical"},
    {division:"Performance Parts",category:"Engine",url:"/engine"},
    {division:"Performance Parts",category:"Misc",url:"/misc"},
    {division:"Performance Parts",category:"Tools",url:"/tools"},
    {division:"Bronco1",category:"Bronco",url:"/bronco-2021"},
    // {division:"Bronco",category:"Camp",url:"/camp"},
    // {division:"Bronco",category:"Bike",url:"/bike"},
    // {division:"Bronco",category:"Cargo",url:"/cargo-products"},
    // {division:"Bronco",category:"Exterior",url:"/exterior"},
    {division:"Bronco",category:"Off-Road",url:"/off-road-2"},

    // Electronics url also routes to /electronics
    // {division:"Bronco",category:"Electronics",url:"/electronics-u-1-electronics"},

    // Interior url also routes to /interior
    // {division:"Bronco",category:"Interior",url:"/interior-u-1-interior"},
    
    {division:"Bronco",category:"Wheels",url:"/bronco-wheels"},
    {division:"Maverick1",category:"Maverick",url:"/ford-maverick-highlight"},
    // {division:"Maverick",category:"Camp",url:"/camp"},
    // {division:"Maverick",category:"Bike",url:"/bike"},
    // {division:"Maverick",category:"Cargo",url:"/cargo-products"},
    {division:"Maverick",category:"Snow",url:"/snow"},
    {division:"Maverick",category:"Dog",url:"/dog"},
    {division:"Maverick",category:"Water",url:"/water"},
    {division:"Maverick",category:"Work",url:"/work"}, 
    {division:"ford-f-150-2021-1",category:"ford-f-150-2021",url:"/ford-f-150-2021"},
    {division:"ford-f-150-2021",category:"Safety",url:"/safety"},
    {division:"ford-f-150-2021",category:"Towing",url:"/towing"}, 
    {division:"ford-f-150-2021",category:"Outdoors",url:"/outdoors"},
    {division:"ford-f-150-2021",category:"Tonneau Covers",url:"/covers-1"}
  ]

 export const subCategory = [
    {division:"Accessories",category:"Bed/Cargo Area",subCategory:"Bed Covers",url:"/covers"},
    {division:"Accessories",category:"Bed/Cargo Area",subCategory:"Bed Rails, Steps and Sport Bars",url:"/bed-rails"},
    {division:"Accessories",category:"Bed/Cargo Area",subCategory:"Cargo Area Products",url:"/cargo-products"},
    {division:"Accessories",category:"Bed/Cargo Area",subCategory:"Liners and Mats",url:"/liners-and-mats"},
    {division:"Accessories",category:"Bed/Cargo Area",subCategory:"Tents",url:"/tents"},
    {division:"Accessories",category:"Electronics",subCategory:"Audio",url:"/audio"},
    {division:"Accessories",category:"Electronics",subCategory:"Dashcam",url:"/dashcam"},
    {division:"Accessories",category:"Electronics",subCategory:"Keyless Entry",url:"/keyless-entry"},
    {division:"Accessories",category:"Electronics",subCategory:"Lamps, Lights and Treatments",url:"/lamps-lights-and-treatments"},
    {division:"Accessories",category:"Electronics",subCategory:"Parking Assist System",url:"/parking-assist-system"},
    {division:"Accessories",category:"Electronics",subCategory:"Performance",url:"/performance-1"},
    {division:"Accessories",category:"Electronics",subCategory:"Rear Seat Entertainment",url:"/rear-seat-entertainment"},
    {division:"Accessories",category:"Electronics",subCategory:"Remote Start and Vehicle Security",url:"/remote-start-vehicle-security"},



    {division:"Accessories",category:"Exterior",subCategory:"Bumpers, Fenders, Doors and Roof",url:"/bumpers-fenders-doors-and-roof"},
    {division:"Accessories",category:"Exterior",subCategory:"Covers, Deflectors and Protectors",url:"/covers-and-protectors"},
    {division:"Accessories",category:"Exterior",subCategory:"Feul",url:"/fuel"},
    // {division:"Accessories",category:"Exterior",subCategory:"Graphics and Stripes",url:"/graphics-stripes-and-trim-kits"},
    {division:"Accessories",category:"Exterior",subCategory:"Hitches, Towing and Recovery",url:"/trailer-towing"},
    {division:"Accessories",category:"Exterior",subCategory:"Performance",url:"/performance"},
    {division:"Accessories",category:"Exterior",subCategory:"Racks and Carriers",url:"/racks-and-carriers"},
    {division:"Accessories",category:"Exterior",subCategory:"Running Boards, Step Bars and Rock Rails",url:"/running-boards-and-step-bars"},
    {division:"Accessories",category:"Exterior",subCategory:"Scoops, Louvers and Grilles",url:"/scoops-and-louvres"},
    {division:"Accessories",category:"Exterior",subCategory:"Splash Guards",url:"/splash-guards"},
    {division:"Accessories",category:"Exterior",subCategory:"Spoilers and Body Kits",url:"/spoilers-body-kits"},
    {division:"Accessories",category:"Exterior",subCategory:"Trim Kits",url:"/trim-kits"},

    {division:"Accessories",category:"Interior",subCategory:"Ash or Coin Cup",url:"/ash-or-coin-cup"},
    {division:"Accessories",category:"Interior",subCategory:"Comfort and Convenience",url:"/comfort-convenience"},
    {division:"Accessories",category:"Interior",subCategory:"Door Sill Plates",url:"/door-sill-plates"},
    {division:"Accessories",category:"Interior",subCategory:"Floor Mats",url:"/floor-mats"},
    {division:"Accessories",category:"Interior",subCategory:"Interior Trim",url:"/interior-trim-kits"},
    {division:"Accessories",category:"Interior",subCategory:"Mirrors",url:"/mirrors"},
    {division:"Accessories",category:"Interior",subCategory:"Safety/Emergency Kits",url:"/safety-emergency-kits"},
    {division:"Accessories",category:"Interior",subCategory:"Seat Covers",url:"/seat-covers"},
    {division:"Accessories",category:"Wheels",subCategory:"Covers/Center Caps",url:"/covers-center-caps"},
    {division:"Accessories",category:"Wheels",subCategory:"Locks",url:"/locks"},
    {division:"Accessories",category:"Wheels",subCategory:"Tires",url:"/tires"},
    {division:"Accessories",category:"Wheels",subCategory:"Wheels",url:"/wheels-1"},
    {division:"Performance Parts",category:"Accessories",subCategory:"Off Road",url:"/off-road"},
    {division:"Performance Parts",category:"Appearance",subCategory:"Bedliners",url:"/bedliners"},
    {division:"Performance Parts",category:"Appearance",subCategory:"Decals/Graphics",url:"/decals-graphics"},
    {division:"Performance Parts",category:"Appearance",subCategory:"Floor Mats",url:"/floor-mats-1"},
    {division:"Performance Parts",category:"Appearance",subCategory:"Graphics",url:"/graphics"},
    {division:"Performance Parts",category:"Appearance",subCategory:"License Plate Frames",url:"/license-plate-frames"},
    {division:"Performance Parts",category:"Appearance",subCategory:"Posters/Banners",url:"/posters-banners"},
    {division:"Performance Parts",category:"Appearance",subCategory:"Rear Hitch",url:"/rear-hitch"},
    {division:"Performance Parts",category:"Appearance",subCategory:"Seats",url:"/seats"},
    {division:"Performance Parts",category:"Appearance",subCategory:"Steering Wheels",url:"/steering-wheels"},
    {division:"Performance Parts",category:"Appearance",subCategory:"Trim",url:"/trim"},
    {division:"Performance Parts",category:"Body",subCategory:"Body In White",url:"/body-in-white"},
    {division:"Performance Parts",category:"Body",subCategory:"Bumpers, Fenders, Door & Roof",url:"/bumpers-fenders-doors-roof"},
    {division:"Performance Parts",category:"Body",subCategory:"Covers, Deflectors & Protectors",url:"/covers-protectors-deflectors"},
    {division:"Performance Parts",category:"Body",subCategory:"Functional",url:"/functional"},
    {division:"Performance Parts",category:"Body",subCategory:"Off-road Enhancement",url:"/off-road-1"},
    {division:"Performance Parts",category:"Body",subCategory:"Panels",url:"/panels"},
    {division:"Performance Parts",category:"Body",subCategory:"Rails/Steps/Bars/Racks",url:"/rails-steps-bars-racks"},
    {division:"Performance Parts",category:"Body",subCategory:"Scoops, Louvers & Grilles",url:"/scoops-and-louvres-1"},
    {division:"Performance Parts",category:"Body",subCategory:"Sill Plates",url:"/sill-plates"},
    {division:"Performance Parts",category:"Body",subCategory:"Spoilers/Body Kits",url:"/spoilers-body-kits-1"},
    {division:"Performance Parts",category:"Body",subCategory:"Towing/Recovery",url:"/towing-recovery"},
    {division:"Performance Parts",category:"Chassis",subCategory:"Brake Kits/Components",url:"/brake-kits-components"},
    {division:"Performance Parts",category:"Chassis",subCategory:"Control Arms/Stabilizers",url:"/control-arms-stabilizers"},
    {division:"Performance Parts",category:"Chassis",subCategory:"Handling Packs",url:"/handling-packs"},
    {division:"Performance Parts",category:"Chassis",subCategory:"Shocks/Adj Suspension",url:"/shocks-adj-suspension"},
    {division:"Performance Parts",category:"Chassis",subCategory:"Springs",url:"/springs"},
    {division:"Performance Parts",category:"Chassis",subCategory:"Steering Systems",url:"/steering-systems"},
    {division:"Performance Parts",category:"Chassis",subCategory:"Wheel Covers/Center Caps",url:"/wheel-covers"},
    {division:"Performance Parts",category:"Chassis",subCategory:"Wheel Locks/Lug Nuts",url:"/wheel-locks"},
    {division:"Performance Parts",category:"Chassis",subCategory:"Wheels",url:"/wheels-2"},
    {division:"Performance Parts",category:"Driveline",subCategory:"Automatic Transmission",url:"/automatic-transmission"},
    {division:"Performance Parts",category:"Driveline",subCategory:"Axle Components",url:"/axle-components"},
    {division:"Performance Parts",category:"Driveline",subCategory:"Axle Shafts",url:"/axle-shafts"},
    {division:"Performance Parts",category:"Driveline",subCategory:"Clutch Related",url:"/clutch-related"},
    {division:"Performance Parts",category:"Driveline",subCategory:"Complete Axle Assy",url:"/complete-axle-assy"},
    {division:"Performance Parts",category:"Driveline",subCategory:"Complete Transmissions",url:"/complete-transmissions"},
    {division:"Performance Parts",category:"Driveline",subCategory:"Differentials",url:"/differentials"},
    {division:"Performance Parts",category:"Driveline",subCategory:"Drive Shafts",url:"/drive-shafts"},
    {division:"Performance Parts",category:"Driveline",subCategory:"Manual Trans",url:"/manual-trans"},
    {division:"Performance Parts",category:"Driveline",subCategory:"Ring & Pinion",url:"/ring-pinion"},
    {division:"Performance Parts",category:"Driveline",subCategory:"Shifters",url:"/shifters"},
    {division:"Performance Parts",category:"Electrical",subCategory:"Air Metering",url:"/air-metering"},
    {division:"Performance Parts",category:"Electrical",subCategory:"Analyzers/Calibrators",url:"/analyzers-calibrators"},
    {division:"Performance Parts",category:"Electrical",subCategory:"Auxiliary Lights",url:"/auxiliary-lights"},
    {division:"Performance Parts",category:"Electrical",subCategory:"Driving Lights",url:"/driving-lights"},
    {division:"Performance Parts",category:"Electrical",subCategory:"Fuel Metering",url:"/fuel-metering"},
    {division:"Performance Parts",category:"Electrical",subCategory:"Gauges",url:"/gauges"},
    {division:"Performance Parts",category:"Electrical",subCategory:"Ignition Related",url:"/ignition-related"},
    {division:"Performance Parts",category:"Electrical",subCategory:"Microprocessors",url:"/microprocessors"},
    {division:"Performance Parts",category:"Electrical",subCategory:"Starters",url:"/starters"},
    {division:"Performance Parts",category:"Electrical",subCategory:"Wiring",url:"/wiring"},
    {division:"Performance Parts",category:"Engine",subCategory:"Cylinder Heads",url:"/cylinder-heads"},
    {division:"Performance Parts",category:"Engine",subCategory:"Dress-Up Kits",url:"/dress-up-kits"},
    {division:"Performance Parts",category:"Engine",subCategory:"Engine Blocks",url:"/engine-blocks"},
    {division:"Performance Parts",category:"Engine",subCategory:"Exhaust Related",url:"/exhaust-related"},
    {division:"Performance Parts",category:"Engine",subCategory:"Fasteners",url:"/fasteners"},
    {division:"Performance Parts",category:"Engine",subCategory:"Flywheels",url:"/flywheels"},
    {division:"Performance Parts",category:"Engine",subCategory:"Fuel Delivery",url:"/fuel-delivery"},
    {division:"Performance Parts",category:"Engine",subCategory:"Gaskets",url:"/gaskets"},
    {division:"Performance Parts",category:"Engine",subCategory:"Intake Related",url:"/intake-related"},
    {division:"Performance Parts",category:"Engine",subCategory:"Oil Pumps/Pans",url:"/oil-pumps-pans"},
    {division:"Performance Parts",category:"Engine",subCategory:"Overhaul Kits",url:"/overhaul-kits"},
    {division:"Performance Parts",category:"Misc",subCategory:"Merchandise",url:"/merchandise"},
    {division:"Performance Parts",category:"Tools",subCategory:"Fender Covers",url:"/fender-covers"},
    {division:"Performance Parts",category:"Tools",subCategory:"Tools",url:"/tools-1"},
  ]
