import { call, put } from "redux-saga/effects";
//Foundation libraries
import mockServerService from "../../../_foundation/apis/mock/mockserver.service";
import { getSite } from "../../../_foundation/hooks/useSite";
//Redux
import * as ACTIONS from "../../action-types/header-categories";
import { TOP_CATEGORIES_DEPTH_LIMIT } from "../../../configs/catalog";

export function* getHeaderWorker(action: any) {
  try {
    const siteInfo = getSite();
    //console.log("SiteeInfo",`${siteInfo?.searchContext}`);
    const payload = action.payload;
    //console.log("path check" + `${siteInfo?.searchContext}/api/v2/categories?storeId=${siteInfo?.storeID}&depthAndLimit=${TOP_CATEGORIES_DEPTH_LIMIT}`)
    const response = yield call(
      mockServerService.getMock,
     
      {},
      {},
     
      `${siteInfo?.searchContext}/api/v2/categories?storeId=${siteInfo?.storeID}&depthAndLimit=${TOP_CATEGORIES_DEPTH_LIMIT}`
      //`${siteInfo?.searchContext}/api/v2/categories/headerCategories`
    );
  
    yield put({
      payload: payload,
      type: ACTIONS.RESPONSE_SUCCESSED_HEADER,
      response: response.data,
      
    })
    //console.log("HeaderResponse",response);
  } catch (error) {
    yield put({ type: ACTIONS.RESPONSE_FAILURE_HEADER, response:error });
    //console.log("HeaderError",error)
  }
}