/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */

export const HOME = "/";

export const OLD_HOME = "/FordUSAccessories";

//Order
export const ACCOUNT = "/account";
export const CART = "/cart";
export const CONTACT = "/contact-us";
export const SITEMAP = "/sitemap";
export const WARRANTY_INFORMATION = "/warranty-information";
export const CHECKOUT = "/checkout";
export const CHECKOUT_SHIPPING = `${CHECKOUT}/shipping`;
export const CHECKOUT_REWARDS = `${CHECKOUT}/rewards`;
export const CHECKOUT_PAYMENT = `${CHECKOUT}/payment`;
export const CHECKOUT_AFTER_PAYMENT = `${CHECKOUT}/after-payment`;

export const CHECKOUT_REVIEW = `${CHECKOUT}/review`;
export const ORDER_CONFIRMATION = "/order-confirmation";
export const ORDER_HISTORY = `${ACCOUNT}/order-history`;
export const MY_GARAGE = `${ACCOUNT}/my-garage`;
export const ADD_NEW_VEHICLE = `${ACCOUNT}/my-garage/new`;
export const EDIT_NEW_VEHICLE = `${ACCOUNT}/my-garage/edit/:vehicleId`;
export const WISH_LIST = `${ACCOUNT}/wish-list`;
export const VIEW_WISH_LIST = "/view-wish-list";
export const RECURRING_ORDERS = "/recurring-orders";
export const INPROGRESS_ORDERS = "/inprogress-orders";
export const REQUISITION_LISTS = "/requisition-lists";
export const ORDER_DETAILS = "/order-details";
export const ORDER_ID_PARAM = "/:orderId";
export const ORDER_DETAILS_ROUTE = `${ORDER_DETAILS}${ORDER_ID_PARAM}`;

//New routing for Shipping and its breadcrumb

export const CHECKOUT_INFORMATION = `${CHECKOUT}/information`;

//User
export const ADDRESS_BOOK = "/account/addresses";
export const ADD_ADDRESS = "/add-address";
export const EDIT_ADDRESS = "/edit-address";
export const ADDRESS_ID_PARAM = "/:addressId";
export const EDIT_ADDRESS_ROUTE = `${EDIT_ADDRESS}${ADDRESS_ID_PARAM}`;
export const PERSONAL_INFORMATION = "/personal-information";

//Account
export const SIGNIN = "/sign-in";
export const BUYER_REGISTRATION = "/buyer-registration";
export const ORG_REGISTRATION = "/organization-registration";

export const DASHBOARD = "/dashboard";
export const FORGOT_PASSWORD = "/forgot-password";
//Search
export const SEARCH = "/search";
export const PERSONAL_INFO = `${ACCOUNT}`;
//register user access only
export const REGISTER_PROTECTED = "register";
//only guest and generic user access
export const GENERIC_PROTECTED = "generic";

export const BUYER_MANAGEMENT = "/buyer-management";
export const APPROVALS_MANAGEMENT = "/approvals-management";
export const ORGANIZATION_MANAGEMENT = "/organization-management";
export const ORDER_APPROVAL = "/order-approval";

//Checkout Profile
export const CHECKOUT_PROFILES = "/list-checkout-profiles";
export const CHECKOUT_PROFILE_CREATE = "/checkout-profile-create";
export const CHECKOUT_PROFILE_EDIT = "/edit-checkout-profile";

// in-progress orders + items
export const IP_ORDER_DETAILS = "/inprogress-order-details";
export const IP_ORDER_DETAILS_ROUTE = `${IP_ORDER_DETAILS}${ORDER_ID_PARAM}`;

//Static Pages

export const FAQ = "/Faq";
export const SHIPPING_DELIVERY = "/shipping-delivery";
export const RETURN = "/return";
export const TNC = "/terms-conditions";
export const TAILGATING = "/blogs/news/tailgating-essentials";
export const BESTTRUCK = "/blogs/news/best-truck-bed-accessories";
export const OFFROADING = "/blogs/news/5-essentials-for-off-road-adventures";
export const OVERLANDING = "/blogs/news/overlanding-101";
export const BESTOVERLANDING = "/blogs/news/8-best-overlanding-accessories-for-your-ford";
export const LEARNMOREREWARDS = "/LearnMoreRewards";

export const MAKERETURN = "/MakeReturn";
export const RETURNSLIP = "/ReturnLabelSlip";
export const REDIRECT_URL_AFTER_LOGIN = "/LoginRedirect";
export const REDIRECT_URL_AFTER_LOGOUT = "/LogoutRedirect";
export const NEWS = "/blogs/news";
export const SPLASHGUARDS = "/blogs/news/benefits-of-splash-guards-for-your-ford-truck-or-suv";
export const BRONCOACCESSORIES = "/blogs/news/9-best-ford-bronco-accessories";
export const MUSTANGACCESSORIES = "/blogs/news/best-ford-mustang-accessories";
export const RANGERACCESSORIES = "/blogs/news/best-ford-ranger-accessories";
export const RAPTORACCESSORIES = "/blogs/news/must-have-ford-raptor-accessories";
export const BRONCOSPORT = "/blogs/news/bronco-vs-bronco-sport";
export const CARCAMPING = "/blogs/news/vehicle-camping-packing-list";
export const BIKERACKS = "/blogs/news/different-types-of-bike-racks";
export const FAMILYROADTRIP = "/blogs/news/family-road-trip-tips";
export const BLACKEDOUTVEHICLE = "/blogs/news/your-guide-to-a-blacked-out-vehicle-look";
export const MOSTMAVERICK = "/blogs/news/make-the-most-of-your-maverick";
export const MUSTHAVEFORD = "/blogs/news/8-must-have-ford-f-150-accessories";
export const MUSTHAVEVEHICLE = "/blogs/news/must-have-vehicle-accessories-for-summer";
export const ROOFRACK = "/blogs/news/how-to-strap-a-kayak-to-a-roof-rack";
export const SNOWBOARD = "/blogs/news/our-best-snowboard-racks";
export const TAKINGCARE = "/blogs/news/taking-care-of-your-vehicle-all-year-long";
export const TOWING = "/blogs/news/our-best-towing-accessories";
export const UNIQUEWAYS = "/blogs/news/5-unique-ways-to-use-your-truck-bed";
export const WEATHERPROOF = "/blogs/news/5-ways-to-weatherproof-your-vehicle";
export const WINTERCARCAMP = "/blogs/news/winter-car-camping-accessories-for-your-ford";
export const WINTERIZE = "/blogs/news/how-to-winterize-your-vehicle";
export const PERFORMANCEPARTS_LANDING = "/ford-performance-parts";
export const ACCESSORIES_LANDING = "/all";
export const WALKINGDEADF150 = "/blogs/news/the-walking-dead-custom-ford-f-150-anguss-build";
export const VINNESBUILD = "/blogs/news/custom-lincoln-aviator-vinnies-build";
export const BRONCOVINNESBUILD = "/blogs/news/orange-custom-ford-bronco-vinnies-build";
export const ANGUSBUILD = "/blogs/news/custom-lincoln-aviator-anguss-build";
export const BRONCOANGUSBUILD = "/blogs/news/the-walking-dead-custom-bronco-anguss-build";
export const F150VINNESBUILD = "/blogs/news/custom-ford-f-150-fx4-vinnies-build";



//Constants for old links
export const ACCOUNT_OLD = `${OLD_HOME}/account`;
export const CONTACT_OLD = `${OLD_HOME}/contact-us`;
export const SITEMAP_OLD = `${OLD_HOME}/sitemap`;
export const WARRANTY_INFORMATION_OLD = `${OLD_HOME}/warranty-information`;
export const CHECKOUT_OLD = `${OLD_HOME}/checkout`;
export const CHECKOUT_SHIPPING_OLD = `${CHECKOUT_OLD}/shipping`;
export const CHECKOUT_PAYMENT_OLD = `${CHECKOUT_OLD}/payment`;
export const SEARCH_OLD = `${OLD_HOME}/search`;


export const ORDER_HISTORY_OLD = `${ACCOUNT_OLD}/order-history`;
export const ORDER_DETAILS_OLD = `${OLD_HOME}/order-details`;
export const ORDER_DETAILS_ROUTE_OLD = `${ORDER_DETAILS_OLD}${ORDER_ID_PARAM}`;
export const MY_GARAGE_OLD = `${ACCOUNT_OLD}/my-garage`;
export const WISH_LIST_OLD = `${ACCOUNT_OLD}/wish-list`;
export const ADDRESS_BOOK_OLD = `${OLD_HOME}/account/addresses`;
export const CHECKOUT_INFORMATION_OLD = `${CHECKOUT}/information`;
export const FAQ_OLD = `${OLD_HOME}/Faq`;
export const SHIPPING_DELIVERY_OLD = `${OLD_HOME}/shipping-delivery`;
export const RETURN_OLD = `${OLD_HOME}/return`;
export const TNC_OLD = `${OLD_HOME}/terms-conditions`;
export const TAILGATING_OLD = `${OLD_HOME}/blogs/news/tailgating-essentials`;
export const BESTTRUCK_OLD = `${OLD_HOME}/blogs/news/best-truck-bed-accessories`;
export const OFFROADING_OLD = `${OLD_HOME}/blogs/news/5-essentials-for-off-road-adventures`;
export const OVERLANDING_OLD = `${OLD_HOME}/blogs/news/overlanding-101`;
export const BESTOVERLANDING_OLD = `${OLD_HOME}/blogs/news/8-best-overlanding-accessories-for-your-ford`;
export const LEARNMOREREWARDS_OLD = `${OLD_HOME}/LearnMoreRewards`;

export const MAKERETURN_OLD = `${OLD_HOME}/MakeReturn`;
export const RETURNSLIP_OLD = `${OLD_HOME}/ReturnLabelSlip`;
export const NEWS_OLD = `${OLD_HOME}/blogs/news`;
export const SPLASHGUARDS_OLD = `${OLD_HOME}/blogs/news/benefits-of-splash-guards-for-your-ford-truck-or-suv`;
export const BRONCOACCESSORIES_OLD = `${OLD_HOME}/blogs/news/9-best-ford-bronco-accessories`;
export const MUSTANGACCESSORIES_OLD = `${OLD_HOME}/blogs/news/best-ford-mustang-accessories`;
export const RANGERACCESSORIES_OLD = `${OLD_HOME}/blogs/news/best-ford-ranger-accessories`;
export const RAPTORACCESSORIES_OLD = `${OLD_HOME}/blogs/news/must-have-ford-raptor-accessories`;
export const BRONCOSPORT_OLD = `${OLD_HOME}/blogs/news/bronco-vs-bronco-sport`;
export const CARCAMPING_OLD = `${OLD_HOME}/blogs/news/vehicle-camping-packing-list`;
export const BIKERACKS_OLD = `${OLD_HOME}/blogs/news/different-types-of-bike-racks`;
export const FAMILYROADTRIP_OLD = `${OLD_HOME}/blogs/news/family-road-trip-tips`;
export const BLACKEDOUTVEHICLE_OLD = `${OLD_HOME}/blogs/news/your-guide-to-a-blacked-out-vehicle-look`;
export const MOSTMAVERICK_OLD = `${OLD_HOME}/blogs/news/make-the-most-of-your-maverick`;
export const MUSTHAVEFORD_OLD = `${OLD_HOME}/blogs/news/8-must-have-ford-f-150-accessories`;
export const MUSTHAVEVEHICLE_OLD = `${OLD_HOME}/blogs/news/must-have-vehicle-accessories-for-summer`;
export const ROOFRACK_OLD = `${OLD_HOME}/blogs/news/how-to-strap-a-kayak-to-a-roof-rack`;
export const SNOWBOARD_OLD = `${OLD_HOME}/blogs/news/our-best-snowboard-racks`;
export const TAKINGCARE_OLD = `${OLD_HOME}/blogs/news/taking-care-of-your-vehicle-all-year-long`;
export const TOWING_OLD = `${OLD_HOME}/blogs/news/our-best-towing-accessories`;
export const UNIQUEWAYS_OLD = `${OLD_HOME}/blogs/news/5-unique-ways-to-use-your-truck-bed`;
export const WEATHERPROOF_OLD = `${OLD_HOME}/blogs/news/5-ways-to-weatherproof-your-vehicle`;
export const WINTERCARCAMP_OLD = `${OLD_HOME}/blogs/news/winter-car-camping-accessories-for-your-ford`;
export const WINTERIZE_OLD = `${OLD_HOME}/blogs/news/how-to-winterize-your-vehicle`;
export const PERFORMANCEPARTS_LANDING_OLD = `${OLD_HOME}/ford-performance-parts`;
export const ACCESSORIES_LANDING_OLD = `${OLD_HOME}/all`;
export const WALKINGDEADF150_OLD = `${OLD_HOME}/blogs/news/the-walking-dead-custom-ford-f-150-anguss-build`;
export const VINNESBUILD_OLD = `${OLD_HOME}/blogs/news/custom-lincoln-aviator-vinnies-build`;
export const BRONCOVINNESBUILD_OLD = `${OLD_HOME}/blogs/news/orange-custom-ford-bronco-vinnies-build`;
export const ANGUSBUILD_OLD = `${OLD_HOME}/blogs/news/custom-lincoln-aviator-anguss-build`;
export const BRONCOANGUSBUILD_OLD = `${OLD_HOME}/blogs/news/the-walking-dead-custom-bronco-anguss-build`;
export const F150VINNESBUILD_OLD = `${OLD_HOME}/blogs/news/custom-ford-f-150-fx4-vinnies-build`;