/* eslint-disable react-hooks/exhaustive-deps */
/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */

//Standard libraries
import React, { Dispatch, Suspense, useState } from "react";
import { BrowserRouter, Route, useHistory ,useLocation } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Axios, { Canceler } from "axios";
import getDisplayName from "react-display-name";
import Box from "@material-ui/core/Box";
import Disclosure from "./components/Disclosure/Disclosure";
import { HeaderFord } from "./components/headerFord/HeaderFord";
//import { Header } from "./components/header";

//Foundation libraries
//import { site } from "./_foundation/constants/site";
import { site } from "./_foundation/constants/site";
import { initAxios } from "./_foundation/axios/axiosConfig";
import { initSite, useSite } from "./_foundation/hooks/useSite";
import LoginGuard from "./_foundation/guard/LoginGuard";
import {
  storageSessionHandler,
  localStorageUtil,
} from "./_foundation/utils/storageUtil";
import { LOCALE } from "./_foundation/constants/common";
import storeService from "./_foundation/apis/transaction/store.service";
import { PRODUCTION } from "./_foundation/constants/common";
//Custom librarie
import { ROUTE_CONFIG } from "./configs/routes";
import {
  CommerceEnvironment,
  DISCOVER_FEATURE,
  EMPTY_STRING,
  HYPHEN,
  UNDERSCORE,
} from "./constants/common";

import { Footer } from "./components/footer";
import { Extensions } from "./components/extensions";
import { useCSRForUser } from "./_foundation/hooks/useCSRForUser";
import SuccessMessageSnackbar from "./components/widgets/message-snackbar/SuccessMessageSnackbar";
import ErrorMessageSnackbar from "./components/widgets/message-snackbar/ErrorMessageSnackbar";
import { IFRAME_RESIZER } from "./_foundation/constants/csr";
//Redux
import { forUserIdSelector, loginStatusSelector } from "./redux/selectors/user";
//import { forUserIdSelector } from "./redux/selectors/user";
import {
  INIT_STATE_FROM_STORAGE_ACTION,
  LISTEN_USER_FROM_STORAGE_ACTION,
} from "./redux/actions/user";
import { GET_COUNTRY_STATE_LIST_ACTION } from "./redux/actions/country";
//UI
import { StyledWrapper } from "./components/StyledUI";
import {
  StyledGrid,
 
} from "@hcl-commerce-store-sdk/react-component";
import {StyledProgressPlaceholder} from './_foundation/hooks/circular-progress/styled-progress-placeholder'
import "./App.scss";
//GA360
//UA
import GTMDLService from "./_foundation/gtm/ua/gtmDataLayer.service";
//GA4
import GA4GTMDLService from "./_foundation/gtm/ga4/gtmDataLayer.service";
import "./components/checkout/checkout.scss"
//Common libraries
import * as ROUTES from "./constants/routes";
// Abode Tag
import AdobeService from "./_foundation/adobeServices/adobeService";
// import AccountDetails from "./components/MyAccount/AccountDetails";
import ScrollTop  from "./_foundation/hooks/use-scrollToTop";
import { categorySections, subCategory } from "./constants/category-adobe-tag"
import Script from "react-load-script";

const ScrollToTop = () => {
  React.useEffect(() => {
    //scroll to top on path change.
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  }, []);
  return <></>;
};

const App: React.FC = (props: any) => {
  const widgetName = getDisplayName(App);
  const loggedIn = useSelector(loginStatusSelector);
  const forUserId = useSelector(forUserIdSelector);
  const dispatch = useDispatch<Dispatch<any>>();
  const { mySite, storeDisplayName } = useSite();
  const { i18n } = useTranslation();
  const { receiveParentMessage } = useCSRForUser();
  const CancelToken = Axios.CancelToken;
  let cancels: Canceler[] = [];
  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };
  const [discover, setDiscover] = React.useState<boolean>(false);
  const location = useLocation();
  const setTranslate = () => {
    /**
     * language preference priority
     * 1. user context, to be implemented with language toggle
     * 2. localStorage (saved for 30 days).
     * 3. store default language.
     */
    // TODO: language toggle, update user language, read language from userContext if it is registered user.
    if (mySite) {
      //check if locale exists in local storage
      if (localStorageUtil.get(LOCALE) === null) {
        //locale does not exist in local storage
        //get language from site default. convert from id to string
        const locale = CommerceEnvironment.languageMap[mySite.defaultLanguageID]
          .split("_")
          .join("-");
        //check if language from site default matches the current store language
        if (locale !== i18n.languages[0]) {
          //if not then change language
          i18n.changeLanguage(locale);
        }
        //set locale into local storage
        localStorageUtil.set(LOCALE, locale.split("-").join("_"));
      } else {
        const locale = localStorageUtil
          .get(LOCALE)
          .split(UNDERSCORE)
          .join(HYPHEN);
        i18n.changeLanguage(locale);
      }
    }
  };
  initAxios(dispatch);

  /**
   * Function to check Discover is enabled for store based on storeId
   *
   * @param storeID
   */
  const isDiscoverEnabled = (storeID: string) => {
    const payload = {
      storeId: storeID,
      ...payloadBase,
    };
    storeService
      .getStoreEnabledFeaturesList(payload)
      .then((res) => {
        if (res.data && res.data.resultList) {
          setDiscover(res.data.resultList.includes(DISCOVER_FEATURE));
        }
      })
      .catch((e) => {
        //console.log(e);
      });
  };
  
  React.useEffect(() => {

       if (location.pathname === ROUTES.CART) {
        (async () => {
          let res = await AdobeService.track({pageName: 'cart:cart view', variantName: 'cart-view', siteSection: 'cart', pagenameNoProduct:"cart:cart view", hierarchy: 'cart:cart view', step: 'scView', eventType: 'cartLanding'});
          // console.log("11111 cart Landing")
          AdobeService.callSatellite(res);
        })()
      } else if (location.pathname === ROUTES.CHECKOUT_INFORMATION || location.pathname === ROUTES.CHECKOUT_INFORMATION_OLD) {
        (async () => {
          let res = await AdobeService.track({pageName: 'cart:checkout:landing page', variantName: 'cart-checkout-landing', siteSection: 'checkout', pagenameNoProduct:"cart:checkout:landing page", hierarchy: 'cart:checkout:checkout', step: 'scCheckout', tool: 'checkout start', eventType: 'cartCheckout'});
          AdobeService.callSatellite(res);
        })()
      } else if (location.pathname === ROUTES.CHECKOUT_SHIPPING || location.pathname === ROUTES.CHECKOUT_SHIPPING_OLD) {
          (async () => {
            let res = await AdobeService.track({pageName: 'cart:checkout:shipping', variantName: 'cart-checkout', siteSection: 'checkout', pagenameNoProduct:"cart:checkout:shipping", hierarchy: 'cart:checkout:checkout', step: 'scView', tool: 'checkout shipping', eventType: 'checkoutShipping'});
            AdobeService.callSatellite(res);
          })()
      } else if (location.pathname === ROUTES.CHECKOUT_PAYMENT || location.pathname === ROUTES.CHECKOUT_PAYMENT_OLD) {
          (async () => {
            let res = await AdobeService.track({pageName: 'cart:checkout:payment', variantName: 'cart-checkout', siteSection: 'checkout', pagenameNoProduct:"cart:checkout:payment", hierarchy: 'cart:checkout:payment', step: 'scView', tool: 'checkout payment', eventType: 'checkoutPayment'});
            AdobeService.callSatellite(res);
          })()
      } else if (location.pathname === ROUTES.CHECKOUT_REWARDS) {
            (async () => {
            let res = await AdobeService.track({pageName: 'cart:checkout:fpr', variantName: 'cart-checkout', siteSection: 'checkout', pagenameNoProduct:"cart:checkout:fpr", hierarchy: 'cart:checkout:checkout', step: 'scView', tool: 'checkout fpr', eventType: 'checkoutfpr'});
            AdobeService.callSatellite(res);
            })() 
      } else if (location.pathname === ROUTES.PERFORMANCEPARTS_LANDING || location.pathname === ROUTES.PERFORMANCEPARTS_LANDING_OLD) {
        (async () => {
          let res = await AdobeService.track({pageName: 'performance parts:landing page', variantName: 'global-nameplate-load', siteSection: 'performance parts', hierarchy: 'performance parts', pagenameNoProduct: "performance parts:landing page", eventType: 'performance parts:landing page'});
          AdobeService.callSatellite(res);
        })()
      } else if (location.pathname === ROUTES.ACCESSORIES_LANDING || location.pathname === ROUTES.ACCESSORIES_LANDING_OLD) {
        (async () => {
          let res = await AdobeService.track({pageName: 'accessories:landing page', variantName: 'global-nameplate-load', siteSection: 'accessories', hierarchy: 'accessories', pagenameNoProduct: "accessories:landing page", eventType: 'accessories:landing page'});
          AdobeService.callSatellite(res);
        })()
      } 
      else if (location.pathname === "/shop-by-brand" || location.pathname === ROUTES.OLD_HOME+"/shop-by-brand") {
        (async () => {
          let res = await AdobeService.track({pageName: 'shop by brands', variantName: 'global-load', pagenameNoProduct :`shop by brands:lp`, siteSection: 'shop by brands', hierarchy: 'shop by brands', eventType: 'globalLoad'});
          AdobeService.callSatellite(res);
        })()
      }
      else if(localStorage.getItem('loginSuccessful') && localStorage.getItem('loginSuccessful') === 'true') {
        (async () => {
          let res = await AdobeService.track({pageName: `fma:login`, variantName: "fma-login-complete", onclickLinkName: "fma:login:complete", onclick:'login:complete', siteSection: "fma", hierarchy: "fma:login", eventType: "Fmaloginsuccess"});
          AdobeService.callSatellite(res);
        })(); 
        localStorage.removeItem('loginSuccessful');
      }
  }, [location.pathname]);

  React.useEffect(() => {
    setTimeout(() => {
    categorySections.map((d)=>{
      if(d.division === "Accessories"){
        if(location.pathname === d.url || location.pathname === ROUTES.OLD_HOME + d.url){
          (async () => {
            let res = await AdobeService.track({pageName: `accessories:${d.category}`,pagenameNoProduct :`accessories:${d.category}`, variantName: 'global-nameplate-load', siteSection: 'accessories', hierarchy: 'accessories', eventType: 'global-nameplate-load category'});
            AdobeService.callSatellite(res);
          })();
        }
      }else if(d.division === "Performance Parts"){
        if(location.pathname === d.url || location.pathname === ROUTES.OLD_HOME + d.url){
          (async () => {
            let res = await AdobeService.track({pageName: `performance parts:${d.category}`,pagenameNoProduct :`performance parts:${d.category}`, variantName: 'global-nameplate-load', siteSection: 'performance parts', hierarchy: 'performance parts', eventType: 'global-nameplate-load category'});
            AdobeService.callSatellite(res);
          })();
        }
      }else if(d.division === "Bronco1"){
        if(location.pathname === d.url || location.pathname === ROUTES.OLD_HOME + d.url){
          (async () => {
            let res = await AdobeService.track({pageName: `vehicle:lp:Bronco`, variantName: 'global-nameplate-load', pagenameNoProduct :`vehicle:lp`, siteSection: 'vehicle lp', hierarchy: 'vehicle lp', eventType: 'global-nameplate-load category'});
            AdobeService.callSatellite(res);
          })();
        }
      } else if (d.division === "ford-f-150-2021-1") {
        if (location.pathname === d.url || location.pathname === ROUTES.OLD_HOME + d.url) {
          (async () => {
            let res = await AdobeService.track({ pageName: `vehicle:lp:ford-f-150-2021`, variantName: 'global-nameplate-load', pagenameNoProduct: `vehicle:lp`, siteSection: 'vehicle lp', hierarchy: 'vehicle lp', eventType: 'global-nameplate-load category' });
            AdobeService.callSatellite(res);
          })();
        }
      } else if (d.division === "ford-f-150-2021") {
        if (location.pathname === d.url || location.pathname === ROUTES.OLD_HOME + d.url) {
          (async () => {
            let res = await AdobeService.track({ pageName: `vehicle:lp:ford-f-150-2021:${d.category}`, variantName: 'global-nameplate-load', pagenameNoProduct: `vehicle:lp:${d.category}`, siteSection: 'vehicle lp', hierarchy: 'vehicle lp', eventType: 'global-nameplate-load category' });
            AdobeService.callSatellite(res);
          })();
        }
      } else if(d.division === "Maverick1"){
        if(location.pathname === d.url || location.pathname === ROUTES.OLD_HOME + d.url){
          (async () => {
            let res = await AdobeService.track({pageName: `vehicle:lp:Maverick`, variantName: 'global-nameplate-load', pagenameNoProduct :`vehicle:lp`, siteSection: 'vehicle lp', hierarchy: 'vehicle lp', eventType: 'global-nameplate-load category'});
            AdobeService.callSatellite(res);
          })();
        }
      }else if(d.division === "Bronco"){
        if(location.pathname === d.url || location.pathname === ROUTES.OLD_HOME + d.url){
          (async () => {
            let res = await AdobeService.track({pageName: `vehicle:lp:Bronco:${d.category}`, variantName: 'global-nameplate-load', pagenameNoProduct :`vehicle:lp:${d.category}`, siteSection: 'vehicle lp', hierarchy: 'vehicle lp', eventType: 'global-nameplate-load category'});
            AdobeService.callSatellite(res);
          })();
        }
      }else if(d.division === "Maverick"){
        if(location.pathname === d.url || location.pathname === ROUTES.OLD_HOME + d.url){
          (async () => {
            let res = await AdobeService.track({pageName: `vehicle:lp:Maverick:${d.category}`, variantName: 'global-nameplate-load', pagenameNoProduct :`vehicle:lp:${d.category}`, siteSection: 'vehicle lp', hierarchy: 'vehicle lp', eventType: 'global-nameplate-load category'});
            AdobeService.callSatellite(res);
          })();
        }
      }
    }) 
  }, 1000);

  }, [location.pathname]);
  
    React.useEffect(()=>{
        subCategory.map((d)=>{
          if(d.division === "Accessories"){
            if(location.pathname === d.url || location.pathname === ROUTES.OLD_HOME + d.url){
              (async () => {
                let res = await AdobeService.track({pageName: `accessories:${d.category}:${d.subCategory}`, pagenameNoProduct :`accessories:${d.category}:${d.subCategory}`, variantName: 'global-nameplate-load', siteSection: 'accessories', hierarchy: 'accessories', eventType: 'global-nameplate-load category subcategory'});
                AdobeService.callSatellite(res);
              })()
            }
          } else if(d.division === "Performance Parts"){
            if(location.pathname === d.url || location.pathname === ROUTES.OLD_HOME + d.url){
              (async () => {
                let res = await AdobeService.track({pageName: `performance parts:${d.category}:${d.subCategory}`, pagenameNoProduct :`performance parts:${d.category}:${d.subCategory}`, variantName: 'global-nameplate-load', siteSection: 'performance parts', hierarchy: 'performance parts', eventType: 'global-nameplate-load category subcategory'});
                AdobeService.callSatellite(res);
              })()
            }
          }
        })
    },[location.pathname])

  React.useEffect(() => {
      if (mySite) {      
      dispatch(INIT_STATE_FROM_STORAGE_ACTION({ ...payloadBase }));
      dispatch(GET_COUNTRY_STATE_LIST_ACTION({ ...payloadBase }));
      storageSessionHandler.triggerUserStorageListener(() =>
        dispatch(LISTEN_USER_FROM_STORAGE_ACTION({ ...payloadBase }))
      );
      setTranslate();
      isDiscoverEnabled(mySite.storeID);
      //GA360
      if (mySite.enableGA) {
        if (mySite.enableUA) {
          GTMDLService.initializeGTM(
            mySite.gtmID,
            mySite.gtmAuth,
            mySite.gtmPreview
          );
        }
        if (mySite.enableGA4 && !mySite.enableUA) {
          GA4GTMDLService.initializeGTM(
            mySite.gtmID,
            mySite.gtmAuth,
            mySite.gtmPreview
          );
        }
      }
    } else {
      initSite(site, dispatch);
    }
  }, [mySite, dispatch]);

  React.useEffect(() => {
   
    if (forUserId) {
   
      window[IFRAME_RESIZER] = {
        onMessage: receiveParentMessage,
      };
    } else {
      window[IFRAME_RESIZER] = undefined;
    }
  }, [forUserId]);
  React.useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
  }, []);
 
  React.useEffect(() => {
    const scriptTag = document.createElement("script");
    scriptTag.setAttribute("type", "text/javascript");
    scriptTag.setAttribute(
      "src",
      `https://www.bing.com/api/maps/mapcontrol?callback=makeMap`
    );
    scriptTag.async = true;
    scriptTag.defer = true;
    document.body.appendChild(scriptTag);


  }, []);
  // public url path for accessing discoverui.js file.
  const publicUrlPath = process.env.PUBLIC_URL
    ? process.env.PUBLIC_URL
    : EMPTY_STRING;
  
  const locationURL = useLocation();
  //console.log("locationURL", locationURL);
  const [displayHeader, setDisplayHeader] = React.useState<any>('');

  const [scriptLoaded, setSriptLoaded] = React.useState<any>(false);
  React.useEffect(() => {
    setDisplayHeader(locationURL.pathname.search("checkout") === 1 || locationURL.pathname.search("order-confirmation") ===1 ? false : true);
    setTimeout(function () {
      let container = document.querySelectorAll('.category-recommendation-container'); 
        container.forEach((item) => {
          item.innerHTML === '' && item.classList.add("category-recommendation-hidden");
        }); 
    },2000);
    
  }, [locationURL]); 
  
  return (
    mySite && (
        <StyledWrapper data-testid="app-wrapper">
          <SuccessMessageSnackbar />
          <ErrorMessageSnackbar />
          <StyledGrid
            container
            direction="column"
            justifyContent="space-evenly"
            alignItems="stretch"
            className="full-viewport-height">
            <StyledGrid item xs={"true"}>
            {displayHeader && (<HeaderFord/>)}
              <ScrollToTop />
            </StyledGrid>
            <StyledGrid item xs className="full-width">
                <Suspense
                  fallback={
                   <StyledProgressPlaceholder className="vertical-padding-20" style={{minHeight:window.innerHeight+'px'}}/>
                  }>
                  <ScrollTop />
                  {renderRoutes(
                    mySite.isB2B ? ROUTE_CONFIG.B2B : ROUTE_CONFIG.B2C
                  )}
                </Suspense>
              </StyledGrid> 
              <StyledGrid item xs={true}>  
                {displayHeader && (
                        /* <Disclosure /> */
                        <Footer />
                )} 
              </StyledGrid>
            {process.env.NODE_ENV !== PRODUCTION && <Extensions />}
          </StyledGrid>
        </StyledWrapper>
    )
  );
};

export default App;