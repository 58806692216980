
import React, { useEffect, useState, useCallback } from "react";
import orderService from "../../../_foundation/apis/transaction/order.service";
import {
  StyledTypography,
  StyledCheckbox
} from "@hcl-commerce-store-sdk/react-component";
import { Link, useHistory } from "react-router-dom";
import { getSite, useSite } from "../../../_foundation/hooks/useSite";
import OrderDetailTable from "../../MyAccount/OrderDetailTable";
import { REG_EX } from "../../../constants/common";

const MakeReturn = (props: any) => {
  window.scrollTo(0, 0);
  const siteInfo = getSite();
  const history = useHistory();
  const { mySite } = useSite();
  const [orderId, setOrderid] = useState<any | []>([]);
  const [orderDetails, setOrderDetails] = useState<any | []>([]);
  const [partNumbers, setPartNumbers] = useState<any | []>([]);
  const [orderLines, setOrderLines] = useState<any | []>([]);
  const [openModal, setOpenModal] = useState<any>();
  const [formState, setFormState] = useState<any>(false);
  const [formStateError, setFormStateError] = useState<any>(false);
  // const auth = orderService.getAuthorization();
  const [OrderIdFromDetail, setOrderIdFromDetail] = useState<string>("");
  // const [returnableItem, setReturnableItem] = useState<string>("");
  const [showIsReturnable, setshowIsReturnable] = useState<any>(false);
  const [isChecked ,setIsChecked] = useState<any>();
  const isMakeReturnEnabled = mySite.storeCfg.userData["Return_Enabled"] === 'false' ? false : true; 
  const validateEmail = (email: string) => {
    const EMAIL = REG_EX.EMAIL;
    return email === undefined || email.trim() === "" || EMAIL.test(email);
  }
  const handleClose = (val) => {
    setOpenModal(val);
  };

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (e.target[0].value === '') {
      setFormState(true)
      setFormStateError("Enter valid order no.")
      return;
    }
    if (!/^[FAE]{3}:\d{8}$/.test(e.target[0].value)) {
      setFormState(true);
      setFormStateError("Enter valid Order Number: (e.g FAE:12345678)");
      return;
    }
    if (e.target[1].value === '') {
      setFormState(true)
      setFormStateError("Enter valid email address")
      return;
    }
    if (!validateEmail(e.target[1].value)) {
      setFormState(true);
      setFormStateError("Enter valid email address");
      return;
  }
    let data = {
      "OrderNo": e.target[0].value,
      "EnterpriseCode": "FordUS",
      "CustomerEMailID": encodeURIComponent(e.target[1].value),
      "DocumentType": "0001",
      "Extn": {
        "ExtnStoreId": siteInfo?.storeID
      }
    };

    orderService.getOrderHistorydDetail(data)
      .then((response) => {

        let checkmap = false;
        for (let i = 0; i < response.data.OrderLines.OrderLine.length; i++) {
          if (response.data.OrderLines.OrderLine[i].Status === "Shipped" ||
            response.data.OrderLines.OrderLine[i].Status === "Completed" ||
            response.data.OrderLines.OrderLine[i].Status === "Return Created" ||
            response.data.OrderLines.OrderLine[i].Status === "Partially Completed" ||
            response.data.OrderLines.OrderLine[i].Status === "Partially Return Completed" ||
            response.data.OrderLines.OrderLine[i].Status === "Partially Return Created" &&
            response.data.OrderLines.OrderLine[i].OrderStatuses.OrderStatus.Status !== "3200.002"
          ) {
            checkmap = true;
          }
        }

        /* 
        * checking if order item is returnable or not 
        */
        let findIsReturnable = (num) => {
          return num.ItemDetails.Extn.ExtnIsReturnable === "Y";
        }
        let filterIsReturnable = response.data.OrderLines.OrderLine.filter(findIsReturnable)
        filterIsReturnable.length !== response.data.OrderLines.OrderLine.length ? setshowIsReturnable(true) : setshowIsReturnable(false)

        /* 
        * checking if order status is either Shipped or Completed 
        * checking if order status is not picked up type
        */
        if (checkmap && orderReturnTimeStamp(response.data.OrderDate)) {
          setFormState(false)
          setOrderDetails(response.data)
        } else {
            if (response.data.Extn.ExtnOrderType === "Dealer Order") {
              let checkReturn :number = 0;
              setFormState(true)
              //Display message based on returnable item purchased
              if ((response.data.Extn as any)?.ExtnOrderType === "Dealer Order" && response.data?.OrderLines) {
                Object.keys(response.data?.OrderLines['OrderLine']).map((item: any, index: number) => {
                  if (response.data.OrderLines['OrderLine'][item]['ItemDetails']['Extn']['ExtnIsReturnable'] === 'Y') {
                      checkReturn = checkReturn+1;
                  }
                });
                (checkReturn>0)?setFormStateError("There are items on your order that are non-returnable .")
              
                  : setFormStateError("This order does not meet the return eligibility criteria .")
              }
            }
           else if (response.data.Status === "Created" || response.data.Status === "Released" ||
            response.data.Status === "Partially Received" || response.data.Status === "Partially Released" ||
            response.data.Status === "Partially Scheduled" || response.data.Status === "Scheduled") {
            setFormState(true)
            setFormStateError("No fulfillment found.")
          } else if (response.data.Status === "Cancelled") {
            setFormState(true)
            setFormStateError("This order is already cancelled")
          } else if (showIsReturnable) {
            setFormState(true)
            setFormStateError("Order items are not eligible for return.")
          } else {
            setFormState(true)
            orderReturnTimeStamp(response.data.OrderDate) ?
              setFormStateError("Return window closed for this order") :
              setFormStateError("Return window closed for this order");
          }
        }
      })
      .catch((err) => {
        err.response.data.errors[0].ErrorDescription != "YFS:Invalid Order" ?
          console.log("AXIOS ERROR: ", err) :
          setFormState(true)
        setFormStateError("Order Not found");
      });
  }, []);

  const updateOrderValue = (e, val, field) => {
    if (val !== '') {
      setFormState(false)
      setFormStateError("")
    }
    if (field === 'order') {
      setOrderid(val);
      if (!/^[FAE]{3}:\d{8}$/.test(val)) {
        setFormState(true);
        setFormStateError("Enter valid Order Number: (e.g FAE:12345678)");
        return;
      }
    }
  }

  const getProductReturnValue = (evt, item, OrderLineKey, OrderedQty) => {
    let data = [...partNumbers];
    let data1 = [...orderLines];
    setIsChecked(evt);
    const ind = orderLines.findIndex(item => (item.OrderLineKey === OrderLineKey));
    if (evt) {
      if (partNumbers.findIndex(itemId => itemId === item) < 0) {
        data.push(item)
      }
      if (ind < 0) {
        data1.push({ "OrderLineKey": OrderLineKey, "OrderedQty": 1 });
      } else {
        data1[ind].OrderedQty = data1[ind].OrderedQty + 1;
      }
    } else {
      if (orderLines[ind].OrderedQty === 1) {
        data.splice(partNumbers.findIndex(itemId => itemId === item), 1)
        data1.splice(orderLines.findIndex(item => item.OrderLineKey === OrderLineKey), 1)
      } else if (orderLines[ind].OrderedQty > 1) {
        // data1.splice(orderLines.findIndex(item => item.OrderLineKey === OrderLineKey), 1)
        data1[ind].OrderedQty = data1[ind].OrderedQty - 1;
      }
    }
    setPartNumbers((prevState) => data)
    setOrderLines((prevState) => data1)
    // selectedReturnableItem(item);
  }

  /* const selectedReturnableItem = (item) => {
    let filteBySelected = (num) => { return num.Item.ItemID === item}
    let filterValue = orderDetails?.OrderLines.OrderLine.filter(filteBySelected)
    setReturnableItem(filterValue);
  } */

  const handleConfirmation = (e) => {
    if (isMakeReturnEnabled) {
      e.preventDefault();
      for (let i = 0; i < orderLines.length; i++) {
        orderLines[i].ReturnReason = e.target[0].value
      }
  
      // if(e.target[0].length > 0, partNumbers.length > 0){
      if (e.target[0].value !== 'Please select a reason' && partNumbers.length > 0) {
        setOpenModal(true);
      }
    } else {
      return false;
    }
    
  }

  const handleSubmitReturn = (e) => {
    e.preventDefault();
    let data = {
      "OrderLine": orderLines,
      "OrderHeaderKey": orderDetails.OrderHeaderKey,
      "DraftOrderFlag": ""
    };
    orderService.returnOrder(data)
      .then((response) => {

        console.log("before return data prder-detail", orderDetails);
        console.log("before return data prder-detail-line", orderLines);
        console.log("return data", response);

        let OrderDetailData = {
          "OrderNo": orderDetails.OrderNo,
          "EnterpriseCode": "FordUS",
          "CustomerEMailID": encodeURIComponent(orderDetails.CustomerEMailID),
          "DocumentType": "0001",
          "Extn": {
            "ExtnStoreId": siteInfo?.storeID
          }
        };
        

        orderService.getOrderHistorydDetail(OrderDetailData)
          .then((responseData) => {
            console.log("return data", responseData);

            history.push({
              pathname: '/ReturnLabelSlip',
              state: {
                orderDetails: responseData.data,
                orderId: response.data.OrderNo,
                OrderLines: orderLines,
                partNumbers: partNumbers,
                ReturnReason: orderLines[0].ReturnReason,
                OrderHeaderKey: response.data.OrderHeaderKey,
                returnOrderDetails: response.data,
              }
            });
            handleClose(false);

        })
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
        handleClose(false);
      });
  }

  useEffect(() => {
    props.location?.state && setOrderIdFromDetail(props.location.state.orderId);
  }, [props.location?.state])


  /* taking time in minutes 
  * 60 minutes 
  * 1 day = 60 * 24
  * 30 days = 60 * 24 * 30
  */
  const orderReturnTimeStamp: any = (orderDate) => {
    let currentTime: any = new Date();
    let orderTime: any = new Date(orderDate);
    if (((currentTime - orderTime) / (60 * 24 * 30 * 1000)) / 2 < 30) { return true } else { return false }
  }

  return (
    <>
      {orderDetails?.OrderLines == undefined && (
        <div className="return-details container">
          <StyledTypography variant="h1" className="return-details__title text-align-center">
            MAKE A RETURN
          </StyledTypography>
          <p className="return-details__description text-align-center">In order to make a return, we'll need the email address you placed the order with and the order number</p>
          <form className="return-details__form" method="post" onSubmit={handleSubmit}>
            {formState && (
              <div className="return-details__form-error">
                <span className="warn warning"></span>
                {formStateError}
              </div>
            )}
            <div className="input-field">
              {/* <div className="return-details__form-error">
                <span className="warn warning"></span>
                Please enter a value
            </div> */}
              <label>Order Number:(e.g FAE:12345678)</label>

              {props.location?.state ? (
                <>
                  <input placeholder="Enter your order number" value={props.location.state.orderId}
                    onChange={evt => updateOrderValue(evt, evt.target.value, 'order')} type="text" id="orderNumber" name="orderNumber" />
                </>
              ) : (
                <>
                  <input placeholder="Enter your order number"
                    onInput={evt => evt.target.value = evt.target.value.toUpperCase()}
                    onChange={evt => updateOrderValue(evt, evt.target.value, 'order')} type="text" id="orderNumber" name="orderNumber" />
                </>
              )}

            </div>
            <div className="input-field">
              {/* <div className="return-details__form-error">
                <span className="warn warning"></span>
                Please enter a value
            </div> */}
              <label>Email Address:</label>
              <input placeholder="Enter email address" type="email" id="email" name="email" onChange={evt => updateOrderValue(evt, evt.target.value, 'email')} />
            </div>

            <div className="return-details__form-actions">
              <button type="submit" className="return-details__form-actions-cta btn btn--rounded btn-blue-hover">Submit Details</button>
              <div>
                <p className="return-details__form-actions-support">Can't find your order number?</p>
                <p className="return-details__form-actions-contact"><Link to="/contact-us" >Contact Customer Service</Link></p>
              </div>
            </div>
          </form>
        </div>
      )}

      {orderDetails?.OrderLines && (
        <div className="return-details-items container">
          <h1 className="return-details-items__title text-align-left">RETURN ITEM(S): #{orderDetails.OrderNo}</h1>

          <div className="return-details-items__subheading">
            <p className="return-details-items__subheading-description">SELECT ITEMS FOR RETURN</p>
            <p className="return-details-items__subheading-price">PRICE</p>
            <p className="return-details-items__subheading-total">TOTAL</p>
          </div>
          <ul className="return-details-items__content" >
            {orderDetails?.OrderLines && orderDetails?.OrderLines.OrderLine?.map((items: any, index: number) => {
              const iterationQty = (items.Item.ItemID !== "1N5TA11"
                && (items.OrderStatuses.OrderStatus[0].Status === "3700" || items.OrderStatuses.OrderStatus[0].Status === "3700.001")
                && items.ItemDetails.Extn.ExtnIsReturnable === "Y") ? parseInt(items.ReturnableQty) : 1;
              return [...Array(iterationQty)].map((val: any, i: number) => {
                return (
                  <li className="return-details-items__content-product">
                    <div className="return-details-items__content-product-info">
                      {items.Item.ItemID !== "1N5TA11" ? (
                        <>
                          {items.OrderStatuses.OrderStatus[0].Status === "3700" ||
                            items.OrderStatuses.OrderStatus[0].Status === "3700.001" ? (
                            <>
                              {items.ItemDetails.Extn.ExtnIsReturnable === "Y" ? (
                                <>
                                  {/* show when all conditions are met or order return*/}
                                  <StyledCheckbox value="receiveEmail" color="primary" className="returnitem-check"
                                    onChange={(e) => getProductReturnValue(
                                      e.target.checked,
                                      items.Item.ItemID,
                                      items.OrderLineKey,
                                      items.OrderedQty,
                                    )} />
                                  <OrderDetailTable partNumber={items.Item.ItemID}></OrderDetailTable>
                                </>
                              ) : (
                                <>
                                  {/* show when order is a non returnable item */}
                                  <StyledCheckbox disabled="disabled" value="receiveEmail" color="primary" />
                                  <OrderDetailTable partNumber={items.Item.ItemID} diasable="true"></OrderDetailTable>
                                  <div className="return-details__form-error">
                                    <span className="warn warning"></span>
                                    {/* This item can not be returned after 30 days, please contact our <Link to="/contact-us" > Contact Customer Service.</Link> */}
                                    This is a non returnable item, please contact our <Link to="/contact-us" > Contact Customer Service.</Link>
                                  </div>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <StyledCheckbox disabled="disabled" value="receiveEmail" color="primary" />
                              <OrderDetailTable partNumber={items.Item.ItemID} diasable="true"></OrderDetailTable>
                              <div className="return-details__form-error">
                                <span className="warn warning"></span>
                                {items.OrderStatuses.OrderStatus[0].Status > "3700.01" ||
                                  items.OrderStatuses.OrderStatus[0].Status < "9000" ? (
                                  <>
                                    {/* show when order Not delivered */}
                                    {items.OrderStatuses.OrderStatus[0].Status === "1300" || items.OrderStatuses.OrderStatus[0].Status === "3200" ? (<>This item can be returned only after the Order fulfilled, please contact our <Link to="/contact-us" > Contact Customer Service.</Link></>) : (
                                      <>This item is already returned, please contact our <Link to="/contact-us" > Contact Customer Service.</Link></>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {/* show when order return is in process*/}
                                    {items.OrderStatuses.OrderStatus[0].Status === "3700.01" ? (<>This item is already returned, please contact our <Link to="/contact-us" > Contact Customer Service.</Link></>)
                                      : (<>This item can not be returned, please contact our <Link to="/contact-us" > Contact Customer Service.</Link></>)}
                                  </>
                                )}
                                {/* This item can not be returned after 30 days, please contact our <Link to="/contact-us" > Contact Customer Service.</Link> */}
                                {/* This is a non returnable item, please contact our <Link to="/contact-us" >Contact Customer Service.</Link> */}
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {/* show when order is installation */}
                          <StyledCheckbox disabled="disabled" value="receiveEmail" color="primary" />
                          <>
                            <img alt="Installation SKU" src="https://accessories.ford.com/gcs/B2C/ford/make-return.webp" width="150" height="150" />
                            <div className="order__details-text" >
                              <p className="order__product-title">Part No: Installation SKU</p>
                              <p className="order__product-text">Installation SKU</p>
                            </div>
                          </>
                        </>
                      )}

                    </div>
                    <p className="return-details-items__content-product-price">${items?.LinePriceInfo.UnitPrice}</p>
                    <p className="return-details-items__content-product-total">
                      {/* ${(items?.LinePriceInfo.UnitPrice * items.OrderedQty).toFixed(2)} */}
                      ${(items?.LinePriceInfo.UnitPrice * 1).toFixed(2)}
                    </p>
                  </li>
                )
              })
            })}
          </ul>

          <div className="return-details-items__price">
            <div>
              <p>SUBTOTAL</p>
              <p>$<span>0.00</span></p>
            </div>
            <div>
              <p>SHIPPING</p>
              <p>FREE</p>
            </div>
            <div>
              <p>TOTAL</p>
              <p>$<span>0.00</span></p>
            </div>
          </div>
          <div className="return-details-items__info">
            <div className="return-details-items__info-customer">
              <p className="return-details-items__info-customer-address">SHIPPING ADDRESS</p>
              <p className="return-details-items__info-customer-details">
                {orderDetails.PersonInfoShipTo && (<span className="address_label">{orderDetails.PersonInfoShipTo.FirstName} {orderDetails.PersonInfoBillTo.LastName} </span>)}
                {orderDetails.PersonInfoShipTo && (<span className="address_label">{orderDetails.PersonInfoShipTo.AddressLine1} </span>)}
                {orderDetails.PersonInfoShipTo && (<span className="address_label">{orderDetails.PersonInfoShipTo.AddressLine2} </span>)}
                {orderDetails.PersonInfoShipTo && (<span className="address_label">{orderDetails.PersonInfoShipTo.AddressLine3} </span>)}
                {orderDetails.PersonInfoShipTo && (<span className="address_label">{orderDetails.PersonInfoShipTo.AddressLine4} </span>)}
                {orderDetails.PersonInfoShipTo && (<span className="address_label">{orderDetails.PersonInfoShipTo.AddressLine5} </span>)}
                {orderDetails.PersonInfoShipTo && (<span className="address_label">{orderDetails.PersonInfoShipTo.AddressLine6} </span>)}
                {orderDetails.PersonInfoShipTo && (<span className="address_label">{orderDetails.PersonInfoShipTo.AddressLine6} </span>)}
                {orderDetails.PersonInfoShipTo && (<span className="address_label">{orderDetails.PersonInfoShipTo.City}, {orderDetails.PersonInfoBillTo.Country} {orderDetails.PersonInfoBillTo.ZipCode}</span>)}
              </p>
              <p className="return-details-items__info-customer-address">BILLING ADDRESS</p>
              <p className="return-details-items__info-customer-details">
                {orderDetails.PersonInfoBillTo && (<span className="address_label">{orderDetails.PersonInfoBillTo.FirstName} {orderDetails.PersonInfoBillTo.LastName} </span>)}
                {orderDetails.PersonInfoBillTo && (<span className="address_label">{orderDetails.PersonInfoBillTo.AddressLine1} </span>)}
                {orderDetails.PersonInfoBillTo && (<span className="address_label">{orderDetails.PersonInfoBillTo.AddressLine2} </span>)}
                {orderDetails.PersonInfoBillTo && (<span className="address_label">{orderDetails.PersonInfoBillTo.AddressLine3} </span>)}
                {orderDetails.PersonInfoBillTo && (<span className="address_label">{orderDetails.PersonInfoBillTo.AddressLine4} </span>)}
                {orderDetails.PersonInfoBillTo && (<span className="address_label">{orderDetails.PersonInfoBillTo.AddressLine5} </span>)}
                {orderDetails.PersonInfoBillTo && (<span className="address_label">{orderDetails.PersonInfoBillTo.AddressLine6} </span>)}
                {orderDetails.PersonInfoBillTo && (<span className="address_label">{orderDetails.PersonInfoBillTo.AddressLine6} </span>)}
                {orderDetails.PersonInfoBillTo && (<span className="address_label">{orderDetails.PersonInfoBillTo.City}, {orderDetails.PersonInfoBillTo.Country} {orderDetails.PersonInfoBillTo.ZipCode}</span>)}
              </p>
              <p className="return-details-items__info-customer-support">Please confirm if these are the details you used to submit the order, if it is not, please {" "}
                <Link to="/contact-us" >Contact Customer Service.</Link>
              </p>
            </div>
            <form className="return-details-items__info-form" method="post" onSubmit={handleConfirmation}>
              <div className="return-details-items__info-form-actions">
                <p>PLEASE PROVIDE A REASON FOR YOUR RETURN:</p>
                <select disabled={!isMakeReturnEnabled}>
                  {/* <option value="Please select a reason">Please select a reason</option>
                  <option value="Not compatible with vehicle">Not compatible with vehicle</option>
                  <option value="Received Damaged">Received Damaged</option>
                  <option value="No longer wanted/ 30days">No longer wanted/ 30days</option>
                  <option value="Wrong item sent">Wrong item sent</option>
                  <option value="Ordered in error">Ordered in error</option> */}
                  {/** values as per OMS  return response and removed not shipped and lost in transist */}
                  <option value="Please select a reason">Please select a reason</option>
                  <option value="RETURN - 30 DAY RETURN">30 Day return</option>
                  <option value="RETURN - CATALOG ERROR">Catalog error</option>
                  <option value="RETURN - DAMAGED UPON RECEIPT">Damaged upon receipt</option>
                  <option value="RETURN - DEFECTIVE UNIT">Defective unit</option>
                  <option value="RETURN - MISSING PIECES">Missing pieces</option>
                  <option value="RETURN - NOT COMPATIBLE">Not compatible</option>
                  <option value="RETURN - ORDERED IN ERROR">Ordered in error</option>
                  <option value="RETURN - WRONG ITEM SENT">Wrong item sent</option>
                </select>
                {!isMakeReturnEnabled && <p style={{ marginBottom: '20px', fontWeight: 'bold', color: 'red', fontSize: '15px', lineHeight:'25px' }}>This return service is currently unavailable, please make a return by calling 844-589-0060.</p>}
                {!isChecked && <p style={{ marginBottom: '20px', fontWeight: 'bold', color: 'red', fontSize: '15px', lineHeight:'25px' }}>please choose the above Checkbox to continue the return process</p>}
                {isMakeReturnEnabled && <button type="submit" className="btn btn--rounded btn-blue-hover">Submit Return</button> }
              </div>
            </form>
          </div>

          {openModal && (<div className="state-popup">
            <div className="return-modal-content state-popup__inner flex f-dir-column">
              <span className="return-modal-content-close state-popup__close"
                onClick={evt => handleClose(false)}>×</span>
              <div className="return-modal-content-header">
                <h3 className="return-modal-content-header-title title--xs m-b-s">RETURN SUMMARY</h3>
                <p className="return-modal-content-header-subtitle">Please confirm that these are the items you wish to return</p>
              </div>
              <div className="return-modal-content-container">
                <ul>
                  <li>
                    {partNumbers && partNumbers.map((item: any, index: number) => (
                      <OrderDetailTable partNumber={item} showQuantity={true} selectedQuantity={orderLines[index].OrderedQty} />
                    ))}
                  </li>
                </ul>
              </div>

              <div className="return-modal-content-actions">
                <button type="button" className="return-modal-content-actions-close btn--white btn--rounded btn-blue-hover"
                  onClick={evt => handleClose(false)}>Change Selection</button>
                <button type="button" className="btn btn--rounded btn-blue-hover"
                  onClick={evt => handleSubmitReturn(evt)}>Confirm &amp; Submit Return</button>
              </div>
            </div>
          </div>)}


        </div>
      )}

    </>
  )
}

export default MakeReturn;
