import {
  FETCH_PDP_DATA,
  RESPONSE_SUCCESSED,
  RESPONSE_FAILURE,
} from "../action-types/product-discription-page-type";

export interface PostsState {
  loading: boolean;
  pdp_data: any;
  error: string | null;
}
const initialState: PostsState = {
  loading: false,
  pdp_data: {},
  error: "Default",
};

export const PDPReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PDP_DATA:
      return {
        ...state,
        loading: true,
      };
    case RESPONSE_SUCCESSED:
      return {
        ...state,
        loading: false,
        pdp_data: action.response,
        error: "sucess",
      };
    case RESPONSE_FAILURE:
      return {
        ...state,
        loading: false,
        pdp_data: {},
        error: "Error",
      };
    default:
      return {
        ...state,
      };
  }
};
