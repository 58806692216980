/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NavigationBar from "./NavigationBar";
import HomeIcon from "@material-ui/icons/Home";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import {
  StyledHeader,
  StyledTypography,
  StyledBox,
  StyledTooltip
} from "@hcl-commerce-store-sdk/react-component";
import { Link } from "react-router-dom";
import "./HeaderFord.scss";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useSite } from "../../_foundation/hooks/useSite";
import { useSelector } from "react-redux";
import { loginStatusSelector } from "../../redux/selectors/user";
import CustomizedDialogs from './ModalOpn';
import { useLocation } from "react-router";
import eSpotService from "../../_foundation/apis/transaction/eSpot.service";
import { EMPTY_STRING } from "../../constants/common";
import HTMLReactParser from "html-react-parser";
//Common libraries
import * as ROUTES from "../../constants/routes";
/**
 * Header component
 * displays Header, Mini Cart and Mega Menu
 *
 */
const HeaderFord: React.FC = () => {
  const { t } = useTranslation();
  const { mySite } = useSite();
  const theme = useTheme();
  const storeID: string = mySite ? mySite.storeID : EMPTY_STRING;
  const catalogID: string = mySite ? mySite.catalogID : EMPTY_STRING;
  const loginStat = useSelector(loginStatusSelector);
  const [openModal1, setOpenModel] = useState(false);
  const isUserLoggedIn = useSelector(loginStatusSelector);
  let url = window.location.href;
  let protocol = window.location.protocol;
  let port = !window.location.port.trim() ? '' : ':' + window.location.port;
  let currentURL = url.indexOf("&krypto") > 0 ? url.substring(0, url.indexOf("&krypto")) : url;
  const FMA_LOGIN_LINK = `${mySite.storeCfg.userData["FMA_SIGN_IN"]}?redirectURL=${protocol}//${window.location.hostname}${port}/LoginRedirect?storeId=${mySite.storeID}&catalogId=${mySite.catalogID}&langId=-1&currentURL=${encodeURIComponent(currentURL)}`;
  const isSmallDesktop = useMediaQuery(theme.breakpoints.down(1024));
  const [Header,setHeader]=useState("");

  function openModel(e) {
    e.preventDefault();
    setOpenModel(true);
  }
  const initMarketingContentHeaderGuest = () => {
    const parameters: any = {
      storeId: storeID,
      name: "US_Header_Espot",
      catalogId: catalogID,
      widget: "e-marketing-spot-widget",
      query: {
        DM_ReturnCatalogGroupId: true,
        DM_FilterResults: false,
      }
    };
    eSpotService
      .findByName(parameters)
      .then((res) => {
        const eSpotRoot = res.data.MarketingSpotData[0];
        setHeader(eSpotRoot.baseMarketingSpotActivityData[0].marketingContentDescription[0].marketingText)
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  useEffect(() => {
    initMarketingContentHeaderGuest();
  }, []);
  const HeaderLinks = () => {
    const HeaderLinks = [
      // {
      //   linkText: t("Header.HeaderLinks.FindADealer"),
      //   linkURL: "FindADealer",
      // },
      {
        linkText: t("Header.HeaderLinks.Wishlist"),
        linkURL: "/account/wish-list",
      },
      {
        linkText: t("Header.HeaderLinks.Contact"),
        linkURL: "/contact-us",
      },
      {
        linkText: t("Header.HeaderLinks.ReturnPolicy"),
        linkURL: "/return",
      },
      {
        linkText: t("Header.HeaderLinks.FAQ"),
        linkURL: "/Faq",
      },
    ];

    const location = useLocation();
    return (
      <div>
        <StyledTypography variant="caption" className="header-top-eyebrow">
          {HeaderLinks.map((v: any) => (
            <span style={{ marginLeft: "52px" }} key={v.linkURL}>
              <StyledTooltip className="header-top-button">
                {
                  v.linkURL == "/account/wish-list" ?
                    <>
                      {isUserLoggedIn ?
                        <>
                          <Link key={v.linkURL} to={v.linkURL} >{v.linkText}</Link>
                        </>
                        :
                        <a href={FMA_LOGIN_LINK}>{v.linkText}</a>
                      }
                    </>
                    :
                    <Link key={v.linkURL} to={v.linkURL} >{v.linkText}</Link>
                }
              </StyledTooltip>
            </span>
          ))}
        </StyledTypography>
      </div>
    );
  };
  const isLoggedIn = loginStat;
  const HeaderContent = 
  HTMLReactParser(Header, {replace: (domNode) => {
    if (domNode['attribs'] && domNode['attribs'].id === "Arizona_popup") {
      return (
        <Link to="#" className="header-top-button1" onClick={openModel}>
         {domNode?.['children'][0]?.data} <ChevronRightIcon />
      </Link>
      );
    }
    else if (domNode['attribs'] && domNode['attribs'].id === "Wishlist") {
      if(isLoggedIn){
        return(
        <Link id="Wishlist" to="/account/wish-list">{domNode?.['children'][0]?.data}</Link>
        );
      }
      return (
        <a id="Wishlist" href={FMA_LOGIN_LINK}>{domNode?.['children'][0]?.data}</a>
   );
   }
   return domNode;
    },
  });
  return (
    <>
      {openModal1 ? <CustomizedDialogs setOpenModel={setOpenModel} /> : null}

      <StyledHeader className="site-header" style={!isSmallDesktop ? {height:'127px'} : {}}>
        <StyledBox className="site-header--eyebrow" style={!isSmallDesktop ? {height:'35px'} : {}}>
          {/* hiddhen on phone */}
          {/* {isSmallDesktop && (
            <StyledBox
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="header-FreePickUp-Bar">
              <button
                type="button"
                className="button header-FreePickUp-Link flex f-vertical-center">
                <HomeIcon />
                Free Pick Up And Returns At The Dealer
                <ChevronRightIcon />
              </button>
            </StyledBox>
          )} */}

          {isSmallDesktop ? (
            <div>
              {/* <Link
                to="#"
                className="header-top-button"
                style={{ background: "#f5f5f5", color: "#102b4e" }} onClick={openModel}>
                Are you in Arizona, Oklahoma or Vermont? <ChevronRightIcon />
              </Link> */}
            </div>
          ) : (
            // <div className="header-top-container container">
            //   <div className="header-top-eyebrow">
            //     <Link to="#" className="header-top-button1" onClick={openModel}>
            //       Are you in Arizona, Oklahoma or Vermont? <ChevronRightIcon />
            //     </Link>
            //   </div>

            //   <HeaderLinks />
            // </div>
            HeaderContent
          )}
        </StyledBox>
        <NavigationBar />
      </StyledHeader>
    </>
  );
};

export { HeaderFord };
