import { call, put } from "redux-saga/effects";
//Foundation libraries
import mockServerService from "../../../_foundation/apis/mock/mockserver.service";
import { getSite } from "../../../_foundation/hooks/useSite";
//Redux
import * as ACTIONS from "../../action-types/shop-accessories";

export function* getFeaturedRideWorker(action: any) {
  try {
    const siteInfo = getSite();
    const payload = action.payload;
    const response = yield call(
      mockServerService.getMock,
      {},
      {},
      `${siteInfo?.transactionContext}/store/${siteInfo?.storeID}/shop-accessories`
    );
   
    yield put({
      payload: payload,
      type: ACTIONS.RESPONSE_SUCCESSED_SHOP,
      response: response.data,
    });
  } catch (error) {
    yield put({ type: ACTIONS.RESPONSE_FAILURE_SHOP, response:error });
  }
}