import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import jsonData from "../staticPagesMetaData.json";
import { useSite } from "../../../../_foundation/hooks/useSite";
import { Helmet } from "react-helmet";

const content =jsonData.contents[30]?.TakingCareOfYourVehicle;

const handleShareClick = (event: React.MouseEvent<HTMLSpanElement>) => {
  const url = event.currentTarget.dataset.copyToClipboard!;
  navigator.clipboard
    .writeText(url)
    .then(() => {
      const confirmation = window.prompt(
        "Copy to clipboard: Ctrl/Cmd+C, Enter",
        url
      );
      if (confirmation) {
        navigator.clipboard.writeText(url);
      }
    })
    .catch(() => {
      window.alert(`Failed to copy link to clipboard: ${url}`);
    });
};

const breadcrumbList = {
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  itemListElement: [
    {
      "@type": "ListItem",
      position: 1,
      name: "Home",
      item: "https://accessories.ford.com/",
    },
    {
      "@type": "ListItem",
      position: 2,
      name: "News",
      item: "https://accessories.ford.com/blogs/news",
    },
    {
      "@type": "ListItem",
      position: 3,
      name: "Taking Care of Your Vehicle All Year Long",
      item: "https://accessories.ford.com/blogs/news/taking-care-of-your-vehicle-all-year-long",
    },
  ],
};

const TakingCareOfYourVehicle = () => {

  const {storeDisplayName } = useSite();
  let port = !window.location.port.trim() ? '' : ':' + window.location.port;

  return (
    <div>
      <Helmet>
        <title>{content?.title}</title>
        <meta name="description" content={content?.metaDescription}></meta>
        <meta name="keywords" content={content?.metaKeyword}/>
        <meta property="og:site_name" content={storeDisplayName}/>
        <meta property="og:url" content={(window.location.protocol +'//'+window.location.hostname+':' + port + window.location.pathname)}/>
        <meta property="og:title" content={content?.title}/>
        <meta property="og:type" content="article"/>  
        <meta property="og:description" content={content?.metaDescription}/>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={content?.title}/>  
        <meta name="twitter:description" content={content?.metaDescription} />
      </Helmet>
      <div id="shopify-section-template-article" className="shopify-section">
        <section 
          title="Blog Hero"
          className="article-hero "
          style={{
            backgroundImage:
              "url(https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/TakingCareOfYourVehicle/allyear_1500x.jpeg)",
          }}>
          <div className="article-hero__container container">
            <h1 className="article-hero__heading title--md">
              Taking Care of Your Vehicle All Year Long
            </h1>

            <div className="article-hero__publish-date">February 01, 2023</div>
          </div>
        </section>

        <div className="article__main">
          <nav className="breadcrumbs" role="navigation">
            <ul className="flex">
              <li>
              <a href="/">
              <span>Home</span>
              </a>
                <span className="breadcrumbs-separator">&gt;</span>
              </li>
              <li>
                <Link to="/blogs/news">
                  <span>News</span>
                </Link>
              </li>
              <span className="breadcrumbs-separator">&gt;</span>
              <li>
                <span>Taking Care of Your Vehicle...</span>
              </li>
            </ul>
          </nav>{" "}
          <script type="application/ld+json">
            {JSON.stringify(breadcrumbList)}
          </script>
          <div className="article-actions">
            <Link className="article-actions__return" to="/blogs/news">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16">
                <g>
                  <path
                    fill="#102b4e"
                    d="M8,0 C12.418,0 16,3.582 16,8 C16,12.418 12.418,16 8,16 C3.582,16 0,12.418 0,8 C0,3.582 3.582,0 8,0 Z M6,3 C5.724,3 5.474,3.112 5.293,3.293 C5.112,3.474 5,3.724 5,4 C5,4.276 5.112,4.526 5.293,4.707 L9.409,8 L5.293,11.293 C5.112,11.474 5,11.724 5,12 C5,12.276 5.112,12.526 5.293,12.707 C5.474,12.888 5.724,13 6,13 C6.276,13 6.526,12.888 6.707,12.707 L11.707,8.707 C11.888,8.526 12,8.276 12,8 C12,7.724 11.888,7.474 11.707,7.293 L6.707,3.293 C6.526,3.112 6.276,3 6,3 Z"></path>
                </g>
              </svg>
              Back to articles
            </Link>

            <div
              className="social-icons "
              itemScope
              itemType="https://schema.org/Organization">
              <link itemProp="url" href="https://accessories.ford.com" />

              <h3 className="social-icons__share-title">Share</h3>

              <div className="social-icons__list-item">
                <a
                  href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/taking-care-of-your-vehicle-all-year-long&amp;text=Taking+Care+of+Your+Vehicle+All+Year+Long"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="16"
                    viewBox="0 0 20 16">
                    <g>
                      <g>
                        <path
                          fill="#102b4e"
                          d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Twitter</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/taking-care-of-your-vehicle-all-year-long"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M.29 5.24h3.2v10.764H.29z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">LinkedIn</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/taking-care-of-your-vehicle-all-year-long"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="16"
                    viewBox="0 0 8 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Facebook</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <span
                  className="share"
                  onClick={handleShareClick}
                  data-copy-to-clipboard="https://accessories.ford.com/blogs/news/taking-care-of-your-vehicle-all-year-long">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Share URL</span>
                </span>
              </div>
            </div>
          </div>
          <article className="article-content rte">
            <h1>
              <b></b>
            </h1>
            <p>
              <span style={{fontWeight: 400}}>
                From snow storms to rainy days to extreme heat, vehicles endure
                a lot throughout the year. Because of changing temperatures and
                seasonal storms, your car requires thorough attention during
                every season. Although car maintenance can seem like a hassle,
                proper care of your vehicle provides efficiency and offers peace
                of mind on your travels. To make your tune-ups a little bit
                easier, follow our seasonal car maintenance checklist to ensure
                your car thrives all year!
              </span>
            </p>
            <p>
              <span style={{fontWeight: 400}}>
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/TakingCareOfYourVehicle/23_FRD_F15_51174_1.jpeg"
                  alt="23 FRD F-15" width={'4472'} height={'100%'}
                />
              </span>
            </p>
            <h2>
              <span style={{fontWeight: 400}}>Winter Vehicle Tune-Ups</span>
            </h2>
            <br />
            <p>
              <span style={{fontWeight: 400}}>
                Winter is not the time to give car maintenance the cold shoulder
                since chilly weather calls for consistent tune-ups and safety
                checks. We’ve outlined a few priorities that should be followed
                up on to prepare for the winter months.
              </span>
            </p>
            <p>&nbsp;</p>
            <h3>
              <span style={{fontWeight: 400}}>Heating</span>
            </h3>
            <p>
              <span style={{fontWeight: 400}}>
                To start, how are your heaters holding up? Don’t be stuck in the
                dead of winter with a faulty heating system. Make sure to check
                in on your engine’s coolant levels, heater fans, HVAC controls,
                and heater core.
              </span>
            </p>
            <p>&nbsp;</p>
            <h3>
              <span style={{fontWeight: 400}}>Headlights</span>
            </h3>
            <p>
              <span style={{fontWeight: 400}}>
                Next up, it is important to check that your headlights are
                working to the best of their ability. Snow storms are difficult
                to navigate regardless, so ensure your vehicle is equipped with
                powerful headlights for your winter journeys. Are you already
                thinking about your next winter trek? Illuminate your vehicle
                with Ford vehicle{" "}
              </span>
              <Link to="/search?searchTerm=fog+lighrt">
                <span style={{fontWeight: 400}}>fog light kits</span>
              </Link>
              <span style={{fontWeight: 400}}>
                {" "}
                to increase visibility during storms and hazy nights.&nbsp;
              </span>
            </p>
            <p>&nbsp;</p>
            <h3>
              <span style={{fontWeight: 400}}>
                Snow &amp; Ice Maintenance
              </span>
            </h3>
            <p>
              <span style={{fontWeight: 400}}>
                Winter can get messy, but your car doesn’t have to be! Keep your
                Ford vehicle’s carpet safe from dirt and snow with{" "}
              </span>
              <Link to="/search?searchTerm=all+terrain+floor+mats">
                <span style={{fontWeight: 400}}>
                  all-terrain floor mats
                </span>
              </Link>
              <span style={{fontWeight: 400}}>
                {" "}
                fitted with deep grooves and raised ridges to stop all the
                winter muck!
              </span>
            </p>
            <p>
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/TakingCareOfYourVehicle/22_FRD_ECO_000043_TNM.jpeg"
                alt="22 FRD ECO" width={'4472'} height={'100%'}
              />
            </p>
            <h2>
              <span style={{fontWeight: 400}}>
                Prepare For a Rainy Day: Spring Car Maintenance&nbsp;
              </span>
            </h2>
            <br />
            <p>
              <span style={{fontWeight: 400}}>
                When it’s time for spring cleaning, it’s time for a spring
                tune-up! Spring is a great time to prepare your car for rising
                temperatures. Before summer hits, consider visiting your Ford
                dealer for a spring tune-up to check your oil, spark plugs,
                battery, and engine coolant. After the wear of winter, who knows
                what your car may be lacking? Trust us, you’re better safe than
                sorry when it comes to your vehicle! Spring into these tips to
                ensure your vehicle is cared for as the weather warms up and the
                rain falls harder.
              </span>
              &nbsp;
            </p>
            <p>&nbsp;</p>
            <h3>
              <span style={{fontWeight: 400}}>Monitoring Your Wipers</span>
            </h3>
            <p>
              <span style={{fontWeight: 400}}>
                Faulty wipers can damage or scratch your windshield, so they
                should be replaced every six to twelve months. Wipers also
                commonly break during winter while enduring the elements, so be
                sure to inspect your blades for cracks, inconsistencies, or
                warping in preparation for spring.&nbsp;
              </span>
            </p>
            <p>&nbsp;</p>
            <h3>
              <span style={{fontWeight: 400}}>Staying Dry</span>
            </h3>
            <p>
              <span style={{fontWeight: 400}}>
                As we all know, when it rains it pours. Remember to check your
                car’s weather stripping to prevent leaks inside your vehicle. If
                you’re driving a truck or open vehicle, be sure to also keep
                your truck bed and valuables dry with a{" "}
              </span>
              <Link to="/search?searchTerm=tonneau+cover">
                <span style={{fontWeight: 400}}>durable tonneau cover</span>
              </Link>
              <span style={{fontWeight: 400}}>
                . And what about the accumulation of mud on your vehicle? Take
                on mud, debris, snow, and rain with our wide variety of{" "}
              </span>
              <Link to="/search?searchTerm=splash+car">
                <span style={{fontWeight: 400}}>
                  front and rear splash guards
                </span>
              </Link>
              <span style={{fontWeight: 400}}>
                . Dirty messes can take a raincheck this spring!&nbsp;
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/TakingCareOfYourVehicle/23_FordMaverick_Hybrid_XLT_02.jpeg"
                alt="Ford Maverick Hybrid XLT" width={'4472'} height={'100%'}
              />
            </p>
            <h2>
              <span style={{fontWeight: 400}}>
                Steering into Summer Tune-Ups
              </span>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                As the heat intensifies, there are a few actions you can take to
                ensure your car conquers the summer slump! Make sure your
                vehicle powers through all of your summer road trips by taking a
                look at the following parts.
              </span>
            </p>
            <p>&nbsp;</p>
            <h3>
              <span style={{fontWeight: 400}}>Check A/C and Cooling</span>
            </h3>
            <p>
              <span style={{fontWeight: 400}}>
                Beat the heat this summer with fully operating air conditioning
                vents. If you notice any strange noises, weak air, or if your
                vents are only pumping room-temperature air, stop in for a
                tune-up. Also, be sure to check your refrigerant levels for
                efficient cooling.
              </span>
            </p>
            <p>&nbsp;</p>
            <h3>
              <span style={{fontWeight: 400}}>Monitor Your Battery</span>
            </h3>
            <p>
              <span style={{fontWeight: 400}}>
                Heat is not your car battery’s best friend. With consistently
                high temperatures, batteries will become less efficient and
                weaken over time. To monitor your battery, make sure to check
                your battery’s charge with the help of a voltmeter.
              </span>
            </p>
            <p>
              <span style={{fontWeight: 400}}>
                Don’t let a dead battery catch you off guard! Be summer ready
                with one of our{" "}
              </span>
              <Link to="/search?searchTerm=jump+start">
                <span style={{fontWeight: 400}}>battery jump starters</span>
              </Link>
              <span style={{fontWeight: 400}}>
                . Monitoring your battery may slip your mind, but a jump starter
                provides peace of mind to keep you charged for the road ahead.
              </span>
              &nbsp;
            </p>
            <p>&nbsp;</p>
            <h3>
              <span style={{fontWeight: 400}}>Protect Your Interior</span>
            </h3>
            <p>
              <span style={{fontWeight: 400}}>
                Powerful UV rays are extremely damaging to your car’s interior
                and can lead to the deterioration of your upholstery. To protect
                your car from the sun, invest in a windshield{" "}
              </span>
              <Link to="/search?searchTerm=sunscreen">
                <span style={{fontWeight: 400}}>sunscreen</span>
              </Link>
              <span style={{fontWeight: 400}}>
                {" "}
                for parking during the summer. Designed with a reflective outer
                layer to keep your vehicle cool, you can say goodbye to your
                boiling steering wheel!
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/TakingCareOfYourVehicle/480x320_RESIZED_20_FRD_MST_52917_AO3A9762custom_resized_03c74523-38e3-42d4-ad55-ef15bbe464d0.jpeg"
                alt="Fall Car Maintenance" width={'480'} height={'100%'}
              />
            </p>
            <p>&nbsp;</p>
            <h2>
              <span style={{fontWeight: 400}}>
                Turn Over a New Leaf: Fall Car Maintenance&nbsp;
              </span>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                Fall is the perfect time of year to prepare your car for winter
                and the upcoming cold weather. Get ready for the sudden drop in
                temperatures by checking these components of your vehicle.
              </span>
            </p>
            <p>&nbsp;</p>
            <h3>
              <span style={{fontWeight: 400}}>Engine Cooling System</span>
              <b>&nbsp;</b>
            </h3>
            <p>
              <span style={{fontWeight: 400}}>
                Prevent your car from freezing in the cold by monitoring your
                coolant levels. Remember, your coolant should be full,
                light-colored, and younger than two years. If not, it should be
                flushed and replaced. For more assistance in changing your
                coolant, visit your local Ford dealer or service center.
              </span>
            </p>
            <p>&nbsp;</p>
            <h3>
              <span style={{fontWeight: 400}}>Oil</span>
            </h3>
            <p>
              <span style={{fontWeight: 400}}>
                As the temperature changes, so should your oil. For cold engines
                to operate properly,{" "}
              </span>
              <a href="https://www.motorcraft.com/us/en_us/home/our-products/chemicals-and-lubricants/motor-oil/gasoline-engine-motor-oil.html" target="_blank">
                <span style={{fontWeight: 400}}>thinner oil</span>
              </a>
              <span style={{fontWeight: 400}}>
                {" "}
                must be used during the fall and winter months. Without the
                correct type of oil, significant wear will be placed on your
                vehicle and engine.&nbsp;&nbsp;
              </span>
            </p>
            <p>&nbsp;</p>
            <h3>
              <span style={{fontWeight: 400}}>Brakes and Tires</span>
            </h3>
            <p>
              <span style={{fontWeight: 400}}>
                Snow and ice reduce the friction between your tires and the
                road, which can make braking and stopping more difficult. To put
                it simply, driving on bald tires and faulty brakes is a recipe
                for disaster on winter drives. Before the seasons change from
                fall to winter, be sure to replace your tires and upgrade your
                brakes to prepare for the cold! Needing a brake upgrade ASAP?
                Check out our high-quality{" "}
              </span>
              <Link to="/search?searchTerm=brake+upgrade">
                <span style={{fontWeight: 400}}>brake upgrade kits</span>
              </Link>
              <span style={{fontWeight: 400}}>
                {" "}
                to increase your vehicle’s safety today.
              </span>
            </p>
            <p>&nbsp;</p>
            <p>
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/TakingCareOfYourVehicle/21_FRD_ECO_100023.jpeg"
                alt="21 FRD ECO"  width={'4000'} height={'100%'}
              />
            </p>
            <h2>
              <span style={{fontWeight: 400}}>
                Take Pride in Your Vehicle
              </span>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                Rain or shine, your car needs attention during every season.
                Vehicle maintenance can sound like a huge job, but with
                consistent tune-ups year-round, you can help avoid unlucky
                surprises. Remember to keep this checklist in mind while
                navigating your next tune-up and seasonal maintenance!&nbsp;
              </span>
            </p>
            <br />
            <p>
              <span style={{fontWeight: 400}}>
                Ready to prep your car for the next season? Explore our
                selection of preventative and protective accessory upgrades for
                your Ford vehicle.
              </span>
            </p>
          </article>
          <div className="article-share">
            <div
              className="social-icons "
              itemScope
              itemType="https://schema.org/Organization">
              <link itemProp="url" href="https://accessories.ford.com" />

              <h3 className="social-icons__share-title">Share Article</h3>

              <div className="social-icons__list-item">
                <a
                  href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/taking-care-of-your-vehicle-all-year-long&amp;text=Taking+Care+of+Your+Vehicle+All+Year+Long"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="16"
                    viewBox="0 0 20 16">
                    <g>
                      <g>
                        <path
                          fill="#102b4e"
                          d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Twitter</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/taking-care-of-your-vehicle-all-year-long"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M.29 5.24h3.2v10.764H.29z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">LinkedIn</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/taking-care-of-your-vehicle-all-year-long"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="16"
                    viewBox="0 0 8 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Facebook</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <span
                  className="share"
                  onClick={handleShareClick}
                  data-copy-to-clipboard="https://accessories.ford.com/blogs/news/taking-care-of-your-vehicle-all-year-long">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Share URL</span>
                </span>
              </div>
            </div>
          </div>
          <div className="article-pagination article-pagination--desktop">
            <Link
              className="article-pagination__item article-pagination__item--previous"
              to="/blogs/news/5-ways-to-weatherproof-your-vehicle" onClick={() => window.scrollTo(0, 0)}>
              <div className="article-pagination__arrow">
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/chevron-thin-white-right_60x.jpeg"
                  alt="Chevron White" width={'32'} height={'62'}
                />
              </div>
              <div className="article-pagination__data">
                <div className="article-pagination__label">
                  Previous Article
                </div>
                <div className="article-pagination__title">
                  5 Ways To Weatherproof Your Vehicle
                </div>
              </div>
            </Link>

            <Link
              className="article-pagination__item article-pagination__item--next"
              to="/blogs/news/8-must-have-ford-f-150-accessories" onClick={() => window.scrollTo(0, 0)}>
              <div className="article-pagination__data">
                <div className="article-pagination__label">Next Article</div>
                <div className="article-pagination__title">
                  8 Must-Have Ford F-150 Accessories
                </div>
              </div>
              <div className="article-pagination__arrow">
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/chevron-thin-white-right_60x.jpeg"
                  alt="Chevron White" width={'32'} height={'62'}
                />
              </div>
            </Link>
          </div>
          <div className="article-pagination article-pagination--mobile">
            <Link
              className="article-pagination__item article-pagination__item--previous"
              to="/blogs/news/5-ways-to-weatherproof-your-vehicle" onClick={() => window.scrollTo(0, 0)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="15"
                viewBox="0 0 8 15">
                <g>
                  <g transform="rotate(-270 4 7.5)">
                    <path
                      fill="#fff"
                      d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z"></path>
                  </g>
                </g>
              </svg>
              Prev Article
            </Link>

            <Link
              className="article-pagination__item article-pagination__item--next"
              to="/blogs/news/8-must-have-ford-f-150-accessories" onClick={() => window.scrollTo(0, 0)}>
              Next Article
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="15"
                viewBox="0 0 8 15">
                <g>
                  <g transform="rotate(-270 4 7.5)">
                    <path
                      fill="#fff"
                      d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z"></path>
                  </g>
                </g>
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TakingCareOfYourVehicle;
