import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import jsonData from "../staticPagesMetaData.json";
import { useSite } from "../../../../_foundation/hooks/useSite";
import { Helmet } from "react-helmet";

const content =jsonData.contents[7]?.BenifitsOfSplashGuards;

const handleShareClick = (event: React.MouseEvent<HTMLSpanElement>) => {
  const url = event.currentTarget.dataset.copyToClipboard!;
  navigator.clipboard
    .writeText(url)
    .then(() => {
      const confirmation = window.prompt(
        "Copy to clipboard: Ctrl/Cmd+C, Enter",
        url
      );
      if (confirmation) {
        navigator.clipboard.writeText(url);
      }
    })
    .catch(() => {
      window.alert(`Failed to copy link to clipboard: ${url}`);
    });
};

const breadcrumbList = {
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  itemListElement: [
    {
      "@type": "ListItem",
      position: 1,
      name: "Home",
      item: "https://accessories.ford.com/",
    },
    {
      "@type": "ListItem",
      position: 2,
      name: "News",
      item: "https://accessories.ford.com/blogs/news",
    },
    {
      "@type": "ListItem",
      position: 3,
      name: "Benefits of Splash Guards for Your Ford Truck or SUV",
      item: "https://accessories.ford.com/blogs/news/benefits-of-splash-guards-for-your-ford-truck-or-suv",
    },
  ],
};

const BenefitsOfSplashGuards = () => {
  const { mySite: site, storeDisplayName } = useSite();
  let port = !window.location.port.trim() ? '' : ':' + window.location.port;
  return (
    <div>
          <Helmet>
            <title>{content?.title}</title>
            <meta name="description" content={content?.metaDescription}></meta>
            <meta name="keywords" content={content?.metaKeyword}/>
            <meta property="og:site_name" content={storeDisplayName} />
            <meta property="og:url" content={(window.location.protocol +'//'+window.location.hostname+':' + port + window.location.pathname)}/>
            <meta property="og:title" content={content?.title} />
            <meta property="og:type" content="article" />
            <meta property="og:description" content={content?.metaDescription}/>
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={content?.title} />
            <meta name="twitter:description" content={content?.metaDescription}/>
          </Helmet>
      <div id="shopify-section-template-article" className="shopify-section">
        <section 
          title="Blog Hero"
          className="article-hero "
          style={{
            backgroundImage:
              "url(https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BenefitsOfSplashGuards/SplashGuards_RS_1500x.jpeg)",
          }}>
          <div className="article-hero__container container">
            <h1 className="article-hero__heading title--md">
              Benefits of Splash Guards for Your Ford Truck or SUV
            </h1>

            <div className="article-hero__publish-date">March 14, 2023</div>
          </div>
        </section>

        <div className="article__main">
          <nav className="breadcrumbs" role="navigation">
            <ul className="flex">
              <li>
              <a href="/">
              <span>Home</span>
              </a>
                <span className="breadcrumbs-separator">&gt;</span>
              </li>
              <li>
                <Link to="/blogs/news">
                  <span>News</span>
                </Link>
              </li>
              <span className="breadcrumbs-separator">&gt;</span>
              <li>
                <span>Benefits of Splash Guards f...</span>
              </li>
            </ul>
          </nav>
          <script type="application/ld+json">
            {JSON.stringify(breadcrumbList)}
          </script>

          <div className="article-actions">
            <Link className="article-actions__return" to="/blogs/news">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16">
                <g>
                  <path
                    fill="#102b4e"
                    d="M8,0 C12.418,0 16,3.582 16,8 C16,12.418 12.418,16 8,16 C3.582,16 0,12.418 0,8 C0,3.582 3.582,0 8,0 Z M6,3 C5.724,3 5.474,3.112 5.293,3.293 C5.112,3.474 5,3.724 5,4 C5,4.276 5.112,4.526 5.293,4.707 L9.409,8 L5.293,11.293 C5.112,11.474 5,11.724 5,12 C5,12.276 5.112,12.526 5.293,12.707 C5.474,12.888 5.724,13 6,13 C6.276,13 6.526,12.888 6.707,12.707 L11.707,8.707 C11.888,8.526 12,8.276 12,8 C12,7.724 11.888,7.474 11.707,7.293 L6.707,3.293 C6.526,3.112 6.276,3 6,3 Z"></path>
                </g>
              </svg>
              Back to articles
            </Link>

            <div
              className="social-icons "
              itemScope
              itemType="https://schema.org/Organization">
              <link itemProp="url" href="https://accessories.ford.com" />

              <h3 className="social-icons__share-title">Share</h3>

              <div className="social-icons__list-item">
                <a
                  href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/benefits-of-splash-guards-for-your-ford-truck-or-suv&amp;text=Benefits+of+Splash+Guards+for+Your+Ford+Truck+or+SUV"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="16"
                    viewBox="0 0 20 16">
                    <g>
                      <g>
                        <path
                          fill="#102b4e"
                          d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Twitter</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/benefits-of-splash-guards-for-your-ford-truck-or-suv"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M.29 5.24h3.2v10.764H.29z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">LinkedIn</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/benefits-of-splash-guards-for-your-ford-truck-or-suv"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="16"
                    viewBox="0 0 8 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Facebook</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <span
                  className="share"
                  onClick={handleShareClick}
                  data-copy-to-clipboard="https://accessories.ford.com/blogs/news/benefits-of-splash-guards-for-your-ford-truck-or-suv">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Share URL</span>
                </span>
              </div>
            </div>
          </div>

          <article className="article-content rte">
            <p>
              <span style={{ fontWeight: 400 }}>
                Are you planning on taking your Ford truck or SUV on an off-road
                or outside adventure soon? If so, it is important to make sure
                your vehicle is prepared with all of the necessary accessories,
                including splash guards. Protect your vehicle and the area
                around it by using splash guards when driving through rough,
                wet, or messy terrain.&nbsp;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BenefitsOfSplashGuards/G9194918_F150_GatorbackRearPair_vhl3z-16a550-l_mj.jpeg"
                  alt="F-150 Gatorback Rear Pair" width={'1500'} height={'1125'}
                />
              </span>
            </p>
            <br />
            <h2>
              <span style={{ fontWeight: 400 }}>What Are Splash Guards?</span>
            </h2>
            <p>
              <Link to="/splash-guards">
                <span style={{ fontWeight: 400 }}>Splash guards</span>
              </Link>
              <span style={{ fontWeight: 400 }}>
                {" "}
                for cars prevent water, rocks, mud, and debris on the road from
                being kicked up and landing on your vehicle. Since trucks and
                SUVs sit higher than most cars, there is a chance for objects
                and materials on the road to be kicked up into the air.
                Primarily, there are two different styles of splash guards:
                stiff molded and flexible flat. Splash guards are mounted on the
                wheel wells of a vehicle, with one behind each tire. While they
                might seem straightforward, splash guards can help prevent
                damage to not only your vehicle but the surrounding areas as
                well. Additionally, splash guards can protect the paint on your
                vehicle from rocks and other road debris, while enhancing its
                appearance. When shopping for splash guards, it’s important to
                obtain the correct size for your vehicle’s model. Our available
                accessories, including splash guards, are engineered to
                perfectly fit Ford vehicles, which provides peace of mind for
                our drivers.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BenefitsOfSplashGuards/Bronco_SplashGuards.jpeg"
                  alt="Bronco Splash Guards" width={'1936'} height={'1288'}
                />
              </span>
            </p>
            <br />
            <h2>
              <span style={{ fontWeight: 400 }}>
                Mud Flaps vs. Splash Guards
              </span>
            </h2>
            <p>
              <span style={{ fontWeight: 400 }}>
                While the terms mud flaps, splash guards, and mudguards can
                often be confusing to differentiate when looking for vehicle
                protection, they can be used interchangeably. People refer to
                these rugged accessories as both mud flaps and splash guards, as
                they both aid in the prevention of road debris from flying up
                and damaging your vehicle. The same simple process is used for
                installing mud flaps and splash guards, so don’t stress about
                what you call it. Here at Ford, we refer to them as splash
                guards. Keep this in mind as you search the site for the right
                splash guards for your vehicle!
              </span>
            </p>
            <p>&nbsp;</p>
            <h2>
              <span style={{ fontWeight: 400 }}>
                5 Benefits of Splash Guards
              </span>
            </h2>
            <ol>
              <li style={{ fontWeight: 400 }}>
                <b>They can help keep your vehicle cleaner. </b>
                <span style={{ fontWeight: 400 }}>
                  Splash guards protect your vehicle from mud and dirt,
                  resulting in less time spent at the car wash.&nbsp;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <b>They protect your vehicle. </b>
                <span style={{ fontWeight: 400 }}>
                  Splash guards prevent rocks and debris from scraping the paint
                  or metal on your vehicle while protecting vulnerable
                  areas.&nbsp;&nbsp;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <b>They lessen the vehicle’s splash radius. </b>
                <span style={{ fontWeight: 400 }}>
                  Reduce the impact of puddles you may go through by deflecting
                  the splash as you drive.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <b>They’re inexpensive. </b>
                <span style={{ fontWeight: 400 }}>
                  Splash guards are an inexpensive way to protect your vehicle
                  and are extremely easy to install.&nbsp;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <b>
                  They are attractive and look good on trucks, SUVs, and some
                  cars.{" "}
                </b>
                <span style={{ fontWeight: 400 }}>
                  Enhance your vehicle’s appearance with splash guards that
                  provide a rugged, customized look.&nbsp;
                </span>
              </li>
            </ol>
            <p>
              <span style={{ fontWeight: 400 }}>
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BenefitsOfSplashGuards/G9195018_F150_GatorbackRearPair_vhl3z-16a550-m_mj.jpeg"
                  alt="F-150 Gatorback Rear Pair"  width={'1500'} height={'1125'}
                />
              </span>
            </p>
            <br />
            <h2>
              <span style={{ fontWeight: 400 }}>
                Do I Need Splash Guards?&nbsp;
              </span>
            </h2>
            <p>
              <span style={{ fontWeight: 400 }}>
                Splash guards are an excellent consideration for those looking
                to maintain their vehicle’s condition, wash it less, and add to
                its rugged look. We have a variety of splash guards for
                different models including, but not limited to, the Ford{" "}
              </span>
              <Link to="/bronco-2021-gatorback-molded-front-splash-guard-pair-1?nosto=categorypage-nosto-2">
                <span style={{ fontWeight: 400 }}>Bronco</span>
              </Link>
              <span style={{ fontWeight: 400 }}>®, </span>
              <Link to="/f-150-2021-carbon-black-molded-front-splash-guard-pair?nosto=categorypage-nosto-2">
                <span style={{ fontWeight: 400 }}>F-150</span>
              </Link>
              <span style={{ fontWeight: 400 }}>®, </span>
              <Link to="/splash-guards-molded-front-pair-with-ford-oval-logo-for-srw-and-4x2-drw-vehicles-with-wheel-lip-molding?nosto=categorypage-nosto-2">
                <span style={{ fontWeight: 400 }}>Super Duty</span>
              </Link>
              <span style={{ fontWeight: 400 }}>®, and </span>
              <Link to="/maverick-2022-molded-front-splash-guards-pair?nosto=categorypage-nosto-2">
                <span style={{ fontWeight: 400 }}>Maverick</span>
              </Link>
              <span style={{ fontWeight: 400 }}>
                ®. If you aren’t sure if splash guards are the right choice for
                you, ask yourself:&nbsp;
              </span>
            </p>
            <br />
            <ul>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Do I travel on dirt roads or off-road frequently?&nbsp;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Do I live in a snowy or rainy climate?
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  Would they look good on my vehicle?&nbsp;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  How expensive is the paint for my vehicle if it needed to be
                  re-finished?
                </span>
              </li>
            </ul>
            <br />
            <p>
              <span style={{ fontWeight: 400 }}>
                Are you ready to keep your vehicle clean and running smoothly,
                no matter the weather? Protect your vehicle from a variety of
                hazards by bringing the power and ruggedness of available Ford
                accessories on your next adventure. Whether off-roading in the
                woods or running rainy day errands, Ford has everything you need
                for your entire journey. Protect your Ford truck or SUV from mud
                and debris today with our wide selection of rugged splash
                guards!
              </span>
            </p>
          </article>

          <div className="article-share">
            <div
              className="social-icons "
              itemScope
              itemType="https://schema.org/Organization">
              <link itemProp="url" href="https://accessories.ford.com" />

              <h3 className="social-icons__share-title">Share Article</h3>

              <div className="social-icons__list-item">
                <a
                  href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/benefits-of-splash-guards-for-your-ford-truck-or-suv&amp;text=Benefits+of+Splash+Guards+for+Your+Ford+Truck+or+SUV"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="16"
                    viewBox="0 0 20 16">
                    <g>
                      <g>
                        <path
                          fill="#102b4e"
                          d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Twitter</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/benefits-of-splash-guards-for-your-ford-truck-or-suv"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M.29 5.24h3.2v10.764H.29z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">LinkedIn</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/benefits-of-splash-guards-for-your-ford-truck-or-suv"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="16"
                    viewBox="0 0 8 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Facebook</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <span
                  className="share"
                  onClick={handleShareClick}
                  data-copy-to-clipboard="https://accessories.ford.com/blogs/news/benefits-of-splash-guards-for-your-ford-truck-or-suv">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Share URL</span>
                </span>
              </div>
            </div>
          </div>

          <div className="article-pagination article-pagination--desktop">
            <Link
              className="article-pagination__item article-pagination__item--previous"
              to="/blogs/news/overlanding-101"
              onClick={() => window.scrollTo(0, 0)}>
              <div className="article-pagination__arrow">
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/chevron-thin-white-right_60x.jpeg"
                  alt="Chevron White" width={'32'} height={'62'}
                />
              </div>
              <div className="article-pagination__data">
                <div className="article-pagination__label">
                  Previous Article
                </div>
                <div className="article-pagination__title">Overlanding 101</div>
              </div>
            </Link>

            <Link
              className="article-pagination__item article-pagination__item--next"
              to="/blogs/news/5-unique-ways-to-use-your-truck-bed"  onClick={() => window.scrollTo(0, 0)}>
              <div className="article-pagination__data">
                <div className="article-pagination__label">Next Article</div>
                <div className="article-pagination__title">
                  5 Unique Ways to Use Your Truck Bed
                </div>
              </div>
              <div className="article-pagination__arrow">
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/chevron-thin-white-right_60x.jpeg"
                  alt="Chevron White" width={'32'} height={'62'}
                />
              </div>
            </Link>
          </div>

          <div className="article-pagination article-pagination--mobile">
            <Link
              className="article-pagination__item article-pagination__item--previous"
              to="/blogs/news/overlanding-101"  onClick={() => window.scrollTo(0, 0)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="15"
                viewBox="0 0 8 15">
                <g>
                  <g transform="rotate(-270 4 7.5)">
                    <path
                      fill="#fff"
                      d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z"></path>
                  </g>
                </g>
              </svg>
              Prev Article
            </Link>

            <Link
              className="article-pagination__item article-pagination__item--next"
              to="/blogs/news/5-unique-ways-to-use-your-truck-bed"  onClick={() => window.scrollTo(0, 0)}>
              Next Article
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="15"
                viewBox="0 0 8 15">
                <g>
                  <g transform="rotate(-270 4 7.5)">
                    <path
                      fill="#fff"
                      d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z"></path>
                  </g>
                </g>
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BenefitsOfSplashGuards;
