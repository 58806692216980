/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
//Custom libraries
import MiniCartPopperContent from "./MiniCartPopperContent";
//Redux
import { numItemsSelector } from "../../redux/selectors/order";
//UI
import {
  StyledAccountPopper,
  StyledButton,
  StyledTypography,
  StyledHeaderActions,
} from "@hcl-commerce-store-sdk/react-component";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { useWinDimsInEM } from "../../_foundation/hooks/use-win-dims-in-em";
import { S_MOBILE_W, XS_MOBILE_W } from "../../constants/common";

interface MiniCartProps {
  id: string;
  open: boolean;
  handleClick: Function;
  handleClose: Function;
}

/**
 * MiniCart component
 * displays number of cart items icon
 * @param props
 */
const MiniCart = React.forwardRef<HTMLButtonElement | null, MiniCartProps>(
  (props: any, ref: any) => {
    const { id, open, handleClick, handleClose } = props;
    const { t } = useTranslation();
    const numItems = useSelector(numItemsSelector);
    const [arrowRef, setArrowRef] = useState<any>(null);
    const { w } = useWinDimsInEM();
    const xSmall = XS_MOBILE_W;
    const small = S_MOBILE_W;
    const offset = w <= xSmall ? { offset: "-48" } : undefined;

    return (
      <>
        {/* <StyledButton
          ref={ref}
          className="header-actionsButton"
          variant="text"
          color="secondary"
          onClick={handleClick}>
          <StyledHeaderActions>
            <ShoppingCartIcon />
            <StyledTypography variant="body1" component="p" className="">
              {numItems}
            </StyledTypography>
          </StyledHeaderActions>
        </StyledButton> */}
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="17"
          viewBox="0 0 19 17">
          <g>
            <g>
              <g>
                <g>
                  <path
                    fill="#112b4d"
                    d="M13.069 14.723a1.656 1.656 0 0 0 3.312 0c0-.914-.742-1.652-1.656-1.652-.914 0-1.656.738-1.656 1.652"></path>
                </g>
                <g>
                  <path
                    fill="#112b4d"
                    d="M7.069 14.723a1.656 1.656 0 0 0 3.312 0c0-.914-.742-1.652-1.656-1.652-.914 0-1.656.738-1.656 1.652"></path>
                </g>
                <g>
                  <path
                    fill="#112b4d"
                    d="M18.734 3.433c.08.1.1.234.069.355L17.44 8.591a.41.41 0 0 1-.393.296H6.193l.448 1.632h9.995a.41.41 0 0 1 0 .821H6.02l-.676-2.453L2.836.817H.41A.409.409 0 1 1 .41 0h3.022L4.45 3.267h13.955a.41.41 0 0 1 .328.166zm-.97.655h-13L5.938 8.07H16.72z"></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
        <span
          style={{ display: numItems > 0 ? "block" : "none" }}
          className="user-menu__cart-item-count "
          data-cart-item-count>
          {numItems}
        </span> */}
        <StyledAccountPopper
          id={id}
          open={open}
          anchorEl={ref?.current}
          onClose={handleClose}
          placement={
            w <= xSmall ? "bottom" : w <= small ? "bottom-end" : "bottom-end"
          }
          modifiers={{
            offset,
            flip: {
              enabled: false,
            },
            preventOverflow: {
              enabled: false,
              boundariesElement: "scrollParent",
            },
            hide: {
              enabled: false,
            },
            arrow: {
              enabled: true,
              element: arrowRef,
            },
          }}
          className="mini-cart-popper"
          style={{ maxWidth: "530px" }}>
          <span className="arrow" ref={setArrowRef} />
          <MiniCartPopperContent handleClose={handleClose} />
        </StyledAccountPopper>
      </>
    );
  }
);

export default MiniCart;
