import React, { Component } from "react";
// Abode Tag
import AdobeService from "../../../_foundation/adobeServices/adobeService";
import jsonData from "./staticPagesMetaData.json";
import { Helmet } from "react-helmet";

const contents =jsonData.contents[3]?.shipping;
class ShippingAndDelivery extends Component {
  componentDidMount(): void {
    //Adobe Tag Service
    (async () => {
      let res = await AdobeService.track({pageName: 'shipping and delivery', siteSection: 'help center', pagenameNoProduct: "shipping and delivery", hierarchy: 'help center', variantName: 'global-load', eventType:'global-load'});
      AdobeService.callSatellite(res);
    })()
  }
  render() {
    return ( 
      <div>
         <Helmet>
          <meta name="description" content={contents?.metaDescription}></meta>
          <meta name="keywords" content={contents?.metaKeyword}/>
          </Helmet>
       <div id="shopify-section-standard__hero" className="shopify-section">
        <section className="page-hero" id="page-hero-">
           <div className="page-hero__background object-cover page-hero__overlay ">
        
             <img 
             className="above-mobile-large" 
             src="https://accessories.ford.com/gcs/B2C/ford/Static_Pages/Shipping%26Delivery/StandardContentPages_ShippingDelivery_BroncoFront_D_88ecad16-ae5f-41ac-97fa-2051b153ff34.jpeg" 
             data-preload="loaded" 
             alt="Desktop Hero"  width={'2880'}
              height={'662'}/>
             
             <img 
                     className="mobile-large-only" 
                     src="https://accessories.ford.com/gcs/B2C/ford/Static_Pages/Shipping%26Delivery/StandardContentPages_ShippingDelivery_BroncoFront_M_72fc10ae-aa43-4505-a998-b21d4f612987.jpeg" 
                     data-preload="loaded" 
                     alt="Mobile Hero"  width={'750'}
                     height={'726'}/>
                    
    
    </div>

    <div className="page-hero__content container">
        <div className="page-hero__content-inner page-hero__content-inner--middle">
                <h1 className="page-hero__heading title--md">
                    Shipping &amp; Delivery
                </h1>
        </div>
    </div>
</section>
<style>
</style>
</div>

<div 
    id="shopify-section-standard__content" 
    className="shopify-section">
    <section 
    className="content-section container--mb-2">
<div 
  className="page__content rte">
    <br></br>
    <br></br>
    <h1 
    style={{ color: "#4d4d4d" }}>Shipping &amp; Delivery
    </h1>

       <p
        style={{
        fontWeight: 400,
        letterSpacing: "2px",
        fontSize: "17px",
        }}>
           Most of the time, your parts will be shipped overnight, depending on availability. However, rare circumstances could prevent overnight delivery (e.g., FedEx shipping delay). If you have any issues receiving your order please click "Chat Now" to speak with one of our representatives or give us a call at 844-589-0060, Monday to Friday, 8:30 a.m. to 5:30 p.m., Eastern.
          <br></br>
          <br></br>
           If an item is in stock, the shipping time depends on which shipping option you’ve chosen and how many items are in your complete order. If everything is in stock, your order will be shipped in one to two business days. However, if unusual circumstances arise, such as a part being on back-order, a shipment delay may occur. If this happens, we will let you know about the delay by email.
           <br></br> 
           <br></br>
           When your order is finalized, our system automatically sends an email, letting you know that your order has shipped. Sometimes, however, the email gets sent but your order may be delayed because parts are in a warehouse or are back-ordered. If this happens, we’ll send another email to let you know. You may also check the tracking number or chat with one of our representatives.
           <br></br>
           <br></br>
           Some hazardous materials cannot be shipped directly to you due to federal regulations. Other parts are simply too large and heavy to ship via FedEx and need to be delivered via a truck with a liftgate.
        </p>
</div>

</section>
</div>
      </div>

    );
  }
} 
export default ShippingAndDelivery;