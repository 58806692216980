import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
//import ExpandLess from '@material-ui/icons/ExpandLess';
//import ExpandMore from '@material-ui/icons/ExpandMore';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
//import Divider from "@material-ui/core/Divider";
import { useSite } from "../../_foundation/hooks/useSite";
import { useSelector } from "react-redux";
import { loginStatusSelector } from "../../redux/selectors/user";
import { EMPTY_STRING } from "../../constants/common";
interface childLinksProps{
  value?: any;
  open?:any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      //maxWidth: 360,
      background: '#0c1218',
     color: '#a8a8a8',
     fontSize:'20px',
     
      //style={{background:"black", color:"White"}}
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }),
);



 const FooterSm=()=> {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openAcc, setOpenAcc] = React.useState(false);
  const [openPerform, setOpenPerform] = React.useState(false);
  const [openProfile, setOpenProfile] = React.useState(false);
  const [openResource, setOpenResource] = React.useState(false);
  const [openRelated, setOpenRelated] = React.useState(false);
  const loginStat = useSelector(loginStatusSelector);
  const { mySite } = useSite();
  const storeID: string = mySite ? mySite.storeID : EMPTY_STRING;
  const catalogID: string = mySite ? mySite.catalogID : EMPTY_STRING;

  

  
     
  const  Accessories=[
        {
          linkDescription: t("Footer.Accessories.Exterior"),
          linkText: t("Footer.Accessories.Exterior"),
          linkURL: "/exterior",
        },
        {
          linkText: t("Footer.Accessories.Electronics"),
          linkURL: "/electronics",
        },
        {
          linkText: t("Footer.Accessories.Interior"),
          linkURL: "/interior",
        },
        {
          linkText: t("Footer.Accessories.Wheels"),
          linkURL: "/wheels",
        },
        {
          linkText: t("Footer.Accessories.BedProducts"),
          linkURL: "/bed-products",
        },
        {
          linkText: t("Footer.Accessories.LincolnAccessories"),
          linkURL: "https://accessories.lincoln.com/",
        },
        {
          linkText: t("View Accessories on Your Vehicle"),
          linkURL: "https://fordaccess.com/Ford.aspx?location=70707",
        },
      ];
    
  const  Performance=[
    {
      linkText: t("Footer.Performance.Appearance"),
      linkURL: "/appearance",
    },
    {
      linkText: t("Footer.Performance.Chassis"),
      linkURL: "/chassis",
    },
    {
      linkText: t("Footer.Performance.Electrical"),
      linkURL: "/electrical",
    },
    {
      linkText: t("Footer.Performance.Body"),
      linkURL: "/body",
    },
    {
      linkText: t("Footer.Performance.Driveline"),
      linkURL: "/driveline",
    }
  ];

  const isLoggedIn = loginStat;
  let protocol = window.location.protocol;
  let port = !window.location.port.trim() ? '' : ':' + window.location.port;
  let url = window.location.href;
  let currentURL = url.indexOf("&krypto") > 0 ? url.substring(0, url.indexOf("&krypto")) : url;
  const FMA_LOGIN_LINK = `${mySite.storeCfg.userData["FMA_SIGN_IN"]}?redirectURL=${protocol}//${window.location.hostname}${port}/LoginRedirect?storeId=${mySite.storeID}&catalogId=${mySite.catalogID}&langId=-1&currentURL=${encodeURIComponent(currentURL)}`
  const FMA_LOGOUT_LINK = `${mySite.storeCfg.userData["FMA_SIGN_OUT"]}?redirectURL=${encodeURIComponent(`${protocol}//${window.location.hostname}${port}/LogoutRedirect?storeId=${mySite.storeID}&catalogId=${mySite.catalogID}&langId=-1&lang=en_us`)}&currentURL=${encodeURIComponent(window.location.origin)}&pid=${localStorage.getItem("pid")}&sessionId=${localStorage.getItem("sessionId")}` 
  const MyProfile = [
    loginStat ?
      {
        linkText: t("LOGOUT"),
        linkURL: FMA_LOGOUT_LINK
      } :
      {
        linkText: t("LOGIN"),
        linkURL: FMA_LOGIN_LINK
      },
    !loginStat ?
      {
        linkText: t("Create an Account"),
        linkURL: FMA_LOGIN_LINK,
      } : {},
    {
      linkText: t("Footer.MyProfile.AccountDetails"),
      linkURL: isLoggedIn ? "/account/addresses" : FMA_LOGIN_LINK,
    },
    {
      linkText: t("Footer.MyProfile.OrdersAndReturns"),
      linkURL: isLoggedIn ? "/account/order-history" : FMA_LOGIN_LINK,
    },
    {
      linkText: t("Footer.MyProfile.MyGarage"),
      linkURL: isLoggedIn ? "/account/my-garage" : FMA_LOGIN_LINK,
    },
  ];

  const Resources=[
    {
      linkText: t("Footer.Resources.FAQ"),
      linkURL: "/Faq",
    },
    {
      linkText: t("Footer.Resources.ContactUs"),
      linkURL: "/contact-us",
    },
    {
      linkText: t("Footer.Resources.ShippingAndDelivery"),
      linkURL: "/shipping-delivery",
    },
    {
      linkText: t("Footer.Resources.Returns"),
      linkURL: "/return",
    },
    {
      linkText: t("Footer.Resources.MakeReturn"),
      linkURL: "/MakeReturn",
    },
    {
      linkText: t("Footer.Resources.TermsAndConditions"),
      linkURL: "/terms-conditions",
    },
    {
      linkText: t("Footer.Resources.WarrantyInfo"),
      linkURL: "/warranty-information",
    },
    {
      linkText: t("Footer.Resources.Feedback"),
      linkURL: "Feedback",
    },
  ];
  
  const   RelatedLinks=[
    {
      linkText: t("Footer.RelatedLinks.Ford"),
      linkURL: "https://www.ford.com/",
    },
    {
      linkText: t("Footer.RelatedLinks.Lincoln"),
      linkURL: "https://www.lincoln.com/",
    },
    {
      linkText: t("Footer.RelatedLinks.ContactFord"),
      linkURL: "https://corporate.ford.com/contact.html",
    },
    {
      linkText: t("Footer.RelatedLinks.Racing"),
      linkURL: "https://performance.ford.com/home.html",
    },
    {
      linkText: t("Footer.RelatedLinks.FordParts"),
      linkURL: "https://parts.ford.com/en.html",
    },
    {
      linkText: t("Footer.RelatedLinks.FordChargeStations"),
      linkURL: "https://chargers.ford.com/",
    },
    {
      linkText: t("Footer.RelatedLinks.FordCollision"),
      linkURL: "https://collision.ford.com/",
    },
    {
      linkText: t("Footer.RelatedLinks.PowerStrokeDiesel"),
      linkURL: "https://powerstrokediesel.com/",
    },
    {
      linkText: t("Footer.RelatedLinks.TireFinder"),
      linkURL: "https://www.ford.com/support/service-maintenance/tire-finder/",
    },
    {
      linkText: t("Footer.RelatedLinks.VintageParts"),
      linkURL: "https://www.vpartsinc.com/",
    },
  ];
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}>
       
          <ListItem onClick={() => setOpenAcc(!openAcc)} 
            className="footer-nav__column" style={{ padding: "0px" }}>
            <ListItemText style={{ marginTop: '0px' }} className="footer-nav__column-title">
              <h2 className="footersection-title-mobile">{"ACCESSORIES"}</h2>
            </ListItemText>
          </ListItem>
           
          <List component="div" disablePadding>
            {openAcc && Accessories.map((v:any)=>(
              <ListItem className={classes.nested}>
                <ListItemText > 
                {v.linkText == "View Accessories on Your Vehicle" ? (<a href={v.linkURL} target="_blank">{v.linkText}</a>) :
                  (
                    <>
                      {v.linkText == t("Footer.Accessories.LincolnAccessories") ? (<a href={v.linkURL} target="_blank">{v.linkText}</a> ) : (
                        <Link
                        key={v.linkURL + "/"}
                        // to={`/${v.linkURL}`}
                        to={v.linkURL}
                        onClick={() => { window.scrollTo(0, 0); }}
                        //title={ v["linkDescription"] ? v["linkDescription"] : v.linkText}
                        >
                          {v.linkText}
                        </Link>
                      )} 
                    </>
                  )
                }
                </ListItemText>
              </ListItem>
            ))}
          </List>
           
          <ListItem onClick={() => setOpenPerform(!openPerform)} className="footer-nav__column" style={{ padding: "0px" }}>
            <ListItemText style={{ marginTop: '0px' }} className="footer-nav__column-title" >
            <h2 className="footersection-title-mobile">{"PERFORMANCE"}</h2>
            </ListItemText>
          </ListItem>

          <List component="div" disablePadding>
            {openPerform && Performance.map((v: any) => (
              <ListItem className={classes.nested}>
                <ListItemText> 
                  <Link
                    key={v.linkURL + "/"}
                    to={v.linkURL}
                    onClick={() => { window.scrollTo(0, 0); }} >
                    {v.linkText}
                  </Link> 
                </ListItemText>
              </ListItem>
            ))}
          </List>
         
          <ListItem  onClick={()=>setOpenProfile(!openProfile)} className="footer-nav__column" style={{padding:"0px"}}>
            <ListItemText style={{marginTop: '0px'}} className="footer-nav__column-title" >
            <h2 className="footersection-title-mobile">{"MY PROFILE"}</h2>
            </ListItemText>
          </ListItem>
           
          <List component="div" disablePadding>
            {openProfile && MyProfile.map((v:any)=>(
              <ListItem className={classes.nested}>
                <ListItemText> 
                  {
                    (isLoggedIn === false || v.linkText === "LOGOUT" || v.linkText === "LOGIN" || v.linkText === "Create an Account") ?
                      ( <a href={v.linkURL}>{v.linkText}</a> ) : (
                        <Link
                          key={v.linkURL + "/"}
                          to={v.linkURL}
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}>
                            {v.linkText}
                        </Link>)
                  }
                </ListItemText>
              </ListItem>
            ))}
          </List>
          
          <ListItem  onClick={()=>setOpenResource(!openResource)} className="footer-nav__column" style={{padding:"0px"}}>
            <ListItemText style={{marginTop: '0px'}} className="footer-nav__column-title" >
            <h2 className="footersection-title-mobile">{"RESOURCES"}</h2>
            </ListItemText>  
          </ListItem>
           
          <List component="div" disablePadding>
            {openResource && Resources.map((v:any)=>(
              <ListItem className={classes.nested}>
                <ListItemText> 
                  {
                      (() => {
                        if (v.linkText === "Feedback")
                          return <a href="javascript:void(0);" onClick={() => { (window as any).KAMPYLE_ONSITE_SDK.showForm(8521) }}>{v.linkText}</a>;
                        else
                          return <Link
                            key={v.linkURL + "/"}
                            to={v.linkURL}
                            onClick={() => window.scrollTo(0, 0)}
                          >
                            {v.linkText}
                          </Link>
                      })()
                    } 
                </ListItemText>
              </ListItem>
            ))}
          </List>
          
          <ListItem  onClick={()=>setOpenRelated(!openRelated)} className="footer-nav__column" style={{padding:"0px"}}>
            <ListItemText style={{marginTop: '0px'}} className="footer-nav__column-title" >
            <h2 className="footersection-title-mobile">{"RELATED LINKS"}</h2>
            </ListItemText> 
          </ListItem>
           
          <List component="div" disablePadding>
            {openRelated && RelatedLinks.map((v:any)=>(
              <ListItem className={classes.nested}>
                <ListItemText> 
                  <a href={v.linkURL} target="_blank" rel="noopener noreferrer">
                    {v.linkText}
                  </a>
                </ListItemText>
              </ListItem>
            ))}
          </List>
    </List>
  );
}
export default FooterSm;