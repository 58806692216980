import {__ISCHECKBOXENABLED,__ISREDEEMSECTIONENABLED,__APIERROR,__ISSHIPPINGADDRESSERROR,__ISBILLINGADDRESSERROR,__ISBOTHCHECKBOXCHECKED,__ISREDEEMAPIERROR} from '../action-types/payment-constant';
export const setActionIsCheckboxEnabled=({isFlag})=>{
    return {
    type:__ISCHECKBOXENABLED,
    payload:isFlag
}};

export const setActionIsShippingAddressError=({isFlag})=>
{
    return {
        type:__ISSHIPPINGADDRESSERROR,
        payload:isFlag

    }
}
export const setActionIsBillingAddressError=({isFlag})=>
{
    return {
        type:__ISBILLINGADDRESSERROR,
        payload:isFlag

    }
}
export const setActionIsRedeemSectionEnabled=({isFlag})=>
{
    return {
        type:__ISREDEEMSECTIONENABLED,
        payload:isFlag

    }
}
export const setActionIsBothCheckedBox=({isFlag})=>
{
    return {
        type:__ISBOTHCHECKBOXCHECKED,
        payload:isFlag

    }
}

export const setActionforRedeemAPIError=({isFlag})=>
{
    return {
        type:__ISREDEEMAPIERROR,
        payload:isFlag

    }
}


export const setActionAPIError=(value)=>({type:__APIERROR,payload:value});