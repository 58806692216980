import {__ISCHECKBOXENABLED,__ISREDEEMSECTIONENABLED,__APIERROR} from '../action-types/payment-constant';
const iniState={
  ischeckboxFlag:false,
    isRedemptionFlag:true,
    isShippingAddressError:false,
    isBillingAddressError:false,
    isBothCheckBoxChecked:false,
    isRedeemAPIError:false,
}
export const PAYMENT_COMMOM_REDUCER=(state=iniState,action)=>
{
   
  switch (action.type)
  {
      case 'IS_CHECKBOX_ENABLED':
          return {...state,ischeckboxFlag:action.payload}
          case 'IS_SHIPPING_ADDRESS_ERROR':
              return {...state,isShippingAddressError:action.payload}
              case 'IS_BILLING_ADDRESS_ERROR':
                return {...state,isBillingAddressError:action.payload}
      case 'IS_REDEEMSECTION_ENABLED':
           return {...state,isRedemptionFlag:action.payload}
           case 'IS_BOTH_CHECKBOX':
            return {...state,isBothCheckBoxChecked:action.payload}
            case 'IS_REDEEM_API_ERROR':
                return {...state,isRedeemAPIError:action.payload}
      default: return state;
          
  }
  
}
