/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React from "react";
import { Link } from "react-router-dom";
//UI
import {
  StyledPaper,
  StyledMenuTypography,
  StyledBox,
} from "@hcl-commerce-store-sdk/react-component";

interface ThreeTierMenuListProps {
  page?: any;
}

/**
 * ThreeTierMenu component
 * expanded menu three tier submenu
 * @param props
 */
const ThreeTierMenuList: React.FC<ThreeTierMenuListProps> = (props: any) => {
  const page = props.page ? props.page : [];
  const getLink = (body) => {
    if (body) {
      var regex = /(<([^>]+)>)/ig
        , result = body.replace(regex, "");
      return result;
    }
    return body;
  }
  return (
    <>
      <StyledPaper className="expanded-menu-three-tier-submenu">
        <StyledBox className="expanded-submenu">
          <Link
            className="expanded-submenu__link expanded-submenu__category"
            to={{
              pathname: page.seo?.href,
              state: {
                breadCrumbTrailEntryView: [
                  { label: page.name, value: page.id, seo: page.seo },
                ],
              },
            }}>
            <StyledMenuTypography
              variant="body1"
              className="expanded-menu-bold">
              {page.name}
            </StyledMenuTypography>
          </Link>
          {page.children && (
            <StyledBox
              className="expanded-submenu__container"
              display="flex"
              direction="row"
              flexWrap="wrap"
              justifyContent="flex-start"
              alignContent="flex-start">
              {page.children.map((page2: any) => (
                <StyledBox key={page2.id} className="expanded-submenu__list">
                  {
                    page2.shortDescription === 'Ford_External_Link' ?
                      (<a
                        className="expanded-submenu__link"
                        href={getLink(page2?.longDescription)}>
                        <StyledMenuTypography
                          variant="body2"
                          className="expanded-menu-bold">
                          {page2.name}
                        </StyledMenuTypography>
                      </a>)
                      :
                      (<Link
                        className="expanded-submenu__link"
                        key={"Link_" + page2.id}
                        to={{
                          pathname: page2.seo?.href,
                          state: {
                            breadCrumbTrailEntryView: [
                              { label: page.name, value: page.id, seo: page.seo },
                              {
                                label: page2.name,
                                value: page2.id,
                                seo: page2.seo,
                              },
                            ],
                          },
                        }}>
                        <StyledMenuTypography
                          variant="body2"
                          className="expanded-menu-bold">
                          {page2.name}
                        </StyledMenuTypography>
                      </Link>)
                  }

                  {page2.children?.length > 0 && (<ul className="expanded-submenu__list__item">
                    {page2.children?.map((page3: any,i) => (
                      <li key={i}>
                        <Link
                          className="expanded-submenu__link"
                          key={page3.id}
                          to={{
                            pathname: page3.seo?.href,
                            state: {
                              breadCrumbTrailEntryView: [
                                {
                                  label: page.name,
                                  value: page.id,
                                  seo: page.seo,
                                },
                                {
                                  label: page2.name,
                                  value: page2.id,
                                  seo: page2.seo,
                                },
                                {
                                  label: page3.name,
                                  value: page3.id,
                                  seo: page3.seo,
                                },
                              ],
                            },
                          }}>
                          <StyledMenuTypography
                            variant="body2"
                            className="expanded-menu-sub-links">
                            {page3.name}
                          </StyledMenuTypography>
                        </Link>
                      </li>
                    ))}
                  </ul>)}
                </StyledBox>
              ))}
            </StyledBox>
          )}
        </StyledBox>
      </StyledPaper>
    </>
  );
};

export default ThreeTierMenuList;
