import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import jsonData from "../staticPagesMetaData.json";
import { useSite } from "../../../../_foundation/hooks/useSite";
import { Helmet } from "react-helmet";

const content =jsonData.contents[24]?.MustHaveVehicleAccessoriesForSummer;

const handleShareClick = (event: React.MouseEvent<HTMLSpanElement>) => {
  const url = event.currentTarget.dataset.copyToClipboard!;
  navigator.clipboard
    .writeText(url)
    .then(() => {
      const confirmation = window.prompt(
        "Copy to clipboard: Ctrl/Cmd+C, Enter",
        url
      );
      if (confirmation) {
        navigator.clipboard.writeText(url);
      }
    })
    .catch(() => {
      window.alert(`Failed to copy link to clipboard: ${url}`);
    });
};

const breadcrumbList = {
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  itemListElement: [
    {
      "@type": "ListItem",
      position: 1,
      name: "Home",
      item: "https://accessories.ford.com/",
    },
    {
      "@type": "ListItem",
      position: 2,
      name: "News",
      item: "https://accessories.ford.com/blogs/news",
    },
    {
      "@type": "ListItem",
      position: 3,
      name: "Must-Have Vehicle Accessories For Summer",
      item: "https://accessories.ford.com/blogs/news/must-have-vehicle-accessories-for-summer",
    },
  ],
};

const MustHaveVehicleAccessoriesForSummer = () => {
  const {storeDisplayName } = useSite();
  let port = !window.location.port.trim() ? '' : ':' + window.location.port;
  return (
    <div>
        <Helmet>
          <title>{content?.title}</title>
          <meta name="description" content={content?.metaDescription}></meta>
          <meta name="keywords" content={content?.metaKeyword}/>
          <meta property="og:site_name" content={storeDisplayName}/>
          <meta property="og:url" content={(window.location.protocol +'//'+window.location.hostname+':' + port + window.location.pathname)}/>
          <meta property="og:title" content={content?.title}/>
          <meta property="og:type" content="article"/>  
          <meta property="og:description" content={content?.metaDescription}/>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={content?.title}/>  
          <meta name="twitter:description" content={content?.metaDescription} />
        </Helmet>
      <div id="shopify-section-template-article" className="shopify-section">
        <section
          title="Blog Hero"
          className="article-hero "
          style={{
            backgroundImage:
              "url(https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/MustHaveVehicleAccessoriesForSummer/RS_SummerAccessories_fc870e18-3649-45f3-91da-ac4594900e73_1500x.jpeg)",
          }}>
          <div className="article-hero__container container">
            <h1 className="article-hero__heading title--md">
              Must-Have Vehicle Accessories For Summer
            </h1>

            <div className="article-hero__publish-date">March 31, 2023</div>
          </div>
        </section>

        <div className="article__main">
          <nav className="breadcrumbs" role="navigation">
            <ul className="flex">
              <li>
              <a href="/">
              <span>Home</span>
              </a>
                <span className="breadcrumbs-separator">&gt;</span>
              </li>
              <li>
                <Link to="/blogs/news">
                  <span>News</span>
                </Link>
              </li>
              <span className="breadcrumbs-separator">&gt;</span>
              <li>
                <span>Must-Have Vehicle Accessori...</span>
              </li>
            </ul>
          </nav>
          <script type="application/ld+json">
            {JSON.stringify(breadcrumbList)}
          </script>
          <div className="article-actions">
            <Link className="article-actions__return" to="/blogs/news">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16">
                <g>
                  <path
                    fill="#102b4e"
                    d="M8,0 C12.418,0 16,3.582 16,8 C16,12.418 12.418,16 8,16 C3.582,16 0,12.418 0,8 C0,3.582 3.582,0 8,0 Z M6,3 C5.724,3 5.474,3.112 5.293,3.293 C5.112,3.474 5,3.724 5,4 C5,4.276 5.112,4.526 5.293,4.707 L9.409,8 L5.293,11.293 C5.112,11.474 5,11.724 5,12 C5,12.276 5.112,12.526 5.293,12.707 C5.474,12.888 5.724,13 6,13 C6.276,13 6.526,12.888 6.707,12.707 L11.707,8.707 C11.888,8.526 12,8.276 12,8 C12,7.724 11.888,7.474 11.707,7.293 L6.707,3.293 C6.526,3.112 6.276,3 6,3 Z"></path>
                </g>
              </svg>
              Back to articles
            </Link>

            <div
              className="social-icons "
              itemScope
              itemType="https://schema.org/Organization">
              <link itemProp="url" href="https://accessories.ford.com" />

              <h3 className="social-icons__share-title">Share</h3>

              <div className="social-icons__list-item">
                <a
                  href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/must-have-vehicle-accessories-for-summer&amp;text=Must-Have+Vehicle+Accessories+For+Summer"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="16"
                    viewBox="0 0 20 16">
                    <g>
                      <g>
                        <path
                          fill="#102b4e"
                          d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Twitter</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/must-have-vehicle-accessories-for-summer"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M.29 5.24h3.2v10.764H.29z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">LinkedIn</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/must-have-vehicle-accessories-for-summer"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="16"
                    viewBox="0 0 8 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Facebook</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <span
                  className="share"
                  data-copy-to-clipboard="https://accessories.ford.com/blogs/news/must-have-vehicle-accessories-for-summer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Share URL</span>
                </span>
              </div>
            </div>
          </div>

          <article className="article-content rte">
            <p>
              <span style={{fontWeight: 400}}>
                With summer in full swing, it’s time to hit the road for the
                summer vacation you’ve been dreaming of. Beaches, mountains, and
                forests all make exceptional destinations when looking for a
                summer trip. No matter where you’re going or what Ford vehicle
                you drive, staying prepared with all the necessary accessories
                can keep you comfortable and ready for the road ahead. From
                seasonal activities to gear that helps cool your car down in the
                summer heat, we’ve put together all the accessories you need for
                a sweet summer!
              </span>
            </p>
            <p>
              <br />
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/MustHaveVehicleAccessoriesForSummer/FamilyRoadTrip_Backseat.jpeg"
                alt="Family Road Trip Back Seat"  width={'1876'} height={'100%'}
              />
            </p>
            <h2>
              <span style={{fontWeight: 400}}>Summertime Essentials</span>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                Whether you’re embarking on a cross-country road trip or simply
                cruising around town, it’s important to have all the tools for
                an exceptional driving experience. Although the summer weather
                and sunshine make for an enjoyable drive, Ford offers a variety
                of accessories to make your summer adventures even more
                blissful.{" "}
              </span>
              <span style={{fontWeight: 400}}>
                If you are looking for some gadgets to entertain passengers,
                keep your car organized or add a little fun to your next summer
                drive, these are the accessories you should explore.
                <br />
                <br />
              </span>
            </p>
            <ul>
              <li style={{fontWeight: 400}}>
                <b>
                  Portable Mini Fridge Freezer -
                  <span style={{fontWeight: 400}}>
                    {" "}
                    Looking to take a long summer trip? Don’t forget your
                    favorite fresh snacks! Our{" "}
                  </span>
                  <Link to="/portable-mini-fridge-freezer-1">
                    <span style={{fontWeight: 400}}>
                      Portable Mini Fridge Freezer
                    </span>
                  </Link>
                  <span style={{fontWeight: 400}}>
                    {" "}
                    provides a compact way to keep your goods cool and ready to
                    consume. The lightweight design combined with a carrying
                    strap makes it easy to take this fridge freezer on the go.
                    The fridge also has a 14-quart LED illuminated compartment
                    for optimal storage and visibility into the fridge/freezer
                    at any time of day.&nbsp;
                  </span>
                </b>
              </li>
              <li style={{fontWeight: 400}}>
                <b>Bike Rack - </b>
                <span style={{fontWeight: 400}}>
                  Take your favorite bicycle along on your summer drives by
                  installing a bike rack on your vehicle. Our bike racks’ stable
                  frames hold onto the bicycle during transportation. We offer
                  many different styles of{" "}
                </span>
                <Link to="/search?searchTerm=bike+rack">
                  <span style={{fontWeight: 400}}>bike racks</span>
                </Link>
                <span style={{fontWeight: 400}}>
                  {" "}
                  from roof-top to hitch-mounted tilting and swing carriers that
                  are made to fit your Ford vehicle!
                </span>
              </li>
              <li style={{fontWeight: 400}}>
                <b>In-Vehicle Entertainment -</b>
                <span style={{fontWeight: 400}}>
                  {" "}
                  Keep your backseat passengers entertained no matter how long
                  the journey is with{" "}
                </span>
                <Link to="/voxx-portable-rear-seat-entertainment-1">
                  <span style={{fontWeight: 400}}>
                    Voxx Portable Rear Seat Entertainment
                  </span>
                </Link>
                <span style={{fontWeight: 400}}>
                  ! This tablet-style entertainment system offers hours of fun
                  while traveling with the ability to watch movies, play games,
                  and even share content between screens. The tablet can also be
                  taken out of the dock for a hands-on experience, but fits your
                  headrest when installed.
                </span>
              </li>
            </ul>
            <h2>
              <span style={{fontWeight: 400}}>
                <br />
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/MustHaveVehicleAccessoriesForSummer/Bronco_SunShade.jpeg"
                  alt="Bronco Sun Shade" width={'4472'} height={'100%'}
                />
                <br />
                Keep It Cool
              </span>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                As much as we try to escape the summer heat by staying in the
                shade or spending time in the air conditioning, your vehicle
                doesn’t have such luxuries. Your car’s interior can get scorched
                by the sun, causing metal and dark materials to become extra
                warm. Getting into an extremely hot car can also be extremely
                uncomfortable, so{" "}
              </span>
              <span style={{fontWeight: 400}}>
                we’ve put together a list of our favorite accessories to make
                your summer safer, cooler, and more enjoyable!
              </span>
              <span style={{fontWeight: 400}}>&nbsp;</span>
            </p>
            <br />
            <ul>
              <li style={{fontWeight: 400}}>
                <b>Sunscreen -</b>
                <span style={{fontWeight: 400}}>
                  {" "}
                  Raising a shade on the inside of your windshield is a quick
                  and simple way to lower the temperature inside your car when
                  it is parked. It can also aid in protecting your vehicle’s
                  interior from fading from UV rays. Our{" "}
                </span>
                <Link to="/search?searchTerm=sunscreen">
                  <span style={{fontWeight: 400}}>sunshade</span>
                </Link>
                <span style={{fontWeight: 400}}>
                  {" "}
                  options are made to fit specific Ford models, so your
                  windshield remains perfectly covered every time.
                </span>
              </li>
            </ul>
            <br />
            <ul>
              <li style={{fontWeight: 400}}>
                <b>Cargo Shade -</b>
                <span style={{fontWeight: 400}}>
                  {" "}
                  Whether you’re making a grocery run, going shopping, or having
                  the car packed up for a road trip,{" "}
                </span>
                <Link to="/search?searchTerm=cargo+shade">
                  <span style={{fontWeight: 400}}>cargo shades</span>
                </Link>
                <span style={{fontWeight: 400}}>
                  {" "}
                  provide coverage for wandering eyes, so passersby will simply
                  see a flat, solid area.
                </span>
              </li>
            </ul>
            <br />
            <ul>
              <li style={{fontWeight: 400}}>
                <b>Side Window Sun Shade -</b>
                <span style={{fontWeight: 400}}> S</span>
                <span style={{fontWeight: 400}}>
                  ince certain vehicles have more window surface area than
                  others, the sun can oftentimes sneak into your eyes through
                  your side windows. Depending on positioning, sun visors may
                  not provide adequate protection for your eyes, so a side
                  window sun shade helps dim harsh sunlight from entering your
                  car. This cooling option can be used both while parked and
                  while driving!
                </span>
              </li>
            </ul>
            <br />
            <img
              src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/MustHaveVehicleAccessoriesForSummer/SummerRoadTrip.jpeg"
              alt="Summer Road Trip" width={'4472'} height={'100%'}
            />
            <br />
            <h2>
              <span style={{fontWeight: 400}}>Soak Up The Sun</span>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                Ready to hit the road this summer? Ensure your next adventure is
                as power- and safety-packed as possible with trusted Ford
                accessories. From exterior vehicle additions to electronics and
                performance parts, Ford has a wide selection of accessories to
                help you enjoy your drives this summer!
              </span>
            </p>
          </article>

          <div className="article-share">
            <div
              className="social-icons "
              itemScope
              itemType="https://schema.org/Organization">
              <link itemProp="url" href="https://accessories.ford.com" />

              <h3 className="social-icons__share-title">Share Article</h3>

              <div className="social-icons__list-item">
                <a
                  href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/must-have-vehicle-accessories-for-summer&amp;text=Must-Have+Vehicle+Accessories+For+Summer"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="16"
                    viewBox="0 0 20 16">
                    <g>
                      <g>
                        <path
                          fill="#102b4e"
                          d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Twitter</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/must-have-vehicle-accessories-for-summer"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M.29 5.24h3.2v10.764H.29z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">LinkedIn</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/must-have-vehicle-accessories-for-summer"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="16"
                    viewBox="0 0 8 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Facebook</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <span
                  className="share"
                  onClick={handleShareClick}
                  data-copy-to-clipboard="https://accessories.ford.com/blogs/news/must-have-vehicle-accessories-for-summer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Share URL</span>
                </span>
              </div>
            </div>
          </div>

          <div className="article-pagination article-pagination--desktop">
            <Link
              className="article-pagination__item article-pagination__item--previous"
              to="/blogs/news/bronco-vs-bronco-sport" onClick={() => window.scrollTo(0, 0)}>
              <div className="article-pagination__arrow">
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/chevron-thin-white-right_60x.jpeg"
                  alt="Chevron White" width={'32'} height={'62'}
                />
              </div>
              <div className="article-pagination__data">
                <div className="article-pagination__label">
                  Previous Article
                </div>
                <div className="article-pagination__title">
                  Bronco vs. Bronco Sport
                </div>
              </div>
            </Link>

            <Link
              className="article-pagination__item article-pagination__item--next"
              to="/blogs/news/your-guide-to-a-blacked-out-vehicle-look" onClick={() => window.scrollTo(0, 0)}>
              <div className="article-pagination__data">
                <div className="article-pagination__label">Next Article</div>
                <div className="article-pagination__title">
                  Your Guide To A “Blacked-Out” Vehicle Look
                </div>
              </div>
              <div className="article-pagination__arrow">
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/chevron-thin-white-right_60x.jpeg"
                  alt="Chevron White"  width={'32'} height={'62'}
                />
              </div>
            </Link>
          </div>

          <div className="article-pagination article-pagination--mobile">
            <Link
              className="article-pagination__item article-pagination__item--previous"
              to="/blogs/news/bronco-vs-bronco-sport" onClick={() => window.scrollTo(0, 0)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="15"
                viewBox="0 0 8 15">
                <g>
                  <g transform="rotate(-270 4 7.5)">
                    <path
                      fill="#fff"
                      d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z"></path>
                  </g>
                </g>
              </svg>
              Prev Article
            </Link>

            <Link
              className="article-pagination__item article-pagination__item--next"
              to="/blogs/news/your-guide-to-a-blacked-out-vehicle-look" onClick={() => window.scrollTo(0, 0)}>
              Next Article
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="15"
                viewBox="0 0 8 15">
                <g>
                  <g transform="rotate(-270 4 7.5)">
                    <path
                      fill="#fff"
                      d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z"></path>
                  </g>
                </g>
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MustHaveVehicleAccessoriesForSummer;
