/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */

export const __ISCHECKBOXENABLED = "IS_CHECKBOX_ENABLED";
export const __ISREDEEMSECTIONENABLED = "IS_REDEEMSECTION_ENABLED";
export const __ISSHIPPINGADDRESSERROR = "IS_SHIPPING_ADDRESS_ERROR";
export const __ISBILLINGADDRESSERROR = "IS_BILLING_ADDRESS_ERROR";
export const __ISBOTHCHECKBOXCHECKED = "IS_BOTH_CHECKBOX";
export const __ISREDEEMAPIERROR = "IS_REDEEM_API_ERROR";
export const __APIERROR = 'Invalid address has been entered. Please enter valid address.';
