import React from 'react';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import jsonData from "../staticPagesMetaData.json";
import { useSite } from "../../../../_foundation/hooks/useSite";
import { Helmet } from "react-helmet";

const content =jsonData.contents[31]?.Towing;

const handleShareClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    const url = event.currentTarget.dataset.copyToClipboard!;
    navigator.clipboard.writeText(url).then(() => {
        const confirmation = window.prompt("Copy to clipboard: Ctrl/Cmd+C, Enter", url);
        if (confirmation) {
            navigator.clipboard.writeText(url);
        }
    }).catch(() => {
        window.alert(`Failed to copy link to clipboard: ${url}`);
    });
};

const breadcrumbList = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
        {
            "@type": "ListItem",
            "position": 1,
            "name": "Home",
            "item": "https://accessories.ford.com/"
        },
        {
            "@type": "ListItem",
            "position": 2,
            "name": "News",
            "item": "https://accessories.ford.com/blogs/news"
        },
        {
            "@type": "ListItem",
            "position": 3,
            "name": "Our Best Towing Accessories",
            "item": "https://accessories.ford.com/blogs/news/our-best-towing-accessories"
        }
    ]
}


const Towing = () => {

    const {storeDisplayName } = useSite();
    let port = !window.location.port.trim() ? '' : ':' + window.location.port;

    return (
        <div>
            <Helmet>
                <title>{content?.title}</title>
                <meta name="description" content={content?.metaDescription}></meta>
                <meta name="keywords" content={content?.metaKeyword}/>
                <meta property="og:site_name" content={storeDisplayName}/>
                <meta property="og:url" content={(window.location.protocol +'//'+window.location.hostname+':' + port + window.location.pathname)}/>
                <meta property="og:title" content={content?.title}/>
                <meta property="og:type" content="article"/>  
                <meta property="og:description" content={content?.metaDescription}/>
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={content?.title}/>  
                <meta name="twitter:description" content={content?.metaDescription} />
            </Helmet>
            <div id="shopify-section-template-article" className="shopify-section">




                <section className="article-hero" title="Blog Hero" style={{ backgroundImage: "url(https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/Towing/Towing_RS_1500x.jpeg)" }}>
                    <div className="article-hero__container container">






                        <h1 className="article-hero__heading title--md">
                            Our Best Towing Accessories
                        </h1>




                        <div className="article-hero__publish-date">
                            March 14, 2023
                        </div>

                    </div>
                </section>



                <div className="article__main">




                    <nav className="breadcrumbs" role="navigation">
                        <ul className="flex">
                            <li>
                            <a href="/">
              <span>Home</span>
              </a>
                <span className="breadcrumbs-separator">&gt;</span>
              </li>
              <li>
                <Link to="/blogs/news">
                  <span>News</span>
                </Link>
                            </li>
                            <span className="breadcrumbs-separator">&gt;</span>
                            <li>
                                <span>Our Best Towing Accessories</span>
                            </li></ul>
                    </nav>
                    <script type="application/ld+json">
                        {JSON.stringify(breadcrumbList)}
                    </script>

                    <div className="article-actions">


                        <Link className="article-actions__return" to="/blogs/news">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><path fill="#102b4e" d="M8,0 C12.418,0 16,3.582 16,8 C16,12.418 12.418,16 8,16 C3.582,16 0,12.418 0,8 C0,3.582 3.582,0 8,0 Z M6,3 C5.724,3 5.474,3.112 5.293,3.293 C5.112,3.474 5,3.724 5,4 C5,4.276 5.112,4.526 5.293,4.707 L9.409,8 L5.293,11.293 C5.112,11.474 5,11.724 5,12 C5,12.276 5.112,12.526 5.293,12.707 C5.474,12.888 5.724,13 6,13 C6.276,13 6.526,12.888 6.707,12.707 L11.707,8.707 C11.888,8.526 12,8.276 12,8 C12,7.724 11.888,7.474 11.707,7.293 L6.707,3.293 C6.526,3.112 6.276,3 6,3 Z"></path></g></svg>
                            Back to articles
                        </Link>




                        <div className="social-icons " itemScope itemType="https://schema.org/Organization">
                            <link itemProp="url" href="https://accessories.ford.com" />


                            <h3 className="social-icons__share-title">
                                Share
                            </h3>


                            <div className="social-icons__list-item">
                                <a href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/our-best-towing-accessories&amp;text=Our+Best+Towing+Accessories" target="_blank" rel="noopener" className="share">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16"><g><g><path fill="#102b4e" d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path></g></g></svg>
                                    <span className="visually-hidden">
                                        Twitter
                                    </span>
                                </a>
                            </div>
                            <div className="social-icons__list-item">
                                <a href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/our-best-towing-accessories" target="_blank" rel="noopener" className="share">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><g><g><path fill="#102b4e" d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path></g><g><path fill="#102b4e" d="M.29 5.24h3.2v10.764H.29z"></path></g><g><path fill="#102b4e" d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path></g></g></g></svg>
                                    <span className="visually-hidden">
                                        LinkedIn
                                    </span>
                                </a>
                            </div>
                            <div className="social-icons__list-item">
                                <a href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/our-best-towing-accessories" target="_blank" rel="noopener" className="share">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16"><g><g><g><path fill="#102b4e" d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path></g></g></g></svg>
                                    <span className="visually-hidden">
                                        Facebook
                                    </span>
                                </a>
                            </div>
                            <div className="social-icons__list-item">
                                <button className="share" onClick={handleShareClick} data-copy-to-clipboard="https://accessories.ford.com/blogs/news/our-best-towing-accessories">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><g><path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path></g></g></svg>
                                    <span className="visually-hidden">
                                        Share URL
                                    </span>
                                </button>
                            </div>


                        </div>

                    </div>



                    <article className="article-content rte">
                        <p><span style={{ fontWeight: 400 }}>Your Ford is made for rugged roads and heavy lifting, so why not put it into action? Make the most of your travels by adding tow hitch accessories to your truck! When you give your vehicle the ability to tow, you greatly expand your Ford vehicle’s possibilities. From boats to trailers and even RVs, your Ford can haul all types of cargo when it is equipped with the proper hitch. Considering hitches have no universal fit, finding the best trailer towing accessories can be difficult to achieve. To make your search a little bit easier, we’ve put together a guide for navigating the most common types of towing accessories!</span></p>
                        <p>&nbsp;</p>
                        <h2><Link to="/search?searchTerm=trailer+hitch+receiver"><span style={{ fontWeight: 400 }}>Trailer Hitch Receiver</span></Link></h2>
                        <img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/Towing/HitchReceiver.jpeg" alt="Hitch Receiver" width='1480' height='100%'/><br />
                        <p><span style={{ fontWeight: 400 }}>To start towing, a trailer hitch receiver may be necessary to attach other tow hitch accessories to your truck. A hitch receiver is a tube that is bolted to the frame of your Ford to enable the connection of towing accessories, bike racks and ball mount hitches. Specifically, Ford hitch receivers are corrosion-resistant and require no additional drilling since they attach to existing holes in your vehicle! Finding the right hitch receiver for your vehicle is no hassle since Ford receivers are tailored to each model.</span> <span style={{ fontWeight: 400 }}>If your vehicle doesn’t have a tow package or a hitch receiver installed, this is a great place to start for future towing endeavors!</span></p>
                        <br />
                        <h2><Link to="/search?searchTerm=trailer+ball"><span style={{ fontWeight: 400 }}>Trailer Hitch Ball Mount And Shank</span></Link></h2>
                        <img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/Towing/BallMount.jpeg"  alt="Ball Mount" width='1024' height='100%' /><br />
                        <p><span style={{ fontWeight: 400 }}>Equip your truck to tow trailers and campers with a hitch ball mount and shank. Each type of ball hitch is sized to support different ranges of gross trailer weight or the total weight of your trailer and cargo. Ford offers ball mounts from 1 ⅞” to 2 </span><span style={{ fontWeight: 400 }}>5/16</span><span style={{ fontWeight: 400 }}>” in diameter. There is no universal fit for ball hitches, so be sure to purchase the correct ball size that fits your hitch and meets the requirements of your cargo! Refer to the following measurements to determine the best ball mount size for your trailer:<br /><br /></span></p>
                        <ul>
                            <li style={{ fontWeight: 400 }}>
                                <Link to="/search?searchTerm=1+7%2F8+inch+Ball+Hitch"><span style={{ fontWeight: 400 }}>1 ⅞” Ball Hitch</span></Link><span style={{ fontWeight: 400 }}> - This ball hitch is best for light-duty towing up to 2,000 lb. Light cargo-like small trailers and compact SUVs are ideal for this smaller ball hitch.&nbsp;</span>
                            </li>
                            <li style={{ fontWeight: 400 }}>
                                <Link to="/search?searchTerm=2%22+ball+hitch"><span style={{ fontWeight: 400 }}>2” Ball Hitch</span></Link><span style={{ fontWeight: 400 }}> - A 2” trailer hitch ball is most commonly used for Class III ball mounts. This size ball hitch carries loads up to 6,000 lb, such as SUVs, boat trailers, and campers.&nbsp;</span>
                            </li>
                            <li style={{ fontWeight: 400 }}>
                                <Link to="/search?searchTerm=2+5%2F16%22+Ball"><span style={{ fontWeight: 400 }}>2 5/16” Ball Hitch</span></Link><span style={{ fontWeight: 400 }}> - This ball hitch is your best option for heavy-duty towing. With the ability to carry up to 25,000 lb, a 2 </span><span style={{ fontWeight: 400 }}>5/16”</span><span style={{ fontWeight: 400 }}> ball hitch manages most large pickup trucks, commercial trailers and 5th-wheel campers.</span>
                            </li>
                        </ul>
                        <br />
                        <h2><Link to="/search?searchTerm=5th+wheel+hitch"><span style={{ fontWeight: 400 }}>5th Wheel Hitch Kit</span></Link></h2>
                        <img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/Towing/5thWheel.jpeg" alt="5th-Wheel Hitch Kit" width='1480' height='100%'/><br />
                        <p><span style={{ fontWeight: 400 }}>A 5th-wheel hitch is the perfect towing accessory for trucks and recreational vehicles. If you’re bringing an RV, camper, or travel trailer along for the ride, this hitch helps to ensure more stability than a traditional ball hitch. Fifth-wheel hitches are also easier to maneuver, given the bed placement of the hitch. Depending on the hitch model, Ford 5th-wheel hitch kits carry weights from 19,000 to 25,000 lb, along with ten-degree front-to-back pivots and four-degree side-to-side pivots.&nbsp;</span></p>
                        <br />
                        <h2><Link to="/search?searchTerm=gooseneck+hitch"><span style={{ fontWeight: 400 }}>Gooseneck Hitch Kit</span></Link></h2>
                        <img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/Towing/GooseneckHitch.jpeg" alt="Gooseneck Hitch" width='1480' height='100%' /><br />
                        <p><span style={{ fontWeight: 400 }}>On the other hand, gooseneck hitches are perfect for commercial towing. If you have livestock trailers, horse trailers, or equipment haulers, a gooseneck hitch will be the best fit for your trailer. Unlike a 5th wheel hitch, a gooseneck hitch is made up of a ball mount and coupler, rather than a head plate and kingpin. Specifically, Ford gooseneck hitch kits are capable of towing maximum trailer weights of 40,000 lb. when properly equipped and include a 2-5/16” hitch ball, a 3” hitch ball, safety chain tie-down bars, and a blow-molded storage case.</span></p>
                        <p>&nbsp;</p>
                        <h2><Link to="/search?searchTerm=gooseneck+hitch"><span style={{ fontWeight: 400 }}>5th Wheel Gooseneck Hitch Prep Package</span></Link></h2>
                        <img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/Towing/GooseneckHitchPrepPackage.jpeg" alt="Gooseneck Hitch Prep Package" width='1480' height='100%'/><br />
                        <p><span style={{ fontWeight: 400 }}>Now that you know their requirements and needs, you may be ready to set up your 5th wheel or gooseneck hitch kit! To add one of these kits, a 5th wheel gooseneck hitch prep package is required for your Ford truck bed. This product is an under-the-bed, cross-member package that includes inner and outer frame L-brackets, a 7-pin wire harness, a trim kit, and more added hardware. Needless to say, this package trains your Ford for the roads ahead. </span><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
                        <p>&nbsp;</p>
                        <h2><Link to="/search?searchTerm=trailer+wiring"><span style={{ fontWeight: 400 }}>Trailer Tow Wiring Kits</span></Link></h2>
                        <p><span style={{ fontWeight: 400 }}><img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/Towing/TrailerTowWiring.jpeg" alt="Trailer Tow Wiring" width='1480' height='100%' /></span></p>
                        <p><span style={{ fontWeight: 400 }}>To activate your trailer’s signal lights, a wiring harness needs to be connected to your vehicle’s electrical system and trailer. The two most common types of wiring for trailers are </span><Link to="/search?searchTerm=4+pin"><span style={{ fontWeight: 400 }}>4-pin</span></Link><span style={{ fontWeight: 400 }}> and </span><Link to="/search?searchTerm=7+pin+"><span style={{ fontWeight: 400 }}>7-pin connectors</span></Link><span style={{ fontWeight: 400 }}>. Both wiring connectors activate brake lights, turn signals, and emergency lights, but a 7-pin harness also includes a 12-volt circuit, electrical input for trailer brakes, and backup lights. Typically, you’ll want to use a 7-pin harness for larger trailers with electric brakes, gooseneck trailers, and 5th-wheel trailers. Unless you’re experienced in tow package installation, professional installation is also recommended for wiring your hitch. If you need help with wiring, be sure to add Ford professional installation with the purchase of your wire kit!</span></p>
                        <br />
                        <h2><Link to="/search?searchTerm=trailer+brake+control"><span style={{ fontWeight: 400 }}>Trailer Brake Controller</span></Link></h2>
                        <h2><span style={{ fontWeight: 400 }}><img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/Towing/TrailerBrakeController.jpeg" alt="Trailer Brake Controller" width='1260' height='100%' />&nbsp;</span></h2>
                        <p><span style={{ fontWeight: 400 }}>Remain confident while towing heavy loads by equipping your vehicle with a trailer brake controller! </span><span style={{ fontWeight: 400 }}>To put it simply, a brake controller connects your trailer’s brakes to your vehicle's brakes so when you press on the brakes in your vehicle, your trailer’s brakes are also activated. I</span><span style={{ fontWeight: 400 }}>f your trailer has </span><span style={{ fontWeight: 400 }}>electric or electric-over-hydraulic brakes specifically, a trailer brake controller is a must. To prepare for your next load, be sure to anticipate longer stopping distances and adjust your braking intensity according to the weight of your cargo, trailer size, and the conditions of your travels.&nbsp;</span></p>
                        <p>&nbsp;</p>
                        <h2><Link to="/search?searchTerm=bed+storage"><span style={{ fontWeight: 400 }}>Truck Bed Storage Box</span></Link></h2>
                        <p><span style={{ fontWeight: 400 }}><img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/Towing/TruckBedStorageBox.jpeg" alt="Truck Bed Storage box" width='1480' height='100%' /></span></p>
                        <p><span style={{ fontWeight: 400 }}>Where should you keep all of your tools and towing accessories when they’re not in use? A truck bed storage box is an excellent option for securing all of your tools in one place while driving! Built specifically to fit your Ford, storage boxes stay attached to your vehicle so all of your equipment remains secure on the road. One great option is a </span><Link to="/search?searchTerm=pivot+storage+box"><span style={{ fontWeight: 400 }}>pivot storage box </span></Link><span style={{ fontWeight: 400 }}>which is mounted between the wheel well and the back of the truck bed to provide a weather-resistant spot for your tools. To carry and organize larger pieces of equipment, invest in a </span><Link to="/search?searchTerm=decked+drawer+system"><span style={{ fontWeight: 400 }}>decked drawer system</span></Link><span style={{ fontWeight: 400 }}> that carries over 200 lb. in each drawer.&nbsp;</span></p>
                        <p>&nbsp;</p>
                        <h2><Link to="/search?searchTerm=backup+camera"><span style={{ fontWeight: 400 }}>Backup Cameras</span></Link></h2>
                        <img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/Towing/BackupCameras.jpeg" alt="Back-up Cameras" width='1480' height='100%' /><br />
                        <p><span style={{ fontWeight: 400 }}>Parking with a hefty pickup truck is hard enough, but adding a trailer to the mix makes parking even more daunting. Increase your vehicle’s visibility with a trailer-mounted backup camera to assist with parking and traffic. Explore all of our </span><Link to="/search?searchTerm=backup+camera"><span style={{ fontWeight: 400 }}>backup cameras</span></Link><span style={{ fontWeight: 400 }}> fit for vehicles with and without Pro Trailer Backup Assist™!</span></p>
                        <br />
                        <h2><span style={{ fontWeight: 400 }}>Time to Tow</span></h2>
                        <span style={{ fontWeight: 400 }}>Towing accessories are not only vital for road trip fun, but they’re essential for preserving your safety and well-being. Prepare yourself thoroughly and be ready for whatever happens by bringing the power and safety of available Ford accessories on your next adventure. With the correct tow accessories for your vehicle, you can focus on the road ahead instead of the trailer behind you. Before you hit the road, be sure to check out our accessories page for even more towing accessories from Ford </span><Link to="/search?searchTerm=tow+hook"><span style={{ fontWeight: 400 }}>tow hooks</span></Link> <span style={{ fontWeight: 400 }}>to </span><Link to="/search?searchTerm=winch+kit"><span style={{ fontWeight: 400 }}>winch kits</span></Link> <span style={{ fontWeight: 400 }}>and </span><Link to="/search?searchTerm=towing+sensor+kit"><span style={{ fontWeight: 400 }}>sensor kits</span></Link><span style={{ fontWeight: 400 }}>!</span>




                    </article>







                    <div className="article-share">

                        <div className="social-icons " itemScope itemType="https://schema.org/Organization">
                            <link itemProp="url" href="https://accessories.ford.com" />


                            <h3 className="social-icons__share-title">
                                Share Article
                            </h3>


                            <div className="social-icons__list-item">
                                <a href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/our-best-towing-accessories&amp;text=Our+Best+Towing+Accessories" target="_blank" rel="noopener" className="share">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16"><g><g><path fill="#102b4e" d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path></g></g></svg>
                                    <span className="visually-hidden">
                                        Twitter
                                    </span>
                                </a>
                            </div>
                            <div className="social-icons__list-item">
                                <a href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/our-best-towing-accessories" target="_blank" rel="noopener" className="share">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><g><g><path fill="#102b4e" d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path></g><g><path fill="#102b4e" d="M.29 5.24h3.2v10.764H.29z"></path></g><g><path fill="#102b4e" d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path></g></g></g></svg>
                                    <span className="visually-hidden">
                                        LinkedIn
                                    </span>
                                </a>
                            </div>
                            <div className="social-icons__list-item">
                                <a href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/our-best-towing-accessories" target="_blank" rel="noopener" className="share">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16"><g><g><g><path fill="#102b4e" d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path></g></g></g></svg>
                                    <span className="visually-hidden">
                                        Facebook
                                    </span>
                                </a>
                            </div>
                            <div className="social-icons__list-item">
                                <button className="share" onClick={handleShareClick} data-copy-to-clipboard="https://accessories.ford.com/blogs/news/our-best-towing-accessories">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><g><path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path></g></g></svg>
                                    <span className="visually-hidden">
                                        Share URL
                                    </span>
                                </button>
                            </div>


                        </div>

                    </div>





                    <div className="article-pagination article-pagination--desktop">

                        <Link className="article-pagination__item article-pagination__item--previous" to="/blogs/news/make-the-most-of-your-maverick" onClick={() => window.scrollTo(0, 0)}>
                            <div className="article-pagination__arrow">
                                <img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/chevron-thin-white-right_60x.jpeg" alt="Chevron White" width='32' height='62' />
                            </div>
                            <div className="article-pagination__data">
                                <div className="article-pagination__label">
                                    Previous Article
                                </div>
                                <div className="article-pagination__title">
                                    Make The Most of Your Maverick®
                                </div>
                            </div>
                        </Link>


                        <Link className="article-pagination__item article-pagination__item--next" to="/blogs/news/best-ford-mustang-accessories" onClick={() => window.scrollTo(0, 0)}>
                            <div className="article-pagination__data">
                                <div className="article-pagination__label">
                                    Next Article
                                </div>
                                <div className="article-pagination__title">
                                    Best Ford Mustang Accessories
                                </div>
                            </div>
                            <div className="article-pagination__arrow">
                                <img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/chevron-thin-white-right_60x.jpeg" alt="Chevron White" width='32' height='62' />
                            </div>
                        </Link>

                    </div>


                    <div className="article-pagination article-pagination--mobile">

                        <Link className="article-pagination__item article-pagination__item--previous" to="/blogs/news/make-the-most-of-your-maverick" onClick={() => window.scrollTo(0, 0)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="15" viewBox="0 0 8 15"><g><g transform="rotate(-270 4 7.5)"><path fill="#fff" d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z"></path></g></g></svg>
                            Prev Article
                        </Link>


                        <Link className="article-pagination__item article-pagination__item--next" to="/blogs/news/best-ford-mustang-accessories" onClick={() => window.scrollTo(0, 0)}>
                            Next Article
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="15" viewBox="0 0 8 15"><g><g transform="rotate(-270 4 7.5)"><path fill="#fff" d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z"></path></g></g></svg>
                        </Link>

                    </div>


                </div>





            </div>
        </div>

    );

}

export default Towing;