/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020, 2021
 *
 *==================================================
 */
//Standard libraries
import { StyledTypography } from "@hcl-commerce-store-sdk/react-component";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useCart } from "../../_foundation/hooks/use-cart";
import {
  storageSessionHandler,
} from "../../_foundation/utils/storageUtil";


interface MiniCartProps {
  closeMinicart?: Function;
}
const EmptyCart: React.FC<MiniCartProps> = (props: any) => {
  const closeEmptyCart = props.closeMinicart;
  const [isShow, setIshow] = useState(false);
  const {
    orderItems,
  } = useCart();

  useEffect(() => {
    if (orderItems.length == 0) {
      storageSessionHandler.removeCheckoutDetails();
      setTimeout(() => {
        setIshow(true);
      }, 1500);
    } else {
      setIshow(false);
    }

  }, [orderItems])

  return (
    <div style={{ display: isShow ? 'block' : 'none' }} className="side-cart__empty-text text-align-center">
      <StyledTypography
        align="center"
        className="horizontal-padding-2">
        Your cart is currently empty.
      </StyledTypography>
      <Link to="/" onClick={closeEmptyCart} className="btn--rounded" style={{ marginTop: 20 }}>Return To Home</Link>
    </div>
  );
};

export default EmptyCart;
