/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020, 2021
 *
 *==================================================
 */
import {
  emeraldTheme as Emerald,
  sapphireTheme as Sapphire,
  fordAccessoriesTheme as FordAccessoriesTheme,
} from "@hcl-commerce-store-sdk/react-component";
const themes = { Emerald, Sapphire, FordAccessoriesTheme };
const CurrentTheme = themes["FordAccessoriesTheme"];
export { CurrentTheme };
