import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
// import { makeStyles, Theme } from "@material-ui/core/styles";
// import AppBar from "@material-ui/core/AppBar";
// import { PersonalInformationLayout } from "../widgets/personal-information";
// // import {AccountLinksGridViewLayout} from '../widgets/account-links-gridview';
// // import AccountDetails from "./AccountDetails";
// import eventBus from "../pdp/EventBus";
// import OrderHistory from "./OrderHistory";
// import {StyledLink} from "@hcl-commerce-store-sdk/react-component";
// import WishList from "./WishList";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from 'react-router-dom';
import { useSite } from "../../_foundation/hooks/useSite";
import {
  ADDRESS_BOOK,
  MY_GARAGE,
  ORDER_HISTORY,
  PERSONAL_INFO,
  WISH_LIST
} from "../../constants/routes";
//Redux
import { loginStatusSelector } from "../../redux/selectors/user";

// import * as ROUTES from "../../constants/routes";
// import { useHistory } from "react-router";
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;

}

// function TabPanel(props: TabPanelProps) {
//    const { children, value, index, ...other } = props;

//   return (
//     <div
//     className=""
//     role="tabpanel"
//     hidden={value !== index}
//     id={`full-width-tabpanel-${index}`}
//     aria-labelledby={`full-width-tab-${index}`}
//     {...other}>
//         {/* {index==0 && history.push({pathname:PERSONAL_INFO})}


//         {index==1 &&  history.push({pathname:ADDRESS_BOOK})}

//         {index==3 &&  history.push({pathname:ORDER_HISTORY})} */}

//     </div>);
// }

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}



const MyAccount = (props) => {
  const { title } = props;
  const location = useLocation();

  const { t } = useTranslation();

  const [value, setValue] = React.useState(0);
  const scrollToAccountDetails = useRef<any>();
  const { mySite } = useSite();
  const loginStatus = useSelector(loginStatusSelector);
  // useEffect(() => {

  //   setTimeout(() => {
  //     eventBus.dispatch("sendTabRef", { tabRefs: scrollToAccountDetails });
  //   }, 1000);
  // }, []);


  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {

    setValue(newValue);

  };

  //   useEffect(()=>
  //   {  
  //     eventBus.on("sendTableValue", (data) =>
  //     {

  //     setValue(data.tabValuefromDashBoard);
  //     ////console.log("TabValueFromDashBoard..",value);

  //     }
  //   )
  // },[]);

 

  var protocol = window.location.protocol;
  var port = !window.location.port.trim() ? '' : ':'+window.location.port;
  const FMA_LOGOUT_LINK=`${mySite.storeCfg.userData["FMA_SIGN_OUT"]}?redirectURL=${encodeURIComponent(`${protocol}//${window.location.hostname}${port}/LogoutRedirect?storeId=${mySite.storeID}&catalogId=${mySite.catalogID}&langId=-1&lang=en_us`)}&currentURL=${encodeURIComponent(window.location.origin)}&pid=${localStorage.getItem("pid")}&sessionId=${localStorage.getItem("sessionId")}`

  let url = window.location.href;
  let currentURL = url.indexOf("&krypto") > 0 ? url.substring(0, url.indexOf("&krypto")) : url;
  const FMA_LOGIN_LINK = `${mySite.storeCfg.userData["FMA_SIGN_IN"]}?redirectURL=${protocol}//${window.location.hostname}${port}/LoginRedirect?storeId=${mySite.storeID}&catalogId=${mySite.catalogID}&langId=-1&currentURL=${encodeURIComponent(currentURL)}`;


  const MyAccountLinks = loginStatus ? [
    {
      title: t("DashBoard"),
      link: PERSONAL_INFO,
    },
    {
      title: t("Account Details"),
      link: ADDRESS_BOOK,
    },
    {
      title: t("My Garage"),
      link: MY_GARAGE,
    },
    {
      title: t("MyAccount.OrderHistory"),
      link: ORDER_HISTORY,
    },

    {
      title: t("MyAccount.Wishlists"),
      link: WISH_LIST,
    },
    {
      title: t("Logout"),
      link: FMA_LOGOUT_LINK,
    },
  ] : [
    {
      title: t("MyAccount.OrderHistory"),
      link: ORDER_HISTORY,
    }
  ];

  useEffect(() => {
    if(!loginStatus && location.pathname.includes("account")) {
      window.location.href = FMA_LOGIN_LINK
    }
  }, []);

  const returnActivaClass = (path) => {
    if (path === location.pathname) {
      return 'account-menu__list-item--active'
    } else if (location.pathname.includes("my-garage") && path.includes("my-garage")) {
      return 'account-menu__list-item--active'
    }
    else if (location.pathname.includes("addresses") && path.includes("addresses")) {
      return 'account-menu__list-item--active'
    }
    return 'account-menu__list-item'
  }
  const openDropDown = (e) => {
    const maxWidth = 990;
    if (window.innerWidth > maxWidth) return;
    if (document.body?.classList.contains('account-menu-open')) {
      document.body.classList.remove('account-menu-open');
    } else {
      document.body.classList.add('account-menu-open');
    }
  }

  function ListItem({ item }) {
    if (item.title == "Logout") {
      return <a href={item.link} onClick={(e) => openDropDown(e)} className="account-menu__link">{item.title}</a>;
    } else {
      return <Link to={item.link} onClick={(e) => openDropDown(e)} className="account-menu__link">{item.title}</Link>;
    }
  }

  if (!loginStatus && location.pathname !== "/order-details") {
    return (<></>);
  } else {
  return (
    <div className="account addresses container">
      <h1 className="account-title">{title ? title : 'My Account'}</h1>

      <div><ul className="account-menu flex f-space-between ">
        {MyAccountLinks?.map((v: any, index: number) => {
          return (
            <li className={returnActivaClass(v.link)} key={index}>
              <ListItem item={v} />
            </li>
          )
        })
        }
      </ul>
        {/* <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            ref={scrollToAccountDetails}
            centered
            aria-label="full width tabs example"
            className="account-menu__list-item">

            {MyAccountLinks.map((v: any, index: number) => {
              return v.link &&
                (v.link === location.pathname) ?
                (
                  <Tab label={v.title} {...a11yProps(index)} />
                ) :
                (
                  <Tab className="account-menu__link" label={v.title} value={v.link} to={v.link} component={Link} {...a11yProps(index)} />
                )
            })
            }

          </Tabs> */}


        {/* <TabPanel value={value} index={0}  />
          <TabPanel value={value} index={1}  />
          <TabPanel value={value} index={2} />
          <TabPanel value={value} index={3}  />
          <TabPanel value={value} index={4}  />
          <TabPanel value={value} index={5}  /> */}

      </div>
    </div>
  );
        }
};

export default MyAccount;
