import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import jsonData from "../staticPagesMetaData.json";
import { useSite } from "../../../../_foundation/hooks/useSite";
import { Helmet } from "react-helmet";

const content =jsonData.contents[20]?.FamilyRoadTripTips;

const handleShareClick = (event: React.MouseEvent<HTMLSpanElement>) => {
  const url = event.currentTarget.dataset.copyToClipboard!;
  navigator.clipboard
    .writeText(url)
    .then(() => {
      const confirmation = window.prompt(
        "Copy to clipboard: Ctrl/Cmd+C, Enter",
        url
      );
      if (confirmation) {
        navigator.clipboard.writeText(url);
      }
    })
    .catch(() => {
      window.alert(`Failed to copy link to clipboard: ${url}`);
    });
};

const breadcrumbList = {
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  itemListElement: [
    {
      "@type": "ListItem",
      position: 1,
      name: "Home",
      item: "https://accessories.ford.com/",
    },
    {
      "@type": "ListItem",
      position: 2,
      name: "News",
      item: "https://accessories.ford.com/blogs/news",
    },
    {
      "@type": "ListItem",
      position: 3,
      name: "Family Road Trip Tips",
      item: "https://accessories.ford.com/blogs/news/family-road-trip-tips",
    },
  ],
};

const FamilyRoadTripTips = () => {
  const {storeDisplayName } = useSite();
  let port = !window.location.port.trim() ? '' : ':' + window.location.port;
  return (
    <div>
        <Helmet>
          <title>{content?.title}</title>
          <meta name="description" content={content?.metaDescription}></meta>
          <meta name="keywords" content={content?.metaKeyword}/>
          <meta property="og:site_name" content={storeDisplayName}/>
          <meta property="og:url" content={(window.location.protocol +'//'+window.location.hostname+':' + port + window.location.pathname)}/>
          <meta property="og:title" content={content?.title}/>
          <meta property="og:type" content="article"/>  
          <meta property="og:description" content={content?.metaDescription}/>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={content?.title}/>  
          <meta name="twitter:description" content={content?.metaDescription} />
        </Helmet>
      <div id="shopify-section-template-article" className="shopify-section">
        <section
          className="article-hero "
          style={{
            backgroundImage:
              "url(https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/FamilyRoadTripTips/FamilyRoadTrip_RS_1500x.jpeg)",
          }}>
          <div className="article-hero__container container">
            <h1 className="article-hero__heading title--md">
              Family Road Trip Tips
            </h1>
            <div className="article-hero__publish-date">March 14, 2023</div>
          </div>
        </section>
        <div className="article__main">
          <nav className="breadcrumbs" role="navigation">
            <ul className="flex">
              <li>
              <a href="/">
              <span>Home</span>
              </a>
                <span className="breadcrumbs-separator">&gt;</span>
              </li>
              <li>
                <Link to="/blogs/news">
                  <span>News</span>
                </Link>
              </li>
              <span className="breadcrumbs-separator">&gt;</span>
              <li>
                <span>Family Road Trip Tips</span>
              </li>
            </ul>
          </nav>
          <script type="application/ld+json">
            {JSON.stringify(breadcrumbList)}
          </script>
          <div className="article-actions">
            <Link className="article-actions__return" to="/blogs/news">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16">
                <g>
                  <path
                    fill="#102b4e"
                    d="M8,0 C12.418,0 16,3.582 16,8 C16,12.418 12.418,16 8,16 C3.582,16 0,12.418 0,8 C0,3.582 3.582,0 8,0 Z M6,3 C5.724,3 5.474,3.112 5.293,3.293 C5.112,3.474 5,3.724 5,4 C5,4.276 5.112,4.526 5.293,4.707 L9.409,8 L5.293,11.293 C5.112,11.474 5,11.724 5,12 C5,12.276 5.112,12.526 5.293,12.707 C5.474,12.888 5.724,13 6,13 C6.276,13 6.526,12.888 6.707,12.707 L11.707,8.707 C11.888,8.526 12,8.276 12,8 C12,7.724 11.888,7.474 11.707,7.293 L6.707,3.293 C6.526,3.112 6.276,3 6,3 Z"></path>
                </g>
              </svg>
              Back to articles
            </Link>
            <div
              className="social-icons "
              itemScope
              itemType="https://schema.org/Organization">
              <link itemProp="url" href="https://accessories.ford.com" />
              <h3 className="social-icons__share-title">Share</h3>
              <div className="social-icons__list-item">
                <a
                  href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/family-road-trip-tips&amp;text=Family+Road+Trip+Tips"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="16"
                    viewBox="0 0 20 16">
                    <g>
                      <g>
                        <path
                          fill="#102b4e"
                          d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Twitter</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/family-road-trip-tips"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M.29 5.24h3.2v10.764H.29z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">LinkedIn</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/family-road-trip-tips"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="16"
                    viewBox="0 0 8 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Facebook</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <span
                  className="share"
                  onClick={handleShareClick}
                  data-copy-to-clipboard="https://accessories.ford.com/blogs/news/family-road-trip-tips">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Share URL</span>
                </span>
              </div>
            </div>
          </div>
          <article className="article-content rte">
            <p>
              <span style={{ fontWeight: 400 }}>
                With so many amazing places to explore throughout the country,
                the chance to see something new is just a car ride away.
                Although road trips may be a more practical option than booking
                a flight and navigating an airport, spending hours in the car
                with your family is a commitment that involves a lot of
                coordinating, especially when children are along for the ride.
                However, road trips offer valuable family time and a chance to
                see places on the way to your destination that you would not be
                able to enjoy from the air. With a little preparation, the right
                packing list, and an equipped vehicle, you will become a family
                road trip pro in no time!
              </span>
            </p>
            <p>
              <i>
                <span style={{ fontWeight: 400 }}>
                  <img
                    src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/FamilyRoadTripTips/GettingReady.jpeg"
                    alt="Getting Ready for your trip" width={'4472'} height={'100%'}
                  />
                  <br />
                  Explorer Platinum with optional equipment and extra cost color
                  shown.
                </span>
              </i>
            </p>
            <p>&nbsp;</p>
            <h2>
              <span style={{ fontWeight: 400 }}>
                Getting Ready For Your Trip
              </span>
            </h2>
            <p>
              <span style={{ fontWeight: 400 }}>
                Before embarking on your family road trip, several preparations
                need to be made. First things first, ensure your vehicle has a
                full tank of gas to ensure you can get as far as possible on the
                first stretch of your trip. Doing your due diligence by mapping
                out fuel stops and bathroom breaks will ensure quick travel time
                and less frequent stops. Another important step to take before
                hitting the road is to have music or podcasts prepared. Take
                this time to listen to your favorite music, listen to an
                audiobook, or learn something new! Prepared playlists of your
                favorite songs and podcasts will prevent phone usage while
                driving and encourage more time to look at the scenery around
                you.&nbsp;
              </span>
            </p>
            <p>
              <span style={{ fontWeight: 400 }}>
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/FamilyRoadTripTips/PackingVehicle.jpeg"
                  alt="Packing Vehicle" width={'4472'} height={'100%'}
                />
              </span>
            </p>
            <h2>
              <span style={{ fontWeight: 400 }}>What To Have On Hand</span>
            </h2>
            <p>
              <span style={{ fontWeight: 400 }}>
                Once you’re ready to hit the road, it is important to ensure you
                have the items on hand that you and your passengers need while
                traveling. From entertainment to food and safety essentials,
                we’ve put together a list to get you started:
              </span>
            </p>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <b>Electronic Devices -</b>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  With the popularity of mobile technology, there will likely
                  need to be electronics like gaming devices, tablets, or movie
                  players on board for entertainment purposes. Don’t forget car
                  chargers and adapters to ensure that devices stay powered for
                  the whole road trip!
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <b>Cleaning Supplies -</b>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  For on-the-go sanitization, it is important to have hand
                  sanitizer or cleansing wipes on hand. These products not only
                  help to clean hands when traditional washing isn’t an option
                  but can prevent spills from becoming permanent souvenirs of
                  your trip.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <b>Snacks and Drinks -</b>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  One of the most effective ways to avoid car ride meltdowns is
                  by having easy-to-consume snacks and drinks within arm’s
                  reach. Depending on the preferences of your family, those may
                  consist of individually packaged crackers, fruit snacks, or
                  pre-cut fruit and cheese. Drinks like juice boxes and bottled
                  water are also effective for mess-free enjoyment.&nbsp;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <Link to="/bronco-2021-first-aid-safety-kit-1">
                  <b>First Aid Safety Kit</b>
                </Link>
                <b> -</b>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  In case of minor injuries both on your journey and after you
                  reach your destination, having a first aid kit handy provides
                  you with the necessary supplies for care. Our first aid safety
                  kit contains supplies in a compact, fire-retardant case that's
                  the perfect fit for nearly any vehicle.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <b>Physical Map - </b>
                <span style={{ fontWeight: 400 }}>
                  It never hurts to have a physical map with you as a backup for
                  your mobile device or in-vehicle navigation system. Maps can
                  be picked up at local gas stations or ordered online and
                  easily fit in your vehicle’s glove box for old-fashioned
                  wayfinding that helps navigate an unfamiliar area.
                </span>
              </li>
            </ul>
            <p>
              <span style={{ fontWeight: 400 }}>
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/FamilyRoadTripTips/PreparingVehicle.jpeg"
                  alt="Preparing vehicle" width={'4472'} height={'100%'}
                />
              </span>
            </p>
            <br />
            <h2>
              <span style={{ fontWeight: 400 }}>Preparing Your Vehicle</span>
            </h2>
            <p>
              <span style={{ fontWeight: 400 }}>
                In addition to your family’s necessities, it’s just as important
                to have your vehicle prepared for your travels. It can be
                overwhelming when preparing your vehicle for a trip, but our
                available accessories make it easier and can even give you more
                room for luggage or other cargo. Here are a few of our favorite
                available Ford Accessories to ensure your vehicle is fully
                equipped for your upcoming trip:
              </span>
            </p>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <Link to="/search?searchTerm=all+weather">
                  <b>All-Weather Floor Mats</b>
                </Link>
                <b> -</b>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  Swapping your carpet mats for all-weather, rubber floor mats
                  are a great way to keep your interior clean and repel spills
                  that may occur on your road trip.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <Link to="/search?searchTerm=cargo+box">
                  <b>Cargo Box</b>
                </Link>
                <b> -</b>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  Looking for a little extra room for luggage or supplies?
                  Utilize the space on the top or in the bed of your vehicle
                  with our cargo boxes and baskets that are available in a
                  variety of sizes.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <Link to="/search?searchTerm=bicycle">
                  <b>Bicycle Rack</b>
                </Link>
                <b> -</b>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  If you’re planning on continuing the adventure once you reach
                  your destination by bringing your family’s bikes along, our
                  bike racks are made for multiple bikes and fit the exact
                  specifications of your Ford.&nbsp;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <Link to="/search?searchTerm=remote+start">
                  <b>Remote Starter </b>
                </Link>
                <b>-</b>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  Keep all your passengers comfortable by setting up a remote
                  starter for your vehicle to get your AC or heat running before
                  you even get in the car.&nbsp;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <Link to="/search?searchTerm=folding+cargo+organizer">
                  <b>Folding Cargo Organizer</b>
                </Link>
                <b> -</b>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  Compartmentalize the items in your trunk with a soft-sided
                  organizer! Our organizers are available in different sizes to
                  suit your needs and are laminated for water repellency and
                  mildew resistance.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <Link to="/search?searchTerm=jump+start">
                  <b>Battery Jump Start Pack</b>
                </Link>
                <b> -</b>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  Help make sure you get to and from your destination by having
                  a battery jump-start kit on hand!&nbsp;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <Link to="/ford-soft-sided-cooler-bag-cargo-organizer-1">
                  {" "}
                  <b>Soft-Sided</b>
                  <b> Cooler Bag</b>
                </Link>
                <b> -</b>
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  This ideal companion for getaways will keep your snacks fresh
                  and organized in your vehicle.&nbsp;&nbsp;
                </span>
              </li>
            </ul>
            <p>
              <span style={{ fontWeight: 400 }}>
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/FamilyRoadTripTips/StayingOrganized.jpeg"
                  alt="Staying Organized" width={'1662'} height={'100%'}
                />
              </span>
            </p>
            <h2>
              <span style={{ fontWeight: 400 }}>
                Staying Organized From Start To Finish
              </span>
            </h2>
            <p>
              <span style={{ fontWeight: 400 }}>
                While getting organized and packing beforehand can assist in
                setting your trip up for success, it’s even more important to
                stay organized throughout your trip. As people use items, eat
                snacks, and get in and out of the vehicle at stops along the
                way, it can be easy for things to become misplaced or messy.
                Taking time to reset the areas in your car periodically can help
                ensure nothing gets too out of hand. Additionally, you could
                create organization systems for certain items to keep them
                separated throughout your trip. Compartmentalizing similar items
                into one section of your car may be helpful. For instance, keep
                all your first aid and cleaning supplies together in one place.
                This will be separate from both the food and drinks and other
                items like jumper cables or car maintenance equipment. It is
                also important not to overpack your vehicle and only bring what
                your family will need.
              </span>
            </p>
            <p>&nbsp;</p>
            <h2>
              <span style={{ fontWeight: 400 }}>
                Making Miles of Memories
              </span>
            </h2>
            <p>
              <span style={{ fontWeight: 400 }}>
                Ready to pack up and pile everyone in the car for a family road
                trip? Prepare yourself thoroughly and stay ready for everyone’s
                needs by bringing the power and safety of available Ford
                accessories on your next adventure. Whether traveling a few
                miles or a thousand, Ford has the essential equipment and tips
                you need for your entire journey. Set out on an unforgettable
                family adventure today with our wide selection of road trip
                accessories!
              </span>
            </p>
          </article>

          <div className="article-share">
            <div
              className="social-icons "
              itemScope
              itemType="https://schema.org/Organization">
              <link itemProp="url" href="https://accessories.ford.com" />

              <h3 className="social-icons__share-title">Share Article</h3>

              <div className="social-icons__list-item">
                <a
                  href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/family-road-trip-tips&amp;text=Family+Road+Trip+Tips"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="16"
                    viewBox="0 0 20 16">
                    <g>
                      <g>
                        <path
                          fill="#102b4e"
                          d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Twitter</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/family-road-trip-tips"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M.29 5.24h3.2v10.764H.29z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">LinkedIn</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/family-road-trip-tips"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="16"
                    viewBox="0 0 8 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Facebook</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <span
                  className="share"
                  onClick={handleShareClick}
                  data-copy-to-clipboard="https://accessories.ford.com/blogs/news/family-road-trip-tips">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Share URL</span>
                </span>
              </div>
            </div>
          </div>

          <div className="article-pagination article-pagination--desktop">
            <Link
              className="article-pagination__item article-pagination__item--previous"
              to="/blogs/news/5-unique-ways-to-use-your-truck-bed"  onClick={() => window.scrollTo(0, 0)}>
              <div className="article-pagination__arrow">
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/chevron-thin-white-right_60x.jpeg"
                  alt="Chevron White" width={'32'} height={'62'}
                />
              </div>
              <div className="article-pagination__data">
                <div className="article-pagination__label">
                  Previous Article
                </div>
                <div className="article-pagination__title">
                  5 Unique Ways to Use Your Truck Bed
                </div>
              </div>
            </Link>

            <Link
              className="article-pagination__item article-pagination__item--next"
              to="/blogs/news/our-best-snowboard-racks" onClick={() => window.scrollTo(0, 0)}>
              <div className="article-pagination__data">
                <div className="article-pagination__label">Next Article</div>
                <div className="article-pagination__title">
                  Our Best Snowboard Racks
                </div>
              </div>
              <div className="article-pagination__arrow">
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/chevron-thin-white-right_60x.jpeg"
                  alt="Chevron White" width={'32'} height={'62'}
                />
              </div>
            </Link>
          </div>

          <div className="article-pagination article-pagination--mobile">
            <Link
              className="article-pagination__item article-pagination__item--previous"
              to="/blogs/news/5-unique-ways-to-use-your-truck-bed" onClick={() => window.scrollTo(0, 0)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="15"
                viewBox="0 0 8 15">
                <g>
                  <g transform="rotate(-270 4 7.5)">
                    <path
                      fill="#fff"
                      d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z"></path>
                  </g>
                </g>
              </svg>
              Prev Article
            </Link>

            <Link
              className="article-pagination__item article-pagination__item--next"
              to="/blogs/news/our-best-snowboard-racks" onClick={() => window.scrollTo(0, 0)}>
              Next Article
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="15"
                viewBox="0 0 8 15">
                <g>
                  <g transform="rotate(-270 4 7.5)">
                    <path
                      fill="#fff"
                      d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z"></path>
                  </g>
                </g>
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FamilyRoadTripTips;
