//Standard libraries
import { debounce } from "redux-saga/effects";
//Redux
import {
  FETCH_PDP_DATA,
  RESPONSE_SUCCESSED,
  RESPONSE_FAILURE,
} from "../../action-types/product-discription-page-type";
import * as WORKERS from "../workers/product-discription-page";

export function* watchSaga() {
  yield debounce(50, FETCH_PDP_DATA, WORKERS.getPDPProduct);
}
