import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import jsonData from "../staticPagesMetaData.json";
import { useSite } from "../../../../_foundation/hooks/useSite";
import { Helmet } from "react-helmet";

const content =jsonData.contents[35]?.WaysToWeatherProofYourVehicle;

const handleShareClick = (event: React.MouseEvent<HTMLSpanElement>) => {
  const url = event.currentTarget.dataset.copyToClipboard!;
  navigator.clipboard
    .writeText(url)
    .then(() => {
      const confirmation = window.prompt(
        "Copy to clipboard: Ctrl/Cmd+C, Enter",
        url
      );
      if (confirmation) {
        navigator.clipboard.writeText(url);
      }
    })
    .catch(() => {
      window.alert(`Failed to copy link to clipboard: ${url}`);
    });
};

const breadcrumbList = {
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  itemListElement: [
    {
      "@type": "ListItem",
      position: 1,
      name: "Home",
      item: "https://accessories.ford.com/",
    },
    {
      "@type": "ListItem",
      position: 2,
      name: "News",
      item: "https://accessories.ford.com/blogs/news",
    },
    {
      "@type": "ListItem",
      position: 3,
      name: "5 Ways To Weatherproof Your Vehicle",
      item: "https://accessories.ford.com/blogs/news/5-ways-to-weatherproof-your-vehicle",
    },
  ],
};

const WaysToWeatherProofYourVehicle = () => {
  const {storeDisplayName } = useSite();
  let port = !window.location.port.trim() ? '' : ':' + window.location.port;
  return (
    <div>
       <Helmet>
          <title>{content?.title}</title>
          <meta name="description" content={content?.metaDescription}></meta>
          <meta name="keywords" content={content?.metaKeyword}/>
          <meta property="og:site_name" content={storeDisplayName}/>
          <meta property="og:url" content={(window.location.protocol +'//'+window.location.hostname+':' + port + window.location.pathname)}/>
          <meta property="og:title" content={content?.title}/>
          <meta property="og:type" content="article"/>  
          <meta property="og:description" content={content?.metaDescription}/>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={content?.title}/>  
          <meta name="twitter:description" content={content?.metaDescription} />
        </Helmet>
      <div id="shopify-section-template-article" className="shopify-section">
        <section
          title="Blog Hero"
          className="article-hero "
          style={{
            backgroundImage:
              "url(https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/WaysToWeatherproof/waterproof_1500x.jpeg)",
          }}>
          <div className="article-hero__container container">
            <h1 className="article-hero__heading title--md">
              5 Ways To Weatherproof Your Vehicle
            </h1>

            <div className="article-hero__publish-date">February 01, 2023</div>
          </div>
        </section>

        <div className="article__main">
          <nav className="breadcrumbs" role="navigation">
            <ul className="flex">
              <li>
              <a href="/">
              <span>Home</span>
              </a>
                <span className="breadcrumbs-separator">&gt;</span>
              </li>
              <li>
                <Link to="/blogs/news">
                  <span>News</span>
                </Link>
              </li>
              <span className="breadcrumbs-separator">&gt;</span>
              <li>
                <span>5 Ways To Weatherproof Your...</span>
              </li>
            </ul>
          </nav>
          <script type="application/ld+json">
            {JSON.stringify(breadcrumbList)}
          </script>

          <div className="article-actions">
            <Link className="article-actions__return" to="/blogs/news">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16">
                <g>
                  <path
                    fill="#102b4e"
                    d="M8,0 C12.418,0 16,3.582 16,8 C16,12.418 12.418,16 8,16 C3.582,16 0,12.418 0,8 C0,3.582 3.582,0 8,0 Z M6,3 C5.724,3 5.474,3.112 5.293,3.293 C5.112,3.474 5,3.724 5,4 C5,4.276 5.112,4.526 5.293,4.707 L9.409,8 L5.293,11.293 C5.112,11.474 5,11.724 5,12 C5,12.276 5.112,12.526 5.293,12.707 C5.474,12.888 5.724,13 6,13 C6.276,13 6.526,12.888 6.707,12.707 L11.707,8.707 C11.888,8.526 12,8.276 12,8 C12,7.724 11.888,7.474 11.707,7.293 L6.707,3.293 C6.526,3.112 6.276,3 6,3 Z"></path>
                </g>
              </svg>
              Back to articles
            </Link>

            <div
              className="social-icons "
              itemScope
              itemType="https://schema.org/Organization">
              <link itemProp="url" href="https://accessories.ford.com" />

              <h3 className="social-icons__share-title">Share</h3>

              <div className="social-icons__list-item">
                <a
                  href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/5-ways-to-weatherproof-your-vehicle&amp;text=5+Ways+To+Weatherproof+Your+Vehicle"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="16"
                    viewBox="0 0 20 16">
                    <g>
                      <g>
                        <path
                          fill="#102b4e"
                          d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Twitter</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/5-ways-to-weatherproof-your-vehicle"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M.29 5.24h3.2v10.764H.29z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">LinkedIn</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/5-ways-to-weatherproof-your-vehicle"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="16"
                    viewBox="0 0 8 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Facebook</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <span
                  className="share"
                  onClick={handleShareClick}
                  data-copy-to-clipboard="https://accessories.ford.com/blogs/news/5-ways-to-weatherproof-your-vehicle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Share URL</span>
                </span>
              </div>
            </div>
          </div>

          <article className="article-content rte">
            <h1>
              <b></b>
            </h1>
            <p>
              <span style={{fontWeight: 400}}>
                If you’ve lived in your current area for a while, chances are
                you know your local seasons pretty well. However, if you
                recently moved, unexpected weather damage can cause unforeseen
                problems. Despite knowing of rain or shine, all types of weather
                can cause damage to your vehicle. From a wet and muddy interior
                from a surprise rainfall; to sun-damaged exterior parts and
                accessories losing their illustrious sheen in the summer, read
                on to discover the top 5 ways to keep your vehicle in tip-top
                shape in all kinds of weather!
              </span>
            </p>
            <p>
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/WaysToWeatherproof/SplashGuards.jpeg"
                alt="Splash Guards" width="1480" height="100%"
              />
            </p>
            <h2>
              <span style={{fontWeight: 400}}>Install Splash Guards</span>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                From the sunny west coast to the snow-trodden midwest, every
                road has dirt and debris that cause damage to the body of your
                vehicle. Without splash guards, rocks and sharp road debris can
                kick up while driving, creating a hazard to the beautiful paint
                on your car. Cold weather states suffer doubly, as ice and snow
                also get kicked up while driving, causing potential problems to
                both the sides and lower body panels of your vehicle. Installing{" "}
              </span>
              <Link to="/splash-guards">
                <span style={{fontWeight: 400}}>splash guards</span>
              </Link>
              <span style={{fontWeight: 400}}>
                {" "}
                that fit the make and model of your specific Ford helps protect
                your vehicle year-round.
              </span>
            </p>
            <p>
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/WaysToWeatherproof/BatteryJumpStart.jpeg"
                alt="Battery Jump Start"  width="1480" height="100%"
              />
            </p>
            <h2>
              <span style={{fontWeight: 400}}>
                Keep a Battery Jump Start Pack on Hand
              </span>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                Electric cars aren’t the only vehicles powered by a battery!
                Your Ford has an internal battery located under the hood that
                powers all the technology and allows you to start your engine
                before every journey. Since everything relies on that battery,
                it’s more important than ever to keep it in tip-top shape.
              </span>
            </p>
            <p>
              <span style={{fontWeight: 400}}>
                Like all car parts, batteries will run their course, but both
                winter and summer activities can create less-than-ideal
                situations for your car’s battery! Cold weather is rough on your
                vehicle’s battery due to dropping temperatures making the
                battery work harder to start your car in the winter.
                Furthermore, car batteries produce less electrical power when
                it’s cold out.
              </span>
            </p>
            <p>
              <span style={{fontWeight: 400}}>
                If you spend the summer months traveling or visiting family away
                from your Ford, a sitting battery in an unused vehicle can cause
                it to go flat. Here’s why: Under regular driving and use, your
                vehicle’s alternator charges your battery while driving. If your
                car sits undriven for an extended time, it hurts your battery.
                Around the two-week mark, a car battery can go completely flat,
                causing you to be unable to start your vehicle, and you may have
                to replace your car’s battery entirely.
              </span>
            </p>
            <p>
              <span style={{fontWeight: 400}}>
                For both weather situations, keep a{" "}
              </span>
              <Link to="/noco-gb-70-battery-jump-start-pack-1">
                <span style={{fontWeight: 400}}>
                  battery jump start pack
                </span>
              </Link>
              <span style={{fontWeight: 400}}>
                {" "}
                on hand to get your Ford vehicle’s battery ready after not being
                in use or enduring a heavy winter. This will help ensure your
                vehicle’s battery is always prepared for your next adventure!
              </span>
            </p>
            <p>
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/WaysToWeatherproof/Weatherproof_FloorLiner.jpeg"
                alt="Weather Proof Floor Liner"  width="1480" height="100%"
              />
            </p>
            <h2>
              <span style={{fontWeight: 400}}>
                Utilize All-Weather Floor Mats&nbsp;
              </span>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                While it is obvious that the exterior of your car is impacted by
                seasonal weather changes, the interior can be affected just as
                much. Luckily, nothing can protect it better from the horrors of
                the seasonal grime that grace (or disgrace!) the bottom of your
                shoes than{" "}
              </span>
              <Link to="/search?searchTerm=all+weather">
                <span style={{fontWeight: 400}}>
                  all-weather floor mats
                </span>
              </Link>
              <span style={{fontWeight: 400}}>
                . In warmer-weather states, everyday grime can stain the carpet
                of your Ford vehicle. In places where there’s less rain or snow,
                dirt and dust can track into your vehicle from your shoes,
                depositing them right into the carpet of your Ford vehicle. For
                the surprise rainfall, monsoon seasons, or states with snow and
                sludge, durable floor mats provide a robust way to trap snow,
                road salt, water, mud and dust, helping protect the original
                carpet. Best of all, Ford all-weather floor mats are engineered
                to fit your vehicle, ensuring the best fit possible.
              </span>
            </p>
            <p>
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/WaysToWeatherproof/VehicleCover.jpeg"
                alt="Vehicle Cover" width="1480" height="100%"
              />
            </p>
            <h2>
              <span style={{fontWeight: 400}}>
                Use a Vehicle Storage Cover
              </span>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                No matter the season, the sun’s UV rays will always beam down on
                us, causing warmth, vitality and irreparable sun damage to the
                exterior of your vehicle. Think of your Ford vehicle’s plastics
                and painted surfaces just like your skin! UV damage year-round,
                no matter your location, can cause dark plastics and shiny paint
                to fade and discolor. Even worse, plastic components both inside
                and outside your vehicle can become brittle in the sun, causing
                them to weaken and even break after everyday use. To best
                protect your Ford from the unrelenting sun, consider a{" "}
              </span>
              <Link to="/search?searchTerm=full+cover">
                <span style={{fontWeight: 400}}>
                  full vehicle storage cover
                </span>
              </Link>
              <span style={{fontWeight: 400}}>
                ! Just like our last tip, getting your storage cover from Ford
                ensures the best fit possible for your vehicle, increasing its
                protection, value, and undeniable style.
              </span>
            </p>
            <p>
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/WaysToWeatherproof/23_FRD_ESP_46633.jpeg"
                alt="Windshield Wipers" width="4472" height="100%"
              />
            </p>
            <h2>
              <span style={{fontWeight: 400}}>
                Keep an Eye on Your Windshield Wipers
              </span>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                Lastly, when discussing the effects of the sun’s rays on your
                vehicle, it’s important to remember that your vehicle’s
                windshield wipers are also susceptible to wear-and-tear and sun
                damage. If you are not protecting your vehicle with a cover, be
                sure to check on your windshield wipers for cracks or tears
                after the summer months. A quick check can keep you ready to go
                as the rain pours, helping ensure a clear field of vision when
                driving in the rain. If you’re unsure of what signs to look for
                when looking for damage, you can always ask a helpful Ford
                advisor at your local dealer!
              </span>
            </p>
            <p>
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/WaysToWeatherproof/C11023622_MACHE_Prem_34FrntPassMotn3Environments_mj.jpeg"
                alt="MACHE Prem_34 Frnt Pass Motn Environments" width="5000" height="100%"
              />
            </p>
            <h2>
              <span style={{fontWeight: 400}}>
                Prepare For the Elements
              </span>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                Inspired to get a head start on weatherproofing your Ford?
                Prepare yourself thoroughly for any season and be ready for
                whatever nature brings by using the power and safety of Ford
                vehicles’ trusted accessories to combat anything mother nature
                throws at you. Ford has everything you need to protect your
                vehicle regardless of rain or shine. Weatherproof your car today
                with our wide selection of parts and accessories!
                <br />
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/WaysToWeatherproof/Weatherproof_Your_Vehicle_Infographic.jpeg"
                  alt="Ways to Weather Proof Your Vehicle Infographic" width={'1600'} height={'100%'}
                />
                <br />
              </span>
            </p>
          </article>

          <div className="article-share">
            <div
              className="social-icons "
              itemScope
              itemType="https://schema.org/Organization">
              <link itemProp="url" href="https://accessories.ford.com" />

              <h3 className="social-icons__share-title">Share Article</h3>

              <div className="social-icons__list-item">
                <a
                  href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/5-ways-to-weatherproof-your-vehicle&amp;text=5+Ways+To+Weatherproof+Your+Vehicle"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="16"
                    viewBox="0 0 20 16">
                    <g>
                      <g>
                        <path
                          fill="#102b4e"
                          d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Twitter</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/5-ways-to-weatherproof-your-vehicle"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M.29 5.24h3.2v10.764H.29z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">LinkedIn</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/5-ways-to-weatherproof-your-vehicle"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="16"
                    viewBox="0 0 8 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Facebook</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <span
                  className="share"
                  onClick={handleShareClick}
                  data-copy-to-clipboard="https://accessories.ford.com/blogs/news/5-ways-to-weatherproof-your-vehicle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Share URL</span>
                </span>
              </div>
            </div>
          </div>

          <div className="article-pagination article-pagination--desktop">
            <Link
              className="article-pagination__item article-pagination__item--previous"
              to="/blogs/news/custom-lincoln-aviator-anguss-build" onClick={() => window.scrollTo(0, 0)}>
              <div className="article-pagination__arrow">
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/chevron-thin-white-right_60x.jpeg"
                  alt="Chevron White" width={'32'} height={'62'} 
                />
              </div>
              <div className="article-pagination__data">
                <div className="article-pagination__label">
                  Previous Article
                </div>
                <div className="article-pagination__title">
                  Custom Lincoln Aviator - Angus's Build
                </div>
              </div>
            </Link>

            <Link
              className="article-pagination__item article-pagination__item--next"
              to="/blogs/news/taking-care-of-your-vehicle-all-year-long" onClick={() => window.scrollTo(0, 0)}>
              <div className="article-pagination__data">
                <div className="article-pagination__label">Next Article</div>
                <div className="article-pagination__title">
                  Taking Care of Your Vehicle All Year Long
                </div>
              </div>
              <div className="article-pagination__arrow">
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/chevron-thin-white-right_60x.jpeg"
                  alt="Chevron White" width={'32'} height={'62'} 
                />
              </div>
            </Link>
          </div>

          <div className="article-pagination article-pagination--mobile">
            <Link
              className="article-pagination__item article-pagination__item--previous"
              to="/blogs/news/custom-lincoln-aviator-anguss-build" onClick={() => window.scrollTo(0, 0)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="15"
                viewBox="0 0 8 15">
                <g>
                  <g transform="rotate(-270 4 7.5)">
                    <path
                      fill="#fff"
                      d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z"></path>
                  </g>
                </g>
              </svg>
              Prev Article
            </Link>

            <Link
              className="article-pagination__item article-pagination__item--next"
              to="/blogs/news/taking-care-of-your-vehicle-all-year-long" onClick={() => window.scrollTo(0, 0)}>
              Next Article
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="15"
                viewBox="0 0 8 15">
                <g>
                  <g transform="rotate(-270 4 7.5)">
                    <path
                      fill="#fff"
                      d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z"></path>
                  </g>
                </g>
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaysToWeatherProofYourVehicle;
