/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Axios, { Canceler } from "axios";
import { Divider } from "@material-ui/core";
import { useSelector } from "react-redux";
import getDisplayName from "react-display-name";
//Foundation libraries
import { useSite } from "../../../_foundation/hooks/useSite";
//Custom libraries
import { ChangePassword } from "../../widgets/change-password";
//Redux
import * as userAction from "../../../redux/actions/user";
import { addressDetailsSelector } from "../../../redux/selectors/account";
import { forUserIdSelector, loginStatusSelector } from "../../../redux/selectors/user";
import { GET_ADDRESS_DETAIL_ACTION } from "../../../redux/actions/account";
import MyAccount from "../../../components/MyAccount/MyAccount";
//UI
import {
  ADDRESS_BOOK,
} from "../../../constants/routes";
import {
  StyledLink,
  StyledGrid,
  StyledPaper,
  StyledTypography,
  StyledTooltip,
} from "@hcl-commerce-store-sdk/react-component";
import rewardsService from "../../../_foundation/apis/rewards/rewards.service";
import axios from "axios";
import eSpotService from "../../../_foundation/apis/transaction/eSpot.service";
import { EMPTY_STRING } from "../../../constants/common";
import HTMLReactParser from "html-react-parser";

function PersonalInformationLayout() {
  const isUserLoggedIn = useSelector(loginStatusSelector);
  const widgetName = getDisplayName(PersonalInformationLayout);
  const addressDetails = useSelector(addressDetailsSelector);
  const forUserId = useSelector(forUserIdSelector);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const AccountInformation = t("PersonalInformation.AccountInformation");
  const Edit = t("PersonalInformation.Edit");
  const Password = t("PersonalInformation.Password");
  const SignOut = t("PersonalInformation.SignOutButton");
  const [myAccountRefs, setMyAccountRefs] = useState<any>();
  const [contactInfo, setContactInfo] = useState<any | []>([]);
  const [fordRewardPoints, setfordRewardPoints] = useState("");
  const [fordRewardMessageCondition, setfordRewardMessageCondition] = useState(false);
  const [fordRewardMessage, setfordRewardMessage] = useState("");
  const [ showFordRewardmessage,setShowFordRewardMessage] = useState(false);
  const loginStatus = useSelector(loginStatusSelector);
  const { mySite } = useSite();
  const storeId = mySite.storeID;
  const RewardsV2API = `${mySite.storeCfg.userData["REWARDS_V2"]}`;
  const storeID: string = mySite ? mySite.storeID : EMPTY_STRING;
  const catalogID: string = mySite ? mySite.catalogID : EMPTY_STRING;
  const [fordLincolnRewardMessage, setFordLincolnRewardMessage] = useState("");
  const [ showFordLincolnRewardMessage,setShowFordLincolnRewardMessage] = useState(false);
  const [fordLincolnRewardMessageCondition, setfordLincolnRewardMessageCondition] = useState(false);
  const isFPRConsentEnabled = mySite.storeCfg.userData["FPR_Consent_Feature"] === 'true' ? true : false; 
  const [userData, setUserData]:any = useState([]);
  const CancelToken = Axios.CancelToken;
  let cancels: Canceler[] = [];

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  
  const logOutUser = (props: any) => {
    let payload = {
      ...payloadBase,
    };
    dispatch(userAction.LOGOUT_REQUESTED_ACTION(payload));
  };

  useEffect(() => {
    if (isUserLoggedIn) { getMyrewards(); }
  }, [isUserLoggedIn]);

  const getMyrewards = async () => {
    await rewardsService.getRewards()
      .then((res) => {
        const response = res?.data;
        if (RewardsV2API === 'true') {
          setfordRewardPoints(response?.ford?.points);
          if(isFPRConsentEnabled) {
            if(response?.ford?.consentAccepted===false && response?.lincoln?.consentAccepted===true && response?.lincoln?.points !==0){
              setfordRewardMessageCondition(true);
              
            }
            if(response?.ford?.consentAccepted===false && response?.lincoln?.consentAccepted===true && response?.lincoln?.points ===0){
              setfordRewardMessageCondition(true);
              
            }
           if(response?.ford?.consentAccepted===false && response?.lincoln?.consentAccepted===false){
              setfordRewardMessageCondition(false);
              setfordLincolnRewardMessageCondition(false);
              
           }
            if(response?.ford?.points===0 && response?.lincoln?.consentAccepted===true && response?.lincoln?.points !== 0){
              setfordRewardMessageCondition(true);
              
            }
            if(response?.ford?.points===0 && response?.lincoln?.consentAccepted===true && response?.lincoln?.points === 0){
              setfordRewardMessageCondition(false);
              
            }
            if(response?.lincoln?.points !== 0 && response?.ford?.points !==0 && response?.ford?.consentAccepted===true && response?.lincoln?.consentAccepted===true){
             setfordLincolnRewardMessageCondition(false);
            }
          }
          
        } else {
          setfordRewardPoints(response?.pointsTotals?.F?.points);
        } 
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  useEffect(() => {
    if (mySite) {
      let payload = {
        ...payloadBase,
      };
      getAdd();
      async function getAdd() {
        await getAllAddress();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mySite]);

  useEffect(() => {
    const response: any = addressDetails &&addressDetails.contactList&& addressDetails.contactList.filter(item => {
      if (item?.addressType == 'ShippingAndBilling') {
        return item;
      }
    }

    );
    setContactInfo(response);

  }, [addressDetails]);

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const initMyAccountFPREspot = () => {
    const parameters: any = {
      storeId: storeID,
      name: "MyAccount_FPR_ConsentNotAccepted_Espot",
      catalogId: catalogID,
      widget: "e-marketing-spot-widget",
    };
    eSpotService
      .findByName(parameters)
      .then((res) => {
        const eSpotRoot = res.data.MarketingSpotData[0];
        setfordRewardMessage(eSpotRoot.baseMarketingSpotActivityData[0].marketingContentDescription[0].marketingText)
        setShowFordRewardMessage(true);
      }) 
      .catch((e) => {
        //console.log(e);
      });
  };


  useEffect(() => {
    if(isFPRConsentEnabled) { 
      initMyAccountFPREspot(); 
    }
  }, []);

 
  const initMarketingEspotFPRBothPoints = () => {
    const parameters: any = {
      storeId: storeID,
      name: "MyAccount_FPR_LAR_BothPoints_Espot",
      catalogId: catalogID,
      widget: "e-marketing-spot-widget",
    };
    eSpotService
      .findByName(parameters)
      .then((res) => {
        const eSpotRoot = res.data.MarketingSpotData[0];
        setFordLincolnRewardMessage(eSpotRoot.baseMarketingSpotActivityData[0].marketingContentDescription[0].marketingText)
        setShowFordLincolnRewardMessage(true);
      }) 
      .catch((e) => {
        //console.log(e);
      });
  };
  useEffect(() => {
    if(isFPRConsentEnabled) { initMarketingEspotFPRBothPoints(); }
  }, []);

  const getAllAddress = async () => {
    try {

      const res = await axios.get(
        `/wcs/resources/store/${storeId}/person/@self`,
        {
          headers: {
            'content-type': 'application/json',
          }
        }
      )
      const resp2: any = res&&res.data&&res.data.contact&& res.data.contact.filter(item => {
        if (item?.addressType == 'ShippingAndBilling') {
          return item;
        }
      }
      );
      setUserData({firstName: res.data.firstName, email1: res.data.logonId});
      setContactInfo(resp2)

    }
    catch (e) {
      console.log("Error--", e)
    }

  }

  return (
    <><MyAccount />
      {/* <StyledPaper className="vertical-padding-2 horizontal-padding-2"> */}
      {loginStatus &&
        <>
          <StyledGrid
            container
            justifyContent="space-between"
            alignItems="center"
            className="bottom-padding-2">
            <StyledGrid item>

              <h2 className="account-subheading title--sm">Account Details</h2>

              {/* <StyledTypography className="account-subheading title"> */}

              {/* {addressDetails?.firstName} {addressDetails?.lastName} */}
              {/* {'Account Details'} */}
              {/* </StyledTypography> */}
            </StyledGrid>
            {/* <StyledGrid item>
          {!forUserId && (
            <StyledButton
              size="small"
              fullWidth
              color="primary"
              onClick={logOutUser}>
              {SignOut}
            </StyledButton>
          )}
        </StyledGrid> */}
          </StyledGrid>

          <StyledGrid container spacing={2}>
            <StyledGrid item xs={12} sm={6} md={6}>
              <StyledTypography variant="overline"
                className="account-subheading title"
              // style={{fontSize:'2rem'}}
              >
                {'Account Information'}
              </StyledTypography>
              <StyledTypography
                // variant="body1"

                className="bottom-margin-1"
                component="div">
                 {userData && (<><StyledTypography
                        style={{ fontSize: '1.5rem', Color: '#4d4d4d' }}
                        component="div" >
                         {userData?.firstName}
                      </StyledTypography>
                      <StyledTypography
                      style={{ fontSize: '1.5rem', Color: '#4d4d4d' }}
                      component="div">
                       {userData?.email1}
                    </StyledTypography></>
                    )
                } 
              </StyledTypography>
              <StyledTypography style={{ marginTop: '1.5rem', fontSize: '1.5rem', color: '#4d4d4d' }}>FordPass Rewards Points balance: {fordRewardPoints}</StyledTypography>
              {(isFPRConsentEnabled && fordRewardMessageCondition) && <StyledTypography className="fordreward-message">{HTMLReactParser(fordRewardMessage)}  </StyledTypography>}
              {(isFPRConsentEnabled && fordLincolnRewardMessageCondition) && 
              <StyledTypography className="fordreward-message">
                {HTMLReactParser(fordLincolnRewardMessage)}  
                </StyledTypography>}

            </StyledGrid>
            <StyledGrid item xs={12} sm={6} md={6}>
              <StyledTypography variant="overline" className="account-subheading title"
              // style={{fontSize:'2rem'}}
              >{'Default Address'}</StyledTypography>
              <StyledTypography
                variant="body1"
                className="bottom-margin-1"
                component="div">


                {contactInfo && contactInfo.map(
                  (item: any, index: number) =>

                    item.primary == "true" && (
                      <>
                        <StyledTypography style={{ fontSize: '1.5rem', Color: '#4d4d4d' }} component="div" key={item}>
                          {item.firstName}
                        </StyledTypography>
                        <StyledTypography style={{ fontSize: '1.5rem', Color: '#4d4d4d' }} component="div" key={item}>
                          {item.addressLine}
                        </StyledTypography>
                        <StyledTypography style={{ fontSize: '1.5rem', Color: '#4d4d4d' }} component="div" key={item}>
                          {item.city}
                        </StyledTypography>
                        <StyledTypography style={{ fontSize: '1.5rem', Color: '#4d4d4d' }} component="div" key={item}>
                          {item.Zipcode}
                        </StyledTypography>
                        <StyledTypography style={{ fontSize: '1.5rem', Color: '#4d4d4d' }} component="div" key={item}>
                          {item.country}
                        </StyledTypography></>
                    )
                )}
              </StyledTypography>
              {/* <ChangePassword /> */}
            </StyledGrid>
            <StyledGrid item xs={12} sm={6} md={6} style={{ paddingTop: '0px' }}>
            </StyledGrid>
            <StyledGrid item xs={12} sm={6} md={6} style={{ paddingTop: '0px' }}>
              <StyledTooltip
                title={t("PersonalInformation.disabledMessage")}
                placement="bottom-start">
                <div>
                  <StyledLink size="small" color="primary" style={isFPRConsentEnabled ? {marginTop:'initial'} : {}} to={ADDRESS_BOOK} className={`account-dashboard__addresses-cta btn btn--rounded btn--border btn--chevron ${isFPRConsentEnabled && 'myaccount-buton'}`}>
                    {'Add/Edit Address'}
                  </StyledLink>
                </div>
              </StyledTooltip>
            </StyledGrid>
          </StyledGrid>
        </>
      }
      {/* </StyledPaper> */}
    </>);
}

export { PersonalInformationLayout };