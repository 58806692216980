import React from 'react';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import jsonData from "../staticPagesMetaData.json";
import { useSite } from "../../../../_foundation/hooks/useSite";
import { Helmet } from "react-helmet";

const content =jsonData.contents[15]?.BroncoSport;
const handleShareClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    const url = event.currentTarget.dataset.copyToClipboard!;
    navigator.clipboard.writeText(url).then(() => {
        const confirmation = window.prompt("Copy to clipboard: Ctrl/Cmd+C, Enter", url);
        if (confirmation) {
            navigator.clipboard.writeText(url);
        }
    }).catch(() => {
        window.alert(`Failed to copy link to clipboard: ${url}`);
    });
};

const breadcrumbList = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
        {
            "@type": "ListItem",
            "position": 1,
            "name": "Home",
            "item": "https://accessories.ford.com/"
        },
        {
            "@type": "ListItem",
            "position": 2,
            "name": "News",
            "item": "https://accessories.ford.com/blogs/news"
        },
        {
            "@type": "ListItem",
            "position": 3,
            "name": "Bronco vs. Bronco Sport",
            "item": "https://accessories.ford.com/blogs/news/bronco-vs-bronco-sport"
        }
    ]
}


const BroncoSport = () => {

    const {storeDisplayName } = useSite();
    let port = !window.location.port.trim() ? '' : ':' + window.location.port;

    return (
        <div>
            <Helmet>
                <title>{content?.title}</title>
                <meta name="description" content={content?.metaDescription}></meta>
                <meta name="keywords" content={content?.metaKeyword}/>
                <meta property="og:site_name" content={storeDisplayName}/>
                <meta property="og:url" content={(window.location.protocol +'//'+window.location.hostname+':' + port + window.location.pathname)}/>
                <meta property="og:title" content={content?.title}/>
                <meta property="og:type" content="article"/>  
                <meta property="og:description" content={content?.metaDescription}/>
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={content?.title}/>  
                <meta name="twitter:description" content={content?.metaDescription} />
            </Helmet>
            <div id="shopify-section-template-article" className="shopify-section">




                <section className="article-hero" title='Blog Hero' style={{ backgroundImage: "url(https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/Bronco_vs_BroncoSport/BroncoVBroncoSport_RS_1500x.jpeg)" }}>
                    <div className="article-hero__container container">






                        <h1 className="article-hero__heading title--md">
                            Bronco vs. Bronco Sport
                        </h1>




                        <div className="article-hero__publish-date">
                            March 14, 2023
                        </div>

                    </div>
                </section>



                <div className="article__main">




                    <nav className="breadcrumbs" role="navigation">
                        <ul className="flex">
                            <li>
                            <a href="/">
              <span>Home</span>
              </a>
                <span className="breadcrumbs-separator">&gt;</span>
              </li>
              <li>
                <Link to="/blogs/news">
                  <span>News</span>
                </Link>
                            </li>
                            <span className="breadcrumbs-separator">&gt;</span>
                            <li>
                                <span>Bronco vs. Bronco Sport</span>
                            </li></ul>
                    </nav>

                    <script type="application/ld+json">
                        {JSON.stringify(breadcrumbList)}
                    </script>

                    <div className="article-actions">


                        <Link className="article-actions__return" to="/blogs/news">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><path fill="#102b4e" d="M8,0 C12.418,0 16,3.582 16,8 C16,12.418 12.418,16 8,16 C3.582,16 0,12.418 0,8 C0,3.582 3.582,0 8,0 Z M6,3 C5.724,3 5.474,3.112 5.293,3.293 C5.112,3.474 5,3.724 5,4 C5,4.276 5.112,4.526 5.293,4.707 L9.409,8 L5.293,11.293 C5.112,11.474 5,11.724 5,12 C5,12.276 5.112,12.526 5.293,12.707 C5.474,12.888 5.724,13 6,13 C6.276,13 6.526,12.888 6.707,12.707 L11.707,8.707 C11.888,8.526 12,8.276 12,8 C12,7.724 11.888,7.474 11.707,7.293 L6.707,3.293 C6.526,3.112 6.276,3 6,3 Z"></path></g></svg>
                            Back to articles
                        </Link>




                        <div className="social-icons " itemScope itemType="https://schema.org/Organization">
                            <link itemProp="url" href="https://accessories.ford.com" />



                            <h3 className="social-icons__share-title">
                                Share
                            </h3>


                            <div className="social-icons__list-item">
                                <a href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/bronco-vs-bronco-sport&amp;text=Bronco+vs.+Bronco+Sport" target="_blank" rel="noopener" className="share">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16"><g><g><path fill="#102b4e" d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path></g></g></svg>
                                    <span className="visually-hidden">
                                        Twitter
                                    </span>
                                </a>
                            </div>
                            <div className="social-icons__list-item">
                                <a href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/bronco-vs-bronco-sport" target="_blank" rel="noopener" className="share">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><g><g><path fill="#102b4e" d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path></g><g><path fill="#102b4e" d="M.29 5.24h3.2v10.764H.29z"></path></g><g><path fill="#102b4e" d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path></g></g></g></svg>
                                    <span className="visually-hidden">
                                        LinkedIn
                                    </span>
                                </a>
                            </div>
                            <div className="social-icons__list-item">
                                <a href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/bronco-vs-bronco-sport" target="_blank" rel="noopener" className="share">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16"><g><g><g><path fill="#102b4e" d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path></g></g></g></svg>
                                    <span className="visually-hidden">
                                        Facebook
                                    </span>
                                </a>
                            </div>
                            <div className="social-icons__list-item">
                                <button className="share" onClick={handleShareClick} data-copy-to-clipboard="https://accessories.ford.com/blogs/news/bronco-vs-bronco-sport">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><g><path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path></g></g></svg>
                                    <span className="visually-hidden">
                                        Share URL
                                    </span>
                                </button>
                            </div>


                        </div>

                    </div>

                    <article className="article-content rte">
                        <p><span style={{ fontWeight: 400 }}>Although they come from the same family, the Bronco and Bronco Sport SUV’s have many differences. From size to capability to the driving experience, both vehicles dance to their own beat! To determine which vehicle is best suited for your interests and lifestyle, keep reading for the main key differences between the Ford Bronco® and Ford Bronco Sport.&nbsp;</span></p>
                        <p><span style={{ fontWeight: 400 }}><img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/Bronco_vs_BroncoSport/SizeVariation.jpeg" alt="Size variation"  width={'4472'} height={'100%'}/></span></p>
                        <p>&nbsp;</p>
                        <h2><span style={{ fontWeight: 400 }}>Size Variation&nbsp;</span></h2>
                        <p><span style={{ fontWeight: 400 }}>The size of your vehicle is crucial for garage space and parking limitations, so it’s important to recognize the size difference between the Bronco and Bronco Sport. The Bronco Sport could be described as a sub-compact SUV with a length of just over 14 feet – </span><span style={{ fontWeight: 400 }}>172.7 inches from bumper to bumper. The two-door Bronco shares similar measurements, with a length of 173.7 inches. On the other hand, the 4-door Bronco is a</span><span style={{ fontWeight: 400 }}> significantly larger vehicle, measuring </span><span style={{ fontWeight: 400 }}>189.4 inches in length. This closely resembles the length of a traditional midsize vehicle.&nbsp;</span></p>
                        <br />
                        <p><span style={{ fontWeight: 400 }}>As far as cargo capacity goes, the 4-door Bronco rules the roost for all of your storage needs. Next up is the Bronco Sport, surprisingly offering more space than the larger, 2-door Bronco. While the 2-door Bronco has a cargo capacity of 52.3 cubic feet with the seats down, the Bronco Sport offers an available 65.2 cubic feet* with the seats down! The Bronco Sport also offers handy storage cubbies, like door pockets and extra storage consoles for your everyday driving needs.&nbsp;&nbsp;</span></p>
                        <br />
                        <p><span style={{ fontWeight: 400 }}>What about towing capacity? Both the 2-door and 4-door Bronco vehicles are rated to tow 3,500 lbs. and the Bronco Raptor is rated to tow 4,500 lbs., meaning you’ll have a backup for every adventure you undertake. The Bronco Sport is also capable of towing a significant amount of weight when properly equipped**, especially the 2.0L Badlands and Heritage Limited trims when equipped with the available Class II Trailer Tow Package. All in all, each Bronco model is ready to support your rugged journeys.&nbsp;</span></p>
                        <br />
                        <p><i><span style={{ fontWeight: 400 }}>*Cargo capacity of the 1.5L EcoBoost models</span></i></p>
                        <p><i><span style={{ fontWeight: 400 }}>**When properly equipped. Max towing varies based on cargo, vehicle configuration, accessories and number of passengers.</span></i><img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/Bronco_vs_BroncoSport/OffRoading.jpeg" alt="Off-Roading" width={'4472'} height={'100%'}/><i><span style={{ fontWeight: 400 }}>Shown with optional equipment and available Ford accessories</span></i></p>
                        <p>&nbsp;</p>
                        <h2><span style={{ fontWeight: 400 }}>Off-Roading Capabilities</span></h2>
                        <p><span style={{ fontWeight: 400 }}>When it comes to rocky terrain, the Bronco is our off-roading winner. Not only does the Bronco have significant ground clearance, but the automatic transmission models are also equipped with </span><span style={{ fontWeight: 400 }}>Advanced 4x4 with Automatic On-Demand Engagement, Rock Crawl mode and Trail Control™ for off-roading. If you opt for the manual transmission, you can score a Hill Descent Control™ and a granny gear to help navigate rocky areas. Although the Bronco Sport is a more sleekly designed, city vehicle, it is also fit for off-roading, specifically with the Badlands and Heritage Limited being the best Bronco Sport trim levels for the job.&nbsp;</span></p>
                        <p><span style={{ fontWeight: 400 }}><img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/Bronco_vs_BroncoSport/CustomizationOptions.jpeg" alt="Customization Option" width={'1756'} height={'100%'} /><br /><i><span style={{ fontWeight: 400 }}>Optional equipment and available Ford accessories shown</span></i></span></p>
                        <p>&nbsp;</p>
                        <h2><span style={{ fontWeight: 400 }}>Customization Options</span></h2>
                        <p><span style={{ fontWeight: 400 }}>If you didn’t think the Bronco or Bronco Sport could get any better, think again. To elevate either model to its peak performance and to best serve your individual needs, customization and add-ons are a must. Fortunately, the options are endless for personalization. To start with off-roading upgrades, you can turn your ride into even more of a beast on the trails. A few of our favorite options for the 4-door and 2-door Bronco vehicles are </span><Link to="/bronco-2021-aeroskin-ii-black-textured-trail-armor-hood-deflector"><span style={{ fontWeight: 400 }}>hood deflectors</span></Link><span style={{ fontWeight: 400 }}>, </span><Link to="/bronco-2021-front-corners-exterior-trim-trail-armor-1"><span style={{ fontWeight: 400 }}>trail armor</span></Link><span style={{ fontWeight: 400 }}>, and </span><Link to="/bronco-sport-2021-aeroskin-ii-textured-hood-deflector"><span style={{ fontWeight: 400 }}>hood deflectors</span></Link><span style={{ fontWeight: 400 }}> and </span><Link to="/search?searchTerm=bronco+sport+protector"><span style={{ fontWeight: 400 }}>protective upgrades</span></Link><span style={{ fontWeight: 400 }}> of its own.</span></p>
                        <p><span style={{ fontWeight: 400 }}>For camping enthusiasts, both vehicles deliver when it comes to accessories tailored to the great outdoors. Both the Ford Bronco and Bronco Sport offer fantastic camping add-ons, such as </span><Link to="/yakima-roof-top-2-person-hd-tent-1"><span style={{ fontWeight: 400 }}>rooftop tents</span></Link><span style={{ fontWeight: 400 }}>, </span><Link to="/yakima-rack-mounted-lockable-cargo-box-1"><span style={{ fontWeight: 400 }}>cargo boxes</span></Link><span style={{ fontWeight: 400 }}>, and </span><Link to="/search?searchTerm=bronco+roof+rack"><span style={{ fontWeight: 400 }}>roof racks</span></Link><span style={{ fontWeight: 400 }}>. When you add even more functionality to your vehicle, your trip can be packed with both excitement and convenience.&nbsp;</span></p>
                        <p><span style={{ fontWeight: 400 }}>To top your vehicle off, reign in the aesthetics! There are endless add-ons to elevate the interior and exterior style of your Bronco. Some of our favorites are </span><Link to="/search?searchTerm=bronco+fender+flares"><span style={{ fontWeight: 400 }}>fender flares</span></Link><span style={{ fontWeight: 400 }}>, </span><Link to="/search?searchTerm=bronco+sound+bar"><span style={{ fontWeight: 400 }}>sound bars</span></Link><span style={{ fontWeight: 400 }}>, </span><Link to="/search?searchTerm=bronco+door+sill+plates"><span style={{ fontWeight: 400 }}>door sill plates</span></Link><span style={{ fontWeight: 400 }}>. Since Ford Bronco accessories are engineered for each specific model, make sure the accessories you decide on are designed to fit your Bronco Sport, 4-Door Bronco, or 2-Door Bronco!</span></p>
                        <p><span style={{ fontWeight: 400 }}><img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/Bronco_vs_BroncoSport/DrivingExperience.jpeg" alt="Driving experience"  width={'4472'} height={'100%'}/><br /><i><span style={{ fontWeight: 400 }}>Vehicle shown with optional equipment and available Ford Accessories. Extra cost paint option shown</span></i></span></p>
                        <p>&nbsp;</p>
                        <h2><span style={{ fontWeight: 400 }}>Driving Experience</span></h2>
                        <p><span style={{ fontWeight: 400 }}>If you’re wanting a smooth, steady driving experience, then the Bronco Sport is the way to go. With its turbocharged available 2.0-liter engine, the Bronco Sport can offer a sporty, polished ride on your commute. On the other hand, the Ford Bronco provides a more thrilling ride – perfect for off-roaders!</span></p>
                        <p><span style={{ fontWeight: 400 }}><img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/Bronco_vs_BroncoSport/InteriorAmenities.jpeg" alt="Interior Amenities" width={'4472'} height={'100%'} /></span></p>
                        <p>&nbsp;</p>
                        <h2><span style={{ fontWeight: 400 }}>Interior Amenities</span></h2>
                        <p><span style={{ fontWeight: 400 }}>Both the Bronco and Bronco Sport are tech giants when it comes to their interior features. In both models, you can find </span><span style={{ fontWeight: 400 }}>Apple CarPlay® and Android Auto™, touchscreen infotainment displays, rearview backup cameras, and phenomenal stereos. T</span><span style={{ fontWeight: 400 }}>he Bronco also offers off-roading tech features like </span><span style={{ fontWeight: 400 }}>trail one-pedal driving and Trail Turn Assist!</span> <span style={{ fontWeight: 400 }}>Comfort is also heavily prioritized in each vehicle’s interior, featuring available power-adjustable, heated seating. While the Bronco Sport comes with a variety of seats and inserts, Ford Bronco upholstery ranges from cloth to marine-grade vinyl to leather-trimmed seating.</span></p>
                        <p><img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/Bronco_vs_BroncoSport/AllTerrainHero.jpeg" alt="All-terrain hero" width={'2092'} height={'100%'}/></p>
                        <p>&nbsp;</p>
                        <h2><span style={{ fontWeight: 400 }}>Unleash Your All-Terrain Hero</span></h2>
                        <span style={{ fontWeight: 400 }}>Who’s ready to get their Bronco on the road? No matter which Bronco model you decide to make your own, both the Bronco and Bronco Sport will make sure every journey is an adventure. If you’re interested in making your Ford Bronco or Ford Bronco Sport your own, be sure to check out the wide variety of available </span><a href="/"><span style={{ fontWeight: 400 }}>Ford accessories</span></a><span style={{ fontWeight: 400 }}> and add-ons engineered for your vehicle. With added personalization and upgrades, you can truly transform your Bronco into your dream car.<br /><img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/Bronco_vs_BroncoSport/Bronco_v._Bronco_Sport_Infographic.jpeg" alt="Bronco vs BroncoSport Infographic" width={'1600'} height={'100%'}/><br /> </span>




                    </article>







                    <div className="article-share">


                        <div className="social-icons " itemScope itemType="https://schema.org/Organization">
                            <link itemProp="url" href="https://accessories.ford.com" />









                            <h3 className="social-icons__share-title">
                                Share Article
                            </h3>


                            <div className="social-icons__list-item">
                                <a href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/bronco-vs-bronco-sport&amp;text=Bronco+vs.+Bronco+Sport" target="_blank" rel="noopener" className="share">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16"><g><g><path fill="#102b4e" d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path></g></g></svg>
                                    <span className="visually-hidden">
                                        Twitter
                                    </span>
                                </a>
                            </div>
                            <div className="social-icons__list-item">
                                <a href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/bronco-vs-bronco-sport" target="_blank" rel="noopener" className="share">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><g><g><path fill="#102b4e" d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path></g><g><path fill="#102b4e" d="M.29 5.24h3.2v10.764H.29z"></path></g><g><path fill="#102b4e" d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path></g></g></g></svg>
                                    <span className="visually-hidden">
                                        LinkedIn
                                    </span>
                                </a>
                            </div>
                            <div className="social-icons__list-item">
                                <a href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/bronco-vs-bronco-sport" target="_blank" rel="noopener" className="share">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16"><g><g><g><path fill="#102b4e" d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path></g></g></g></svg>
                                    <span className="visually-hidden">
                                        Facebook
                                    </span>
                                </a>
                            </div>
                            <div className="social-icons__list-item">
                                <button className="share" onClick={handleShareClick} data-copy-to-clipboard="https://accessories.ford.com/blogs/news/bronco-vs-bronco-sport">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><g><path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path></g></g></svg>
                                    <span className="visually-hidden">
                                        Share URL
                                    </span>
                                </button>
                            </div>


                        </div>

                    </div>





                    <div className="article-pagination article-pagination--desktop">

                        <Link className="article-pagination__item article-pagination__item--previous" to="/blogs/news/winter-car-camping-accessories-for-your-ford"  onClick={() => window.scrollTo(0, 0)}>
                            <div className="article-pagination__arrow">
                                <img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/chevron-thin-white-right_60x.jpeg" alt="Chevron White" width={'32'} height={'62'} />
                            </div>
                            <div className="article-pagination__data">
                                <div className="article-pagination__label">
                                    Previous Article
                                </div>
                                <div className="article-pagination__title">
                                    Winter Car Camping Accessories for Your Ford
                                </div>
                            </div>
                        </Link>


                        <Link className="article-pagination__item article-pagination__item--next" to="/blogs/news/must-have-vehicle-accessories-for-summer"  onClick={() => window.scrollTo(0, 0)}>
                            <div className="article-pagination__data">
                                <div className="article-pagination__label">
                                    Next Article
                                </div>
                                <div className="article-pagination__title">
                                    Must-Have Vehicle Accessories For Summer
                                </div>
                            </div>
                            <div className="article-pagination__arrow">
                                <img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/chevron-thin-white-right_60x.jpeg" alt="Chevron White" width={'32'} height={'62'}/>
                            </div>
                        </Link>

                    </div>


                    <div className="article-pagination article-pagination--mobile">

                        <Link className="article-pagination__item article-pagination__item--previous" to="/blogs/news/winter-car-camping-accessories-for-your-ford"  onClick={() => window.scrollTo(0, 0)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="15" viewBox="0 0 8 15"><g><g transform="rotate(-270 4 7.5)"><path fill="#fff" d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z"></path></g></g></svg>
                            Prev Article
                        </Link>


                        <Link className="article-pagination__item article-pagination__item--next" to="/blogs/news/must-have-vehicle-accessories-for-summer"  onClick={() => window.scrollTo(0, 0)}>
                            Next Article
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="15" viewBox="0 0 8 15"><g><g transform="rotate(-270 4 7.5)"><path fill="#fff" d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z"></path></g></g></svg>
                        </Link>

                    </div>


                </div>





            </div>


        </div>

    );

}

export default BroncoSport;