/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { createAction } from "@reduxjs/toolkit";
//Redux
import { FETCH_MODEL_YEAR_REQUESTED, FETCH_VEHICLES_REQUESTED } from "../action-types/search-now";

const getModelsListAction = createAction<any, string>(
  FETCH_MODEL_YEAR_REQUESTED
);
const getVehiclesAction = createAction<any, string>(
  FETCH_VEHICLES_REQUESTED
);
export { getModelsListAction, getVehiclesAction };
