/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { lazy } from "react";
//Custom libraries
import * as ROUTES from "../constants/routes";
import { RouteConfig } from "react-router-config";
import { PersonalInformationLayout } from "../components/widgets/personal-information/PersonalInformationLayout";
import ContactUs from "../components/pages/static_components/ContactUs";
import SiteMap from "../components/pages/static_components/siteMap";
import WarrantyInformation from "../components/pages/static_components/WarrantyInformation";
import ShippingAndDelivery from "../components/pages/static_components/ShippingAndDelivery";
import Return from "../components/pages/static_components/Return";
import tnc from "../components/pages/static_components/tnc";
import MakeReturn from "../components/pages/static_components/makeReturn";
import OrderReturnLabelSlip from "../components/pages/static_components/orderLabelnSlip";
import { AfterLogin } from "../components/pages/afterLogin/AfterLogin";
import News from "../components/pages/static_components/RoadAhead/News";
import BenefitsOfSplashGuards from "../components/pages/static_components/RoadAhead/BenefitsOfSplashGuards";
import BestFordBroncoAccessories from "../components/pages/static_components/RoadAhead/BestFordBroncoAccessories";
import Winterize from "../components/pages/static_components/RoadAhead/Winterize";
import WinterCarCamp from "../components/pages/static_components/RoadAhead/WinterCarCamp";
import WaysToWeatherProofYourVehicle from "../components/pages/static_components/RoadAhead/WaysToWeatherProofYourVehicle";
import UniqueWaysToUseYourTruckBed from "../components/pages/static_components/RoadAhead/UniqueWaysToUseYourTruckBed";
import Towing from "../components/pages/static_components/RoadAhead/Towing";
import TakingCareOfYourVehicle from "../components/pages/static_components/RoadAhead/TakingCareOfYourVehicle";
import SnowBoard from "../components/pages/static_components/RoadAhead/SnowBoard";
import RoofRack from "../components/pages/static_components/RoadAhead/RoofRack";
import MustHaveVehicleAccessoriesForSummer from "../components/pages/static_components/RoadAhead/MustHaveVehicleAccessoriesForSummer";
import MustHaveFordAccessories from "../components/pages/static_components/RoadAhead/MustHaveFordAccessories";
import MostOfMaverick from "../components/pages/static_components/RoadAhead/MostOfMaverick";
import GuideToBlackedOutVehicleLook from "../components/pages/static_components/RoadAhead/GuideToBlackedOutVehicleLook";
import FamilyRoadTripTips from "../components/pages/static_components/RoadAhead/FamilyRoadTripTips";
import DifferentTypeOfBikeRacks from "../components/pages/static_components/RoadAhead/DifferentTypeOfBikeRacks";
import CarCamping from "../components/pages/static_components/RoadAhead/CarCamping";
import BroncoSport from "../components/pages/static_components/RoadAhead/BroncoSport";
import BestFordRaptorAccessories from "../components/pages/static_components/RoadAhead/BestFordRaptorAccessories";
import BestFordRangerAccessories from "../components/pages/static_components/RoadAhead/BestFordRangerAccessories";
import BestFordMustangAccessories from "../components/pages/static_components/RoadAhead/BestFordMustangAccessories";
import WalkingDead150 from "../components/pages/static_components/RoadAhead/WalkingDeadF150";
import VinnesBuild from "../components/pages/static_components/RoadAhead/VinnesBuild";
import BroncoVinnesBuild from "../components/pages/static_components/RoadAhead/BroncoVinnesBuild";
import AngusBuild from "../components/pages/static_components/RoadAhead/AngusBuild";
import BroncoAngusBuild from "../components/pages/static_components/RoadAhead/BroncoAngusBuild";
import F150VinnesBuild from "../components/pages/static_components/RoadAhead/F150VinnesBuild";
import NotFound from "../components/commerce-layouts/not-found";
import { AfterLogout } from "../components/pages/afterLogin/AfterLogout";



//Common pages
const SEO = lazy(() => import("../_foundation/seo/SEO"));
//const PersonalInformationLayout =lazy(()=>import("../components/widgets/personal-information/PersonalInformationLayout"));
//component
const SignIn = lazy(() => import("../components/pages/sign-in/SignIn"));
const ForgotPassword = lazy(
  () => import("../components/pages/forgot-password/ForgotPassword")
);
const MyGarage = lazy(
  () => import("../components/MyAccount/MyGarage")
);
const AddNewVehicle = lazy(
  () => import("../components/MyAccount/AddVehicle")
);
const AddressBook = lazy(
  () => import("../components/MyAccount/AccountDetails")
);
const EditAddress = lazy(
  () => import("../components/pages/address-book/EditAddress")
);
const AddAddress = lazy(
  () => import("../components/pages/address-book/AddAddress")
);
const SearchResults = lazy(
  () => import("../components/pages/search-results/SearchResults")
);

//checkout
const Shipping = lazy(
  () => import("../components/pages/checkout/shipping/Shipping")
);
const CheckoutRewardsDiscount = lazy(() => import("../components/widgets/checkout-rewards-discount/checkout-rewards-discount"));
const Billing = lazy(() => import("../components/widgets/checkout-payment"));
const AfterPayment = lazy(() => import("../components/widgets/checkout-payment/after-payment"));

const Review = lazy(() => import("../components/widgets/checkout-review"));
const CheckoutProfiles = lazy(
  () => import("../components/pages/checkout-profile/CheckoutProfiles")
);

//Emerald pages
// const Account = lazy(
//   () => import("../components/pages/_emerald/account/Account")
// );

// const Account = lazy(
//   () => import("../components/MyAccount/MyAccount")
// );


//Sapphire pages
const Dashboard = lazy(
  () => import("../components/pages/_sapphire/dashboard/Dashboard")
);

const Account = lazy(
  () => import("../components/MyAccount/DashBoard")
);
// const OrderHistoryPage = lazy(
//   () => import("../components/pages/_sapphire/order/OrderHistoryPage")
// );
const RecurringOrders = lazy(
  () => import("../components/pages/_sapphire/order/RecurringOrdersPage")
);
const OrderDetailsPage = lazy(
  () => import("../components/pages/_sapphire/order/OrderDetailsPage")
);
const OrderHistoryPage = lazy(
  () => import("../components/MyAccount/OrderHistory")
);
const OrderDetails = lazy(
  () => import("../components/MyAccount/OrderDetails")
);
const AccountSummary = lazy(
  () => import("../components/pages/_sapphire/account-summary/AccountSummary")
);
const BuyerUserRegistration = lazy(
  () =>
    import(
      "../components/pages/_sapphire/buyer-user-registration/BuyerUserRegistration"
    )
);
const BuyerOrganizationRegistration = lazy(
  () =>
    import(
      "../components/pages/_sapphire/buyer-organization-registration/BuyerOrganizationRegistration"
    )
);
const AdminTools = lazy(
  () => import("../components/pages/_sapphire/adminTools/AdminTools")
);
const CheckoutProfileCreate = lazy(
  () => import("../components/pages/checkout-profile/CheckoutProfileCreate")
);
const InprogressOrderDetailsPage = lazy(
  () =>
    import("../components/pages/_sapphire/order/inprogress-order-details-page")
);

const InprogressOrders = lazy(
  () => import("../components/pages/_sapphire/order/inprogress-orders")
);

const Faq = lazy(
  () => import("../components/pages/static_components/Faq")
);
//wish List
const WishListView = lazy(
  () => import("../components/pages/wish-list/wishlist-view")
);

const Tailgating = lazy(
  () => import("../components/pages/static_components/RoadAhead/TailgatingEssentials")
)
const BestTruck = lazy(
  () => import("../components/pages/static_components/RoadAhead/BestTruckBedAccessories")
)
const OffRoad = lazy(
  () => import("../components/pages/static_components/RoadAhead/OffRoadAdventures")
)
const OverLanding = lazy(
  () => import("../components/pages/static_components/RoadAhead/OverLanding")
)
const BestOverLanding = lazy(
  () => import("../components/pages/static_components/RoadAhead/BestOverLanding")
)
const LearnMoreRewards = lazy(
  () => import("../components/pages/static_components/LearnMoreRewards")
);
const CheckoutRouteConfig: RouteConfig[] = [
  //New routes config
  {
    key: ROUTES.CHECKOUT_INFORMATION,
    path: ROUTES.CHECKOUT_INFORMATION,
    component: Shipping,
  },
  {
    key: ROUTES.CHECKOUT_SHIPPING,
    path: ROUTES.CHECKOUT_SHIPPING,
    component: Shipping,
  }, {
    key: ROUTES.CHECKOUT_REWARDS,
    path: ROUTES.CHECKOUT_REWARDS,
    component: CheckoutRewardsDiscount,
  },
  {
    key: ROUTES.CHECKOUT_PAYMENT,
    path: ROUTES.CHECKOUT_PAYMENT,
    component: Billing,
  },
  {
    key: ROUTES.CHECKOUT_REVIEW,
    path: ROUTES.CHECKOUT_REVIEW,
    component: Review,
  },
  {
    key: ROUTES.CHECKOUT_AFTER_PAYMENT,
    path: ROUTES.CHECKOUT_AFTER_PAYMENT,
    component: AfterPayment,
  },
];

const B2BRouteConfig: RouteConfig[] = [
  {
    key: ROUTES.DASHBOARD,
    path: ROUTES.DASHBOARD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: Dashboard,
  },
  {
    key: ROUTES.PERSONAL_INFORMATION,
    path: ROUTES.PERSONAL_INFORMATION,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: AccountSummary,
  },

  {
    key: ROUTES.SIGNIN,
    path: ROUTES.SIGNIN,
    exact: true,
    isProtected: ROUTES.GENERIC_PROTECTED,
    component: SignIn,
  },
  {
    key: ROUTES.FORGOT_PASSWORD,
    path: ROUTES.FORGOT_PASSWORD,
    exact: true,
    isProtected: ROUTES.GENERIC_PROTECTED,
    component: ForgotPassword,
  },
  {
    key: ROUTES.ORG_REGISTRATION,
    path: ROUTES.ORG_REGISTRATION,
    exact: true,
    isProtected: ROUTES.GENERIC_PROTECTED,
    component: BuyerOrganizationRegistration,
  },
  {
    key: ROUTES.BUYER_REGISTRATION,
    path: ROUTES.BUYER_REGISTRATION,
    exact: true,
    isProtected: ROUTES.GENERIC_PROTECTED,
    component: BuyerUserRegistration,
  },
  {
    key: ROUTES.CHECKOUT_PROFILE_CREATE,
    path: ROUTES.CHECKOUT_PROFILE_CREATE,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: CheckoutProfileCreate,
  },
  {
    key: ROUTES.CHECKOUT_PROFILE_EDIT,
    path: ROUTES.CHECKOUT_PROFILE_EDIT,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: CheckoutProfileCreate,
  },
  {
    key: ROUTES.CHECKOUT_PROFILES,
    path: ROUTES.CHECKOUT_PROFILES,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: CheckoutProfiles,
  },
  {
    key: ROUTES.SEARCH,
    path: ROUTES.SEARCH,
    exact: true,
    component: SearchResults,
  },
  {
    key: ROUTES.ORDER_HISTORY,
    path: ROUTES.ORDER_HISTORY,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: OrderHistoryPage,
  },
  {
    key: ROUTES.RECURRING_ORDERS,
    path: ROUTES.RECURRING_ORDERS,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: RecurringOrders,
  },
  {
    key: ROUTES.ORDER_DETAILS,
    path: ROUTES.ORDER_DETAILS_ROUTE,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: OrderDetailsPage,
  },
  {
    key: ROUTES.ADDRESS_BOOK,
    path: ROUTES.ADDRESS_BOOK,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: AddressBook,
  },
  {
    key: ROUTES.EDIT_ADDRESS,
    path: ROUTES.EDIT_ADDRESS_ROUTE,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: EditAddress,
  },
  {
    key: ROUTES.ADD_ADDRESS,
    path: ROUTES.ADD_ADDRESS,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: AddAddress,
  },
  {
    key: ROUTES.ORGANIZATION_MANAGEMENT,
    path: ROUTES.ORGANIZATION_MANAGEMENT,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: AdminTools,
  },
  {
    key: ROUTES.BUYER_MANAGEMENT,
    path: ROUTES.BUYER_MANAGEMENT,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: AdminTools,
  },
  {
    key: ROUTES.APPROVALS_MANAGEMENT,
    path: ROUTES.APPROVALS_MANAGEMENT,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: AdminTools,
  },
  {
    key: ROUTES.ORDER_APPROVAL,
    path: ROUTES.ORDER_APPROVAL,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: AdminTools,
  },
  {
    key: ROUTES.IP_ORDER_DETAILS,
    path: ROUTES.IP_ORDER_DETAILS_ROUTE,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: InprogressOrderDetailsPage,
  },
  {
    key: ROUTES.INPROGRESS_ORDERS,
    path: ROUTES.INPROGRESS_ORDERS,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: InprogressOrders,
  },


  {
    key: "other",
    path: "/*",
    exact: true,
    component: SEO,
  },
];
const B2CRouteConfig: RouteConfig[] = [
  {
    key: ROUTES.SIGNIN,
    path: ROUTES.SIGNIN,
    exact: true,
    isProtected: ROUTES.GENERIC_PROTECTED,
    component: SignIn,
  },
  {
    key: ROUTES.FORGOT_PASSWORD,
    path: ROUTES.FORGOT_PASSWORD,
    exact: true,
    isProtected: ROUTES.GENERIC_PROTECTED,
    component: ForgotPassword,
  },
  {
    key: ROUTES.CHECKOUT_PROFILE_CREATE,
    path: ROUTES.CHECKOUT_PROFILE_CREATE,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: CheckoutProfileCreate,
  },
  {
    key: ROUTES.CHECKOUT_PROFILE_EDIT,
    path: ROUTES.CHECKOUT_PROFILE_EDIT,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: CheckoutProfileCreate,
  },
  {
    key: ROUTES.CHECKOUT_PROFILES,
    path: ROUTES.CHECKOUT_PROFILES,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: CheckoutProfiles,
  },
  {
    key: ROUTES.ACCOUNT,
    path: ROUTES.ACCOUNT,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: Account,
  },
  {
    key: ROUTES.PERSONAL_INFO,
    path: ROUTES.PERSONAL_INFO,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: PersonalInformationLayout,
  },
  {
    key: ROUTES.SITEMAP,
    path: ROUTES.SITEMAP,
    component: SiteMap,
  },
  {
    key: ROUTES.SITEMAP_OLD,
    path: ROUTES.SITEMAP_OLD,
    component: SiteMap,
  },
  {
    key: ROUTES.CONTACT,
    path: ROUTES.CONTACT,
    component: ContactUs,
  },
  {
    key: ROUTES.CONTACT_OLD,
    path: ROUTES.CONTACT_OLD,
    component: ContactUs,
  },
  {
    key: ROUTES.WARRANTY_INFORMATION,
    path: ROUTES.WARRANTY_INFORMATION,
    component: WarrantyInformation,
  },
  {
    key: ROUTES.WARRANTY_INFORMATION_OLD,
    path: ROUTES.WARRANTY_INFORMATION_OLD,
    component: WarrantyInformation,
  },
  {
    key: ROUTES.MY_GARAGE,
    path: ROUTES.MY_GARAGE,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: MyGarage,
  },
  {
    key: ROUTES.MY_GARAGE_OLD,
    path: ROUTES.MY_GARAGE_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: MyGarage,
  },
  {
    key: ROUTES.ADD_NEW_VEHICLE,
    path: ROUTES.ADD_NEW_VEHICLE,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: AddNewVehicle,
  },
  {
    key: ROUTES.EDIT_NEW_VEHICLE,
    path: ROUTES.EDIT_NEW_VEHICLE,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: AddNewVehicle,
  },
  {
    key: ROUTES.WISH_LIST,
    path: ROUTES.WISH_LIST,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: WishListView,
  },
  {
    key: ROUTES.WISH_LIST_OLD,
    path: ROUTES.WISH_LIST_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: WishListView,
  },
  {
    key: ROUTES.SEARCH,
    path: ROUTES.SEARCH,
    exact: true,
    component: SearchResults,
  },
  {
    key: ROUTES.SEARCH_OLD,
    path: ROUTES.SEARCH_OLD,
    exact: true,
    component: SearchResults,
  },
  {
    key: ROUTES.ADDRESS_BOOK,
    path: ROUTES.ADDRESS_BOOK,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: AddressBook,
  },
  {
    key: ROUTES.ADDRESS_BOOK_OLD,
    path: ROUTES.ADDRESS_BOOK_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: AddressBook,
  },
  {
    key: ROUTES.EDIT_ADDRESS,
    path: ROUTES.EDIT_ADDRESS_ROUTE,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: EditAddress,
  },
  {
    key: ROUTES.ADD_ADDRESS,
    path: ROUTES.ADD_ADDRESS,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: AddAddress,
  },
  {
    key: ROUTES.ORDER_HISTORY,
    path: ROUTES.ORDER_HISTORY,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: OrderHistoryPage,
  },
  {
    key: ROUTES.ORDER_HISTORY_OLD,
    path: ROUTES.ORDER_HISTORY_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: OrderHistoryPage,
  },
  {
    key: ROUTES.ORDER_DETAILS,
    path: ROUTES.ORDER_DETAILS_ROUTE,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: OrderDetails,
  },
  {
    key: ROUTES.ORDER_DETAILS_ROUTE_OLD,
    path: ROUTES.ORDER_DETAILS_ROUTE_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: OrderDetails,
  },
  {
    key: ROUTES.IP_ORDER_DETAILS,
    path: ROUTES.IP_ORDER_DETAILS_ROUTE,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: InprogressOrderDetailsPage,
  },
  {
    key: ROUTES.INPROGRESS_ORDERS,
    path: ROUTES.INPROGRESS_ORDERS,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: InprogressOrders,
  },

  {
    key: ROUTES.FAQ,
    path: ROUTES.FAQ,
    exact: true,
    component: Faq,
  },
  {
    key: ROUTES.FAQ_OLD,
    path: ROUTES.FAQ_OLD,
    component: Faq,
  },
  {
    key: ROUTES.RETURN,
    path: ROUTES.RETURN,
    component: Return,
  },
  {
    key: ROUTES.RETURN_OLD,
    path: ROUTES.RETURN_OLD,
    component: Return,
  },
  {
    key: ROUTES.MAKERETURN,
    path: ROUTES.MAKERETURN,
    component: MakeReturn,
  },
  {
    key: ROUTES.MAKERETURN_OLD,
    path: ROUTES.MAKERETURN_OLD,
    component: MakeReturn,
  },
  {
    key: ROUTES.RETURNSLIP,
    path: ROUTES.RETURNSLIP,
    component: OrderReturnLabelSlip,
  },
  {
    key: ROUTES.RETURNSLIP_OLD,
    path: ROUTES.RETURNSLIP_OLD,
    component: OrderReturnLabelSlip,
  },
  {
    key: ROUTES.SHIPPING_DELIVERY,
    path: ROUTES.SHIPPING_DELIVERY,
    component: ShippingAndDelivery,
  },

  {
    key: ROUTES.TNC,
    path: ROUTES.TNC,
    component: tnc,
  },
  {
    key: ROUTES.TNC_OLD,
    path: ROUTES.TNC_OLD,
    component: tnc,
  },
  {
    key: ROUTES.TAILGATING,
    path: ROUTES.TAILGATING,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: Tailgating,
  },
  {
    key: ROUTES.TAILGATING_OLD,
    path: ROUTES.TAILGATING_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: Tailgating,
  },
  {
    key: ROUTES.BESTTRUCK,
    path: ROUTES.BESTTRUCK,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: BestTruck,
  },
  {
    key: ROUTES.BESTTRUCK_OLD,
    path: ROUTES.BESTTRUCK_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: BestTruck,
  },
  {
    key: ROUTES.OFFROADING,
    path: ROUTES.OFFROADING,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: OffRoad,
  },
  {
    key: ROUTES.OFFROADING_OLD,
    path: ROUTES.OFFROADING_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: OffRoad,
  },
  {
    key: ROUTES.OVERLANDING,
    path: ROUTES.OVERLANDING,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: OverLanding,
  },
  {
    key: ROUTES.OVERLANDING_OLD,
    path: ROUTES.OVERLANDING_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: OverLanding,
  },
  {
    key: ROUTES.BESTOVERLANDING,
    path: ROUTES.BESTOVERLANDING,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: BestOverLanding,
  },
  {
    key: ROUTES.BESTOVERLANDING_OLD,
    path: ROUTES.BESTOVERLANDING_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: BestOverLanding,
  },
  {
    key: ROUTES.LEARNMOREREWARDS,
    path: ROUTES.LEARNMOREREWARDS,
    component: LearnMoreRewards
  },
  {
    key: ROUTES.LEARNMOREREWARDS_OLD,
    path: ROUTES.LEARNMOREREWARDS_OLD,
    component: LearnMoreRewards
  },
  {
    key: ROUTES.REDIRECT_URL_AFTER_LOGIN,
    path: ROUTES.REDIRECT_URL_AFTER_LOGIN,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: AfterLogin,
  },
  {
    key: ROUTES.REDIRECT_URL_AFTER_LOGOUT,
    path: ROUTES.REDIRECT_URL_AFTER_LOGOUT,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: AfterLogout,
  },
  {
    key: ROUTES.NEWS,
    path: ROUTES.NEWS,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: News,
  },
  {
    key: ROUTES.NEWS_OLD,
    path: ROUTES.NEWS_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: News,
  },
  {
    key: ROUTES.SPLASHGUARDS,
    path: ROUTES.SPLASHGUARDS,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: BenefitsOfSplashGuards,
  },
  {
    key: ROUTES.SPLASHGUARDS_OLD,
    path: ROUTES.SPLASHGUARDS_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: BenefitsOfSplashGuards,
  },
  {
    key: ROUTES.BRONCOACCESSORIES,
    path: ROUTES.BRONCOACCESSORIES,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: BestFordBroncoAccessories,
  },
  {
    key: ROUTES.BRONCOACCESSORIES_OLD,
    path: ROUTES.BRONCOACCESSORIES_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: BestFordBroncoAccessories,
  },
  {
    key: ROUTES.MUSTANGACCESSORIES,
    path: ROUTES.MUSTANGACCESSORIES,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: BestFordMustangAccessories,
  },
  {
    key: ROUTES.MUSTANGACCESSORIES_OLD,
    path: ROUTES.MUSTANGACCESSORIES_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: BestFordMustangAccessories,
  },
  {
    key: ROUTES.RANGERACCESSORIES,
    path: ROUTES.RANGERACCESSORIES,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: BestFordRangerAccessories,
  },
  {
    key: ROUTES.RANGERACCESSORIES_OLD,
    path: ROUTES.RANGERACCESSORIES_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: BestFordRangerAccessories,
  },
  {
    key: ROUTES.RAPTORACCESSORIES,
    path: ROUTES.RAPTORACCESSORIES,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: BestFordRaptorAccessories,
  },
  {
    key: ROUTES.RAPTORACCESSORIES_OLD,
    path: ROUTES.RAPTORACCESSORIES_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: BestFordRaptorAccessories,
  },
  {
    key: ROUTES.BRONCOSPORT,
    path: ROUTES.BRONCOSPORT,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: BroncoSport,
  },
  {
    key: ROUTES.BRONCOSPORT_OLD,
    path: ROUTES.BRONCOSPORT_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: BroncoSport,
  },
  {
    key: ROUTES.CARCAMPING,
    path: ROUTES.CARCAMPING,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: CarCamping,
  },
  {
    key: ROUTES.CARCAMPING_OLD,
    path: ROUTES.CARCAMPING_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: CarCamping,
  },
  {
    key: ROUTES.BIKERACKS,
    path: ROUTES.BIKERACKS,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: DifferentTypeOfBikeRacks,
  },
  {
    key: ROUTES.BIKERACKS_OLD,
    path: ROUTES.BIKERACKS_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: DifferentTypeOfBikeRacks,
  },
  {
    key: ROUTES.FAMILYROADTRIP,
    path: ROUTES.FAMILYROADTRIP,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: FamilyRoadTripTips,
  },
  {
    key: ROUTES.FAMILYROADTRIP_OLD,
    path: ROUTES.FAMILYROADTRIP_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: FamilyRoadTripTips,
  },
  {
    key: ROUTES.BLACKEDOUTVEHICLE,
    path: ROUTES.BLACKEDOUTVEHICLE,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: GuideToBlackedOutVehicleLook,
  },
  {
    key: ROUTES.BLACKEDOUTVEHICLE_OLD,
    path: ROUTES.BLACKEDOUTVEHICLE_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: GuideToBlackedOutVehicleLook,
  },
  {
    key: ROUTES.MOSTMAVERICK,
    path: ROUTES.MOSTMAVERICK,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: MostOfMaverick,
  },
  {
    key: ROUTES.MOSTMAVERICK_OLD,
    path: ROUTES.MOSTMAVERICK_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: MostOfMaverick,
  },
  {
    key: ROUTES.MUSTHAVEFORD,
    path: ROUTES.MUSTHAVEFORD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: MustHaveFordAccessories,
  },
  {
    key: ROUTES.MUSTHAVEFORD_OLD,
    path: ROUTES.MUSTHAVEFORD_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: MustHaveFordAccessories,
  },
  {
    key: ROUTES.MUSTHAVEVEHICLE,
    path: ROUTES.MUSTHAVEVEHICLE,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: MustHaveVehicleAccessoriesForSummer,
  },
  {
    key: ROUTES.MUSTHAVEVEHICLE_OLD,
    path: ROUTES.MUSTHAVEVEHICLE_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: MustHaveVehicleAccessoriesForSummer,
  },
  {
    key: ROUTES.ROOFRACK,
    path: ROUTES.ROOFRACK,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: RoofRack,
  },
  {
    key: ROUTES.ROOFRACK_OLD,
    path: ROUTES.ROOFRACK_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: RoofRack,
  },
  {
    key: ROUTES.SNOWBOARD,
    path: ROUTES.SNOWBOARD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: SnowBoard,
  },
  {
    key: ROUTES.SNOWBOARD_OLD,
    path: ROUTES.SNOWBOARD_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: SnowBoard,
  },
  {
    key: ROUTES.TAKINGCARE,
    path: ROUTES.TAKINGCARE,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: TakingCareOfYourVehicle,
  },
  {
    key: ROUTES.TAKINGCARE_OLD,
    path: ROUTES.TAKINGCARE_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: TakingCareOfYourVehicle,
  },
  {
    key: ROUTES.TOWING,
    path: ROUTES.TOWING,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: Towing,
  },
  {
    key: ROUTES.TOWING_OLD,
    path: ROUTES.TOWING_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: Towing,
  },
  {
    key: ROUTES.UNIQUEWAYS,
    path: ROUTES.UNIQUEWAYS,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: UniqueWaysToUseYourTruckBed,
  },
  {
    key: ROUTES.UNIQUEWAYS_OLD,
    path: ROUTES.UNIQUEWAYS_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: UniqueWaysToUseYourTruckBed,
  },
  {
    key: ROUTES.WEATHERPROOF,
    path: ROUTES.WEATHERPROOF,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: WaysToWeatherProofYourVehicle,
  },
  {
    key: ROUTES.WEATHERPROOF_OLD,
    path: ROUTES.WEATHERPROOF_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: WaysToWeatherProofYourVehicle,
  },
  {
    key: ROUTES.WINTERCARCAMP,
    path: ROUTES.WINTERCARCAMP,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: WinterCarCamp,
  },
  {
    key: ROUTES.WINTERCARCAMP_OLD,
    path: ROUTES.WINTERCARCAMP_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: WinterCarCamp,
  },
  {
    key: ROUTES.WINTERIZE,
    path: ROUTES.WINTERIZE,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: Winterize,
  },
  {
    key: ROUTES.WINTERIZE_OLD,
    path: ROUTES.WINTERIZE_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: Winterize,
  },
  {
    key: ROUTES.WALKINGDEADF150,
    path: ROUTES.WALKINGDEADF150,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: WalkingDead150,
  },
  {
    key: ROUTES.WALKINGDEADF150_OLD,
    path: ROUTES.WALKINGDEADF150_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: WalkingDead150,
  },
  {
    key: ROUTES.VINNESBUILD,
    path: ROUTES.VINNESBUILD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: VinnesBuild,
  },
  {
    key: ROUTES.VINNESBUILD_OLD,
    path: ROUTES.VINNESBUILD_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: VinnesBuild,
  },
  {
    key: ROUTES.BRONCOVINNESBUILD,
    path: ROUTES.BRONCOVINNESBUILD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: BroncoVinnesBuild,
  },
  {
    key: ROUTES.BRONCOVINNESBUILD_OLD,
    path: ROUTES.BRONCOVINNESBUILD_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: BroncoVinnesBuild,
  },
  {
    key: ROUTES.ANGUSBUILD,
    path: ROUTES.ANGUSBUILD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: AngusBuild,
  },
  {
    key: ROUTES.ANGUSBUILD_OLD,
    path: ROUTES.ANGUSBUILD_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: AngusBuild,
  },
  {
    key: ROUTES.BRONCOANGUSBUILD,
    path: ROUTES.BRONCOANGUSBUILD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: BroncoAngusBuild,
  },
  {
    key: ROUTES.BRONCOANGUSBUILD_OLD,
    path: ROUTES.BRONCOANGUSBUILD_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: BroncoAngusBuild,
  },
  {
    key: ROUTES.F150VINNESBUILD,
    path: ROUTES.F150VINNESBUILD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: F150VinnesBuild,
  },
  {
    key: ROUTES.F150VINNESBUILD_OLD,
    path: ROUTES.F150VINNESBUILD_OLD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: F150VinnesBuild,
  },
  {
    key: "other",
    path: "/*",
    exact: true,
    component: SEO,
  },
  // {
  //   key: "other",
  //   path: "/:id",
  //   exact: true,
  //   component: SEO,
  // },
  // {
  //   key: ROUTES.HOME,
  //   path: ROUTES.HOME,
  //   exact: true,
  //   component: SEO,
  // },
  // {
  //   key: "other",
  //   path: "*",
  //   // exact: true,
  //   component: NotFound,
  // },
];
export const ROUTE_CONFIG = {
  B2B: B2BRouteConfig,
  B2C: B2CRouteConfig,
  Checkout: CheckoutRouteConfig,
};
