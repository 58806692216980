import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import jsonData from "../staticPagesMetaData.json";
import { useSite } from "../../../../_foundation/hooks/useSite";
import { Helmet } from "react-helmet";

const content =jsonData.contents[21]?.GuideToBlackedOutVehicleLook;

const handleShareClick = (event: React.MouseEvent<HTMLSpanElement>) => {
  const url = event.currentTarget.dataset.copyToClipboard!;
  navigator.clipboard
    .writeText(url)
    .then(() => {
      const confirmation = window.prompt(
        "Copy to clipboard: Ctrl/Cmd+C, Enter",
        url
      );
      if (confirmation) {
        navigator.clipboard.writeText(url);
      }
    })
    .catch(() => {
      window.alert(`Failed to copy link to clipboard: ${url}`);
    });
};

const breadcrumbList = {
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  itemListElement: [
    {
      "@type": "ListItem",
      position: 1,
      name: "Home",
      item: "https://accessories.ford.com/",
    },
    {
      "@type": "ListItem",
      position: 2,
      name: "News",
      item: "https://accessories.ford.com/blogs/news",
    },
    {
      "@type": "ListItem",
      position: 3,
      name: "Your Guide To A “Blacked-Out” Vehicle Look",
      item: "https://accessories.ford.com/blogs/news/your-guide-to-a-blacked-out-vehicle-look",
    },
  ],
};

const GuideToBlackedOutVehicleLook = () => {
  const {storeDisplayName } = useSite();
  let port = !window.location.port.trim() ? '' : ':' + window.location.port;
  return (
    <div>
        <Helmet>
          <title>{content?.title}</title>
          <meta name="description" content={content?.metaDescription}></meta>
          <meta name="keywords" content={content?.metaKeyword}/>
          <meta property="og:site_name" content={storeDisplayName}/>
          <meta property="og:url" content={(window.location.protocol +'//'+window.location.hostname+':' + port + window.location.pathname)}/>
          <meta property="og:title" content={content?.title}/>
          <meta property="og:type" content="article"/>  
          <meta property="og:description" content={content?.metaDescription}/>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={content?.title}/>  
          <meta name="twitter:description" content={content?.metaDescription} />
        </Helmet>
      <div id="shopify-section-template-article" className="shopify-section">
        <section
          title="Blog Hero"
          className="article-hero"
          style={{
            backgroundImage:
              "url(https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/GuideToBlackedOutVehicleLook/RS_BlackedOutVehicle_1500x.jpeg)",
          }}>
          <div className="article-hero__container container">
            <h1 className="article-hero__heading title--md">
              Your Guide To A “Blacked-Out” Vehicle Look
            </h1>

            <div className="article-hero__publish-date">March 31, 2023</div>
          </div>
        </section>

        <div className="article__main">
          <nav className="breadcrumbs" role="navigation">
            <ul className="flex">
              <li>
              <a href="/">
              <span>Home</span>
              </a>
                <span className="breadcrumbs-separator">&gt;</span>
              </li>
              <li>
                <Link to="/blogs/news">
                  <span>News</span>
                </Link>
              </li>
              <span className="breadcrumbs-separator">&gt;</span>
              <li>
                <span>Your Guide To A “Blacked-Ou...</span>
              </li>
            </ul>
          </nav>
          <script type="application/ld+json">
            {JSON.stringify(breadcrumbList)}
          </script>

          <div className="article-actions">
            <Link className="article-actions__return" to="/blogs/news">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16">
                <g>
                  <path
                    fill="#102b4e"
                    d="M8,0 C12.418,0 16,3.582 16,8 C16,12.418 12.418,16 8,16 C3.582,16 0,12.418 0,8 C0,3.582 3.582,0 8,0 Z M6,3 C5.724,3 5.474,3.112 5.293,3.293 C5.112,3.474 5,3.724 5,4 C5,4.276 5.112,4.526 5.293,4.707 L9.409,8 L5.293,11.293 C5.112,11.474 5,11.724 5,12 C5,12.276 5.112,12.526 5.293,12.707 C5.474,12.888 5.724,13 6,13 C6.276,13 6.526,12.888 6.707,12.707 L11.707,8.707 C11.888,8.526 12,8.276 12,8 C12,7.724 11.888,7.474 11.707,7.293 L6.707,3.293 C6.526,3.112 6.276,3 6,3 Z"></path>
                </g>
              </svg>
              Back to articles
            </Link>

            <div
              className="social-icons "
              itemScope
              itemType="https://schema.org/Organization">
              <link itemProp="url" href="https://accessories.ford.com" />

              <h3 className="social-icons__share-title">Share</h3>

              <div className="social-icons__list-item">
                <a
                  href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/your-guide-to-a-blacked-out-vehicle-look&amp;text=Your+Guide+To+A+%E2%80%9CBlacked-Out%E2%80%9D+Vehicle+Look"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="16"
                    viewBox="0 0 20 16">
                    <g>
                      <g>
                        <path
                          fill="#102b4e"
                          d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Twitter</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/your-guide-to-a-blacked-out-vehicle-look"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M.29 5.24h3.2v10.764H.29z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">LinkedIn</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/your-guide-to-a-blacked-out-vehicle-look"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="16"
                    viewBox="0 0 8 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Facebook</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <span
                  className="share"
                  onClick={handleShareClick}
                  data-copy-to-clipboard="https://accessories.ford.com/blogs/news/your-guide-to-a-blacked-out-vehicle-look">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Share URL</span>
                </span>
              </div>
            </div>
          </div>

          <article className="article-content rte">
            <p>
              <span style={{fontWeight: 400}}>
                While there are some Ford vehicle owners focused on performance
                and off-road capabilities, others prioritize cosmetic upgrades
                that enhance their vehicle’s look. One of the most popular
                exterior trends in the industry right now is achieving a
                “blacked-out” look. From wheel swaps to lettering changes and
                various add-ons, Ford has a wide selection of black accessories
                that were engineered for your model and allow personalization,
                so your vehicle reflects you.
              </span>
            </p>
            <p>
              <span style={{fontWeight: 400}}>
                There’s no right or wrong answer when it comes to making your
                vehicle your own. Some owners go the extra mile and tweak
                several aspects of their Ford vehicle, while others make minor
                adjustments and changes as they go. No matter how you make your
                vehicle your own, you can be confident knowing that you already
                have one of the best rides on the road: your Ford vehicle. We’ve
                put together a list of blacked-out accessories that can be added
                to spruce up your vehicle and give it some attitude on the road.
                <br />
                <br />
              </span>
            </p>
            <img
              src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/GuideToBlackedOutVehicleLook/BlackedOutVehicle.jpeg"
              alt="Blacked-Out Vehicle"  width={'4472'} height={'100%'}
            />
            <br />
            <h2>
              <span style={{fontWeight: 400}}>
                What is “Blacking-Out” Your Vehicle?
              </span>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                This trendy term was coined from the idea of swapping
                traditionally chrome or shiny bits of exterior parts on your
                vehicle for a black look. For example, your vehicle might have
                door handles that match the paint color of your car, chrome or
                body-colored side mirrors, and most commonly, chrome or
                silver-colored wheels. All of these, plus more, are all common
                areas of your vehicle that can be swapped for shiny or
                matte-finished black versions to achieve a sleek look!
                Blacked-out accessories and trim pieces look good on any color
                Ford, adding a subtle hint of personality and detail to your
                ride.
                <br />
                <br />
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/GuideToBlackedOutVehicleLook/BlackWheel.jpeg"
                  alt="Black Wheel" width={'1480'} height={'100%'}
                />
                <br />
              </span>
            </p>
            <h2>
              <Link to="/search?searchTerm=black+wheel">
                <span style={{fontWeight: 400}}>Blacked-Out Rims</span>
              </Link>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                One of the most impactful ways to add an edgy feel to your Ford
                vehicle is by swapping your wheels for black wheels or wheels
                with black accents. Today, many vehicles on the road come
                standard with silver or bright chrome wheels. However, you can
                make yours stand out by purchasing black rims that are made
                specifically for your vehicle! Explore all of our{" "}
              </span>
              <Link to="/wheels">
                <span style={{fontWeight: 400}}>wheel accessories</span>
              </Link>
              <span style={{fontWeight: 400}}>
                {" "}
                to find an option that is compatible with your exact
                model.&nbsp;
              </span>
            </p>
            <p>
              <span style={{fontWeight: 400}}>
                Be sure to finish the look by swapping your silver lug nuts for{" "}
              </span>
              <Link to="/search?searchTerm=black+lug+nut">
                <span style={{fontWeight: 400}}>black lug nuts</span>
              </Link>
              <span style={{fontWeight: 400}}> and adding </span>
              <Link to="/search?searchTerm=center+cap">
                <span style={{fontWeight: 400}}>black center caps</span>
              </Link>
              <span style={{fontWeight: 400}}>
                . These small details pack a punch when changing out your rims
                and give your blacked-out upgrades a cohesive appearance!
                <br />
                <br />
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/GuideToBlackedOutVehicleLook/Black_RoofRack.jpeg"
                  alt="Black Roof Rack" width={'1398'} height={'100%'}
                />
                <br />
              </span>
            </p>
            <h2>
              <Link to="/search?searchTerm=black+roof">
                <span style={{fontWeight: 400}}>Black Roof Rails</span>
              </Link>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                If a sporty and rugged look is what you’re after, consider
                adding black roof rails to your Ford vehicle. Roof rails add
                practicality, value, and style. Does your vehicle already have
                roof rails? Some models might come with chrome roof rails, so
                swapping those out with black rails can complement the look when
                blacking out your vehicle. If yours already has black roof
                rails, consider adding{" "}
              </span>
              <Link to="/search?searchTerm=cross+bar">
                <span style={{fontWeight: 400}}>black crossbars</span>
              </Link>
              <span style={{fontWeight: 400}}>
                {" "}
                to complete the look and add to your Ford vehicle’s ruggedness!
                <br />
                <br />
              </span>
            </p>
            <p>
              &nbsp;
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/GuideToBlackedOutVehicleLook/G10083020_F150_XLT_SprCb_DetailTailgateBadge_mj.jpeg"
                alt="Detail Tailgate Badge" width={'1979'} height={'100%'}
              />
            </p>
            <p>
              <i>
                <span style={{fontWeight: 400}}>
                  Available Ford Accessories shown.
                  <br />
                  <br />
                </span>
              </i>
            </p>
            <h2>
              <Link to="/search?searchTerm=black+lettering">
                <span style={{fontWeight: 400}}>Black Lettering</span>
              </Link>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                One of our favorite ways to achieve a blacked-out look is by
                adding black lettering to your vehicle. The lettering on your
                Ford vehicle consists of any or all elements on the exterior of
                your car that brand your vehicle or state the model name. For
                example, if you have an Explorer, there’s lettering on your
                front hood that stylishly spells out “Explorer”. Likewise,
                Broncos have “Bronco” written out on the front grille, and the
                Ford F-150 famously spells out the model name on the tailgate,
                giving it a strong presence.
              </span>
            </p>
            <p>
              <span style={{fontWeight: 400}}>
                Ford has you covered by offering blacked-out lettering for most
                Ford vehicle models! Some models, like the Bronco and Bronco
                Sport, even have lettering elements in various colors, meaning
                you can black out the rest of your vehicle but highlight the
                model name in a stand-out accent! Additionally, the signature
                blue Ford oval badging can also be swapped for a{" "}
              </span>
              <Link to="/search?searchTerm=black+oval">
                <span style={{fontWeight: 400}}>black Ford logo</span>
              </Link>
              <span style={{fontWeight: 400}}>
                , tying together the whole look and adding a subtle detail that
                goes a long way.
                <br />
              </span>
            </p>
            <p>&nbsp;</p>
            <h2>
              <span style={{fontWeight: 400}}>More to Discover</span>
            </h2>
            <span style={{fontWeight: 400}}>
              If you’re feeling inspired to turn heads on the road with a
              blacked-out vehicle, you can fully personalize your ride with the
              power and safety of available Ford accessories. And if you’re
              wanting to go further than what we’ve included on this list, we
              have several{" "}
            </span>
            <Link to="/appearance">
              <span style={{fontWeight: 400}}>
                other certified Ford accessories
              </span>
            </Link>
            <span style={{fontWeight: 400}}>
              {" "}
              that can add to your vehicle’s appearance and help you achieve any
              style you’re after. Add your personal touch to your car today with
              our wide selection of parts and accessories!
            </span>
          </article>

          <div className="article-share">
            <div
              className="social-icons "
              itemScope
              itemType="https://schema.org/Organization">
              <link itemProp="url" href="https://accessories.ford.com" />

              <h3 className="social-icons__share-title">Share Article</h3>

              <div className="social-icons__list-item">
                <a
                  href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/your-guide-to-a-blacked-out-vehicle-look&amp;text=Your+Guide+To+A+%E2%80%9CBlacked-Out%E2%80%9D+Vehicle+Look"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="16"
                    viewBox="0 0 20 16">
                    <g>
                      <g>
                        <path
                          fill="#102b4e"
                          d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Twitter</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/your-guide-to-a-blacked-out-vehicle-look"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M.29 5.24h3.2v10.764H.29z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">LinkedIn</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/your-guide-to-a-blacked-out-vehicle-look"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="16"
                    viewBox="0 0 8 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Facebook</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <span
                  className="share"
                  onClick={handleShareClick}
                  data-copy-to-clipboard="https://accessories.ford.com/blogs/news/your-guide-to-a-blacked-out-vehicle-look">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Share URL</span>
                </span>
              </div>
            </div>
          </div>

          <div className="article-pagination article-pagination--desktop">
            <Link
              className="article-pagination__item article-pagination__item--previous"
              to="/blogs/news/must-have-vehicle-accessories-for-summer" onClick={() => window.scrollTo(0, 0)}>
              <div className="article-pagination__arrow">
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/chevron-thin-white-right_60x.jpeg"
                  alt="Chevron White" width={'32'} height={'62'}
                />
              </div>
              <div className="article-pagination__data">
                <div className="article-pagination__label">
                  Previous Article
                </div>
                <div className="article-pagination__title">
                  Must-Have Vehicle Accessories For Summer
                </div>
              </div>
            </Link>

            <Link
              className="article-pagination__item article-pagination__item--next"
              to="/blogs/news/vehicle-camping-packing-list" onClick={() => window.scrollTo(0, 0)}>
              <div className="article-pagination__data">
                <div className="article-pagination__label">Next Article</div>
                <div className="article-pagination__title">
                  Vehicle Camping Packing List
                </div>
              </div>
              <div className="article-pagination__arrow">
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/chevron-thin-white-right_60x.jpeg"
                  alt="Chevron White" width={'32'} height={'62'}
                />
              </div>
            </Link>
          </div>

          <div className="article-pagination article-pagination--mobile">
            <Link
              className="article-pagination__item article-pagination__item--previous"
              to="/blogs/news/must-have-vehicle-accessories-for-summer"  onClick={() => window.scrollTo(0, 0)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="15"
                viewBox="0 0 8 15">
                <g>
                  <g transform="rotate(-270 4 7.5)">
                    <path
                      fill="#fff"
                      d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z"></path>
                  </g>
                </g>
              </svg>
              Prev Article
            </Link>

            <Link
              className="article-pagination__item article-pagination__item--next"
              to="/blogs/news/vehicle-camping-packing-list"  onClick={() => window.scrollTo(0, 0)}>
              Next Article
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="15"
                viewBox="0 0 8 15">
                <g>
                  <g transform="rotate(-270 4 7.5)">
                    <path
                      fill="#fff"
                      d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z"></path>
                  </g>
                </g>
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuideToBlackedOutVehicleLook;
