import React, { Component,useEffect,useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useLocation } from "react-router-dom";
import jsonData from "../staticPagesMetaData.json";
import { useSite } from "../../../../_foundation/hooks/useSite";
import { Helmet } from "react-helmet";
import AdobeService from "../../../../_foundation/adobeServices/adobeService";

const content =jsonData.contents[38]?.News;

const News=()=>{

  interface User {
    id: number;
    url: string;
    img: string;
    title: string;
    date: string;
    category?: string;
  }

  const {storeDisplayName } = useSite();
  let port = !window.location.port.trim() ? '' : ':' + window.location.port;

  const jsonData: User[] = [
        {
            id: 1,
            url: "news/different-types-of-bike-racks",
            img: 'https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/RS_BikeRack_1214x358.progressive.jpeg',
            title: 'Different Types of Bike Racks',
            date: 'March 31, 2023',
          },
          {
            id: 2,
            url: "news/how-to-strap-a-kayak-to-a-roof-rack",
            img: 'https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/RS_StrapKayakToRoof_1214x358.progressive.jpeg',
            title: 'How to Strap a Kayak to a Roof Rack',
            date: 'March 31, 2023',
          },
          {
            id: 3,
             url: "news/must-have-ford-raptor-accessories", 
            img:"https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/RS_RaptorAccessories_1214x358.progressive.jpeg",
            title: "Must-Have Ford Raptor Accessories",
            date: 'March 31, 2023',
          },
          {
            id: 4,
             url: "news/vehicle-camping-packing-list", 
            img:"https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/RS_CarCamping_1214x358.progressive.jpeg",
            title: "Vehicle Camping Packing List",
            date: 'March 31, 2023',
          },
          {
            id: 5,
             url: "news/your-guide-to-a-blacked-out-vehicle-look", 
            img:"https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/RS_BlackedOutVehicle_1214x358.progressive.jpeg",
            title: "Your Guide To A “Blacked-Out” Vehicle Look",
            date: 'March 31, 2023',
                },
          {
            id: 6,
             url: "news/must-have-vehicle-accessories-for-summer", 
            img:"https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/RS_SummerAccessories_fc870e18-3649-45f3-91da-ac4594900e73_1214x358.progressive.jpeg",
            title: "Must-Have Vehicle Accessories For Summer",
            date: 'March 31, 2023',
                },
          {
            id: 7,
             url: "news/bronco-vs-bronco-sport", 
            img:"https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/BroncoVBroncoSport_RS_1214x358.jpeg",
            title: "Bronco vs. Bronco Sport",
            date: "March 14, 2023"
                },
          {
            id: 8,
            url:"news/winter-car-camping-accessories-for-your-ford", 
            img:"https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/WinterCarCamping_RS_1214x358.jpeg",
            title: "Winter Car Camping Accessories for Your Ford",
            date: "March 14, 2023"
                },
          {
            id: 9,
             url: "news/best-ford-mustang-accessories", 
            img:"https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/Mustang_RS_1214x358.jpeg",
            title: "Best Ford Mustang Accessories",
            date: "March 14, 2023"
                },
          {
            id: 10,
             url: "news/our-best-towing-accessories",
            img:"https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/Towing_RS_1214x358.progressive.jpeg",
            title: "Our Best Towing Accessories",
            date: "March 14, 2023"
                },
          {
            id: 11,
             url: "news/make-the-most-of-your-maverick", 
            img:"https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/Maverick_RS_1214x358.progressive.jpeg",
            title: "Make The Most of Your Maverick®",
            date: "March 14, 2023"
                },
          {
            id: 12,
             url: "news/how-to-winterize-your-vehicle", 
            img:"https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/Winterize_RS_1214x358.progressive.jpeg",
            title: "How To Winterize Your Vehicle",
            date: "March 14, 2023"
                },
          {
            id: 13,
             url: "news/our-best-snowboard-racks", 
            img:"https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/SnowboardRack_RS_1214x358.progressive.jpeg",
            title: "Our Best Snowboard Racks",
            date: "March 14, 2023"
                },
          {
            id: 14,
             url: "news/family-road-trip-tips",
            img:"https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/FamilyRoadTrip_RS_1214x358.progressive.jpeg",
            title: "Family Road Trip Tips",
            date: "March 14, 2023"
                },
          {
            id: 15,
             url: "news/5-unique-ways-to-use-your-truck-bed", 
            img:"https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/UniqueTruckBed_RS_1214x358.progressive.jpeg",
            title: "5 Unique Ways to Use Your Truck Bed",
            date: "March 14, 2023"
                },
          {
            id: 16,
             url: "news/5-unique-ways-to-use-your-truck-bed", 
            img:"https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/SplashGuards_RS_1214x358.progressive.jpeg",
            title: "Benefits of Splash Guards for Your Ford Truck or SUV",
            date: "March 14, 2023"
                },
          {
            id: 17,
             url: "news/overlanding-101", 
            img:"https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/overlanding_1214x358.progressive.jpeg",
            title: "Overlanding 101",
            date: "February 01, 2023"
                },
          {
            id: 18,
             url: "news/tailgating-essentials", 
            img:"https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/tailgating_1214x358.progressive.jpeg",
            title: "Tailgating Essentials",
            date: "February 01, 2023"
                },
          {
            id: 19,
             url: "news/best-truck-bed-accessories",
            img:"https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/besttruckbed_1214x358.progressive.jpeg",
            title: "Best Truck Bed Accessories",
            date: "February 01, 2023"
                },
          {
            id: 20,
             url: "news/5-essentials-for-off-road-adventures", 
            img:"https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/off-road_1214x358.progressive.jpeg",
            title: "5 Essentials For Off-Road Adventures",
            date: "February 01, 2023"
                },
          {
            id: 21,
             url: "news/8-best-overlanding-accessories-for-your-ford", 
            img:"https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/bestoverlanding_be119c96-1c18-462f-8e17-e426d3df275b_1214x358.progressive.jpeg",
            title: "8 Best Overlanding Accessories for Your Ford",
            date: "February 01, 2023"
                },
          {
            id: 22,
             url: "news/9-best-ford-bronco-accessories",
            img:"https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/bestbronco_3f3d9cf7-72a7-4f85-80f1-c622013388af_1214x358.progressive.jpeg",
            title: "9 Best Ford Bronco Accessories",
            date: "February 01, 2023"
                },
          {
            id: 23,
             url: "news/best-ford-ranger-accessories",
            img:"https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/ranger_1214x358.progressive.jpeg",
            title: "Best Ford Ranger Accessories",
            date: "February 01, 2023"
                },
          {
            id: 24,
             url: "news/8-must-have-ford-f-150-accessories",
            img:"https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/f150_a131a5d5-d897-4b17-8dcc-7606ec7bd103_1214x358.progressive.jpeg",
            title: "8 Must-Have Ford F-150 Accessories",
            date: "February 01, 2023"
                },
          {
            id: 25,
             url: "news/taking-care-of-your-vehicle-all-year-long",
            img:"https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/allyear_1214x358.progressive.jpeg",
            title: "Taking Care of Your Vehicle All Year Long",
            date: "February 01, 2023"
                },
          {
            id: 26,
             url: "news/5-ways-to-weatherproof-your-vehicle",
            img:"https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/waterproof_1214x358.progressive.jpeg",
            title: "5 Ways To Weatherproof Your Vehicle",
            date: "February 01, 2023"
                },
          {
            id: 27,
             url: "news/custom-lincoln-aviator-anguss-build",
            img:"https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/2016LincolnMKX_291_bf_C9g_with_lights_on_LED_1214x358.progressive.jpeg",
            title: "Custom Lincoln Aviator - Angus's Build",
            date: "February 09, 2021",
            category:"Performance"
                },
          {
            id: 28,
             url: "news/custom-lincoln-aviator-vinnies-build",
            img:"https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/MKX_Concept_278_bc_3m_C1c_lights_on_1214x358.progressive.jpeg",
            title: "Custom LINCOLN AVIATOR - Vinnie's Build",
            date: "February 09, 2021",
            category:"Accessories"
                },
          {
            id: 29,
             url: "news/orange-custom-ford-bronco-vinnies-build",
            img:"https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/vinnies-bp_1214x358.jpeg",
            title: "ORANGE Custom Ford BRONCO - Vinnie's Build",
            date: "January 25, 2021",
            category:"Tips And Tricks"
                },
          {
            id: 30,
             url: "news/the-walking-dead-custom-bronco-anguss-build",
            img:"https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/angus-bp_1214x358.jpeg",
            title: "The Walking Dead Custom Bronco - Angus's Buil",
            date: "January 25, 2021",
            category:"Performance"
                },
          {
            id: 31,
             url: "news/custom-ford-f-150-fx4-vinnies-build",
            img:"https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/2015_F150_60FT8584_v1_1214x358.progressive.jpeg",
            title: "Custom Ford F-150 FX4 - Vinnie's Build",
            date: "January 07, 2021",
            category:"Accessories"
                },
          {
            id: 32,
             url: "news/the-walking-dead-custom-ford-f-150-anguss-build",
            img:"https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/2017_Raptor_293_ab_C7_1214x358.progressive.jpeg",
            title: "The Walking Dead Custom Ford F-150 - Angus's Build",
            date: "January 07, 2021",
            category:"Performance"
                }
      ];
      const [selectedCategory, setSelectedCategory] = useState("All Articles");
      const [users, setUsers] = useState(jsonData.slice(0, 32));
      const [pageNumber, setPageNumber] = useState(0);
      //console.log(users);
      const usersPerPage = 15;
      const pagesVisited = pageNumber * usersPerPage;
      users.map((user)=>{
        //console.log(user.url)
      })
      const filteredUsers = selectedCategory === "All Articles" ? users : users.filter(user => user.category === selectedCategory);
      const displayUsers = filteredUsers
        .slice(pagesVisited, pagesVisited + usersPerPage)
        .map((user) => {
          // //console.log(user.url)
          return (
            <div className="article-card" key={user.id}>
              <Link to={user.url} className="article-card__image" onClick={() => window.scrollTo(0, 0)}>
              {user.id >= 27 && user.id <= 32 && (
            <div className="article-card__category">{user.category}</div>
          )}
                <img data-preload="loaded" src={user.img} alt={user.title}  width={'100%'} height={'100%'}  />
              </Link>
              <h2 className="article-card__title title--xxs">
                <a href={user.url}>{user.title}</a>
              </h2>
              <div className="article-card__publish-date">{user.date}</div>
            </div>
          );
        });
        const location = useLocation();

        React.useEffect(()=>{
            // if(location.pathname == "/blogs/news"){
              (async () => {
                let res = await AdobeService.track({
                  pageName: `blogs:landing`,
                  variantName: "blog-load",
                  siteSection: "blogs",
                  hierarchy: "blogs",
                  eventType: "blog-load",
                });
                AdobeService.callSatellite(res);
              })();
            // }
         },[])

      const pageCount = Math.ceil(users.length / usersPerPage);
      
      const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
      const changePage = ({ selected }) => {
        setPageNumber(selected);
        setSelectedCategory("All Articles");
      };
      const [searchTerm, setSearchTerm] = useState('');
      const [searchResults, setSearchResults] = useState<User[]>([]);
      
      const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
       const filteredUsers = jsonData.filter((user) =>
      user.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(filteredUsers);
      };
      const previousClassName = pageNumber === 0 ? 'previousBttn hidden' : 'previousBttn';
      const nextClassName = pageNumber === pageCount - 1 ? 'nextBttn hidden' : 'nextBttn';
      const [isOpen, setIsOpen] = useState(false);
      const handleDropdownClick = () => {
        setIsOpen(!isOpen);
      };
    
      const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setIsOpen(false);
      };
      if (searchResults.length > 0) {
        return (
          <div>
            <section className="search-breadcrumbs">
              <div className="search-breadcrumbs__container container">
                <div className="search-breadcrumbs__breadcrumbs">
                  <nav className="breadcrumbs" role="navigation">
                    <ul className="flex">
                      <li>
                        <a href="/">
                          <span>Home</span>
                        </a>
                        <span className="breadcrumbs-separator">&gt;</span>
                      </li>
                      <li>
                        <span>Search Results</span>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </section>
            <section className="search-page-terms-header">
              <div className="search-page-terms-header__container container">
                <h1 className="search-page-terms-header__heading title--sm">
                  You searched for "<span className="search-page-terms-header__term">{searchTerm}</span>"
                </h1>
                <span className="search-page-terms-header__results-text">
                  {searchResults.length} results found
                </span>
              </div>
            </section>
            <section className="search-results">
              <div className="search-results__container container">
                <span className="search-results__heading">Articles Found</span>
                <div className="search-grid search-grid__articles" data-product-grid="">
                  {searchResults.slice(0,8).map((user) => (
                    <div className="article-card" key={user.id}>
                    <a href={user.url} className="article-card__image">
                    {user.id >= 27 && user.id <= 32 && (
                  <div className="article-card__category">{user.category}</div>
                )}
                      <img data-preload="loaded" src={user.img} alt={user.title} width={'100%'} height={'100%'} />
                    </a>
                    <h2 className="article-card__title title--xxs">
                      <a href={user.url}>{user.title}</a>
                    </h2>
                    <div className="article-card__publish-date">{user.date}</div>
                  </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        );
      }
    return(

<div>
  <Helmet>
      <meta property="og:site_name" content={storeDisplayName}/>
      <meta property="og:url" content={(window.location.protocol +'//'+window.location.hostname+':' + port + window.location.pathname)}/>
      <meta property="og:title" content={content?.title}/>
      <meta property="og:type" content="website"/>  
      <meta property="og:description" content={content?.metaDescription}/>
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={content?.title}/>  
      <meta name="twitter:description" content={content?.metaDescription} />
   </Helmet>
<div id="shopify-section-template-blog" className="shopify-section">

<section className="page-hero" id="page-hero-">
 
    <div className="page-hero__background object-cover page-hero__overlay ">
   
            <img className="above-mobile-large" src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/blog-hero_1500x.jpeg" data-preload="loaded" width={'1500'} height={'469'} alt="Desktop Hero"/>        
                <img className="mobile-large-only" src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAHead_BlogPage/blog-hero_1500x.jpeg" data-preload="loaded" alt="Mobile Hero" width={'1500'} height={'469'}/>
             
    </div>

    <div className="page-hero__content container">
        <div className="page-hero__content-inner page-hero__content-inner--middle">
                <h1 className="page-hero__heading title--md">
                    E-Transit will help bring cleaner air to towns and cities
                </h1>
            
                <div className="page-hero__textarea rte">
                    <p>
                        </p><p>We need vans more than ever, as people around the world have discovered how essential it can be for goods and services to be…</p>
                    <p></p>
                </div>

                <a href="#" className="page-hero__cta btn--white btn--rounded btn--chevron">
                    Read More
                </a>
            
        </div>
    </div>

    <form className="page-hero-search" onSubmit={handleSearch}>
      <input
        className="page-hero-search__input"
        id="search-blog"
        type="text"
        placeholder="Search Blog"
        name="term"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <button className="page-hero-search__submit" type="submit">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
          <g>
            <g>
              <g>
                <path fill="none" stroke="#112b4e" strokeMiterlimit="20" strokeWidth=".75" d="M9.5 18a8.5 8.5 0 1 0 0-17 8.5 8.5 0 0 0 0 17z"></path>
              </g>
              <g>
                <path fill="#112b4e" d="M15.357 15.361c-.476.482-.476 1.446 0 1.928L22.977 25s.475 0 1.428-.964c.952-.964.476-.964.476-.964l-7.62-7.71c-.475-.482-1.428-.482-1.904 0z"></path>
              </g>
            </g>
          </g>
        </svg>
        <span className="visually-hidden">Search</span>
      </button>
    </form>
</section>


<div className="blog-filter-and-sort container">

    <div className="blog-filter-and-sort__filter">
    <div className="blog-filter-and-sort__filter-list active">
            <div className={`blog-filter-and-sort__filter-list-item ${selectedCategory === 'All Articles' ? 'active' : ''}`}>
              <a onClick={() => setSelectedCategory('All Articles')}>All Articles</a>
            </div>

            <div className={`blog-filter-and-sort__filter-list-item ${selectedCategory === 'Accessories' ? 'active' : ''}`}>
              <a onClick={() => setSelectedCategory('Accessories')}>Accessories</a>
            </div>

            <div className={`blog-filter-and-sort__filter-list-item ${selectedCategory === 'Performance' ? 'active' : ''}`}>
              <a onClick={() => setSelectedCategory('Performance')}>Performance</a>
            </div>

            <div className={`blog-filter-and-sort__filter-list-item ${selectedCategory === 'Tips And Tricks' ? 'active' : ''}`}>
              <a onClick={() => setSelectedCategory('Tips And Tricks')}>Tips and Tricks</a>
            </div>
          </div>

       <div className={`blog-article-filter-dropdown fake-dropdown ${isOpen ? 'open' : ''}`} onClick={handleDropdownClick}>
       <div className="fake-dropdown__selected-option">{selectedCategory}</div>
      
      <div className="fake-dropdown__option-list">
        <div className={`fake-dropdown_option fake-dropdown_option--selected ${selectedCategory === 'All Articles' ? 'active' : ''}`}>
          <a onClick={() => handleCategoryClick('All Articles')}>All Articles</a>
        </div>
        <div className={`fake-dropdown__option ${selectedCategory === 'Accessories' ? 'active' : ''}`}>
          <a onClick={() => handleCategoryClick('Accessories')}>Accessories</a>
        </div>
        <div className={`fake-dropdown__option ${selectedCategory === 'Performance' ? 'active' : ''}`}>
          <a onClick={() => handleCategoryClick('Performance')}>Performance</a>
        </div>
        <div className={`fake-dropdown__option ${selectedCategory === 'Tips And Tricks' ? 'active' : ''}`}>
          <a onClick={() => handleCategoryClick('Tips And Tricks')}>Tips and Tricks</a>
        </div>
      </div>
    </div>
      

    </div>
</div>

<div>
      <div className="blog-article-grid grid--3 container">
        {displayUsers}
      </div>
      <div className="pagination ">
      <div className="pagination__container container">
        <div className="pagination__item-count">
          {`${pagesVisited + 1} - ${Math.min(pagesVisited + usersPerPage, users.length)} of ${users.length} results`}
        </div>

        <ReactPaginate
          previousLabel={'< Prev'}
          nextLabel={'Next >'}
          pageCount={pageCount}
          onPageChange={(selected) => {changePage(selected);scrollToTop();}}
          containerClassName={'pagination__page-select'}
          previousClassName={previousClassName}
          nextClassName={nextClassName}
          disabledClassName={'paginationDisabled'}
          activeClassName={'paginationActive'}
        />

        <div className="pagination__page-select pagination__page-select--mobile">
        {pageNumber > 0 && (
    <span className={previousClassName}>
      <button onClick={() => { changePage({ selected: pageNumber - 1 }); scrollToTop();}}>
        Prev
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="15" viewBox="0 0 8 15">
          <g>
            <g transform="rotate(-270 4 7.5)">
              <path fill="#fff" d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z" />
            </g>
          </g>
        </svg>
      </button>
    </span>
  )}
    {`${pageNumber + 1} of ${pageCount}`}{pageNumber < pageCount - 1 && (
    <span className={nextClassName}>
      <button onClick={() => {changePage({ selected: pageNumber + 1 });scrollToTop();}}>
        Next
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="15" viewBox="0 0 8 15">
          <g>
            <g transform="rotate(-270 4 7.5)">
              <path fill="#fff" d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z" />
            </g>
          </g>
        </svg>
      </button>
    </span>
  )}
        </div>
      </div>
    </div>
     
    </div>

</div>


        </div>
    );
}

export default News;