import { call, put } from "redux-saga/effects";
//Foundation libraries
import mockServerService from "../../../_foundation/apis/mock/mockserver.service";
import { getSite } from "../../../_foundation/hooks/useSite";
//Redux
import * as ACTIONS from "../../action-types/product-details";

export function* getProductWorker(action: any) {
  try {
    const siteInfo = getSite();
    //console.log("SiteeInfo",`${siteInfo?.storeID}`);
    const payload = action.payload;
    const response = yield call(
      mockServerService.getMock,     
      {},
      {},
      `${siteInfo?.transactionContext}/store/${siteInfo?.storeID}/productDetails`
    );
    yield put({
      payload: payload,
      type: ACTIONS.RESPONSE_SUCCESSED_PRODUCT,
      response: response.data,
    })
    //console.log("ProductResponse",response);
  } catch (error) {
    yield put({ type: ACTIONS.RESPONSE_FAILURE_PRODUCT, response:error });
  }
}