import React from 'react';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import jsonData from "../staticPagesMetaData.json";
import { useSite } from "../../../../_foundation/hooks/useSite";
import { Helmet } from "react-helmet";

const content =jsonData.contents[17]?.CarCamping;

const handleShareClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    const url = event.currentTarget.dataset.copyToClipboard!;
    navigator.clipboard.writeText(url).then(() => {
        const confirmation = window.prompt("Copy to clipboard: Ctrl/Cmd+C, Enter", url);
        if (confirmation) {
            navigator.clipboard.writeText(url);
        }
    }).catch(() => {
        window.alert(`Failed to copy link to clipboard: ${url}`);
    });
};

const breadcrumbList = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
        {
            "@type": "ListItem",
            "position": 1,
            "name": "Home",
            "item": "https://accessories.ford.com/"
        },
        {
            "@type": "ListItem",
            "position": 2,
            "name": "News",
            "item": "https://accessories.ford.com/blogs/news"
        },
        {
            "@type": "ListItem",
            "position": 3,
            "name": "Vehicle Camping Packing List",
            "item": "https://accessories.ford.com/blogs/news/vehicle-camping-packing-list"
        }
    ]
}


const CarCamping = () => {

    const {storeDisplayName } = useSite();
    let port = !window.location.port.trim() ? '' : ':' + window.location.port;

    return (
        <div>
            <Helmet>
                <title>{content?.title}</title>
                <meta name="description" content={content?.metaDescription}></meta>
                <meta name="keywords" content={content?.metaKeyword}/>
                <meta property="og:site_name" content={storeDisplayName}/>
                <meta property="og:url" content={(window.location.protocol +'//'+window.location.hostname+':' + port + window.location.pathname)}/>
                <meta property="og:title" content={content?.title}/>
                <meta property="og:type" content="article"/>  
                <meta property="og:description" content={content?.metaDescription}/>
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={content?.title}/>  
                <meta name="twitter:description" content={content?.metaDescription} />
            </Helmet>
            <div id="shopify-section-template-article" className="shopify-section">




                <section className="article-hero" title="Blog Hero" style={{ backgroundImage: "url(https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/CarCamping/RS_CarCamping_1500x.jpeg)" }}>
                    <div className="article-hero__container container">



                        <h1 className="article-hero__heading title--md">
                            Vehicle Camping Packing List
                        </h1>




                        <div className="article-hero__publish-date">
                            March 31, 2023
                        </div>

                    </div>
                </section>



                <div className="article__main">




                    <nav className="breadcrumbs" role="navigation">
                        <ul className="flex">
                            <li>
                            <a href="/">
              <span>Home</span>
              </a>
                <span className="breadcrumbs-separator">&gt;</span>
              </li>
              <li>
                <Link to="/blogs/news">
                  <span>News</span>
                </Link>
                            </li>
                            <span className="breadcrumbs-separator">&gt;</span>
                            <li>
                                <span>Vehicle Camping Packing List</span>
                            </li></ul>
                    </nav>
                    <script type="application/ld+json">
                        {JSON.stringify(breadcrumbList)}
                    </script>

                    <div className="article-actions">


                        <Link className="article-actions__return" to="/blogs/news">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><path fill="#102b4e" d="M8,0 C12.418,0 16,3.582 16,8 C16,12.418 12.418,16 8,16 C3.582,16 0,12.418 0,8 C0,3.582 3.582,0 8,0 Z M6,3 C5.724,3 5.474,3.112 5.293,3.293 C5.112,3.474 5,3.724 5,4 C5,4.276 5.112,4.526 5.293,4.707 L9.409,8 L5.293,11.293 C5.112,11.474 5,11.724 5,12 C5,12.276 5.112,12.526 5.293,12.707 C5.474,12.888 5.724,13 6,13 C6.276,13 6.526,12.888 6.707,12.707 L11.707,8.707 C11.888,8.526 12,8.276 12,8 C12,7.724 11.888,7.474 11.707,7.293 L6.707,3.293 C6.526,3.112 6.276,3 6,3 Z"></path></g></svg>
                            Back to articles
                        </Link>




                        <div className="social-icons " itemScope itemType="https://schema.org/Organization">
                            <link itemProp="url" href="https://accessories.ford.com" />

                            <h3 className="social-icons__share-title">
                                Share
                            </h3>


                            <div className="social-icons__list-item">
                                <a href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/vehicle-camping-packing-list&amp;text=Vehicle+Camping+Packing+List" target="_blank" rel="noopener" className="share">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16"><g><g><path fill="#102b4e" d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path></g></g></svg>
                                    <span className="visually-hidden">
                                        Twitter
                                    </span>
                                </a>
                            </div>
                            <div className="social-icons__list-item">
                                <a href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/vehicle-camping-packing-list" target="_blank" rel="noopener" className="share">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><g><g><path fill="#102b4e" d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path></g><g><path fill="#102b4e" d="M.29 5.24h3.2v10.764H.29z"></path></g><g><path fill="#102b4e" d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path></g></g></g></svg>
                                    <span className="visually-hidden">
                                        LinkedIn
                                    </span>
                                </a>
                            </div>
                            <div className="social-icons__list-item">
                                <a href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/vehicle-camping-packing-list" target="_blank" rel="noopener" className="share">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16"><g><g><g><path fill="#102b4e" d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path></g></g></g></svg>
                                    <span className="visually-hidden">
                                        Facebook
                                    </span>
                                </a>
                            </div>
                            <div className="social-icons__list-item">
                                <button className="share" onClick={handleShareClick} data-copy-to-clipboard="https://accessories.ford.com/blogs/news/vehicle-camping-packing-list">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><g><path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path></g></g></svg>
                                    <span className="visually-hidden">
                                        Share URL
                                    </span>
                                </button>
                            </div>


                        </div>

                    </div>



                    <article className="article-content rte">
                        <p><span style={{ fontWeight: 400 }}>Did you know that most campgrounds in state and national parks allow vehicle access to campsites? This is the perfect opportunity for camping with your vehicle! While many think this type of camping involves sleeping in your vehicle, it actually refers to any camping location that doesn’t require hiking or narrow trails and you can drive your car to. These scenarios are great for both new and experienced campers since your vehicle becomes your landing zone and central hub for extra supplies, gear, and shelter if needed. This emphasizes the importance of outfitting your vehicle to be prepared for camping excursions so you are equipped with items you need for an enjoyable outing. And since you don’t have to carry the gear to your campsite, you can bring luxuries that would otherwise be too heavy or bulky. We’ve put together a packing list to make sure you have everything you need for your next vehicle camping adventure!&nbsp;<br /><img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/CarCamping/RooftopTent.jpeg" alt="Car camping Rooftop tent"  width={'1480'} height={'100%'}/><br /></span></p>
                        <i><span style={{ fontWeight: 400 }}>Available Ford Accessories are shown.<br /><br /></span></i>
                        <h2><span style={{ fontWeight: 400 }}>Tent &amp; Sleeping Bags</span></h2>
                        <p><span style={{ fontWeight: 400 }}>With any camping excursion, you will need a place to rest your head after a full day of adventure. Luckily, when you have your vehicle with you, there are more options than traditional camping. Regardless, you should always have sleeping bags, pillows, and blankets on hand for a restful night’s sleep. If your vehicle has a bed or the ability for an available </span><Link to="/search?searchTerm=tent"><span style={{ fontWeight: 400 }}>rooftop tent</span></Link><span style={{ fontWeight: 400 }}>, you can experience unique sleeping accommodations that wouldn’t be available to you without your Ford vehicle. With available </span><Link to="/search?searchTerm=truck+tent"><span style={{ fontWeight: 400 }}>truck bed tents</span></Link><span style={{ fontWeight: 400 }}>, you have the option to add a mattress or other ways to improve comfort by sleeping on an enclosed, elevated surface. If you’re planning on taking your SUV, we offer a selection of available </span><Link to="/search?searchTerm=SUV+tents"><span style={{ fontWeight: 400 }}>SUV tents</span></Link><span style={{ fontWeight: 400 }}> that allow for your vehicle space to act as an extension of your sleeping quarters. This is great for families that could benefit from more space, while still spending time together.</span></p>
                        <p>&nbsp;</p>
                        <img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/CarCamping/PortablePowerSource.jpeg" alt="Car camping Portable Power source" width={'1480'} height={'100%'}/><br />
                        <h2><span style={{ fontWeight: 400 }}>Portable Power Source</span></h2>
                        <p><span style={{ fontWeight: 400 }}>Whether you’re looking for an unplugged camping experience or want to keep your devices charged while you’re out, having a portable power source on hand is important for convenience. To be most prepared, campers should never solely rely on a car’s power for their trip in the case of a dead battery. However, suppose you do end up needing to jump-start a dead battery. In that case, we have a variety of </span><Link to="/search?searchTerm=jump+start"><span style={{ fontWeight: 400 }}>battery jump-start packs</span></Link><span style={{ fontWeight: 400 }}> that provide multiple jump-starts on a single charge and can even work as portable power sources for recharging 12V and USB devices.</span></p>
                        <br /><img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/CarCamping/RoadShower.jpeg" alt="Car camping road shower" width={'1480'} height={'100%'}/><br />
                        <h2><span style={{ fontWeight: 400 }}>Road Shower</span></h2>
                        <p><span style={{ fontWeight: 400 }}>Especially when taking long camping trips, it’s important to stay on top of your personal hygiene with accessories that make on-the-go cleanliness possible. Our vast selection of </span><Link to="/search?searchTerm=shower"><span style={{ fontWeight: 400 }}>road showers and shower heads</span></Link><span style={{ fontWeight: 400 }}> bring the luxury of a shower to the outdoors while providing perfect pressure and efficiently using stored water. And if you’re taking a Bronco, Bronco Sport, Expedition or Explorer on your next camping adventure, our </span><Link to="/roof-rail-mounted-camping-shower-1"><span style={{ fontWeight: 400 }}>Roof Rail Mounted Camping Shower</span></Link><span style={{ fontWeight: 400 }}> provides privacy that attaches directly to your vehicle just like a traditional awning!</span></p>
                        <br />
                        <p><span style={{ fontWeight: 400 }}>If you’re looking for a separate showering space, the </span><Link to="/changing-room-shower-stand-alone-by-overland-1"><span style={{ fontWeight: 400 }}>Overland Stand-Alone Shower-Changing Room</span></Link><span style={{ fontWeight: 400 }}> adds some privacy to your campsite. With stakes to securely anchor, this product provides space for showering, changing clothes, or even a portable bathroom. For showering purposes, it features a solar shower bag holder with a 2-gallon shower bag and two mesh pockets for amenities such as shampoo and soap. It is easy to both setup and pack up with an oversized carrying bag that lets it stay with you on all of your travels.<br /><br /><img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/CarCamping/PortableFridgeFreezer.jpeg" alt="Portable Fridge/Freezer" width={'1480'} height={'100%'} /><br /></span></p>
                        <h2><span style={{ fontWeight: 400 }}>Portable Fridge/Freezer</span></h2>
                        <p><span style={{ fontWeight: 400 }}>Planning meals and securing all necessary groceries is one of the most important aspects of preparing for a camping trip. Luckily, you can keep all of your favorite food cool on the go with our </span><Link to="/portable-mini-fridge-freezer-1"><span style={{ fontWeight: 400 }}>Portable Mini Fridge Freezer</span></Link><span style={{ fontWeight: 400 }}>! This travel companion features an 18-quart, LED-illuminated compartment, and an electronic thermostat. While not always needed on camping trips, having a portable fridge is an example of a luxury that camping with your vehicle offers. Cold refreshments are the perfect way to treat yourself after a long hike or cool down on a warm summer day!<br /><br /><img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/CarCamping/YakimaAwning.jpeg" alt="Yakima Awning" width={'1480'} height={'100%'} /><br /></span></p>
                        <h2><span style={{ fontWeight: 400 }}>Awning</span></h2>
                        <p><span style={{ fontWeight: 400 }}>When spending an extended amount of time at your campsite, it should feel comfortable and accommodating. You also should be able to enjoy your time outdoors, rain or shine. Adding an awning to your car not only gives you shade on a sunny day but provides durability for all-weather relaxation. Our available </span><Link to="/yakima-awning-1"><span style={{ fontWeight: 400 }}>Yakima Awning</span></Link><span style={{ fontWeight: 400 }}> mounts easily to the side of your base rack, only takes up six inches of bar space and provides 42 sq. ft. of cover. It is easy to pitch – just pull it out, prop it up and settle in!<br /><br /></span></p>
                        <h2><span style={{ fontWeight: 400 }}>Level Up Your Camping Adventure</span></h2>
                        <p><span style={{ fontWeight: 400 }}>With these essential products in tow, you will be ready to make the most of your vehicle camping adventures. Since 1903, Ford has continued to be a pivotal leader in the automobile industry while exemplifying care for the vehicles we make. This passion extends into protecting and amplifying our vehicles with a multitude of accessories. Our vast selection of accessories for all of our vehicles is engineered to fit your model and will set you up for any journey ahead. Ready to take the road less traveled? Explore our range of accessories and purchase your favorite camping essentials today!<br /><img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/CarCamping/Ford_Accessories_Car_Camping_Infographic.jpeg" alt="Car Camping Infographic" width={'1600'} height={'100%'}/><br /></span></p>




                    </article>







                    <div className="article-share">


                        <div className="social-icons " itemScope itemType="https://schema.org/Organization">
                            <link itemProp="url" href="https://accessories.ford.com" />









                            <h3 className="social-icons__share-title">
                                Share Article
                            </h3>


                            <div className="social-icons__list-item">
                                <a href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/vehicle-camping-packing-list&amp;text=Vehicle+Camping+Packing+List" target="_blank" rel="noopener" className="share">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16"><g><g><path fill="#102b4e" d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path></g></g></svg>
                                    <span className="visually-hidden">
                                        Twitter
                                    </span>
                                </a>
                            </div>
                            <div className="social-icons__list-item">
                                <a href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/vehicle-camping-packing-list" target="_blank" rel="noopener" className="share">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><g><g><path fill="#102b4e" d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path></g><g><path fill="#102b4e" d="M.29 5.24h3.2v10.764H.29z"></path></g><g><path fill="#102b4e" d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path></g></g></g></svg>
                                    <span className="visually-hidden">
                                        LinkedIn
                                    </span>
                                </a>
                            </div>
                            <div className="social-icons__list-item">
                                <a href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/vehicle-camping-packing-list" target="_blank" rel="noopener" className="share">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16"><g><g><g><path fill="#102b4e" d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path></g></g></g></svg>
                                    <span className="visually-hidden">
                                        Facebook
                                    </span>
                                </a>
                            </div>
                            <div className="social-icons__list-item">
                                <button className="share" onClick={handleShareClick} data-copy-to-clipboard="https://accessories.ford.com/blogs/news/vehicle-camping-packing-list">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g><g><path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path></g></g></svg>
                                    <span className="visually-hidden">
                                        Share URL
                                    </span>
                                </button>
                            </div>


                        </div>

                    </div>





                    <div className="article-pagination article-pagination--desktop">

                        <Link className="article-pagination__item article-pagination__item--previous" to="/blogs/news/your-guide-to-a-blacked-out-vehicle-look" onClick={() => window.scrollTo(0, 0)}>
                            <div className="article-pagination__arrow">
                                <img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/chevron-thin-white-right_60x.jpeg" alt="Chevron White" width={'32'} height={'62'}/>
                            </div>
                            <div className="article-pagination__data">
                                <div className="article-pagination__label">
                                    Previous Article
                                </div>
                                <div className="article-pagination__title">
                                    Your Guide To A “Blacked-Out” Vehicle Look
                                </div>
                            </div>
                        </Link>


                        <Link className="article-pagination__item article-pagination__item--next" to="/blogs/news/must-have-ford-raptor-accessories" onClick={() => window.scrollTo(0, 0)}>
                            <div className="article-pagination__data">
                                <div className="article-pagination__label">
                                    Next Article
                                </div>
                                <div className="article-pagination__title">
                                    Must-Have Ford Raptor Accessories
                                </div>
                            </div>
                            <div className="article-pagination__arrow">
                                <img src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/chevron-thin-white-right_60x.jpeg" alt="Chevron White" width={'32'} height={'62'} />
                            </div>
                        </Link>

                    </div>


                    <div className="article-pagination article-pagination--mobile">

                        <Link className="article-pagination__item article-pagination__item--previous" to="/blogs/news/your-guide-to-a-blacked-out-vehicle-look" onClick={() => window.scrollTo(0, 0)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="15" viewBox="0 0 8 15"><g><g transform="rotate(-270 4 7.5)"><path fill="#fff" d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z"></path></g></g></svg>
                            Prev Article
                        </Link>


                        <Link className="article-pagination__item article-pagination__item--next" to="/blogs/news/must-have-ford-raptor-accessories" onClick={() => window.scrollTo(0, 0)}>
                            Next Article
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="15" viewBox="0 0 8 15"><g><g transform="rotate(-270 4 7.5)"><path fill="#fff" d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z"></path></g></g></svg>
                        </Link>

                    </div>


                </div>





            </div>

        </div>

    );

}

export default CarCamping;