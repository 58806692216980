import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import jsonData from "../staticPagesMetaData.json";
import { useSite } from "../../../../_foundation/hooks/useSite";
import { Helmet } from "react-helmet";

const content =jsonData.contents[8]?.BestFordBroncoAccessories;

const handleShareClick = (event: React.MouseEvent<HTMLSpanElement>) => {
  const url = event.currentTarget.dataset.copyToClipboard!;
  navigator.clipboard
    .writeText(url)
    .then(() => {
      const confirmation = window.prompt(
        "Copy to clipboard: Ctrl/Cmd+C, Enter",
        url
      );
      if (confirmation) {
        navigator.clipboard.writeText(url);
      }
    })
    .catch(() => {
      window.alert(`Failed to copy link to clipboard: ${url}`);
    });
};

const breadcrumbList = {
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  itemListElement: [
    {
      "@type": "ListItem",
      position: 1,
      name: "Home",
      item: "https://accessories.ford.com/",
    },
    {
      "@type": "ListItem",
      position: 2,
      name: "News",
      item: "https://accessories.ford.com/blogs/news",
    },
    {
      "@type": "ListItem",
      position: 3,
      name: "9 Best Ford Bronco Accessories",
      item: "https://accessories.ford.com/blogs/news/9-best-ford-bronco-accessories",
    },
  ],
};

const BestFordBroncoAccessories = () => {
  const { mySite: site, storeDisplayName } = useSite();
  let port = !window.location.port.trim() ? '' : ':' + window.location.port;
  return (
    <div>
        <Helmet>
          <title>{content?.title}</title>
          <meta name="description" content={content?.metaDescription}></meta>
          <meta name="keywords" content={content?.metaKeyword}/>
          <meta property="og:site_name" content={storeDisplayName} />
          <meta property="og:url" content={(window.location.protocol +'//'+window.location.hostname+':' + port + window.location.pathname)}/>
          <meta property="og:title" content={content?.title} />
          <meta property="og:type" content="article"/>
          <meta property="og:description" content={content?.metaDescription}/>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={content?.title} />
          <meta name="twitter:description" content={content?.metaDescription}/>
         </Helmet>
      <div id="shopify-section-template-article" className="shopify-section">
        <section 
          title="Blog Hero"
          className="article-hero "
          style={{
            backgroundImage:
              "url(https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BestFordBroncoAccessories/bestbronco_3f3d9cf7-72a7-4f85-80f1-c622013388af_1500x.jpeg)",
          }}>
          <div className="article-hero__container container">
            <h1 className="article-hero__heading title--md">
              9 Best Ford Bronco Accessories
            </h1>

            <div className="article-hero__publish-date">February 01, 2023</div>
          </div>
        </section>

        <div className="article__main">
          <nav className="breadcrumbs" role="navigation">
            <ul className="flex">
              <li>
              <a href="/">
              <span>Home</span>
              </a>
                <span className="breadcrumbs-separator">&gt;</span>
              </li>
              <li>
                <Link to="/blogs/news">
                  <span>News</span>
                </Link>
              </li>
              <span className="breadcrumbs-separator">&gt;</span>
              <li>
                <span>9 Best Ford Bronco Accessories</span>
              </li>
            </ul>
          </nav>
          <script type="application/ld+json">
            {JSON.stringify(breadcrumbList)}
          </script>

          <div className="article-actions">
            <Link className="article-actions__return" to="/blogs/news">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16">
                <g>
                  <path
                    fill="#102b4e"
                    d="M8,0 C12.418,0 16,3.582 16,8 C16,12.418 12.418,16 8,16 C3.582,16 0,12.418 0,8 C0,3.582 3.582,0 8,0 Z M6,3 C5.724,3 5.474,3.112 5.293,3.293 C5.112,3.474 5,3.724 5,4 C5,4.276 5.112,4.526 5.293,4.707 L9.409,8 L5.293,11.293 C5.112,11.474 5,11.724 5,12 C5,12.276 5.112,12.526 5.293,12.707 C5.474,12.888 5.724,13 6,13 C6.276,13 6.526,12.888 6.707,12.707 L11.707,8.707 C11.888,8.526 12,8.276 12,8 C12,7.724 11.888,7.474 11.707,7.293 L6.707,3.293 C6.526,3.112 6.276,3 6,3 Z"></path>
                </g>
              </svg>
              Back to articles
            </Link>

            <div
              className="social-icons "
              itemScope
              itemType="https://schema.org/Organization">
              <link itemProp="url" href="https://accessories.ford.com" />

              <h3 className="social-icons__share-title">Share</h3>

              <div className="social-icons__list-item">
                <a
                  href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/9-best-ford-bronco-accessories&amp;text=9+Best+Ford+Bronco+Accessories"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="16"
                    viewBox="0 0 20 16">
                    <g>
                      <g>
                        <path
                          fill="#102b4e"
                          d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Twitter</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/9-best-ford-bronco-accessories"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M.29 5.24h3.2v10.764H.29z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">LinkedIn</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/9-best-ford-bronco-accessories"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="16"
                    viewBox="0 0 8 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Facebook</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <span
                  className="share"
                  onClick={handleShareClick}
                  data-copy-to-clipboard="https://accessories.ford.com/blogs/news/9-best-ford-bronco-accessories">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Share URL</span>
                </span>
              </div>
            </div>
          </div>

          <article className="article-content rte">
            <h1>
              <b></b>
            </h1>
            <p>
              <span style={{ fontWeight: 400 }}>
                After years of anticipation, the Bronco is back with a
                re-release that has masterfully integrated transportation with
                the thrill of adventure. Bronco owners are excited to make their
                vehicle their own, and what better way than customization? To
                give Bronco owners an unmatched driving experience, Ford and its
                trusted partners have crafted elite accessories that are
                engineered to perfectly fit and complement the Ford Bronco and
                Bronco Sport. Are you ready to redefine your Bronco?&nbsp;
              </span>
            </p>
            <p>&nbsp;</p>
            <h2>
              <Link to="/search?searchTerm=bronco+roof+rack+yakima">
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BestFordBroncoAccessories/Bronco_RoofRack.jpeg"
                  alt="Bronco Roof Rack"
                  style={{ fontWeight: 400 }} width={'1480'} height={'1110'}
                />
              </Link>
            </h2>
            <h2>
              <Link to="/search?searchTerm=bronco+roof+rack+yakima">
                <span style={{ fontWeight: 400 }}>Roof Rack</span>
              </Link>
            </h2>
            <p>
              <span style={{ fontWeight: 400 }}>
                Starting at the top of your Bronco (and our list), the first
                accessory you should consider for your Bronco is a classNameic
                roof rack. Serious adventurers and daily drivers alike need
                ample space for their gear, in addition to the space within
                their vehicle. From mounted cargo boxes to paddleboards and
                skis, a roof rack ensures all of your essentials can come along
                for the ride. No matter your adventure, your Bronco is ready to
                support your journey!
              </span>
            </p>
            <p>&nbsp;</p>
            <h2>
              <Link to="/bronco-2021-ford-performance-by-warn%C2%AE-winch-kit">
                <span>
                  <img
                    src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BestFordBroncoAccessories/WinchKit.jpeg"
                    alt="Winch kit"
                    style={{ fontWeight: 400 }} width={'1480'} height={'1480'}
                  />
                </span>
              </Link>
            </h2>
            <h2>
              <Link to="/bronco-2021-ford-performance-by-warn%C2%AE-winch-kit">
                <span></span>
              </Link>
            </h2>
            <h2>
              <Link to="/bronco-2021-ford-performance-by-warn%C2%AE-winch-kit">
                <span style={{ fontWeight: 400 }}>Winch Kit</span>
              </Link>
            </h2>
            <p>
              <span style={{ fontWeight: 400 }}>
                The Ford Bronco was made to conquer rugged terrain. Feel
                confident testing the strength of your vehicle’s off-road
                capabilities with the robust Ford Performance by WARN Bronco
                Winch Kit on hand. With the ability to pull 10,000 pounds, this
                winch kit was co-developed by Ford and WARN engineers to recover
                your Bronco from sticky situations and get you back into action.
                The kit includes everything you need for recovery and offers an
                OEM level of fit, finish durability, and vehicle integration.{" "}
                <Link to="/bronco-2021-ford-performance-by-warn%C2%AE-winch-kit">
                  See important vehicle and installation requirements and
                  warnings.
                </Link>
              </span>
            </p>
            <p>&nbsp;</p>
            <h2>
              <Link to="/search?searchTerm=bronco+bike+rack+yakima">
                <span>
                  <img
                    src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BestFordBroncoAccessories/Bronco_BikeRack.jpeg"
                    alt="Bronco Bike Rack"
                    style={{ fontWeight: 400 }}  width={'1480'} height={'1110'}
                  />
                </span>
              </Link>
            </h2>
            <h2>
              <Link to="/search?searchTerm=bronco+bike+rack+yakima">
                <span style={{ fontWeight: 400 }}>Bike Rack</span>
              </Link>
            </h2>
            <p>
              <span style={{ fontWeight: 400 }}>
                Fit for the outdoor enthusiast, our mounted bike racks are truly
                one of the best Bronco accessories for cycling fanatics.
                Depending on the specific bike rack model, your Bronco can carry
                up to four bikes on the hitch or rooftop of your vehicle! Our
                wide selection of bike racks contains many versatile options,
                making this one of the best Bronco Sport accessories as well.
              </span>
            </p>
            <p>&nbsp;</p>
            <h2>
              <Link to="/yakima-roof-top-2-person-hd-tent-1">
                <span>
                  <img
                    src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BestFordBroncoAccessories/Bronco_RoofTent.jpeg"
                    alt="Bronco Roof Tent"
                    style={{ fontWeight: 400 }} width={'1480'} height={'1110'}
                  />
                </span>
              </Link>
            </h2>
            <h2>
              <Link to="/yakima-roof-top-2-person-hd-tent-1">
                <span style={{ fontWeight: 400 }}>Roof Tent</span>
              </Link>
            </h2>
            <p>
              <span style={{ fontWeight: 400 }}>
                Lucky for you, camping just got a whole lot easier with your
                Bronco! Forget the dirt and pesky critters, when you invest in
                the Yakima Roof Top 2-person Heavy-Duty Tent
              </span>{" "}
              <span style={{ fontWeight: 400 }}>
                to camp on the roof of your Ford Bronco or Ford Bronco Sport,
                you’re able to adventure in every season. This tent features a
                waterproof PU coating, a built-in mattress, integrated locks,
                and a weather-shedding rainfly so you’ll have a more luxurious
                base camp on your camping excursions!
              </span>
            </p>
            <p>
              <br />
              <img
                src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BestFordBroncoAccessories/WheelsAndTires.jpeg"
                alt="Wheels and Tires" width={'1480'} height={'1480'}
              />
            </p>
            <h2>
              <Link to="/search?searchTerm=bronco+wheel+kit">
                <span style={{ fontWeight: 400 }}></span>
              </Link>
            </h2>
            <h2>
              <Link to="/search?searchTerm=bronco+wheel+kit">
                <span style={{ fontWeight: 400 }}>Wheels and Tires</span>
              </Link>
            </h2>
            <p>
              <span style={{ fontWeight: 400 }}>
                Our next best Bronco accessory is about both aesthetics and
                performance. Whether you’re going for upgraded off-road
                capabilities or a sleek city look, our wide variety of wheel
                kits give Bronco owners endless possibilities for their vehicle.
                Want to upgrade to 35” inch tires? No worries, our 17”+ wheel
                kits are the perfect fit for your upgrade. And while you’re at
                it, don’t forget to make the ultimate addition to your vehicle’s
                look with a unique{" "}
              </span>
              <Link to="/search?searchTerm=bronco+tire+cover">
                <span style={{ fontWeight: 400 }}>Bronco tire cover</span>
              </Link>
              <span style={{ fontWeight: 400 }}> of your choice. </span>
            </p>
            <p>&nbsp;</p>
            <h3>
              <Link to="/bronco-2021-safari-bar-kit">
                <span>
                  <img
                    src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BestFordBroncoAccessories/Bronco_FrontBumper.jpeg"
                    alt="Bronco Front Bumoper"  width={'804'} height={'600'}
                  />
                </span>
              </Link>
            </h3>
            <h2>
              <Link to="/bronco-2021-safari-bar-kit">
                <span style={{ fontWeight: 400 }}>Front Bumper Add-Ons</span>
              </Link>
            </h2>
            <p>
              <span>
                <span style={{ fontWeight: 400 }}>
                  Turn your Ford Bronco into an off-roading expert with modular
                  front bumper add-ons and protection. Built of cold-rolled
                  steel, our Safari Bar can be bolted to your bumper for added
                  security against jagged rocks, debris, and other obstacles.
                  For even more protection against rugged terrain, consider a{" "}
                </span>
                <Link to="/steel-full-body-bash-plate-for-vehicles-with-modular-front-bumper-2dr">
                  <span style={{ fontWeight: 400 }}>
                    steel full-body bash plate{" "}
                  </span>
                </Link>
                <span style={{ fontWeight: 400 }}>
                  to guard the underside of your vehicle.
                </span>
              </span>
            </p>
            <p>&nbsp;</p>
            <h2>
              <Link to="/search?searchTerm=bronco+step+bars">
                <span>
                  <img
                    src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BestFordBroncoAccessories/Bronco_StepBars.jpeg"
                    alt="Bronco Step Bars" width={'1480'} height={'1110'}
                  />
                </span>
              </Link>
            </h2>
            <h2>
              <Link to="/search?searchTerm=bronco+step+bars">
                <span style={{ fontWeight: 400 }}>
                  Side Steps and Step Bars
                </span>
              </Link>
            </h2>
            <p>
              <span style={{ fontWeight: 400 }}>
                For easy exits and access to your Bronco, side steps, step bars,
                and running boards are perfect for elevating your vehicle’s
                comfort and usability. Not to mention, step-ups can act as added
                protection for the body of your Bronco by shielding your doors
                from road debris and other obstacles! This accessory is
                especially important if you’ve customized your Bronco with
                larger tires.
              </span>
            </p>
            <p>&nbsp;</p>
            <h2>
              <Link to="/search?searchTerm=bronco+floor+mats">
                <span>
                  <img
                    src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BestFordBroncoAccessories/Bronco_FloorMats.jpeg"
                    alt="Bronco Floor Mats"  width={'1480'} height={'1110'}
                  />
                </span>
              </Link>
            </h2>
            <h2>
              <Link to="/search?searchTerm=bronco+floor+mats">
                <span style={{ fontWeight: 400 }}>Floor Mats</span>
              </Link>
            </h2>
            <p>
              <span style={{ fontWeight: 400 }}>
                After any adventure, dirty shoes mean you had a successful day,
                but a dirty carpet in your Bronco shouldn’t be a low point of
                your wild journey. Keep your carpet and flooring clean with
                all-weather floor mats and liners that are easy to install. Our
                mats are made of durable, Thermoplastic Elastomer (TPE) to
                provide maximum protection against mud or dirt that occurs on
                your excursions. Our wide variety of durable floor mats lets you
                keep your Bronco clean and your rough country adventures messy!
              </span>
            </p>
            <p>&nbsp;</p>
            <h2>
              <Link to="/search?searchTerm=bronco+tow+hooks">
                <span>
                  <img
                    src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BestFordBroncoAccessories/09775174-afe0-48be-9495-ed0468e50c5d_1480x_7b2a9fc5-9871-4410-b0a4-2ea56990ff02.jpeg"
                    alt="Bronco two hooks"
                    style={{ fontWeight: 400 }}  width={'1080'} height={'1080'}
                  />
                </span>
              </Link>
              <span style={{ fontWeight: 400 }}>
                <br />
              </span>
            </h2>
            <h2>
              <Link to="/search?searchTerm=bronco+tow+hooks">
                <span style={{ fontWeight: 400 }}>Tow Hooks</span>
              </Link>
            </h2>
            <p>
              <span style={{ fontWeight: 400 }}>
                Caught in a sticky situation? Don’t fret! With the installation
                of a pair of tow hooks, you can be prepared. Before your next
                adventure, be sure to snag a pair to easily attach to your
                modular front bumper. Not to mention, our tow hooks come in
                three different colors to suit each of your Bronco’s moods.{" "}
                <Link to="/2021-bronco-tow-hooks-pair-orange">
                  Orange
                </Link>
                ,{" "}
                <Link to="/2021-bronco-tow-hooks-pair-red?refSrc=7306598777023&amp;nosto=productpage-nosto-1-fallback-nosto-1">
                  red
                </Link>{" "}
                or{" "}
                <Link to="/2021-bronco-tow-hooks-pair-blue">
                  blue
                </Link>
                : there’s no limit when it comes to customizing your Bronco!{" "}
              </span>
            </p>
            <p>&nbsp;</p>
            <h2>
              <span style={{ fontWeight: 400 }}>More Ford Bronco Parts</span>
            </h2>
            <p>
              <span style={{ fontWeight: 400 }}>
                Are you looking for more ways to upgrade your Bronco with new
                accessories? From{" "}
              </span>
              <Link to="/search?searchTerm=bronco+exhaust">
                <span style={{ fontWeight: 400 }}>exhaust upgrades</span>
              </Link>
              <span style={{ fontWeight: 400 }}> to </span>
              <Link to="/search?searchTerm=bronco+splash+guards">
                <span style={{ fontWeight: 400 }}>splash guards</span>
              </Link>
              <span style={{ fontWeight: 400 }}> and </span>
              <Link to="/search?searchTerm=bronco+sound+bar">
                <span style={{ fontWeight: 400 }}>sound bars</span>
              </Link>
              <span style={{ fontWeight: 400 }}>
                , the options for your Bronco and Bronco Sport are limitless.
                Trusted Ford vehicle accessory products allow you to stay ready
                for whatever adventure awaits by adding features to your vehicle
                with the power and expertise of Ford. Set your vehicle up for
                the ultimate off-roading experience today with our wide
                selection of Ford Bronco and Ford Bronco Sport
                accessories!&nbsp;
                <br />
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BestFordBroncoAccessories/Best_Bronco_Accessories.jpeg"
                  alt="Best Bronco Accessories" width={'1800'} height={'1800'}
                />
                <br />
              </span>
            </p>
          </article>

          <div className="article-share">
            <div
              className="social-icons "
              itemScope
              itemType="https://schema.org/Organization">
              <link itemProp="url" href="https://accessories.ford.com" />

              <h3 className="social-icons__share-title">Share Article</h3>

              <div className="social-icons__list-item">
                <a
                  href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/9-best-ford-bronco-accessories&amp;text=9+Best+Ford+Bronco+Accessories"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="16"
                    viewBox="0 0 20 16">
                    <g>
                      <g>
                        <path
                          fill="#102b4e"
                          d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Twitter</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/9-best-ford-bronco-accessories"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M.29 5.24h3.2v10.764H.29z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">LinkedIn</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/9-best-ford-bronco-accessories"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="16"
                    viewBox="0 0 8 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Facebook</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <span
                  className="share"
                  onClick={handleShareClick}
                  data-copy-to-clipboard="https://accessories.ford.com/blogs/news/9-best-ford-bronco-accessories">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Share URL</span>
                </span>
              </div>
            </div>
          </div>

          <div className="article-pagination article-pagination--desktop">
            <Link
              className="article-pagination__item article-pagination__item--previous"
              to="/blogs/news/best-ford-ranger-accessories"  onClick={() => window.scrollTo(0, 0)}>
              <div className="article-pagination__arrow">
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/chevron-thin-white-right_60x.jpeg"
                  alt="Chevron White" width={'32'} height={'62'}
                />
              </div>
              <div className="article-pagination__data">
                <div className="article-pagination__label">
                  Previous Article
                </div>
                <div className="article-pagination__title">
                  Best Ford Ranger Accessories
                </div>
              </div>
            </Link>

            <Link
              className="article-pagination__item article-pagination__item--next"
              to="/blogs/news/8-best-overlanding-accessories-for-your-ford"  onClick={() => window.scrollTo(0, 0)}>
              <div className="article-pagination__data">
                <div className="article-pagination__label">Next Article</div>
                <div className="article-pagination__title">
                  8 Best Overlanding Accessories for Your Ford
                </div>
              </div>
              <div className="article-pagination__arrow">
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/chevron-thin-white-right_60x.jpeg"
                  alt="Chevron White" width={'32'} height={'62'} 
                />
              </div>
            </Link>
          </div>

          <div className="article-pagination article-pagination--mobile">
            <Link
              className="article-pagination__item article-pagination__item--previous"
              to="/blogs/news/best-ford-ranger-accessories"  onClick={() => window.scrollTo(0, 0)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="15"
                viewBox="0 0 8 15">
                <g>
                  <g transform="rotate(-270 4 7.5)">
                    <path
                      fill="#fff"
                      d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z"></path>
                  </g>
                </g>
              </svg>
              Prev Article
            </Link>

            <Link
              className="article-pagination__item article-pagination__item--next"
              to="/blogs/news/8-best-overlanding-accessories-for-your-ford"  onClick={() => window.scrollTo(0, 0)}>
              Next Article
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="15"
                viewBox="0 0 8 15">
                <g>
                  <g transform="rotate(-270 4 7.5)">
                    <path
                      fill="#fff"
                      d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z"></path>
                  </g>
                </g>
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BestFordBroncoAccessories;
