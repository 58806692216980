/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { ChangeEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OK } from "http-status-codes";
import { useTranslation } from "react-i18next";
import Axios, { Canceler } from "axios";
import { useHistory, Link, useLocation } from "react-router-dom";
import getDisplayName from "react-display-name";
import styled from "styled-components";
//Foundation libraries
import { useSite } from "../../../_foundation/hooks/useSite";
import siteContentService from "../../../_foundation/apis/search/siteContent.service";
import searchDisplayService from "../../../_foundation/apis/transaction/searchDisplay.service";
//Custom libraries
import {
  CommerceEnvironment,
  KEY_CODES,
  SEARCHTERM,
} from "../../../constants/common";
import { SEARCH } from "../../../constants/routes";
import { KEYWORD_LIMIT } from "../../../configs/catalog";
//Redux
import { currentContractIdSelector } from "../../../redux/selectors/contract";
import * as searchActions from "../../../redux/actions/search";
//UI
import {
  StyledGrid,
  StyledTextField,
  StyledIconButton,
  StyledMenuItem,
  StyledSearchBar,
  StyledMenuTypography,
} from "@hcl-commerce-store-sdk/react-component";
import { InputAdornment, ClickAwayListener } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import AdobeService from "../../../_foundation/adobeServices/adobeService";
import HTMLReactParser from "html-react-parser";

const SearchBar: React.FC<SearchBarProps> = ({
  showSearchBar,
  openSearchBar,
  closeSearchBar,
}) => {
  const widgetName = getDisplayName(SearchBar);
  const contractId = useSelector(currentContractIdSelector);
  const [keywordSuggestions, setKeywordSuggestions] = React.useState<
    Array<Object>
  >([]);
  const [categorySuggestions, setCategorySuggestions] = React.useState<
    Array<Object>
  >([]);
  const [brandSuggestions, setBrandSuggestions] = React.useState<Array<Object>>(
    []
  );
  const [productSuggestions, setProductSuggestions] = React.useState<Array<Object>>(
    []
  );
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const searchField = t("SearchBar.SearchField");
  const keywordTitle = t("SearchBar.KeywordTitle");
  const categoryTitle = t("SearchBar.CategoryTitle");
  //const brandTitle = t("SearchBar.BrandTitle");
  const productTitle = t("SearchBar.ProductTitle");
  const [input, setInput] = React.useState("");
  const [nameList, setNameList] = React.useState<Array<string>>([]);
  const [index, setIndex] = React.useState(0);
  let nameListIndex = 1;
  const { mySite } = useSite();
  const dispatch = useDispatch();
  const [showKeywords, setShowKeywords] = React.useState(false);
  const [showCategories, setShowCategories] = React.useState(false);
  const [showBrands, setShowBrands] = React.useState(false);
  const [showProducts, setShowProducts] = React.useState(false);

  const [categories, setCategories] = React.useState<Array<string>>([]);
  const [brands, setBrands] = React.useState<Array<string>>([]);
  const [categoriesUrl, setCategoriesUrl] = React.useState<Map<any, any>>(
    () => new Map()
  );

  const currentVehicle = localStorage.getItem("currentVehicle") ? localStorage.getItem("currentVehicle") : "";

  const Image = styled.img<{
    src: any;
  }>`
    position: relative;
    width: 32px;
    height: 32px;
    cursor: pointer;
    margin-bottom: 4px;
  `;
  const [inputDisabled, setinputDisabled] = React.useState(false);

  const clearSuggestions = () => {
    setIndex(0);
    setKeywordSuggestions([]);
    setCategorySuggestions([]);
    setBrandSuggestions([]);
    setProductSuggestions([]);
    setShowKeywords(false);
    setShowCategories(false);
    setShowBrands(false);
    setShowProducts(false);
  };

  const clearSuggestionsAndUpdateInputField = (str: string) => {
    clearSuggestions();
    str = callRegex(str);
    setInput(str);
    setShowSearchBar(!showSearchBar);
  };

  const clearSuggestionsAndInputField = () => {
    clearKeywords();
    clearSuggestions();
    setInput("");
  };

  const clearKeywords = () => {
    dispatch(searchActions.KEYWORDS_RESET_ACTION(""));
  };

  const setKeywordsToLocalStorage = (list: string[]) => {
    dispatch(searchActions.KEYWORDS_UPDATED_ACTION(list));
  };
  const CancelToken = Axios.CancelToken;
  let cancels: Canceler[] = [];

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  const brandPayloadBase: any = {
    widget: widgetName,
    query: {pageSize: "300"},
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  const xPayloadBase : any = {
    widget: widgetName,
    query: {searchType: "100", pageSize: "5"},
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),

  }

  if (currentVehicle != "" && mySite.storeCfg.userData["ford.YMMFilter"]) {
    xPayloadBase["query"] = {
      ...xPayloadBase["query"],
      "_wcf.search.filter.expr" : "facets."+ mySite.storeCfg.userData["ford.YMMFilter"] +".value.raw:\""+currentVehicle+"\"",
    };
  }

  useEffect(() => {
    if (mySite && contractId) {
      const catalogId = mySite?.catalogID;
      const parameters: any = {
        responseFormat: "application/json",
        suggestType: ["Category", "Brand"],

        contractId: contractId,
        catalogId: catalogId,
        ...brandPayloadBase,
      };
      siteContentService
        .findSuggestionsUsingGET(parameters)
        .then((res) => {
          if (res.status === OK) {

            const categoriesResponse = res?.data?.suggestionView?.find(i => i.identifier === "Category")?.entry;
            const brandsResponse =  res?.data?.suggestionView?.find(i => i.identifier === "Brand")?.entry;
            generateCategoriesList(categoriesResponse);
            generateCategoriesURL(categoriesResponse);
            generateBrandsList(brandsResponse);
            setinputDisabled(false);
          }
        })
        .catch((e) => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mySite, t, contractId]);

  useEffect(() => {
    const queryString = location.search;
    const params = new URLSearchParams(queryString);
    const searchTermValue = params.get(SEARCHTERM);
    if (searchTermValue === null) {
      setInput("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateCategoriesList = (categoriesResponse: any[]) => {
    const lists: string[] = [];
    for (let i = 0; i < categoriesResponse.length && categoriesResponse[i].shortDescription != "Ford_External_Link"; i++) {
      lists.push(categoriesResponse[i].fullPath);
    }
    setCategories(lists);
  };
  const generateBrandsList = (brandsResponse: any[]) => {
    const lists: string[] = [];
    if (brandsResponse) {
      for (let i = 0; i < brandsResponse.length; i++) {
        lists.push(brandsResponse[i].name);
      }
      setBrands(lists);
    }
  };

  const generateCategoriesURL = (categoriesResponse: any[]) => {
    const categoriesUrl = new Map();
    for (let i = 0; i < categoriesResponse.length; i++) {
      let url = categoriesResponse[i].seo ? categoriesResponse[i].seo.href : "";
      categoriesUrl.set(categoriesResponse[i].fullPath, url);
    }
    setCategoriesUrl(categoriesUrl);
  };

  const handleLookAheadSearch = (event: ChangeEvent, type: string) => {
    event.persist();
    const element = event.currentTarget as HTMLInputElement;
    if(element.value !== "") {
      sessionStorage.removeItem("selectedPage");
      sessionStorage.removeItem("selectedPageOffSet");
      }
    setInput(element.value);
    retrieveSuggestions(element.value);
  };

  const retrieveSuggestions = (searchTerm: any) => {
    searchTerm = searchTerm.trim();
    if (searchTerm.length > 1) {
      setTimeout(function () {
        const storeID = mySite.storeID;
        const catalogId = mySite.catalogID;

        const parameters: any = {
          responseFormat: "application/json",
          storeId: storeID,
          term: searchTerm,
          limit: KEYWORD_LIMIT,
          contractId: contractId,
          catalogId: catalogId,
          suggestType: ["Keyword", "Product"],
          ...xPayloadBase,
        };

        siteContentService
          .findSuggestionsUsingGET(parameters)
          .then((res) => {
            if (res.status === OK) {
              const keywordSuggestions = res?.data?.suggestionView?.find(i => i.identifier === "Keyword")?.entry;
              const productResponse = res?.data?.suggestionView?.find(i => i.identifier === "Product")?.entry;
              if (keywordSuggestions) {
                let list: string[] = [];
                generateSuggestionList(keywordSuggestions, searchTerm, list);
                generateCatgoriesAndBrandsSuggestions(searchTerm, list);
                generateProductSuggestion(productResponse,list);
                setNameList(list);
              }
            }
          });
      }, 300);
    }
    clearKeywords();
    clearSuggestions();
  };

  const generateCatgoriesAndBrandsSuggestions = (
    userInput: string,
    listTemp: string[]
  ) => {
    const regex = new RegExp(userInput, "ig");
    const matchedCategories = categories?.filter((e) => e.match(regex));
    let lists: object[] = [];
    if (matchedCategories) {
      for (let suggestion of matchedCategories) {
        if (lists.length === 4) {
          break;
        }
        let suggestionSkeleton = JSON.parse(
          JSON.stringify(CommerceEnvironment.suggestionSkeleton)
        );

        suggestionSkeleton.arrIndex = nameListIndex;
        suggestionSkeleton.id = "";
        suggestionSkeleton.name = suggestion;
        suggestionSkeleton.url = categoriesUrl.get(suggestion);
        nameListIndex++;
        lists.push(suggestionSkeleton);
        listTemp.push(suggestion);
      }
    }
    setCategorySuggestions(lists);
    setShowCategories(true);
    const matchedBrands = brands?.filter((e) => e.match(regex));
    let lists2: object[] = [];
    if (matchedBrands) {
      for (let suggestion of matchedBrands) {
        if (lists2.length === 4) {
          break;
        }
        let suggestionSkeleton = JSON.parse(
          JSON.stringify(CommerceEnvironment.suggestionSkeleton)
        );

        suggestionSkeleton.arrIndex = nameListIndex;
        suggestionSkeleton.id = "";
        suggestionSkeleton.name = suggestion;
        suggestionSkeleton.url = SEARCH + "?" + SEARCHTERM + "=" + suggestion;
        nameListIndex++;
        lists2.push(suggestionSkeleton);
        listTemp.push(suggestion);
      }
    }
    setBrandSuggestions(lists2);
    setShowBrands(true);
  };



  const generateProductSuggestion = (
    productsResponse: any[],
    listTemp: string[]
  ) => {
    

    let lists2: object[] = [];
    if (productsResponse && productsResponse.length > 0) {
      for (let suggestion of productsResponse) {
        if (lists2.length === 4) {
          break;
        }
        let suggestionSkeleton = JSON.parse(
          JSON.stringify(CommerceEnvironment.suggestionSkeleton)
        );

        suggestionSkeleton.arrIndex = nameListIndex;
        suggestionSkeleton.id = "";
        suggestionSkeleton.name = suggestion.name;
        suggestionSkeleton.thumbnail = suggestion.thumbnail;
        suggestionSkeleton.partNumber = suggestion.partNumber;
        suggestionSkeleton.url = suggestion.seo.href;
        nameListIndex++;
        lists2.push(suggestionSkeleton);
        listTemp.push(suggestion.name);
      }
    }
    setProductSuggestions(lists2);
    setShowProducts(true);
  };


  const removeSpecialCharactors = (searchText: any) => {
    if (searchText.slice(-1) === '-') {
      searchText = searchText.slice(0, -1)
    }    

    if (searchText?.match(/"/g)?.length === 1) {
      searchText = searchText.replace('"', ' ')
    }

    const pattern = [/:/g, /\//g];
    pattern.forEach(ele => {
      searchText = searchText.replace(ele, ' ')
                              .replace('  ', '');
    })

    return searchText;
  }



  const generateSuggestionList = (
    keywordSuggestions: any[],
    userInput: string,
    listTemp: string[]
  ) => {
    const lists: object[] = [];

    listTemp.push(userInput);
    const listTemp2: string[] = [];

    for (let suggestion of keywordSuggestions) {
      if (keywordSuggestions) {
        let suggestionSkeleton = JSON.parse(
          JSON.stringify(CommerceEnvironment.suggestionSkeleton)
        );
        let sTerm = removeSpecialCharactors(suggestion.term);
        if (listTemp2.indexOf(sTerm) === -1) {
          suggestionSkeleton.arrIndex = nameListIndex;
          suggestionSkeleton.id = "";
          suggestionSkeleton.name = sTerm;
          suggestionSkeleton.url =
            SEARCH + "?" + SEARCHTERM + "=" + sTerm;
          listTemp.push(sTerm);
          lists.push(suggestionSkeleton);
          listTemp2.push(sTerm);
          nameListIndex++;
        }
      }
    }
    setKeywordSuggestions(lists);
    setKeywordsToLocalStorage(listTemp2);
    setShowKeywords(true);
  };

  const callRegex = (str: string) => {
    const regex2 = new RegExp(">", "ig");
    let arr: string[];
    if (str.match(regex2)) {
      arr = str.split(">");
      str = arr[arr.length - 1].trim();
    }
    return str;
  };
  const onKeyDown = (e) => {
    let len = nameList ? nameList.length : 0;
    let str = "";
    if(e.keyCode === 13){
      retrieveSuggestions(input);
    }
    if (e.keyCode === KEY_CODES.UP) {
      e.preventDefault();

      if (index === 0) {
        return;
      }
      setIndex(index - 1);
      if (nameList) {
        str = callRegex(nameList[index - 1]);
        setInput(str);
      }
    } else if (e.keyCode === KEY_CODES.DOWN) {
      e.preventDefault();

      if (index === len - 1) {
        setIndex(0);
        if (nameList) {
          str = callRegex(nameList[0]);
          setInput(str);
        }
        return;
      }
      setIndex(index + 1);
      if (nameList) {
        str = callRegex(nameList[index + 1]);
        setInput(str);
      }
    }
  };

  const submitSearch = (props: any) => {
    props.preventDefault();
    clearSuggestions();

    if (input && input.trim() !== "") {
      let url = "";
      const storeID = mySite.storeID;
      const searchTerm = input.trim();
      const parameters: any = {
        storeId: storeID,
        searchTerm: searchTerm,
        ...payloadBase,
      };
      searchDisplayService
        .getSearchDisplayView(parameters)
        .then((res) => {
          if (res.status === OK) {
            url = res?.data.redirecturl;

            if (url === undefined) {
              url = SEARCH + "?" + SEARCHTERM + "=" + searchTerm;
            }
            redirectTo(url);
          }
        })
        .catch((e) => {
          url = SEARCH + "?" + SEARCHTERM + "=" + searchTerm;
          redirectTo(url);
        });
         //  Adobe Tag Implementation
         (async () => {
          let res = await AdobeService.track({
            pageName: "search results",
            onclickLinkName:`search catalog:<nameplate>`,
            onClick:`search catalog:${searchTerm}`,
            variantName: "search-catalog",
            siteSection: "search",
            pagenameNoProduct: "search results",
            hierarchy: "search",
            eventType: "globalSearch",
          });
          AdobeService.callSatellite(res);
        })();
    }
  };

  const redirectTo = (url: string) => {
    clearSuggestions();
    setShowSearchBar(false);
    //redirect
    if (url.startsWith("http")) {
      window.location.href = url;
    } else {
      history.push(url);
    }
  };

  const clickAway = (prev) => {
    clearSuggestionsAndInputField()
    setShowSearchBar(!prev);
  };

  const setShowSearchBar = (boolean) => {
    if (boolean) {
      openSearchBar();
    } else {
      closeSearchBar();
    }
  };


  
  return (
    <ClickAwayListener onClickAway={clickAway}>
      <StyledSearchBar className="searchContainer">
        <form onSubmit={submitSearch} noValidate>
          <StyledTextField
            margin="normal"
            size="small"
            autoFocus
            className="searchInputText"
            autoComplete="off"
            type="text"
            disabled={inputDisabled}
            placeholder={searchField}
            value={input}
            name="searchTerm"
            onChange={(e) => handleLookAheadSearch(e, "searchTerm")}
            onKeyDown={onKeyDown}
            InputProps={{
              endAdornment: (
                <>
                  {showKeywords || showCategories || showProducts ? (
                    <InputAdornment position="end">
                      <StyledIconButton onClick={clearSuggestionsAndInputField}>
                        <CloseIcon titleAccess={t("SearchBar.Clear")} />
                      </StyledIconButton>
                    </InputAdornment>
                  ) : (
                      <InputAdornment position="start">
                      <SearchIcon
                        onClick={() => setShowSearchBar(!showSearchBar)}
                      />
                    </InputAdornment>
                  )}
                </>
              ),
            }}
          />
        </form>

        {(showKeywords || showCategories || showProducts) && (
          <ClickAwayListener
            onClickAway={() => {
              //clearSuggestionsAndInputField();
            }}>
            <ul className="searchBar-results">
              {showKeywords && (
                <>
                  <StyledMenuTypography
                    variant="body2"
                    className="searchBar-resultsCategory">
                    {keywordTitle}
                  </StyledMenuTypography>
                  {keywordSuggestions?.map((e: any, i: number) => (
                    <Link
                      className="Testcase1-link"
                      key={'brand-'+i}
                      to={e.url}
                      onClick={() =>
                        clearSuggestionsAndUpdateInputField(e.name)
                      }>
                      <StyledMenuItem>
                        <StyledMenuTypography
                          variant="body1"
                          className={e.arrIndex === index ? "active" : ""}
                          key={e.arrIndex}
                          id={'megamenu_department_'+e.id}
                          title={e.name}>
                          {e.name}
                        </StyledMenuTypography>
                      </StyledMenuItem>
                    </Link>
                  ))}
                </>
              )}

              {showCategories && (
                <>
                  <StyledMenuTypography
                    variant="body2"
                    className="searchBar-resultsCategory">
                    {categoryTitle}
                  </StyledMenuTypography>
                  {categorySuggestions?.map((e: any, i: number) => (
                    <Link
                      key={'category-'+i}
                      to={e.url}
                      onClick={(evt) =>
                        clearSuggestionsAndUpdateInputField(e.name)
                      }>
                      <StyledMenuItem>
                        <StyledMenuTypography
                          variant="body1"
                          className={e.arrIndex === index ? "active" : ""}
                          key={e.arrIndex}
                          id={'megamenu_department_'+e.id}
                          title={e.name}>
                          {e.name}
                        </StyledMenuTypography>
                      </StyledMenuItem>
                    </Link>
                  ))}
                </>
              )}

              {showProducts && (
                <>
                  <StyledMenuTypography
                    variant="body2"
                    className="searchBar-resultsCategory">
                    {productTitle}
                  </StyledMenuTypography>
                  {productSuggestions?.map((e: any, i: number) => (
                    <Link
                     className="searchCatThumb"
                      key={'product-'+i}
                      to={e.url}
                      onClick={(evt) =>
                        clearSuggestionsAndUpdateInputField(e.name)
                      }>
                      <StyledMenuItem>
                        <Image
                          className="boost-pfs-filter-product-item-image"
                          src={e.thumbnail}></Image>
                        <StyledGrid className="searchlistDesc">
                          <StyledMenuTypography
                            style={{ paddingLeft: "10px" }}
                            variant="body1"
                            className={e.arrIndex === index ? "active" : ""}
                            key={e.arrIndex}
                            id={'megamenu_department_'+e.id}
                            title={HTMLReactParser(e.name)}>
                            {HTMLReactParser(e.name)} <br/>
                            {e.partNumber}
                          </StyledMenuTypography>
                        </StyledGrid>
                      </StyledMenuItem>
                    </Link>
                  ))}
                </>
              )}
            </ul>
          </ClickAwayListener>
        )}
      </StyledSearchBar>
    </ClickAwayListener>
  );
};

interface SearchBarProps {
  showSearchBar: boolean;
  openSearchBar: any;
  closeSearchBar: any;
}

export { SearchBar };
