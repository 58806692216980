/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */

export const FETCH_POPULAR_PRODUCT_LIST = "FETCH_POPULAR_PRODUCT_LIST";
export const RESPONSE_SUCCESSED = "RESPONSE_SUCCESSED";
export const RESPONSE_FAILURE = "RESPONSE_FAILURE";
