import { createReducer, AnyAction } from "@reduxjs/toolkit";
//Redux
import initStates from "./initStates";
import * as ACTIONS from "../action-types/popular-product";
const popularProductReducer = createReducer(
  initStates.popularProducts,
  (builder) => {
    builder.addCase(
      ACTIONS.RESPONSE_SUCCESSED,
      (state: any, action: AnyAction) => {
        const response = action.response;
        state.productList = response.MarketingSpotData;
      }
    );

    builder.addCase(
      ACTIONS.RESPONSE_FAILURE,
      (state: any, action: AnyAction) => {
        const response = action.response;
        state.productList = response;
      }
    );
  }
);
export { popularProductReducer };
