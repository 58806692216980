import { call, put } from "redux-saga/effects";
//Foundation libraries
import mockServerService from "../../../_foundation/apis/mock/mockserver.service";
import { getSite } from "../../../_foundation/hooks/useSite";
//Redux
import {
  RESPONSE_SUCCESSED,
  RESPONSE_FAILURE,
} from "../../action-types/product-discription-page-type";

export function* getPDPProduct(action: any) {
  try {
    //console.log("calling in saga");
    const siteInfo = getSite();
    const payload = action.payload;
    const response = yield call(
      mockServerService.getMock,
      {},
      {},
      `${siteInfo?.transactionContext}/store/${siteInfo?.storeID}/product-discription-page`
    );

    yield put({
      payload: payload,
      type: RESPONSE_SUCCESSED,
      response: response.data,
    });
  } catch (error) {
    yield put({ type: RESPONSE_FAILURE, response: error });
  }
}
