import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  StyledTypography,
} from "@hcl-commerce-store-sdk/react-component";
import { Link } from "react-router-dom";
import OrderDetailTable from "../../MyAccount/OrderDetailTable";
import PackageSlipPopup from './packingslip/PackageSlipPopup';
import orderService from "../../../_foundation/apis/transaction/order.service";
import { Backdrop, CircularProgress, makeStyles, } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
const OrderReturnLabelSlip = (props: any) => {
  // const auth = orderService.getAuthorization();
  const [openModal, setOpenModal] = useState<any>();
  const [showPackageSlip, setShowPackageSlip] = useState<boolean>(false);
  const [loader, setLoader] = useState(false);
  const classes = useStyles();

  let returnList = props?.location.state.orderDetails?.ReturnOrders.ReturnOrder.filter((val) => (val.OrderNo === props?.location.state.orderId))

  const handleClose = (val) => {
    setOpenModal(val);
  };

  const printCallback = (data) => {
    let winparams = 'dependent=yes,locationbar=no,scrollbars=yes,menubar=yes,' +
      'resizable,screenX=50,screenY=50,width=850,height=1050';

    let htmlPop = '<embed width=100% height=100%'
      + ' type="application/pdf"'
      + ' src="data:image/png;base64,'
      + escape(data)
      + '"></embed>';

    let printWindow: any = window.open("", "PDF", winparams);
    printWindow.document.write(htmlPop);
  }

  const returnLabelHandle = (e, ele) => {
    setLoader(true)
    e.preventDefault();
    let data = {
      "OrderLines": {
        "OrderLine": {
          "Item": {
            "ItemID": ele.Item.ItemID
          },
          "OrderLineKey": ele.OrderLineKey
        }
      },
      "OrderNo": props?.location.state.returnOrderDetails.OrderNo,
      "OrderHeaderKey": props?.location.state.returnOrderDetails.OrderHeaderKey
    };

    orderService.returnLabel(data)
      .then((response) => {
        setLoader(false)
        printCallback(response.data.ReturnLabel)
      })
      .catch((err) => {
        setLoader(false)
        //console.log("AXIOS ERROR: ", err);
      });
  }

  const showSlipPopup = (val) => {
    setShowPackageSlip(val);
    setOpenModal(val);
  }

  return (
    <>
    <Backdrop open={loader} className={classes.backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      <div id="orderLabel">
        <div className="return-details container">
          <StyledTypography variant="h1" className="return-details__title text-align-center">Confirmation of Return</StyledTypography>
          <p className="return-details__description text-align-center">Thank you for actioning your return. <br /> More information about returns can be found on the <Link to="/Faq" >FAQ</Link> page.</p>
        </div>

        <div className="return-details-items container">
          <ul className="return-details-items__content" >
            {
              returnList[0].OrderLines?.OrderLine.map((ele) => {
              return (<li className="return-details-items__content-product">
                <div className="return-details-items__content-product-info" style={{ width: '50%' }}>
                  <OrderDetailTable partNumber={ele.Item.ItemID}></OrderDetailTable>
                </div>

                <p className="return-details-items__content-product-price" style={{ marginBottom: '4.5em' }}>
                  QTY:{ele.OrderedQty}
                </p>
                <p className="return-details-items__content-product-total" style={{ marginBottom: '4.5em' }}>
                  ${Number(ele.LinePriceInfo.UnitPrice).toFixed(2)}
                </p>
                <p className="return-details-items__content-product-total" style={{ width: '30%' }}>
                  <div className="return-details__form-actions">
                      <button type="submit" onClick={evt => showSlipPopup(true)}
                        className="return-details__form-actions-cta btn btn--rounded btn-blue-hover">
                        Download Return Slip
                      </button>
                      <button type="submit" onClick={evt => returnLabelHandle(evt, ele)}
                        className="return-details__form-actions-cta btn btn--rounded btn-blue-hover">
                        Download Return Label
                      </button>

                  </div>
                </p>
              </li>
              )
            })}
          </ul>
        </div>
        {showPackageSlip && openModal && (
          <>
            <div className="state-popup">
              <div className="state-popup__inner flex f-dir-column">
                <span className="state-popup__close" onClick={evt => handleClose(false)}>×</span>
                <div className="return-modal-content-container">
                  <PackageSlipPopup
                    OrderLines={props.location.state.OrderLines}
                    orderData={props.location.state.orderDetails}
                    returnReason={props.location.state.ReturnReason}
                    partNumbers={props?.location.state.partNumbers}
                    closePackageSlipPopup={() => setShowPackageSlip(false)} />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );

}

export default OrderReturnLabelSlip;
