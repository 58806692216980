import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import jsonData from "../staticPagesMetaData.json";
import { useSite } from "../../../../_foundation/hooks/useSite";
import { Helmet } from "react-helmet";

const content =jsonData.contents[9]?.BestFordMustang;

const handleShareClick = (event: React.MouseEvent<HTMLSpanElement>) => {
  const url = event.currentTarget.dataset.copyToClipboard!;
  navigator.clipboard
    .writeText(url)
    .then(() => {
      const confirmation = window.prompt(
        "Copy to clipboard: Ctrl/Cmd+C, Enter",
        url
      );
      if (confirmation) {
        navigator.clipboard.writeText(url);
      }
    })
    .catch(() => {
      window.alert(`Failed to copy link to clipboard: ${url}`);
    });
};

const breadcrumbList = {
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  itemListElement: [
    {
      "@type": "ListItem",
      position: 1,
      name: "Home",
      item: "https://accessories.ford.com/",
    },
    {
      "@type": "ListItem",
      position: 2,
      name: "News",
      item: "https://accessories.ford.com/blogs/news",
    },
    {
      "@type": "ListItem",
      position: 3,
      name: "Best Ford Mustang Accessories",
      item: "https://accessories.ford.com/blogs/news/best-ford-mustang-accessories",
    },
  ],
};

const BestFordMustangAccessories = () => {
  const {storeDisplayName } = useSite();
  let port = !window.location.port.trim() ? '' : ':' + window.location.port;
  return (
    <div>
       <Helmet>
          <title>{content?.title}</title>
          <meta name="description" content={content?.metaDescription}></meta>
          <meta name="keywords" content={content?.metaKeyword}/>
          <meta property="og:site_name" content={storeDisplayName}/>
          <meta property="og:url" content={(window.location.protocol +'//'+window.location.hostname+':' + port + window.location.pathname)}/>
          <meta property="og:title" content={content?.title}/>
          <meta property="og:type" content="article"/>  
          <meta property="og:description" content={content?.metaDescription}/>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={content?.title}/>  
          <meta name="twitter:description" content={content?.metaDescription} />
        </Helmet>
      <div id="shopify-section-template-article" className="shopify-section">
        <section title="Blog Hero"
          className="article-hero "
          style={{
            backgroundImage:
              "url(https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BestFordMustangAccessories/Mustang_RS_1500x.jpeg)",
          }}>
          <div className="article-hero__container container">
            <h1 className="article-hero__heading title--md">
              Best Ford Mustang Accessories
            </h1>

            <div className="article-hero__publish-date">March 14, 2023</div>
          </div>
        </section>
        <div className="article__main">
          <nav className="breadcrumbs" role="navigation">
            <ul className="flex">
              <li>
              <a href="/">
              <span>Home</span>
              </a>
                <span className="breadcrumbs-separator">&gt;</span>
              </li>
              <li>
                <Link to="/blogs/news">
                  <span>News</span>
                </Link>
              </li>
              <span className="breadcrumbs-separator">&gt;</span>
              <li>
                <span>Best Ford Mustang Accessories</span>
              </li>
            </ul>
          </nav>
          <script type="application/ld+json">
            {JSON.stringify(breadcrumbList)}
          </script>
          <div className="article-actions">
            <Link className="article-actions__return" to="/blogs/news">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16">
                <g>
                  <path
                    fill="#102b4e"
                    d="M8,0 C12.418,0 16,3.582 16,8 C16,12.418 12.418,16 8,16 C3.582,16 0,12.418 0,8 C0,3.582 3.582,0 8,0 Z M6,3 C5.724,3 5.474,3.112 5.293,3.293 C5.112,3.474 5,3.724 5,4 C5,4.276 5.112,4.526 5.293,4.707 L9.409,8 L5.293,11.293 C5.112,11.474 5,11.724 5,12 C5,12.276 5.112,12.526 5.293,12.707 C5.474,12.888 5.724,13 6,13 C6.276,13 6.526,12.888 6.707,12.707 L11.707,8.707 C11.888,8.526 12,8.276 12,8 C12,7.724 11.888,7.474 11.707,7.293 L6.707,3.293 C6.526,3.112 6.276,3 6,3 Z"></path>
                </g>
              </svg>
              Back to articles
            </Link>
            <div
              className="social-icons "
              itemScope
              itemType="https://schema.org/Organization">
              <link itemProp="url" href="https://accessories.ford.com" />
              <h3 className="social-icons__share-title">Share</h3>

              <div className="social-icons__list-item">
                <a
                  href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/best-ford-mustang-accessories&amp;text=Best+Ford+Mustang+Accessories"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="16"
                    viewBox="0 0 20 16">
                    <g>
                      <g>
                        <path
                          fill="#102b4e"
                          d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Twitter</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/best-ford-mustang-accessories"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M.29 5.24h3.2v10.764H.29z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">LinkedIn</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/best-ford-mustang-accessories"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="16"
                    viewBox="0 0 8 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Facebook</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <span
                  className="share"
                  onClick={handleShareClick}
                  data-copy-to-clipboard="https://accessories.ford.com/blogs/news/best-ford-mustang-accessories">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Share URL</span>
                </span>
              </div>
            </div>
          </div>
          <article className="article-content rte">
            <p>
              <span
                style={{
                  fontWeight: 400,
                  fontSize: "16px",
                  color: "#4d4d4d",
                  letterSpacing: "1.5px",
                }}>
                As one of the most recognizable sports cars on the road today,
                the Ford Mustang continues to provide a top-quality driving
                experience centered around performance. And with several ways to
                customize the Mustang through accessory products, drivers can
                take pride in their car like never before by truly making it
                their own. We’ve put together a list of the ten best Ford
                Mustang accessories that are engineered to perfectly fit your
                vehicle and complement your unique driving style!
              </span>
            </p>
            <br />
            <p>
              <span style={{fontWeight: 400}}>
                Customizing your vehicle with the best Mustang accessories can
                be very exciting, but with so many products, it is important to
                select options that tailor to the individual ways you use your
                car. Whether you’re looking for accessories that increase your
                Mustang’s performance, curb appeal, or ease of use, we’ve put
                together a list of the ten best Ford accessory products that are
                made for your exact Mustang model. Sit down, buckle up and get
                ready to dive in!
              </span>
            </p>
            <br />
            <img
              src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BestFordMustangAccessories/Mustang_CarCover.jpeg"
              alt="Mustang Car Cover" width={'1000'} height={'100%'}
            />
            <br />
            <h2>
              <Link to="/2015-2020-mustang-coupe-ford-performance-car-cover">
                <span style={{fontWeight: 400}}>Car Cover</span>
              </Link>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                We know you never want to be away from your beloved Mustang, but
                there are often times when you have to store your vehicle or
                when it is forced to endure the outside elements. Keep your ride
                protected from dust, debris, and wild weather conditions with a
                storage cover that is engineered to fit your Mustang model. This
                Ford Performance Car Cover fits the 2015-2022 Mustang GT and
                Mustang EcoBoost Coupe Models with or without factory spoiler
                options and is made from WeatherShield Fabric with Nextec
                encapsulation for excellent protection and compact
                storage.&nbsp;
              </span>
            </p>
            <br />
            <p>
              <span style={{fontWeight: 400}}>
                Plus, this cover keeps your car looking good even when it’s
                covered up with a sleek gray cover featuring a silk-screened
                Ford Performance logo, Pony logo on the grille, and Mustang logo
                on the rear. Help keep your car looking its best for the next
                time you drive it with a protective cover!
              </span>
            </p>
            <br />
            <img
              src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BestFordMustangAccessories/Mustang_HoodLiftKit.jpeg"
              alt="Mustang Hood Lift Kit" width={'1480'} height={'100%'}
            />
            <br />
            <h2>
              <Link to="/2015-2021-mustang-hood-lift-kit-w-laser-engraved-ford-performance-logo">
                <span style={{fontWeight: 400}}>Hood Lift Kit</span>
              </Link>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                Let the Mustang 2015-2022 Hood Lift Kit do the heavy lifting for
                you. Once you simply start to lift your hood, the system takes
                over by lifting and firmly holding your hood at the raised
                position. The gas struts are rated at 90 pounds to hold up OEM
                and specialty hoods. Additionally, your engine bay will look
                clean with a durable black powder-coated finish on the struts’
                lightweight steel and aluminum material.
              </span>
            </p>
            <br />
            <img
              src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BestFordMustangAccessories/Mustang_CargoAreaLiner.jpeg"
              alt="Mustang Cargo Area Liner" width={'3600'} height={'100%'}
            />
            <br />
            <h2>
              <Link to="/mustang-2015-2021-cargo-area-liner-1">
                <span style={{fontWeight: 400}}>Cargo Area Liner</span>
              </Link>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                When you drive a vehicle like a Ford Mustang, keeping it nice
                and clean is a must. Our durable cargo liners protect your trunk
                from spills, dirt, and grime with stain-resistant polyethylene
                material. This lightweight tray features a raised lip to help
                contain spills and since it’s a Ford Accessories product that is
                engineered to perfectly fit your Mustang, it molds into the
                contours of our vehicle’s trunk. Accidents happen so keep your
                Mustang prepared and protected from the next spill or muddy
                adventure with a liner that can easily be wiped off without a
                trace!
              </span>
            </p>
            <br />
            <img
              src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BestFordMustangAccessories/Mustang_FloorMats.jpeg"
              alt="Mustang Floor Mats" width={'576'} height={'100%'}
            />
            <br />
            <h2>
              <Link to="/search?searchTerm=mustang+floor+mat">
                <span style={{fontWeight: 400}}>Floor Mats</span>
              </Link>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                If you’re protecting your trunk from spills in the cargo area,
                it might be a good idea to protect your car floor with floor
                mats as well. It’s common to track in dirt, dust, and other
                debris on our shoes when we get in our car, so having an extra
                layer between your shoes and your Mustang’s interior is never a
                bad idea. Premium-grade{" "}
              </span>
              <Link to="/search?searchTerm=mustang+carpet+floor+mat">
                <span style={{fontWeight: 400}}>carpet floor mats</span>
              </Link>
              <span style={{fontWeight: 400}}>
                {" "}
                are a great option for a little extra cushion under your feet,
                while all-weather,{" "}
              </span>
              <Link to="/search?searchTerm=mustang+rubber+floor+mat">
                <span style={{fontWeight: 400}}>rubber floor mats</span>
              </Link>
              <span style={{fontWeight: 400}}>
                {" "}
                are helpful for messy situations as they are durable and easy to
                clean. Additionally, all mats have a nib or acoustic backing to
                prevent slippage on even your wildest thrill rides.
              </span>
            </p>
            <p>
              <span style={{fontWeight: 400}}>
                <br />
              </span>
              <span style={{fontWeight: 400}}>
                Regardless of what type of floor mat is best for you and the way
                you use your Mustang, you can be confident knowing that our mats
                are custom fit to the exact contour of each vehicle. While
                you’re browsing our floor mat options, be sure to check model
                year compatibility to ensure a perfect fit!
              </span>
            </p>
            <br />
            <img
              src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BestFordMustangAccessories/Mustang_DoorSillPlates.jpeg"
              alt="Mustang Door Sill Plates" width={'3600'} height={'100%'}
            />
            <br />
            <h2>
              <Link to="/mustang-2015-2021-illuminated-door-sill-plates-1">
                <span style={{fontWeight: 400}}>Door Sill Plates</span>
              </Link>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                We know you’re proud of your Mustang, so let the world know what
                you drive with illuminated{" "}
              </span>
              <Link to="/door-sill-plates">
                <span style={{fontWeight: 400}}>door sill plates</span>
              </Link>
              <span style={{fontWeight: 400}}>
                {" "}
                whenever you open your car door! These brushed-stainless steel
                sill plates emit a white LED glow that spells out the vehicle’s
                name and shut off automatically when your car doors close.
              </span>
            </p>
            <br />
            <img
              src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BestFordMustangAccessories/Mustang_CarbonFiberShiftKnob.jpeg"
              alt="Mustang Carbon Fiber Shift Knob" width={'1480'} height={'100%'}
            />
            <br />
            <h2>
              <Link to="/mustang-2015-2021-black-carbon-fiber-6-speed-shift-knob-1">
                <span style={{fontWeight: 400}}>
                  Black Carbon Fiber 6-Speed Shift Knob
                </span>
              </Link>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                Now that you’ve seen some functional Mustang accessories for
                accessibility and ease of use, let’s dive into some of our
                carbon fiber accessory options. Our Mustang 2015-2022 Black
                Carbon Fiber 6-Speed Shift Knob adds a sleek look to the
                interior of your Mustang while making gear shifting even more
                fun. Not only will you be shifting with style, but you’ll be
                using a smooth shift knob that gives a custom feel to your
                Mustang.&nbsp;
              </span>
            </p>
            <br />
            <img
              src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BestFordMustangAccessories/Mustang_CarbonFiberRearPanel.jpeg"
              alt="Mustang Carbon Fiber Rear Panel" width={'1480'} height={'100%'}
            />
            <br />
            <h2>
              <Link to="/2020-21-gt500-carbon-fiber-rear-deck-lid-trim-panel">
                <span style={{fontWeight: 400}}>
                  Carbon Fiber Rear Deck Lid Trim Panel
                </span>
              </Link>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                Customize your 2020-2022 Shelby GT500 with a carbon fiber rear
                deck lid trim panel! This top-quality product from Ford
                Performance is a direct replacement of the OE component and
                features 2x2 twill carbon fiber cloth with a gloss finish.
                Complete with a cobra snake emblem installed, your Mustang
                Shelby will look even better than ever!
              </span>
            </p>
            <br />
            <img
              src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BestFordMustangAccessories/Mustang_CarbonFiberFrontSplitter.jpeg"
              alt="Mustang Carbon Fiber Front Splitter" width={'1480'} height={'100%'}
            />
            <br />
            <h2>
              <Link to="/2020-21-gt500-carbon-fiber-front-splitter-kit">
                <span style={{fontWeight: 400}}>
                  Carbon Fiber Front Splitter Kit
                </span>
              </Link>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                To round out the carbon fiber additions to your Mustang, add the
                Ford Performance Carbon Fiber Front Splitter Kit to your
                2020-2022 Shelby GT500. A direct replacement of the OE
                component, it is made of clear-coated dry carbon fiber that
                weighs 4 lbs less than stock components to take your ride
                experience to the next level!
              </span>
            </p>
            <br />
            <img
              src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BestFordMustangAccessories/Mustang_CobraEmblem.jpeg"
              alt="Mustang Cobra Emblem" width={'1480'} height={'100%'}
            />
            <br />
            <h2>
              <Link to="/search?searchTerm=mustang+emblem">
                <span style={{fontWeight: 400}}>Emblem Enhancements</span>
              </Link>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                We know you’re proud to be a Mustang driver, so why not show off
                that pride and add a little extra Mustang spirit to your car? We
                have emblem enhancements for wherever you want to place them!
                From branded wheel center caps to dash and fender emblems for
                Mustang models dating back to 1979, the options are endless with
                available Ford Accessories.
              </span>
            </p>
            <br />
            <img
              src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/BestFordMustangAccessories/Mustang_Dashcam.jpeg"
              alt="Mustang Dashcam" width={'1480'} height={'100%'}
            />
            <br />
            <h2>
              <Link to="/dashcam-140-dash-camera">
                <span style={{fontWeight: 400}}>Dash Cam</span>
              </Link>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                Are you taking your Mustang out on a ride you want to remember
                forever? Film the action while driving with a dash-mounted
                camera! Our dash cam bundles offer full 140° viewing angles so
                you can capture the whole scene, wide dynamic ranges for correct
                exposure and contrast, and easy-to-use software for configuring
                settings. Additionally, the camera can add a layer of security
                to your Mustang with a motion detector and impact sensor that
                alerts it to start recording when in Parking Surveillance Mode
                so you are made aware of any incidents that occur while it’s
                parked. Be sure to follow the Owners Guide instructions for
                installation and securing guidelines for any dash-mount
                accessories!
              </span>
            </p>
            <p>&nbsp;</p>
            <h2>
              <span style={{fontWeight: 400}}>More Ford Mustang Parts</span>
            </h2>
            <p>
              <span style={{fontWeight: 400}}>
                As a Mustang owner, we know you’re always seeking even more ways
                to enhance your driving experience with the best Mustang
                accessories. From performance parts like crate engines, wheels,
                and chassis upgrades, there are numerous additional ways to
                personalize your Mustang for your exact needs. Ford trusted
                accessory products allow you to stay ready for whatever thrill
                ride awaits by adding features to your vehicle with the power
                and expertise of Ford. Set your sports car up for the ultimate
                adrenaline-filled experience today with our vast selection of
                available{" "}
              </span>
              <a href="">
                <span style={{fontWeight: 400}}>Ford Mustang parts</span>
                <span style={{fontWeight: 400}}> and accessories! </span>
              </a>
            </p>
          </article>
          <div className="article-share">
            <div
              className="social-icons "
              itemScope
              itemType="https://schema.org/Organization">
              <link itemProp="url" href="https://accessories.ford.com" />
              <h3 className="social-icons__share-title">Share Article</h3>
              <div className="social-icons__list-item">
                <a
                  href="https://twitter.com/share?url=https://accessories.ford.com/blogs/news/best-ford-mustang-accessories&amp;text=Best+Ford+Mustang+Accessories"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="16"
                    viewBox="0 0 20 16">
                    <g>
                      <g>
                        <path
                          fill="#102b4e"
                          d="M19.556 1.894a8.313 8.313 0 0 1-2.31.638A4.01 4.01 0 0 0 19.009.3a7.978 7.978 0 0 1-2.542.978A4.004 4.004 0 0 0 13.539 0c-2.22 0-4.007 1.814-4.007 4.038 0 .32.027.628.093.92C6.292 4.796 3.343 3.187 1.362.737a4.086 4.086 0 0 0-.55 2.04 4.05 4.05 0 0 0 1.782 3.356 3.938 3.938 0 0 1-1.812-.498v.045a4.049 4.049 0 0 0 3.212 3.967c-.326.09-.682.132-1.05.132-.258 0-.517-.014-.76-.068.52 1.598 1.994 2.774 3.746 2.812a8.02 8.02 0 0 1-4.97 1.722c-.33 0-.645-.015-.96-.056A11.23 11.23 0 0 0 6.15 16c7.378 0 11.41-6.154 11.41-11.488 0-.178-.005-.35-.014-.522a8.029 8.029 0 0 0 2.01-2.096z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Twitter</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.linkedin.com/sharing/share-offsite/?url=https://accessories.ford.com/blogs/news/best-ford-mustang-accessories"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M15.999 16.005v-.001h.004V10.08c0-2.898-.626-5.13-4.025-5.13-1.634 0-2.73.894-3.178 1.741h-.047v-1.47H5.53v10.783h3.356v-5.34c0-1.406.267-2.765 2.014-2.765 1.72 0 1.746 1.604 1.746 2.856v5.25z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M.29 5.24h3.2v10.764H.29z"></path>
                        </g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M1.89 0C.848 0 0 .843 0 1.882c0 1.04.847 1.9 1.89 1.9 1.045 0 1.892-.86 1.892-1.9A1.888 1.888 0 0 0 1.89 0z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">LinkedIn</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <a
                  href="https://www.facebook.com/sharer.php?u=https://accessories.ford.com/blogs/news/best-ford-mustang-accessories"
                  target="_blank"
                  rel="noopener"
                  className="share">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="16"
                    viewBox="0 0 8 16">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#102b4e"
                            d="M6.54 2.657H8V.113A18.857 18.857 0 0 0 5.872 0C3.766 0 2.324 1.325 2.324 3.76V6H0v2.844h2.324V16h2.849V8.845h2.23L7.757 6H5.172V4.04c0-.822.222-1.384 1.367-1.384z"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Facebook</span>
                </a>
              </div>
              <div className="social-icons__list-item">
                <span
                  className="share"
                  onClick={handleShareClick}
                  data-copy-to-clipboard="https://accessories.ford.com/blogs/news/best-ford-mustang-accessories">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16">
                    <g>
                      <g>
                        <path d="M1.75 8a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm10 5a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0zm4-10a2.75 2.75 0 0 1-4.849 1.777L5.682 7.39a2.76 2.76 0 0 1 .02 1.121l5.266 2.636a2.75 2.75 0 1 1-.67 1.342L5.031 9.853a2.75 2.75 0 1 1-.07-3.78L10.28 3.41A2.75 2.75 0 1 1 15.75 3zm-4 0a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0z"></path>
                      </g>
                    </g>
                  </svg>
                  <span className="visually-hidden">Share URL</span>
                </span>
              </div>
            </div>
          </div>
          <div className="article-pagination article-pagination--desktop">
            <Link
              className="article-pagination__item article-pagination__item--previous"
              to="/blogs/news/our-best-towing-accessories"  onClick={() => window.scrollTo(0, 0)}>
              <div className="article-pagination__arrow">
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/chevron-thin-white-right_60x.jpeg"
                  alt="Chevron White"  width={'32'} height={'62'}
                />
              </div>
              <div className="article-pagination__data">
                <div className="article-pagination__label">
                  Previous Article
                </div>
                <div className="article-pagination__title">
                  Our Best Towing Accessories
                </div>
              </div>
            </Link>
            <Link
              className="article-pagination__item article-pagination__item--next"
              to="/blogs/news/winter-car-camping-accessories-for-your-ford"  onClick={() => window.scrollTo(0, 0)}>
              <div className="article-pagination__data">
                <div className="article-pagination__label">Next Article</div>
                <div className="article-pagination__title">
                  Winter Car Camping Accessories for Your Ford
                </div>
              </div>
              <div className="article-pagination__arrow">
                <img
                  src="https://accessories.ford.com/gcs/B2C/ford/RoadAhead/RoadAhead_InternalBlogs/chevron-thin-white-right_60x.jpeg"
                  alt="Chevron White" width={'32'} height={'62'}
                />
              </div>
            </Link>
          </div>
          <div className="article-pagination article-pagination--mobile">
            <Link
              className="article-pagination__item article-pagination__item--previous"
              to="/blogs/news/our-best-towing-accessories"  onClick={() => window.scrollTo(0, 0)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="15"
                viewBox="0 0 8 15">
                <g>
                  <g transform="rotate(-270 4 7.5)">
                    <path
                      fill="#fff"
                      d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z"></path>
                  </g>
                </g>
              </svg>
              Prev Article
            </Link>

            <Link
              className="article-pagination__item article-pagination__item--next"
              to="/blogs/news/winter-car-camping-accessories-for-your-ford"  onClick={() => window.scrollTo(0, 0)}>
              Next Article
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="15"
                viewBox="0 0 8 15">
                <g>
                  <g transform="rotate(-270 4 7.5)">
                    <path
                      fill="#fff"
                      d="M-3 9.822a.957.957 0 0 0 0 1.39c.4.384 1.046.384 1.446 0l5.558-5.34 5.558 5.34c.4.383 1.047.383 1.447 0a.956.956 0 0 0 0-1.39L4.727 3.788a1.042 1.042 0 0 0-.723-.288c-.262 0-.523.096-.723.288z"></path>
                  </g>
                </g>
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BestFordMustangAccessories;
